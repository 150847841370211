import {SelectItem} from 'primeng/api';
import {AbstractAdvanceFilter} from './abstract-advance-filter';

export class NumberAdvanceFilter extends AbstractAdvanceFilter {

  public getValueFromChange(event, param) {
      return event.value;
  }

  public setFilterValue(filterValue: any): this {
    this.filterValue = filterValue;

    if (this.operatorValue === null && this.advanceFilter.operator && this.advanceFilter.operator.options) {
      this.setFirstNonEmptyOperatorValue();
    }

    return this;
  }

  public setFilterOperatorValue(operatorValue: string): this {
    this.operatorValue = operatorValue;

    if (this.operatorValue === null) {
      this.filterValue = null;
    }

    return this;
  }

  private setFirstNonEmptyOperatorValue(): void {
    this.advanceFilter.operator.options.subscribe((options: SelectItem[] = []) => {
      if (options.length > 0 && options.find((aOption) => aOption.value !== null)) {
        this.setFilterOperatorValue(options.find((aOption) => aOption.value !== null).value);
      }
    });
  }
}
