import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {CrmCompany, DeliveryItem, Invoice, PaymentType} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {DeliveryRepository} from '../repositories/delivery.repository';
import {BankAccount} from './bank-account';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('deliveries')
@CrossDependency(() => {
    return {
        contact: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: CrmCompany
        },
        bankAccount: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: BankAccount
        },
        paymentType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PaymentType
        },
        invoice: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Invoice
        },
        items: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: DeliveryItem
        }
    };
})
@Repository(DeliveryRepository)
export class Delivery {

    protected id: string;

    @AssociationOne(CrmCompany)
    protected contact;

    @AssociationOne(BankAccount)
    protected bankAccount;

    @AssociationOne(PaymentType)
    protected paymentType;

    @AssociationOne(Invoice)
    protected invoice;

    @AssociationMany(DeliveryItem)
    protected items;

    protected incoming = false;
    protected deliveryNumber: string;
    protected deliveryAddress: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected deliveryDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected currencyDate: Date;
    protected price: number;
    protected description: string;

    public getId(): string {
        return this.id;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getContact(): CrmCompany | undefined {
        return this.contact;
    }

    public setContact(contact: CrmCompany): Delivery {
        this.contact = contact;
        return this;
    }

    public setPaymentType(paymentType: PaymentType): Delivery {
        this.paymentType = paymentType;
        return this;
    }

    public getPaymentType(): PaymentType | undefined {
        return this.paymentType;
    }

    public setInvoice(invoice: Invoice): Delivery {
        this.invoice = invoice;
        return this;
    }

    public getInvoice(): Invoice|undefined {
        return this.invoice;
    }

    public getItems(): DeliveryItem[] | undefined {
        return this.items;
    }

    public setItems(items: DeliveryItem[] = []): Delivery {
        this.items = items;
        return this;
    }

    public getDeliveryNumber(): string {
        return this.deliveryNumber;
    }

    public setDeliveryNumber(deliveryNumber: string) {
        this.deliveryNumber = deliveryNumber;
        return this;
    }

    public isIncoming(): boolean {
        return this.incoming;
    }

    public setIncoming(incoming: boolean) {
        this.incoming = incoming;
        return this;
    }

    public getDeliveryDate(): Date | null {
        return this.deliveryDate;
    }

    public setDeliveryDate(deliveryDate: Date) {
        this.deliveryDate = deliveryDate;
        return this;
    }

    public getDeliveryAddress(): string {
        return this.deliveryAddress;
    }

    public setDeliveryAddress(deliveryAddress: string) {
        this.deliveryAddress = deliveryAddress;
        return this;
    }

    public getCurrencyDate(): Date | null {
        return this.currencyDate;
    }

    public setCurrencyDate(currencyDate: Date) {
        this.currencyDate = currencyDate;
        return this;
    }

    public getBankAccount(): BankAccount {
        return this.bankAccount;
    }

    public setBankAccount(bankAccount: BankAccount) {
        this.bankAccount = bankAccount;
        return this;
    }

    public getPrice(): number {
        return this.price;
    }

    public setPrice(price: number) {
        this.price = price;
        return this;
    }

    public getDescription(): string | null {
        return this.description;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }
}
