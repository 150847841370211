import {Component, OnInit} from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.scss']
})
export class HomeComponent implements OnInit {
    cols: any[];

    chartData: any;
    chartData2: any;
    events: any[];
    fullcalendarOptions: any;
    gmapOptions: any;
    overlays: any[];
    map: google.maps.Map;

    constructor() {
    }

    ngOnInit() {

        this.gmapOptions = {
            center: {lat: 45.24049962013143, lng: 19.8254299437537},
            zoom: 14
        };
        this.overlays = [
            new google.maps.Marker({position: {lat: 45.24049962013143, lng: 19.8254299437537}, title: 'Centrala'}),
            new google.maps.Marker({position: {lat: 45.2373498357657, lng: 19.82681157476298}, title: 'Dizajneri'})
        ];

        this.chartData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul'],
            datasets: [
                {
                    label: 'Zaposleni na određeno',
                    data: [56, 54, 60, 57, 59, 60],
                    fill: false,
                    borderColor: '#FFC107'
                },
                {
                    label: 'Zaposleni na neodređeno',
                    data: [66, 69, 70, 67, 67, 69],
                    fill: false,
                    borderColor: '#03A9F4'
                }
            ]
        };

        this.chartData2 = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul'],
            datasets: [
                {
                    label: 'Kašnjenja',
                    data: [23, 45, 23, 11, 16, 19, 15],
                    fill: false,
                    backgroundColor: '#FFC107'
                },
                {
                    label: 'Neprimerno ponašanje',
                    data: [4, 8, 11, 25, 21, 15, 19],
                    fill: true,
                    backgroundColor: '#03A9F4'
                }
            ]
        };

        this.fullcalendarOptions = {
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            header: {
                right: 'prev,next, today',
                left: 'title'
            }
        };
    }
}
