import {EntityManagerMetaDataService} from '../service/meta/entity-manager-meta-data.service';
import {Meta} from '../service/meta/meta';

export function EntityDefaultFilters(filters: any) {
    let target = null;

    return (...args) => {
        target = args[0].prototype;

        const metaService = new EntityManagerMetaDataService();

        if (!metaService.hasMetaDataProperty(target, Meta.META_ENTITY_DEFAULT_FILTERS)) {
            metaService.setMetaDataProperty(target, Meta.META_ENTITY_DEFAULT_FILTERS, {});
        }

        if (target && Object.keys(filters).length > 0) {
            metaService.createMetaData(target)
                .setMetaDataProperty(target, Meta.META_ENTITY_DEFAULT_FILTERS, filters);
        }
    };
}
