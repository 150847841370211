<div class="splash-screen"
     *ngIf="!isAppLoaded"
>
    <div class="splash-loader-container">
        <svg class="splash-loader" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="splash-path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
    </div>
</div>

<div class="layout-wrapper" (click)="onLayoutClick()"
     [hidden]="!isAppLoaded"
     [ngClass]="{'layout-wrapper-overlay-sidebar': isOverlay(),
                 'layout-wrapper-slim-sidebar': isSlim(),
                 'layout-wrapper-horizontal-sidebar': isHorizontal(),
                 'layout-wrapper-overlay-sidebar-active': overlayMenuActive,
                 'layout-wrapper-sidebar-inactive': staticMenuDesktopInactive,
                 'layout-wrapper-sidebar-mobile-active': staticMenuMobileActive}">
    <app-topbar *ngIf="isLoggedIn"></app-topbar>

    <div *ngIf="isLoggedIn" class="layout-sidebar" (click)="onMenuClick($event)"
         [ngClass]="{'layout-sidebar-dark': menuMode !== 'light',
         'layout-sidebar-gradient':  menuMode === 'gradient'}">
        <p-scrollPanel #layoutMenuScroller [style]="{height: '100%' }">
            <div class="sidebar-scroll-content">
                <app-inline-profile *ngIf="profileMode=='inline' && !isHorizontal()"></app-inline-profile>
                <app-menu [reset]="resetMenu"></app-menu>
            </div>
        </p-scrollPanel>
    </div>

    <div class="layout-main">
        <app-main-navigation *ngIf="isLoggedIn && isMainNavigationVisible"></app-main-navigation>

        <div #layoutMainContent class="layout-main-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<p-toast position="top-right"></p-toast>
<p-confirmDialog [style]="{width: '30vw'}"></p-confirmDialog>
<app-splash-screen></app-splash-screen>
