import {Injectable, Injector} from '@angular/core';
import {HttpRequest, HttpResponse} from '@angular/common/http';
import {EntityManagerConfigurator} from '../entity-manager.configurator';
import {cloneDeep} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EntityManagerModifierService {

  private configuration;

  public constructor(
    private injector: Injector
  ) {
    this.configuration = this.injector.get<EntityManagerConfigurator>(EntityManagerConfigurator);
  }

  public modifyRequest(entity: any, request: HttpRequest<any>): HttpRequest<any> {
    const modifiersTypes = this.configuration.providers || [];

    entity = cloneDeep(entity);

    for (const modifierType of modifiersTypes) {
      const modifier = this.injector.get(modifierType);

      if (typeof modifier.modifyRequest === 'function') {
        request = modifier.modifyRequest(entity, request);
      }
    }

    return request;
  }

  public modifyResponse(entity: any, request: HttpRequest<any>, response: HttpResponse<any>|any, caller: any): any {
    const modifiersTypes = this.configuration.providers || [];

    for (const modifierType of modifiersTypes) {
      const modifier = this.injector.get(modifierType);

      if (typeof modifier.modifyResponse === 'function') {
        response = modifier.modifyResponse(entity, request, response, caller);
      }
    }

    return response;
  }
}
