import {Route} from 'entity-manager';
import {Company} from './company';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Country} from './country';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {CrmCompanyType} from "./crm-company-type";

@Route('crm_companies')
@CrossDependency(() => {
    return {
        country: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Country
        },
        company: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Company
        },
        crmCompanyType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: CrmCompanyType
        }
    };
})
export class CrmCompany {

    @AssociationOne(Country)
    protected country: Country;

    @AssociationOne(Company)
    protected company: Company;

    @AssociationOne(CrmCompanyType)
    protected crmCompanyType: CrmCompanyType;

    protected id: string;

    protected name: string;

    protected name2: string;

    protected pib: string;

    protected postalCode: string;

    protected city: string;

    protected address: string;

    protected phoneNumber: string;

    protected faxNumber: string;

    protected bankAccount: string;

    protected contactInfo: string;

    protected contactMemo: string;

    protected comment: string;

    public identificationNumber: string;

    protected hasVat: boolean;

    protected isRetail: boolean;

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): CrmCompany {
        this.id = id;
        return this;
    }

    public getName(): string | undefined {
        return this.name;
    }

    public setName(name: string): CrmCompany {
        this.name = name;
        return this;
    }

    public getName2(): string | undefined {
        return this.name2;
    }

    public setName2(name2: string): CrmCompany {
        this.name2 = name2;
        return this;
    }

    public getPib(): string | undefined {
        return this.pib;
    }

    public setPib(pib: string): CrmCompany {
        this.pib = pib;
        return this;
    }

    public getIdentificationNumber(): string | undefined {
        return this.identificationNumber;
    }

    public setIdentificationNumber(identificationNumber: string): CrmCompany {
        this.identificationNumber = identificationNumber;
        return this;
    }

    public getPostalCode(): string | undefined {
        return this.postalCode;
    }

    public setPostalCode(postalCode: string): CrmCompany {
        this.postalCode = postalCode;
        return this;
    }

    public getCountry(): Country | undefined {
        return this.country;
    }

    public setCountry(country: Country): CrmCompany | undefined {
        this.country = country;
        return this;
    }

    public getCity(): string | undefined {
        return this.city;
    }

    public setCity(city: string): CrmCompany {
        this.city = city;
        return this;
    }

    public getAddress(): string | undefined {
        return this.address;
    }

    public setAddress(address: string): CrmCompany {
        this.address = address;
        return this;
    }

    public getPhoneNumber(): string | undefined {
        return this.phoneNumber;
    }

    public setPhoneNumber(phoneNumber: string): CrmCompany {
        this.phoneNumber = phoneNumber;
        return this;
    }

    public getFaxNumber(): string | undefined {
        return this.faxNumber;
    }

    public setFaxNumber(faxNumber: string): CrmCompany {
        this.faxNumber = faxNumber;
        return this;
    }

    public getBankAccount(): string | undefined {
        return this.bankAccount;
    }

    public setBankAccount(bankAccount: string): CrmCompany {
        this.bankAccount = bankAccount;
        return this;
    }

    public getContactInfo(): string | undefined {
        return this.contactInfo;
    }

    public setContactInfo(contactInfo: string): CrmCompany {
        this.contactInfo = contactInfo;
        return this;
    }

    public getContactMemo(): string | undefined {
        return this.contactMemo;
    }

    public setContactMemo(contactMemo: string): CrmCompany {
        this.contactMemo = contactMemo;
        return this;
    }

    public getComment(): string | undefined {
        return this.comment;
    }

    public setComment(comment: string): CrmCompany {
        this.comment = comment;
        return this;
    }

    public getHasVat(): boolean | undefined {
        return this.hasVat;
    }

    public setHasVat(hasVat: boolean): CrmCompany {
        this.hasVat = hasVat;
        return this;
    }

    public getIsRetail(): boolean | undefined {
        return this.isRetail;
    }

    public setIsRetail(isRetail: boolean): CrmCompany {
        this.isRetail = isRetail;
        return this;
    }

    public getCompany(): Company | undefined {
        return this.company;
    }

    public setCompany(company: Company): CrmCompany {
        this.company = company;
        return this;
    }

    public getCrmCompanyType(): CrmCompanyType | undefined {
        return this.crmCompanyType;
    }

    public setCrmCompanyType(crmCompanyType: CrmCompanyType): CrmCompany | undefined {
        this.crmCompanyType = crmCompanyType;
        return this;
    }

}
