<div class="login-body">
    <div class="login-topbar">
        <a routerLink="/login" class="logo">
            <img src="../../../../assets/layout/images/logo/bizhub.svg" />
        </a>
    </div>

    <form [formGroup]="form" (ngSubmit)="doLogin()" class="form-signin">
        <div class="login-panel ui-fluid">
            <div class="ui-g">
                <div class="ui-g-12 login-icon-container">
                    <img class="login-icon" src="../../../../assets/layout/images/icon-login.png"
                         alt="california-layout"/>
                </div>
                <div class="ui-g-12 ui-lg-12 ui-md-12 logo-container">
                    <div class="login-detail">
                        <span>{{ 'LOGIN.WELCOME' | translate }}</span>
                    </div>
                </div>
                <div class="ui-g-12 ui-lg-12 ui-md-12" *ngIf="form.errors?.loginError">
                    <div class="input-wrapper">
                        <span class="error">{{ form.errors.loginError }}</span>
                    </div>
                </div>
                <div class="ui-g-12 ui-lg-12 ui-md-12">
                    <div class="input-wrapper">
                        <label>{{ 'LOGIN.USER_NAME' | translate }}</label>
                        <input appDisableAutofill  formControlName="username" type="username" autocomplete="username"
                               required="required"
                               class="ui-inputtext ui-corner-all ui-state-default ui-widget"
                               [ngClass]="{'ui-state-filled': form.controls.username.value}"
                               data-selenium-id="login-email"
                        >
                    </div>
                </div>
                <div class="ui-g-12 ui-lg-12 ui-md-12">
                    <div class="input-wrapper">
                        <label>{{ 'LOGIN.PASSWORD' | translate }}</label>
                        <input appDisableAutofill  formControlName="password" type="password" required="required"
                               autocomplete="current-password"
                               class="ui-inputtext ui-corner-all ui-state-default ui-widget"
                               [ngClass]="{'ui-state-filled': form.controls.password.value}"
                               data-selenium-id="login-password"
                        >
                    </div>
                </div>

<!--                <div class="ui-g-12 ui-lg-12 ui-md-12 chkbox-container">-->
<!--                    <div class="remember-me">-->
<!--                        <input appDisableAutofill  type="checkbox" class="ui-chkbox ui-chkbox-box">-->
<!--                        <span>{{ 'LOGIN.REMEMBER_ME' | translate }}</span>-->
<!--                        <a href="#">{{ 'LOGIN.FORGOT_PASSWORD' | translate }}</a>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="ui-g-12 ui-lg-12 ui-md-12 button-container">
                    <button type="submit"
                            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only">
                        <span class="ui-button-text ui-c">{{ 'LOGIN.SIGN_IN' | translate }}</span>
                    </button>

                </div>
            </div>
        </div>
    </form>
</div>
<!-- /.login-body -->
