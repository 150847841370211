import { AbstractActionHandler } from './abstract-action-handler';
import { DashboardBoxWrapperComponent } from '../../component/dashboard-box-wrapper/dashboard-box-wrapper.component';

export class BoxEditedActionHandler extends AbstractActionHandler {

    public handleAction() {
        const dashboardBoxWrapper = this.getParameterValue('dashboardBoxWrapper'),
            dashboardRendererService = this.dashboardComponent.getDashboardRendererService();

        if (dashboardBoxWrapper instanceof DashboardBoxWrapperComponent) {
            const toEditDashboardBox = dashboardBoxWrapper.getBoxComponent().getDashboardBox();

            // use renderer service to remove box!!, because it needs to remove box AND edit
            dashboardBoxWrapper.getBoxViewContainerRef().remove();
            dashboardRendererService.renderBoxComponentInWrapperComponent(
                dashboardBoxWrapper.getDashboardBox(),
                dashboardBoxWrapper
            );
        }
    }

    public getPayload() {
        const dashboardBoxWrapper = this.getParameterValue('dashboardBoxWrapper'),
            dashboardBox = dashboardBoxWrapper.getBoxComponent().getDashboardBox();

        return dashboardBox;
    }
}