import {AbstractAdvanceFilter} from './abstract-advance-filter';

export class MultiselectAdvanceFilter extends AbstractAdvanceFilter {

    public getValueFromChange(event, param) {
        return event;
    }

    public buildFilterParams(params: any, filter): void {
        const filterValue = this.getFilterValue();
        for (let i = 0; i < filterValue.length; i++) {
            params[`${this.advanceFilter.key}[${i}]`] = filterValue[i];
        }
    }
}
