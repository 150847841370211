import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Vehicle} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';

@Route('vehicle_licence_plates')

@CrossDependency(() => {
    return {
        vehicle: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vehicle
        }
    };
})
export class VehicleLicencePlate {

    @AssociationOne(Vehicle)
    protected vehicle: Vehicle;
    protected id: string;
    protected licencePlate: string;

    public setVehicle(vehicle: Vehicle): this {
        this.vehicle = vehicle;
        return this;
    }

    public getVehicle(): Vehicle|undefined {
        return this.vehicle;
    }

    public setLicencePlate(licencePlate: string): this {
        this.licencePlate = licencePlate;
        return this;
    }

    public getLicencePlate(): string | undefined {
        return this.licencePlate;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }
}
