import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Invoice} from '../entities/invoice';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {EmployeeMonthlyPayment} from '../entities/employee-monthly-payment';

@Injectable({
    providedIn: 'root'
})
export class EmployeeMonthlyPaymentRepository extends EntityRepository {

    public regulate(entryMonth: number, entryYear: number): Observable<void> {
        const route = this.meta.getMetaDataProperty(new EmployeeMonthlyPayment(), Meta.META_ROUTE);

        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}${route}/regulate/year/${entryYear}/month/${entryMonth}`,
            {}
        ).pipe(
            map((invoice: any) => {
                return this.parser.getParser().parse(new Invoice(), invoice);
            })
        );
    }

    public getTotal(params: any = {}): Observable<any[]> {
        const route = this.meta.getMetaDataProperty(new EmployeeMonthlyPayment(), Meta.META_ROUTE);

        return this.connection.get(environment.ENTITY_MANAGER_URL_PREFIX + route + '/total', {params})
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    public getMinDatePayment(params: any = {}): Observable<any> {
        const route = this.meta.getMetaDataProperty(new EmployeeMonthlyPayment(), Meta.META_ROUTE);

        return this.connection.get(environment.ENTITY_MANAGER_URL_PREFIX + route + '/get_min_date', {params})
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }
}
