import {Route} from 'entity-manager';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {BankAccount} from './bank-account';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {SoftwareAccess} from './entities';
import {MediaObject} from './entities';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {AssociationManyToMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many-to-many';
import {AssociateMonthlyPayment} from './associate-monthly-payment';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {AssociateRepository} from '../repositories/associate.repository';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';

@Route('associates')
@CrossDependency(() => {
    return {
        softwareAccesses: {
            mapping: Meta.META_ASSOCIATIONS_MANY_TO_MANY,
            entity: SoftwareAccess
        },
        bankAccount: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: BankAccount
        },
        medias: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: MediaObject
        },
        currentMonthlyPayment: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: AssociateMonthlyPayment
        }
    };
})
@Repository(AssociateRepository)
@EntityDefaultFilters({
    isDismissed: false
})
export class Associate {
    protected id: string;
    protected firstName: string;
    protected lastName: string;
    protected companyName: string;
    protected address: string;
    protected city: string;
    protected phoneNumber: string;
    protected email: string;
    protected cooperationNotice: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected cooperationFromDate: Date;

    @AssociationOne(BankAccount)
    protected bankAccount: BankAccount;

    @AssociationMany(MediaObject)
    protected medias: MediaObject[];

    @AssociationManyToMany(SoftwareAccess)
    protected softwareAccesses: SoftwareAccess[];

    @AssociationOne(AssociateMonthlyPayment)
    protected currentMonthlyPayment: AssociateMonthlyPayment;

    protected hasActivePaymentDeductionMediGroupContract: boolean;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string|undefined {
        return this.id;
    }

    public setFirstName(firstName: string): this {
        this.firstName = firstName;
        return this;
    }

    public getFirstName(): string|undefined {
        return this.firstName;
    }

    public setLastName(lastName: string): this {
        this.lastName = lastName;
        return this;
    }

    public getLastName(): string|undefined {
        return this.lastName;
    }

    public getFullName(): string {
        return this.getFirstName() + ' ' + this.getLastName();
    }

    public setCompanyName(companyName: string): this {
        this.companyName = companyName;
        return this;
    }

    public getCompanyName(): string|undefined {
        return this.companyName;
    }

    public setAddress(address: string): this {
        this.address = address;
        return this;
    }

    public getAddress(): string|undefined {
        return this.address;
    }

    public setCity(city: string): this {
        this.city = city;
        return this;
    }

    public getCity(): string|undefined {
        return this.city;
    }

    public setPhoneNumber(phoneNumber: string): this {
        this.phoneNumber = phoneNumber;
        return this;
    }

    public getPhoneNumber(): string|undefined {
        return this.phoneNumber;
    }

    public setEmail(email: string): this {
        this.email = email;
        return this;
    }

    public getEmail(): string|undefined {
        return this.email;
    }

    public setCooperationNotice(cooperationNotice: string): this {
        this.cooperationNotice = cooperationNotice;
        return this;
    }

    public getCooperationNotice(): string|undefined {
        return this.cooperationNotice;
    }

    public setCooperationFromDate(cooperationFromDate: Date): this {
        this.cooperationFromDate = cooperationFromDate;
        return this;
    }

    public getCooperationFromDate(): Date|undefined {
        return this.cooperationFromDate;
    }

    public setSoftwareAccesses(softwareAccesses: SoftwareAccess[]): Associate {
        this.softwareAccesses = softwareAccesses;
        return this;
    }

    public getSoftwareAccesses(): SoftwareAccess[] {
        return this.softwareAccesses;
    }

    public setMedias(medias: MediaObject[]): this {
        this.medias = medias;
        return this;
    }

    public getMedias(): MediaObject[] {
        return this.medias;
    }

    public setBankAccount(bankAccount: BankAccount): Associate {
        this.bankAccount = bankAccount;
        return this;
    }

    public getBankAccount(): BankAccount {
        return this.bankAccount;
    }

    public setCurrentMonthlyPayment(currentMonthlyPayment: AssociateMonthlyPayment): this {
        this.currentMonthlyPayment = currentMonthlyPayment;
        return this;
    }

    public getCurrentMonthlyPayment(): AssociateMonthlyPayment|undefined {
        return this.currentMonthlyPayment;
    }

    public setHasActivePaymentDeductionMediGroupContract(hasActivePaymentDeductionMediGroupContract: boolean): this {
        this.hasActivePaymentDeductionMediGroupContract = hasActivePaymentDeductionMediGroupContract;
        return this;
    }

    public getHasActivePaymentDeductionMediGroupContract(): boolean|undefined {
        return this.hasActivePaymentDeductionMediGroupContract;
    }
}
