import { Injectable } from '@angular/core';
import { Parameter } from '../parameter';
import { DashboardAction } from '../dashboard-action';
import { DashboardComponent } from '../../component/dashboard/dashboard.component';
import { AbstractActionHandler } from '../action-handlers/abstract-action-handler';
import { AddBoxActionHandler } from '../action-handlers/add-box-action-handler';
import { BoxAddedActionHandler } from '../action-handlers/box-added-action-handler';
import { RemoveBoxActionHandler } from '../action-handlers/remove-box-action-handler';
import { BoxRemovedActionHandler } from '../action-handlers/box-removed-action-handler';
import { BoxEditedActionHandler } from '../action-handlers/box-edited-action-handler';
import { LayoutChangedActionHandler } from '../action-handlers/layout-changed-action-handler';

@Injectable()
export class DashboardActionService {

    private dashboardComponent: DashboardComponent = null;

    public on(action: DashboardAction, parameters: Parameter[] = []) {
        const handler = this.createActionHandler(action, parameters);
        handler.handleAction();

        if (action) {
            this.getDashboardComponent().actionDone.emit({
                action,
                payload: handler.getPayload()
            });
        }
    }

    private createActionHandler(action: DashboardAction, parameters: Parameter[] = []): AbstractActionHandler|null {
        let actionHandler = null;

        switch (action) {
            case DashboardAction.AddBox:
                actionHandler = new AddBoxActionHandler();
                break;
            case DashboardAction.BoxAdded:
                actionHandler = new BoxAddedActionHandler();
                break;
            case DashboardAction.RemoveBox:
                actionHandler = new RemoveBoxActionHandler();
                break;
            case DashboardAction.BoxRemoved:
                actionHandler = new BoxRemovedActionHandler();
                break;
            case DashboardAction.BoxEdited:
                actionHandler = new BoxEditedActionHandler();
                break;
            case DashboardAction.LayoutChanged:
                actionHandler = new LayoutChangedActionHandler();
                break;
        }

        if (actionHandler === null) {
            throw new Error(`ActionHandler for ${action} not found!`);
        }

        actionHandler.setParameters(parameters)
            .setDashboardComponent(this.dashboardComponent)
            .setDashboardActionService(this);

        return actionHandler;
    }

    public setDashboardComponent(dashboardComponent: DashboardComponent): DashboardActionService {
        this.dashboardComponent = dashboardComponent;
        return this;
    }

    public getDashboardComponent(): DashboardComponent {
        return this.dashboardComponent;
    }

}
