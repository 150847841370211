import {TableColumn} from '../../shared/table-column';
import {AbstractEditor} from './abstract-editor';

export class NumberEditor extends AbstractEditor {

    public onEdit(event: any, column: TableColumn, entity: object): void {
        entity[column.key] = event.value;
    }

    public isEditFinished(event: any, column: TableColumn, entity: object): boolean {
        const originalEvent = event?.event;

        if (originalEvent && originalEvent instanceof MouseEvent) {
            return true;
        }

        if (originalEvent && originalEvent instanceof FocusEvent && originalEvent.type === 'blur') {
            return true;
        }

        return originalEvent && (originalEvent.code === 'Enter' || originalEvent.code === 'NumpadEnter' || originalEvent.code === 'Tab');
    }
}
