import {PropertyPayloadModifierInterface} from '../../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier-interface';
import * as moment from 'moment';

export class DatetimeWithoutTimezonePropertyPayloadModifier implements PropertyPayloadModifierInterface {

    public modify(property: string, entity: any) {
        const value = entity[property];

        if (typeof value !== 'undefined' && value instanceof Date && value.toString() === 'Invalid Date') {
            entity[property] = null;
            return;
        }

        if (typeof value === 'string') {
            const date = moment(value);

            entity[property] = date.format('YYYY-MM-DDTHH:mm:ss');
        }

        if (value instanceof Date) {
            entity[property] = moment(value).format('YYYY-MM-DDTHH:mm:ss');
        }
    }
}
