import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Employee, WorkNoteType, EmployeeMonthlyPayment} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from "../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier";
import {StringPropertyPayloadModifier} from "../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier";

export enum WorkNoteDeductionSubTypeEnum {
    SubTypeWorkViolationMonthlySalaryDeductionPercentage = 'workViolationMonthlySalaryDeductionPercentage'
}

@Route('work_note_deductions')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteType
        },
        employeeMonthlyPayment: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeMonthlyPayment
        }
    };
})
export class WorkNoteDeduction {

    @AssociationOne(Employee)
    protected employee;

    @AssociationOne(WorkNoteType)
    protected type;

    @AssociationOne(EmployeeMonthlyPayment)
    protected employeeMonthlyPayment;

    protected id: string;
    protected entryYear: number;
    protected entryMonth: number;
    protected deductionPercentage: number;
    protected subType: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected deductionAbsolute: string;

    public setEmployee(employee: Employee): WorkNoteDeduction {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setType(type: WorkNoteType): WorkNoteDeduction {
        this.type = type;
        return this;
    }

    public getType(): WorkNoteType | undefined {
        return this.type;
    }

    public setEmployeeMonthlyPayment(employeeMonthlyPayment: EmployeeMonthlyPayment): WorkNoteDeduction {
        this.employeeMonthlyPayment = employeeMonthlyPayment;
        return this;
    }

    public getEmployeeMonthlyPayment(): EmployeeMonthlyPayment | undefined {
        return this.employeeMonthlyPayment;
    }

    public setId(id: string): WorkNoteDeduction {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setEntryYear(value: number) {
        this.entryYear = value;
        return this;
    }

    public getEntryYear(): number | undefined {
        return this.entryYear;
    }

    public setEntryMonth(value: number) {
        this.entryMonth = value;
        return this;
    }

    public getEntryMonth(): number | undefined {
        return this.entryMonth;
    }

    public setDeductionPercentage(value: number) {
        this.deductionPercentage = value;
        return this;
    }

    public getDeductionPercentage(): number | undefined {
        return this.deductionPercentage;
    }

    public setSubType(subType: string) {
        this.subType = subType;
        return this;
    }

    public getSubType(): string | undefined {
        return this.subType;
    }

    public setDeductionAbsolute(deductionAbsolute: string) {
        this.deductionAbsolute = deductionAbsolute;
        return this;
    }

    public getDeductionAbsolute(): string | undefined {
        return this.deductionAbsolute;
    }
}
