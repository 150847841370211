import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Workflow} from './workflow';
import {Runner} from './runner';
import {WorkflowAction} from './workflow-action';
import {WorkflowCondition} from './workflow-condition';
import {WorkflowActionStatus} from './workflow-action-status';
import {WorkflowConditionStatus} from './workflow-condition-status';
import {WorkflowActionInterface} from './workflow-action.interface';
import {WorkflowConditionInterface} from './workflow-condition.interface';

export class WorkflowRunner {

    public constructor(
        private workflow: Workflow
    ) {
        this.workflow = workflow;
    }

    public run(): Observable<WorkflowActionStatus|WorkflowConditionStatus> {
        return this.executeRunner(this.workflow.getRunnerAt(0));
    }

    private executeRunner(runner: Runner): Observable<WorkflowActionStatus|WorkflowConditionStatus> {
        const object: any = runner.object;

        if (object instanceof WorkflowAction) {
            return this.executeActionRunner(runner);
        }

        if (object instanceof WorkflowCondition) {
            return this.executeConditionRunner(runner);
        }

        if (object instanceof Workflow) {
            return this.executeWorkflowRunner(runner);
        }

        return this.executeFinish(runner);
    }

    private executeActionRunner(runner: Runner): Observable<WorkflowActionStatus|WorkflowConditionStatus> {
        const nextRunner = this.workflow.getNextRunner(runner);

        if (runner.context === 'startWith' || runner.context === 'then') {
            return this.doExecuteActionRunner(runner, nextRunner);
        }

        const previousConditionRunner = this.workflow.getPreviousConditionRunner(runner);

        if (runner.context === 'isTrue' && previousConditionRunner && previousConditionRunner.context === 'if' &&
            previousConditionRunner.status &&
            previousConditionRunner.status.status === true
        ) {
            return this.doExecuteActionRunner(runner, nextRunner);
        }

        if (runner.context === 'isFalse' && previousConditionRunner && previousConditionRunner.context === 'if' &&
            previousConditionRunner.status &&
            previousConditionRunner.status.status === false
        ) {
            return this.doExecuteActionRunner(runner, nextRunner);
        }

        if (this.workflow.hasNextRunner(runner)) {
            return this.executeRunner(nextRunner);
        }

        return this.executeFinish(
            this.workflow.getPreviousExecutedRunner(runner)
        );
    }

    private executeConditionRunner(runner: Runner): Observable<WorkflowConditionStatus> {
        const nextRunner = this.workflow.getNextRunner(runner),
            runnerObject: WorkflowCondition|any = runner.object;

        return runnerObject.run().pipe(
            catchError((error) => {
                return error;
            }),
            switchMap((status: WorkflowConditionStatus) => {
                runner.status = status;

                if (this.workflow.hasNextRunner(runner)) {
                    return this.executeRunner(nextRunner);
                }

                return this.executeFinish(runner);
            })
        );
    }

    private executeWorkflowRunner(runner: Runner): Observable<WorkflowActionStatus|WorkflowConditionStatus> {
        const nextRunner = this.workflow.getNextRunner(runner),
            previousConditionRunner = this.workflow.getPreviousConditionRunner(runner);

        if (runner.context === 'startWith' || runner.context === 'then') {
            return this.doExecuteWorkflowRunner(runner, nextRunner);
        }

        if (runner.context === 'isTrue' && previousConditionRunner && previousConditionRunner.context === 'if' &&
            previousConditionRunner.status &&
            previousConditionRunner.status.status === true
        ) {
            return this.doExecuteWorkflowRunner(runner, nextRunner);
        }

        if (runner.context === 'isFalse' && previousConditionRunner && previousConditionRunner.context === 'if' &&
            previousConditionRunner.status &&
            previousConditionRunner.status.status === false
        ) {
            return this.doExecuteWorkflowRunner(runner, nextRunner);
        }

        if (this.workflow.hasNextRunner(runner)) {
            return this.executeRunner(nextRunner);
        }

        return this.executeFinish(
            this.workflow.getPreviousExecutedRunner(runner)
        );
    }

    private executeFinish(runner: Runner): Observable<WorkflowActionStatus|WorkflowConditionStatus> {
        return of(runner.status);
    }

    private doExecuteActionRunner(runner: Runner, nextRunner: Runner): Observable<WorkflowActionStatus|WorkflowConditionStatus> {
        const runnerObject: WorkflowAction|any = runner.object;

        return runnerObject.run().pipe(
            catchError((error) => {
                return error;
            }),
            switchMap((status: WorkflowActionStatus) => {
                runner.status = status;
                runner.isExecuted = true;

                if (status.stop) {
                    return this.executeFinish(runner);
                }

                if (this.workflow.hasNextRunner(runner)) {
                    return this.executeRunner(nextRunner);
                }

                return this.executeFinish(runner);
            })
        );
    }

    private doExecuteWorkflowRunner(runner: Runner, nextRunner: Runner): Observable<WorkflowActionStatus|WorkflowConditionStatus> {
        const runnerObject: Workflow|any = runner.object,
            workflowRunner = new WorkflowRunner(runnerObject);

        return workflowRunner.run().pipe(
            catchError((error) => {
                return error;
            }),
            switchMap((status: WorkflowActionStatus|WorkflowConditionStatus) => {
                runner.status = status;

                if (this.workflow.hasNextRunner(runner)) {
                    return this.executeRunner(nextRunner);
                }

                return this.executeFinish(runner);
            })
        );
    }
}
