import { Route } from 'entity-manager';

@Route('units')
export class Unit {
    protected code: string;
    protected id: string;
    protected name: string;
    protected description: string;
    protected isDefault: boolean;

    public getCode(): string | undefined {
        return this.code;
    }

    public setCode(code: string): Unit {
        this.code = code;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): Unit {
        this.id = id;
        return this;
    }

    public getName(): string | undefined {
        return this.name;
    }

    public setName(name: string): Unit {
        this.name = name;
        return this;
    }

    public getDescription(): string | undefined {
        return this.description;
    }

    public setDescription(description: string): Unit {
        this.description = description;
        return this;
    }

    public setIsDefault(isDefault: boolean): Unit {
        this.isDefault = isDefault;
        return this;
    }

    public getIsDefault(): boolean|undefined {
        return this.isDefault;
    }
}
