<div class="container">
    <p-card header="{{header}}">
        <div class="ui-g"
             *ngIf="context === 'main'"
        >
            <ng-content select="[main]"></ng-content>
        </div>

        <div class="ui-g"
             *ngIf="context === 'secondary'"
        >
            <ng-content select="[secondary]"></ng-content>
        </div>
    </p-card>
</div>
