import { Component } from '@angular/core';
import { DashboardBoxWrapperComponent } from '../dashboard-box-wrapper/dashboard-box-wrapper.component';

@Component({
    selector: 'app-dashboard-box-tile-wrapper',
    templateUrl: './dashboard-box-tile-wrapper.component.html',
    styleUrls: ['./dashboard-box-tile-wrapper.component.scss']
})
export class DashboardBoxTileWrapperComponent extends DashboardBoxWrapperComponent {

}
