import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SplashScreenService} from '../../services/splash-screen.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit{
    public isActive = false;

    public constructor(
        protected splashScreenService: SplashScreenService,
        protected cdr: ChangeDetectorRef
    ) {

    }

    ngOnInit() {
        this.splashScreenService.splashScreenChanged.subscribe((value: boolean) => {
            this.isActive = value;
            this.cdr.detectChanges();
        });
    }
}
