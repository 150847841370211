import {Route} from 'entity-manager';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {StockInfoRepository} from '../repositories/stock-info.repository';
import {Product} from './product';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('stock_infos')
@CrossDependency(() => {
    return {
        product: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Product
        }
    };
})
@Repository(StockInfoRepository)
export class StockInfo {

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected date: Date;
    protected totalQuantity: number;
    protected incomeQuantity: number;
    protected outcomeQuantity: number;

    @AssociationOne(Product)
    protected product;

    protected id: string;

    public setProduct(product: Product): StockInfo {
        this.product = product;
        return this;
    }

    public getProduct(): Product | undefined {
        return this.product;
    }

    public getDate(): Date|null {
       return this.date;
    }

    public setDate(date: Date): StockInfo {
        this.date = date;
        return this;
    }

    public getTotalQuantity(): number {
        return this.totalQuantity;
    }

    public setTotalQuantity(totalQuantity: number): StockInfo {
        this.totalQuantity = totalQuantity;
        return this;
    }

    public getIncomeQuantity(): number {
        return this.incomeQuantity;
    }

    public setIncomeQuantity(incomeQuantity: number): StockInfo {
        this.incomeQuantity = incomeQuantity;
        return this;
    }

    public getOutcomeQuantity(): number {
        return this.outcomeQuantity;
    }

    public setOutcomeQuantity(outcomeQuantity: number): StockInfo {
        this.outcomeQuantity = outcomeQuantity;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setId(id: string): StockInfo {
        this.id = id;
        return this;
    }
}
