import {Employee} from './entities';
import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

@Route('employee_emergency_contacts')
@CrossDependency( () => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class EmployeeEmergencyContact {

    protected id: string;

    @AssociationOne(Employee)
    protected employee: Employee;

    protected firstName = '';
    protected lastName = '';
    protected phone = '';
    protected mobilePhone = '';

    public getId(): string {
        return this.id;
    }

    public setId(id: string): EmployeeEmergencyContact {
        this.id = id;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public setEmployee(employee: Employee): EmployeeEmergencyContact {
        this.employee = employee;
        return this;
    }

    public getFirstName(): string | undefined {
        return this.firstName;
    }

    public setFirstName(firstName: string|null): EmployeeEmergencyContact {
        this.firstName = firstName;
        return this;
    }

    public getLastName(): string | undefined {
        return this.lastName;
    }

    public setLastName(lastName: string|null): EmployeeEmergencyContact {
        this.lastName = lastName;
        return this;
    }

    public getPhone(): string | undefined {
        return this.phone;
    }

    public setPhone(phone: string|null): EmployeeEmergencyContact {
        this.phone = phone;
        return this;
    }

    public getMobilePhone(): string | undefined {
        return this.mobilePhone;
    }

    public setMobilePhone(mobilePhone: string|null): EmployeeEmergencyContact {
        this.mobilePhone = mobilePhone;
        return this;
    }
}
