import {Injectable} from '@angular/core';
import {AbstractFilter} from './filter/abstract-filter';
import {TableComponent} from '../table.component';
import {TableAdvanceFilter} from '../shared/table-advance-filter';
import {AbstractAdvanceFilter} from './advance-filter/abstract-advance-filter';
import {AutocompleteAdvanceFilter} from './advance-filter/autocomplete-advance-filter';
import {TextAdvanceFilter} from './advance-filter/text-advance-filter';
import {CalendarAdvanceFilter} from './advance-filter/calendar-advance-filter';
import {CalendarRangeAdvanceFilter} from './advance-filter/calendar-range-advance-filter';
import {CheckboxAdvanceFilter} from './advance-filter/checkbox-advance-filter';
import {NumberAdvanceFilter} from './advance-filter/number-advance-filter';
import {DropdownAdvanceFilter} from './advance-filter/dropdown--advance-filter';
import {TriStateCheckboxAdvanceFilter} from './advance-filter/tri-state-checkbox-advance-filter';
import {CalendarMultiDateRangeFilter} from './filter/calendar-multi-date-range-filter';
import {CalendarMultiDateRangeAdvanceFilter} from './advance-filter/calendar-multi-date-range-advance-filter';
import {MultiselectAdvanceFilter} from './advance-filter/multiselect-advance-filter';

@Injectable()
export class TableAdvanceFilterService {

    public filters: {[filterKey: string]: AbstractAdvanceFilter } = {};

    protected table: TableComponent = null;

    public constructor() {
      this.defineToKeyValue();
    }

    public getFilterOperatorValue(advanceFilter: TableAdvanceFilter): any {
      const filter = this.filters[this.getFilterKey(advanceFilter)];

      return filter ? filter.getFilterOperatorValue() : null;
    }

    public getFilterValue(advanceFilter: TableAdvanceFilter, param?: any): any {
      const filter = this.filters[this.getFilterKey(advanceFilter)];

      return filter ? filter.getFilterValue(param) : null;
    }

    public getOrCreateFilter(advanceFilter: TableAdvanceFilter): AbstractAdvanceFilter {
      const filter = this.filters[this.getFilterKey(advanceFilter)];

      return filter ? filter : this.getFilter(advanceFilter);
    }

    public getFilter(advanceFilter: TableAdvanceFilter): AbstractAdvanceFilter {
        let filter = null;

        switch (advanceFilter.type) {
            case 'calendar':
                filter = new CalendarAdvanceFilter();
                break;
            case 'calendar-range':
                filter = new CalendarRangeAdvanceFilter();
                break;
            case 'calendar-multi-date-range':
                filter = new CalendarMultiDateRangeAdvanceFilter();
                break;
            case 'checkbox':
                filter = new CheckboxAdvanceFilter();
                break;
            case 'triStateCheckbox':
                filter = new TriStateCheckboxAdvanceFilter();
                break;
            case 'number':
                filter = new NumberAdvanceFilter();
                break;
            case 'dropdown':
                filter = new DropdownAdvanceFilter();
                break;
            case 'autocomplete':
                filter = new AutocompleteAdvanceFilter();
                break;
            case 'multiselect':
                filter = new MultiselectAdvanceFilter();
                break;
            default:
                filter = new TextAdvanceFilter();
        }
        filter.advanceFilter = advanceFilter;

        return filter;
    }

    public addFilter(filterKey: string, filter: AbstractAdvanceFilter): this {
      this.filters[filterKey] = filter;

      return this;
    }

    public removeFilter(filterKey: string): this {
      delete this.filters[filterKey];

      return this;
    }

    public hasFilter(filterKey: string): boolean {
        return typeof this.filters[filterKey] !== 'undefined' && this.filters[filterKey] !== null;
    }

    public addFilters(filters: {[filterKey: string]: AbstractAdvanceFilter}): this {

      for (const filterKey in filters) {
        if (filters.hasOwnProperty(filterKey) && filters[filterKey] instanceof AbstractAdvanceFilter) {
          const filter: AbstractAdvanceFilter = filters[filterKey];

          this.addFilter(filterKey, filter);
        }
      }

      return this;
    }

    public getFilters(): {[filterKey: string]: AbstractFilter|any } {
      return this.filters;
    }

    public setTable(table: TableComponent): this {
        this.table = table;
        return this;
    }

    public getTable(): TableComponent {
        return this.table;
    }

    public getFilterKey(filter: TableAdvanceFilter): string {
      return filter.key;
    }

    private defineToKeyValue(): void {
      Object.defineProperty(this.filters, 'toKeyValue', {
        value: function() {
          const filterParams = {};

          for (const filterKey in this) {
            if (this.hasOwnProperty(filterKey)) {
              const filter: AbstractFilter = this[filterKey];

              let filterValue = filter.getFilterParsedValue();

              if (filter.getFilterParsedOperator() !== null) {
                filterValue = {
                  operator: filter.getFilterParsedOperator(),
                  value: filter.getFilterParsedValue()
                };
              }

              filterParams[filterKey] = filterValue;
            }
          }
          return filterParams;
        },
        enumerable: false
      });
    }
}
