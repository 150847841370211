<a *ngIf="isText()"
   href="{{getDownloadUrl()}}"
   target="_blank"
   pTooltip="{{mediaObject.getName()}}"
   tooltipStyleClass="tooltip"
   tooltipPosition="top"
>
    <i class="fa fa-file-pdf-o"></i>
    {{mediaObject.getName()}} - {{mediaObject.getCreatedAt() | appDate }}
</a>

<div *ngIf="isImage() && showCreatedAt">
    {{mediaObject.getName()}} - {{mediaObject.getCreatedAt() | appDate }}
</div>
<img
    *ngIf="isImage()"
    src="{{getDownloadUrl()}}"
    alt="{{mediaObject.getName()}}"
>
