import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {InvoiceItem} from '../entities/invoice-item';
import {Invoice} from "../entities/invoice";

@Injectable({
    providedIn: 'root'
})
export class InvoiceItemRepository extends EntityRepository {

    public sortEntities(entities: InvoiceItem[] = []): Observable<any> {
        const ids = [];

        for (const entity of entities) {
            ids.push(entity.getId());
        }

        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}invoice_items/sort`,
            ids
        ).pipe(
            map(() => {
                return null;
            })
        );
    }
}
