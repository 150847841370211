import {Route} from 'entity-manager';

export enum WorkNoteStateEnum {
    PendingApproval = 'pendingApproval',
    Approved = 'approved',
    Declined = 'declined'
}

@Route('work_note_states')
export class WorkNoteState {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;

    public setId(id: string): WorkNoteState {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string): WorkNoteState {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string): WorkNoteState {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string): WorkNoteState {
        this.description = description;
        return this;
    }

    public getDescription() {
        return this.description;
    }
}
