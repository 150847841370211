import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {EmployeeRequest} from './entities';

export enum EmployeeRequestTypeEnum {
    BookVacation = 'bookVacation',
    BookDayOff = 'bookDayOff',
    BookDaysInAdvance = 'bookDaysInAdvance',
    BookHoursInAdvance = 'bookHoursInAdvance',
    BookDaysBasedOnHoursWorked = 'bookDaysBasedOnHoursWorked',
    CancelBookedDays = 'cancelBookedDays',
    TransferVacationDaysToCurrentYear = 'transferVacationDaysToCurrentYear',
    ConvertOvertimeToDayOff = 'convertOvertimeToDayOff',
    ConvertOvertimeToSalary = 'convertOvertimeToSalary',
    TakeFromVacationDays = 'takeFromVacationDays',
    TakeFromDaysOff = 'takeFromDaysOff',
    GiveDayOff = 'giveDayOff',
    CoverTimeAbsentWithOvertime = 'coverTimeAbsentWithOvertime',
    ConvertAbsenceToSalaryReduction = 'convertAbsenceToSalaryReduction'
}

export enum EmployeeRequestHourTypeEnum {
    Absence = 'absence',
    Overtime = 'overtime'
}

@Route('employee_request_types')
@CrossDependency(() => {
    return {
        employeeRequest: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeRequest
        }
    };
})
export class EmployeeRequestType {

    protected id: string;
    protected name: string;
    protected code: string;
    protected description: string;
    protected minDaysBeforeForNotice: number;

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setMinDaysBeforeForNotice(minDaysBeforeForNotice: number) {
        this.minDaysBeforeForNotice = minDaysBeforeForNotice;
        return this;
    }

    public getMinDaysBeforeForNotice(): number {
        return this.minDaysBeforeForNotice;
    }
}
