import {Route} from 'entity-manager';
import {AbstractSetting} from './abstract-setting';
import {User} from './user';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

@Route('user_settings')
@CrossDependency(() => {
    return {
        owner: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        }
    };
})
export class UserSetting extends AbstractSetting {

    public setOwner(user: User): this {
        this.owner = user;
        return this;
    }

    public getOwner(): User|undefined {
        return this.owner;
    }
}
