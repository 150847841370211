import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';
import {AuthenticationService} from '../../core/services/authentication.service';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {Message} from '../../core/entities/message';
import {Paginated} from '../../shared/table/shared/paginated';
import {Route} from '../../shared/enums/route';
import {Router} from '@angular/router';
import {MessageNotificatorService} from '../../core/services/message/message-notificator.service';

@Component({
    selector: 'app-notification',
    styleUrls: ['./app.notification.component.scss'],
    templateUrl: './app.notification.component.html'
})
export class AppNotificationComponent implements OnInit {


    public unreadMessages = 0;
    public messages: any[];
    public placeHolderImage = 'assets/layout/images/logo/alex-circle-logo.svg';

    public constructor(
        public app: AppComponent,
        public authentication: AuthenticationService,
        protected entityManager: EntityManagerService,
        protected cdr: ChangeDetectorRef,
        protected router: Router,
        protected messageNotificator: MessageNotificatorService
    ) {
    }

    public ngOnInit(): void {
        this.messageNotificator.getInstantData(this.authentication.getUser())
            .subscribe((pagination: any) => {
                this.unreadMessages = 0;
                this.messages = pagination.messages;
                this.unreadMessages = pagination.unread;
                this.cdr.detectChanges();
            });
        this.messageNotificator.getData(this.authentication.getUser())
            .subscribe((pagination: any) => {
                this.unreadMessages = 0;
                this.messages = pagination.messages;
                this.unreadMessages = pagination.unread;
                this.cdr.detectChanges();
            });

        this.messageNotificator.messageRead.subscribe((message: Message) => {
            this.decreaseUnreadCount();
            this.updateMessage(message);
            this.cdr.detectChanges();
        });
    }

    public onClick(message) {
        const route = Route.MESSAGE_DETAILS.replace(':messageId', message.getId());
        if (!message.getIsRead()) {
            this.markAsRead(message);
        }

        this.router.navigate([route]).then();
    }

    private markAsRead(message: Message) {
        message.setIsRead(true);
        message.setReadAt(new Date());
        this.entityManager.persist(message).flush(message).subscribe(
            () => {
                this.decreaseUnreadCount();
            }
        );
    }

    private updateMessage(clickedMessage: Message) {
        this.messages.map((message: Message) => {
            if (clickedMessage.getId() === message.getId()) {
                message.setIsRead(true);
            }
            return message;
        });
    }

    private decreaseUnreadCount() {
        if (this.unreadMessages > 0) {
            this.unreadMessages--;
        }
    }

    public allMessages() {
        const route = Route.MESSAGE_LIST;
        this.router.navigate([route]).then();
    }

    public getSubjectClasses(message: Message) {
        let classes = [
            'subject',
        ];

        classes.push('weight_' + message.getWeight())

        classes.push(message.getIsRead() ? 'read' : 'unread')

        return classes;
    }
}
