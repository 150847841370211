export enum KeyboardShortcutContext {
    Add = 'add',
    Copy = 'copy',
    Paste = 'paste',
    Delete = 'delete',
    SelectAll = 'selectAll',
    Save = 'save'
}

export interface TableKeyboardShortcut {
    event: KeyboardEvent;
    context: KeyboardShortcutContext;
}
