import {Injectable} from "@angular/core";
import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from "@angular/common/http";
import {CacheService} from '../services/cache.service';
import { of } from 'rxjs';
import { tap } from "rxjs/operators";

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    constructor(private cacheService: CacheService) {
    }

    public intercept(httpRequest: HttpRequest<any>, handler: HttpHandler) {

        if(httpRequest.method !== 'GET') {
            this.cacheService.invalidateCache();
            return handler.handle(httpRequest);
        }

        const cachedResponse: HttpResponse<any> = this.cacheService.get(httpRequest.urlWithParams);

        if (cachedResponse) {
            return of(cachedResponse);
        }

        return handler.handle(httpRequest)
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse && event.headers.has('Cache-Control') && event.headers.get('Cache-Control').includes('public')) {
                        this.cacheService.put(httpRequest.urlWithParams, event);
                    }
                })
            );
    }
}
