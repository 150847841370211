import {Injectable} from '@angular/core';
import {LocaleSettings} from 'primeng';
import {TranslateService} from '@ngx-translate/core';
import {DATE_FORMAT_SESSION_KEY} from '../../core/workflow/user/set-locale.action';
import {DATE_FORMAT_DEFAULT} from '../pipes/date.pipe';

@Injectable()
export class CalendarLocaleService {

    public constructor(
        private translate: TranslateService
    ) {

    }

    public getLocaleSettings(): LocaleSettings {

        return {
            firstDayOfWeek: 1,
            dayNames: [
                this.getTranslation('DAY_NAMES.SUNDAY'),
                this.getTranslation('DAY_NAMES.MONDAY'),
                this.getTranslation('DAY_NAMES.TUESDAY'),
                this.getTranslation('DAY_NAMES.WEDNESDAY'),
                this.getTranslation('DAY_NAMES.THURSDAY'),
                this.getTranslation('DAY_NAMES.FRIDAY'),
                this.getTranslation('DAY_NAMES.SATURDAY')
            ],
            dayNamesShort: [
                this.getTranslation('DAY_NAMES_SHORT.SUNDAY'),
                this.getTranslation('DAY_NAMES_SHORT.MONDAY'),
                this.getTranslation('DAY_NAMES_SHORT.TUESDAY'),
                this.getTranslation('DAY_NAMES_SHORT.WEDNESDAY'),
                this.getTranslation('DAY_NAMES_SHORT.THURSDAY'),
                this.getTranslation('DAY_NAMES_SHORT.FRIDAY'),
                this.getTranslation('DAY_NAMES_SHORT.SATURDAY')
            ],
            dayNamesMin: [
                this.getTranslation('DAY_NAMES_MIN.SUNDAY'),
                this.getTranslation('DAY_NAMES_MIN.MONDAY'),
                this.getTranslation('DAY_NAMES_MIN.TUESDAY'),
                this.getTranslation('DAY_NAMES_MIN.WEDNESDAY'),
                this.getTranslation('DAY_NAMES_MIN.THURSDAY'),
                this.getTranslation('DAY_NAMES_MIN.FRIDAY'),
                this.getTranslation('DAY_NAMES_MIN.SATURDAY')
            ],
            monthNames: [
                this.getTranslation('MONTH_NAMES.JANUARY'),
                this.getTranslation('MONTH_NAMES.FEBRUARY'),
                this.getTranslation('MONTH_NAMES.MARCH'),
                this.getTranslation('MONTH_NAMES.APRIL'),
                this.getTranslation('MONTH_NAMES.MAY'),
                this.getTranslation('MONTH_NAMES.JUNE'),
                this.getTranslation('MONTH_NAMES.JULY'),
                this.getTranslation('MONTH_NAMES.AUGUST'),
                this.getTranslation('MONTH_NAMES.SEPTEMBER'),
                this.getTranslation('MONTH_NAMES.OCTOBER'),
                this.getTranslation('MONTH_NAMES.NOVEMBER'),
                this.getTranslation('MONTH_NAMES.DECEMBER')
            ],
            monthNamesShort: [
                this.getTranslation('MONTH_NAMES_SHORT.JANUARY'),
                this.getTranslation('MONTH_NAMES_SHORT.FEBRUARY'),
                this.getTranslation('MONTH_NAMES_SHORT.MARCH'),
                this.getTranslation('MONTH_NAMES_SHORT.APRIL'),
                this.getTranslation('MONTH_NAMES_SHORT.MAY'),
                this.getTranslation('MONTH_NAMES_SHORT.JUNE'),
                this.getTranslation('MONTH_NAMES_SHORT.JULY'),
                this.getTranslation('MONTH_NAMES_SHORT.AUGUST'),
                this.getTranslation('MONTH_NAMES_SHORT.SEPTEMBER'),
                this.getTranslation('MONTH_NAMES_SHORT.OCTOBER'),
                this.getTranslation('MONTH_NAMES_SHORT.NOVEMBER'),
                this.getTranslation('MONTH_NAMES_SHORT.DECEMBER')
            ],
            today: this.getTranslation('TODAY'),
            clear: this.getTranslation('CLEAR'),
            dateFormat: sessionStorage.getItem(DATE_FORMAT_SESSION_KEY) || DATE_FORMAT_DEFAULT,
            weekHeader: this.getTranslation('WEEK_HEADER')
        };
    }

    private getTranslation(key: string): string {
        return this.translate.instant(`CALENDAR.LOCALE_SETTINGS.${key}`);
    }
}
