import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as MenuItemBackendImport from '../entities/menu-item';
import {MenuItem, TreeNode} from 'primeng/api';
import {AuthorizerService} from '../services/security/authorizer.service';

@Injectable({
    providedIn: 'root'
})
export class MenuItemRepository extends EntityRepository {

    private static isAuthorized(menuItem: MenuItemBackendImport.MenuItem, mask?: string): boolean {
        if (!mask) {
            return true;
        }

        return AuthorizerService.isGranted(mask, menuItem);
    }

    private static parseMenuItem(menuItem: MenuItemBackendImport.MenuItem, mask?: string): MenuItem {

        const children = [];
        if (menuItem.getChildren() instanceof Array) {
            for (const menuItemChild of menuItem.getChildren()) {
                if (MenuItemRepository.isAuthorized(menuItemChild, mask)) {
                    children.push(MenuItemRepository.parseMenuItem(menuItemChild, mask));
                }
            }
        }

        return {
            label: menuItem.getTitle(),
            icon: menuItem.getIcon(),
            routerLink: children.length === 0 ? menuItem.getLink() : '',
            items: children.length > 0 ? children : undefined
        };
    }

    private static parseTreeNode(menuItem: MenuItemBackendImport.MenuItem): TreeNode {

        const children = [];
        if (menuItem.getChildren() instanceof Array) {
            for (const menuItemChild of menuItem.getChildren()) {
                children.push(MenuItemRepository.parseTreeNode(menuItemChild));
            }
        }

        return {
            label: menuItem.getTitle(),
            icon: menuItem.getIcon(),
            data: menuItem,
            children: children.length > 0 ? children : undefined
        };
    }

    public findMoreAndParseToPrimeNg(params: any, mask?: string): Observable<MenuItem[]> {
        return super.findMore(params).pipe(map((paginated: any) => {
            const parsed = [];

            for (const menuItem of paginated.data) {
                if (MenuItemRepository.isAuthorized(menuItem, mask)) {
                    parsed.push(MenuItemRepository.parseMenuItem(menuItem, mask));
                }
            }

            return parsed;
        }));
    }

    public findMoreAndParseToTreeNode(params: any): Observable<TreeNode[]> {
        return super.findMore(params).pipe(map((paginated: any) => {
            const parsed = [];

            for (const menuItem of paginated.data) {
                parsed.push(MenuItemRepository.parseTreeNode(menuItem));
            }

            return parsed;
        }));
    }
}
