import {Observable} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Http} from '../../helper/http';
import {IdParameterMissing} from '../../error/id-parameter-missing.error';
import {Meta} from '../meta/meta';
import {EntityManagerModifierService} from '../entity-manager-modifier.service';
import {EntityManagerMetaDataService} from '../meta/entity-manager-meta-data.service';
import {EntityManagerParserService} from '../parser/entity-manager-parser.service';
import {EntityManagerConfigurator} from '../../entity-manager.configurator';
import {EntityManagerService} from '../entity-manager.service';

export interface EntityTypeAware {
    entityType: any;
}

@Injectable({
    providedIn: 'root'
})
export class EntityRepository implements EntityTypeAware {

    public entityType: any;

    private configuration;

    public constructor(
      protected connection: HttpClient,
      protected meta: EntityManagerMetaDataService,
      protected modifier: EntityManagerModifierService,
      protected parser: EntityManagerParserService,
      protected injector: Injector,
      protected entityManager: EntityManagerService
    ) {
      this.configuration = this.injector.get<EntityManagerConfigurator>(EntityManagerConfigurator);
    }

    public find(id: string|number): Observable<any> {
        return this.doFind(this.entityType, id);
    }

    public findOne(params: any): Observable<any> {
        return this.doFindOne(this.entityType, params);
    }

    public findMore(params: any): Observable<any[]> {
        return this.doFindMore(this.entityType, params);
    }

    private doFind(type: any, id: string|number): Observable<any> {
      if (!id) {
        throw new IdParameterMissing();
      }

      const apiRoute = this.meta.getMetaDataProperty(new type(), Meta.META_ROUTE),
        request = this.modifier.modifyRequest(
          new type(),
          new HttpRequest<any>('GET', this.configuration.urlPrefix + apiRoute + '/' + id)
        );

      return this.connection
        .get(request.url, {
          headers: request.headers,
          params: request.params
        })
        .pipe(map((data: any) => {
            return this.modifier.modifyResponse(
                new type(),
                new HttpRequest<any>('GET', this.configuration.urlPrefix + apiRoute),
                data,
                'find'
            );
        }));
    }

    public doFindOne(type: any, params: any = {}): Observable<any> {
      const httpParams: HttpParams = Http.objectToHttpParams(params);

      const apiRoute = this.meta.getMetaDataProperty(new type(), Meta.META_ROUTE),
        request = this.modifier.modifyRequest(
          new type(),
          new HttpRequest<any>('GET', this.configuration.urlPrefix + apiRoute, {
            params: httpParams
          })
        );

      return this.connection
        .get(request.url, {
          headers: request.headers,
          params: request.params
        })
        .pipe(map((data: any) => {
            return this.modifier.modifyResponse(
                new type(),
                new HttpRequest<any>('GET', this.configuration.urlPrefix + apiRoute, {
                    params: httpParams
                }),
                data,
                'findOne'
            );
        }));
    }

    public doFindMore(type: typeof Object, params: any = {}): Observable<any[]> {
      const httpParams: HttpParams = Http.objectToHttpParams(params);

      const apiRoute = this.meta.getMetaDataProperty(new type(), Meta.META_ROUTE),
        request = this.modifier.modifyRequest(
          new type(),
          new HttpRequest<any>('GET', this.configuration.urlPrefix + apiRoute, {
            params: httpParams
          })
        );

      return this.connection
        .get(request.url, {
          headers: request.headers,
          params: request.params
        })
        .pipe(map((data: any[]) => {
            return this.modifier.modifyResponse(
                new type(),
                new HttpRequest<any>('GET', this.configuration.urlPrefix + apiRoute, {
                    params: httpParams
                }),
                data,
                'findMore'
            );
        }));
    }
}
