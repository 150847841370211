<p-dialog
  *ngIf="isBoxListModalVisible"
  [(visible)]="isBoxListModalVisible"
  [responsive]="true"
>
  <app-dashboard-box-list *ngIf="isBoxListModalVisible" [dashboardComponent]="this"></app-dashboard-box-list>
</p-dialog>

<div class="dashboard dashboard-container-fluid container-fluid">

    <p-toolbar *ngIf="dashboardService.getSetting().isAddToolbarVisible">
      <div class="ui-toolbar-group-left">
        <button label="{{ 'COMMON.ADD' | translate }}" pButton type="button" (click)="onAddBox()" icon='fa fa-plus'></button>
      </div>
    </p-toolbar>

    <div class="dashboard-boxes-container ui-g">
        <ng-template #dashboardBoxesContainer></ng-template>
    </div>
</div>
