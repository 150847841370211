import {Directive, ElementRef, Input, NgZone, OnInit} from '@angular/core';
import {EditableColumn, Table} from 'primeng/table';
import {TableColumn} from '../shared/table-column';
import {TableComponent} from '../table.component';
import {TableInlineService} from '../services/table-inline.service';
import {DomHandler} from 'primeng/dom';

@Directive({
    selector: '[appTableEditableColumn]',
    providers: [
        EditableColumn,
        TableInlineService
    ],
    host: {
        '(keyup)': 'onKeyUp($event)'
    }
})
export class TableEditableColumnDirective extends EditableColumn implements OnInit {

    @Input() public table: TableComponent = null;
    @Input() public column: TableColumn = null;
    @Input() public appTableEditableColumn;
    @Input() public data;
    @Input() public exitInlineEditOnEnter = false;

    public constructor(
        public dt: Table,
        public el: ElementRef,
        public zone: NgZone,
        public inline: TableInlineService
    ) {
        super(dt, el, zone);
    }

    public ngOnInit(): void {
        this.inline
            .setTable(this.table)
            .setDirective(this);
    }

    public onKeyDown(event: KeyboardEvent): void {

        if (this.isEnabled()) {

            // escape
            if (event.keyCode === 27) {
                if (this.dt.isEditingCellValid()) {
                    DomHandler.removeClass(this.dt.editingCell, 'ui-editing-cell');
                    this.dt.editingCell = null;
                    this.dt.onEditCancel.emit({ field: this.field, data: this.data });
                }

                event.preventDefault();

                // tab
            } else if (event.keyCode === 9) {
                this.dt.onEditComplete.emit({ field: this.field, data: this.data });

                this.inline.handleTabPressed(event);
            }
        }
    }

    public onKeyUp(event: KeyboardEvent): void {

        if (this.isEnabled()) {

            // enter
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                this.inline.handleEnterPressed(event);
                event.preventDefault();
            }
        }
    }


}
