import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {MediaObject} from '../entities/media-object';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

@Injectable({
    providedIn: 'root'
})
export class MediaObjectRepository extends EntityRepository {

    public upload(mediaObject: MediaObject, mediaObjectClassName: string, mediaObjectContext: string): Observable<any> {
        const route = this.meta.getMetaDataProperty(mediaObject, Meta.META_ROUTE);

        const fd = new FormData();

        fd.append('name', mediaObject.getName());
        fd.append('file', mediaObject.getFile());
        fd.append('entityId', mediaObject.getEntity().getId());
        fd.append('entityFqn', mediaObject.getEntity().constructor.name);
        fd.append('fileType', mediaObject.getFileType());
        fd.append('mediaObjectClassName', mediaObjectClassName);
        fd.append('mediaObjectContext', mediaObjectContext);

        return this.connection.post(
            environment.ENTITY_MANAGER_URL_PREFIX + route,
            fd,
            {
                headers: undefined
            }
        )
            .pipe(
                map((aMediaObject: MediaObject) => {
                    return this.parser.getParser().parse(new MediaObject(), aMediaObject);
                })
            );
    }

    public download(mediaObject: MediaObject, mediaObjectClassName: string): Observable<any> {
        const route = this.meta.getMetaDataProperty(new MediaObject(), Meta.META_ROUTE);

        return this.connection.get(
            `${environment.ENTITY_MANAGER_URL_PREFIX}${route}/${mediaObject.getId()}/${mediaObjectClassName}/download`,
            {
                responseType: 'blob'
            }
        ).pipe(
            map((data) => {
                const blob = new Blob([data], { type: mediaObject.getFileType() }),
                    url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style.setProperty('display', 'none');

                a.href = url;
                a.download = mediaObject.getContentUrl();
                a.click();
                window.URL.revokeObjectURL(url);
            })
        );
    }

    public remove(mediaObject: MediaObject, mediaObjectClassName: string): Observable<any> {
        const route = this.meta.getMetaDataProperty(new MediaObject(), Meta.META_ROUTE);

        return this.connection.delete(
            `${environment.ENTITY_MANAGER_URL_PREFIX}${route}/${mediaObject.getId()}/${mediaObjectClassName}/delete`
        );
    }

    public findMore(params: any): Observable<MediaObject[]> {
        const route = this.meta.getMetaDataProperty(new MediaObject(), Meta.META_ROUTE);

        return this.connection.get(
        `${environment.ENTITY_MANAGER_URL_PREFIX}${route}/${params.mediaObjectClassName}/${params.mediaObjectOwnerId}/${params.mediaObjectContext}/get`,
        ).pipe(
            map((responseBody) => {
                const mediaObjects = this.parser.getParser().parseArray(new MediaObject(), responseBody['hydra:member']);

                for (const mediaObject of mediaObjects) {
                    mediaObject.setEntity(params.mediaObjectAware);
                }

                return mediaObjects;
            })
        );
    }

    public findOne(params: any): Observable<MediaObject> {
        const route = this.meta.getMetaDataProperty(new MediaObject(), Meta.META_ROUTE);

        return this.connection.get(
            `${environment.ENTITY_MANAGER_URL_PREFIX}${route}/${params.mediaObjectClassName}/${params.mediaObjectOwnerId}/${params.mediaObjectContext}/get`,
        ).pipe(
            map((responseBody) => {
                const entity = responseBody && responseBody['hydra:member'] ? responseBody['hydra:member'][0] : null;

                if (!entity) {
                    return null;
                }

                return this.parser.getParser().parse(new MediaObject(), entity);
            })
        );
    }
}
