import {Route} from 'entity-manager';

@Route('vacation_absence_ticket_statuses')
export class VacationAbsenceTicketStatus {
    protected status: VacationAbsenceTicketStatus;
    protected name: string;
    protected code: string;
    protected description: string;


    public setStatus(status: VacationAbsenceTicketStatus): VacationAbsenceTicketStatus {
        this.status = status;
        return this;
    }

    public getStatus(): VacationAbsenceTicketStatus | undefined {
        return this.status;
    }

    public setName(name: string): this {
        this.name = name;
        return this;
    }

    public getName(): string | undefined {
        return this.name;
    }

    public setCode(code: string): this {
        this.code = code;
        return this;
    }

    public getCode(): string | undefined {
        return this.code;
    }

    public setDescription(description: string): this {
        this.description = description;
        return this;
    }

    public getDescription(): string | undefined {
        return this.description;
    }
}
