import {Component, OnInit} from '@angular/core';
import { AppComponent} from '../../app.component';
import {trigger, state, transition, style, animate} from '@angular/animations';
import {AuthenticationService} from '../../core/services/authentication.service';
import {MediaObject} from '../../core/entities/media-object';
import {environment} from '../../../environments/environment';
import {BootstrapService} from '../../core/services/bootstrap.service';
import {UserSwitchService} from "../../core/user-switch/user-switch.service";
import {RoleService} from "../../core/services/role.service";
import {EmployeeAutocompleteHandler} from "../../core/autocomplete-handlers/employee.autocomplete-handler";
import {UserAutocompleteHandler} from "../../core/autocomplete-handlers/user.autocomplete-handler";
import {User} from "../../core/entities/user";

@Component({
    selector: 'app-inline-profile',
    styleUrls: ['./app.profile.component.scss'],
    templateUrl: './app.profile.component.html',
    animations: [
        trigger('menu', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*'
            })),
            state('hidden', style({
                height: '0px'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ],
    providers: [
        UserAutocompleteHandler
    ]
})
export class AppProfileComponent implements OnInit {

    public userFullName = '';
    public profilePictureUrl = 'assets/layout/images/placeholder-profile.jpg';
    public switchUserSelected: User = null;

    constructor(
        public userAutocompleteHandler: UserAutocompleteHandler,
        public app: AppComponent,
        public authentication: AuthenticationService,
        public bootstrapService: BootstrapService,
        public userSwitchService: UserSwitchService,
        public roleService: RoleService
    ) {
    }

    public ngOnInit(): void {
        this.bootstrapService.bootstrapped.subscribe(() => {
            this.initUser();
        });

        this.initUser();
    }

    protected initUser(): void {
        const profilePicture = this.authentication.getUser().getMedia('profilePicture');

        this.userFullName = this.authentication.getUser().getFullName();

        if (profilePicture instanceof MediaObject) {
            this.profilePictureUrl = environment.BACKEND_ROOT_URL + profilePicture.getContentUrl();
        }
    }

    public getOriginalUserFullName(): string {
        const user = this.userSwitchService.getOriginalUser();
        return user.getFullName();
    }

    public onSwitchUserSelected(user: User): void {
        this.switchUserSelected = user;
    }
}
