import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private localStorage: any;
    private environment;

    public constructor(
    ) {
        this.localStorage = localStorage;
        this.environment = environment;
    }

    public setItem(key: string, value: any) {
        this.localStorage.setItem(this.getUniqueKey(key), JSON.stringify(value));
        return this;
    }

    public getItem(key: string): any | null {
        return JSON.parse(this.localStorage.getItem(this.getUniqueKey(key)));
    }

    public removeItem(key) {
        this.localStorage.removeItem(this.getUniqueKey(key));
        return this;
    }

    public itemExists(key): boolean {
        return this.localStorage.getItem(this.getUniqueKey(key)) !== null;
    }

    private getUniqueKey(key: string): string {
        return this.environment.key + '-' + key;
    }
}
