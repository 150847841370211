import {Route} from 'entity-manager';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {MenuItemRepository} from '../repositories/menu-item.repository';
import {DomainObjectInterface} from './permission/domain-object.interface';

@Route('menu_items')
@Repository(MenuItemRepository)
export class MenuItem implements DomainObjectInterface {
    protected id: string;
    protected title: string;
    protected icon: string;
    protected key: string;
    protected link: string;
    protected isGranted;

    public getObjectIdentifier(): string {
        return this.key;
    }

    @AssociationMany(MenuItem)
    protected children: MenuItem[] = [];

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): MenuItem {
        this.id = id;
        return this;
    }

    public getTitle(): string | undefined {
        return this.title;
    }

    public setTitle(title: string): MenuItem {
        this.title = title;
        return this;
    }

    public getIcon(): string | undefined {
        return this.icon;
    }

    public setIcon(icon: string): MenuItem {
        this.icon = icon;
        return this;
    }

    public getKey(): string | undefined {
        return this.key;
    }

    public setKey(key: string): MenuItem {
        this.key = key;
        return this;
    }

    public getLink(): string | undefined {
        return this.link;
    }

    public setLink(link: string): MenuItem {
        this.link = link;
        return this;
    }

    public setChildren(menuItems: MenuItem[] = []): MenuItem {
        this.children = menuItems;
        return this;
    }

    public getChildren(): MenuItem[] {
        return this.children;
    }

    public getIsGranted() {
        return this.isGranted;
    }

    public setIsGranted(mask, isGranted): MenuItem {
        this.isGranted[mask] = isGranted;
        return this;
    }
}
