import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {HomeComponent} from './common/home/home.component';
import {LoginComponent} from './common/login/login.component';
import {SharedModule} from '../shared/shared.module';
import {LogoutComponent} from './common/logout/logout.component';
import {FileModule} from '../file/file.module';
import {MessagesBoxComponent} from './common/home/messages-box/messages-box.component';
import {CountBoxComponent} from './common/home/count-box/count-box.component';
import {MessagesCounterComponent} from './common/home/messages-counter/messages-counter.component';

@NgModule({
    imports: [
        SharedModule,
        FileModule,
    ],
    exports: [
        HomeComponent,
        LoginComponent,
        LogoutComponent,
        MessagesBoxComponent,
        CountBoxComponent,
        MessagesCounterComponent
    ],
    declarations: [
        HomeComponent,
        LoginComponent,
        LogoutComponent,
        MessagesBoxComponent,
        CountBoxComponent,
        MessagesCounterComponent
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {
}
