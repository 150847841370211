import {Route} from 'entity-manager';
import {
    PropertyPayloadModifier
} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {
    DecimalPropertyPayloadModifier
} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {EmployeeTravelExpense} from './entities';

@Route('total_travel_expenses')
@CrossDependency(() => {
    return {
        employeeTravelExpenses: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: EmployeeTravelExpense
        }
    };
})

export class TotalTravelExpense {

    @AssociationMany(EmployeeTravelExpense)
    protected employeeTravelExpenses: EmployeeTravelExpense[];

    protected id: string;
    protected entryYear: number;
    protected entryMonth: number;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalDebt;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected remainingDebt;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected entryDebt;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalCompanyTransport;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalOwnTransport;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalTransportationCard;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalSpentBasedOnTypeOfTransportation;

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getEntryYear(): number {
        return this.entryYear;
    }

    public setEntryYear(entryYear: number): TotalTravelExpense {
        this.entryYear = entryYear;
        return this;
    }

    public getEntryMonth(): number {
        return this.entryMonth;
    }

    public setEntryMonth(entryMonth: number): TotalTravelExpense {
        this.entryMonth = entryMonth;
        return this;
    }

    public getEmployeeTravelExpenses(): EmployeeTravelExpense[] | undefined {
        return this.employeeTravelExpenses;
    }

    public setEmployeeTravelExpenses(employeeTravelExpenses: EmployeeTravelExpense[]): TotalTravelExpense {
        this.employeeTravelExpenses = employeeTravelExpenses;
        return this;
    }

    public getTotalDebt(): string {
        return this.totalDebt;
    }

    public setTotalDebt(totalDebt: string): TotalTravelExpense {
        this.totalDebt = totalDebt;
        return this;
    }

    public getRemainingDebt(): string {
        return this.remainingDebt;
    }

    public setRemainingDebt(remainingDebt: string): TotalTravelExpense {
        this.remainingDebt = remainingDebt;
        return this;
    }

    public getEntryDebt(): string {
        return this.entryDebt;
    }

    public setEntryDebt(entryDebt: string): TotalTravelExpense {
        this.entryDebt = entryDebt;
        return this;
    }

    public getTotalCompanyTransport(): string {
        return this.totalCompanyTransport;
    }

    public setTotalCompanyTransport(totalCompanyTransport: string): TotalTravelExpense {
        this.totalCompanyTransport = totalCompanyTransport;
        return this;
    }

    public getTotalOwnTransport(): string {
        return this.totalOwnTransport;
    }

    public setTotalOwnTransport(totalOwnTransport: string): TotalTravelExpense {
        this.totalOwnTransport = totalOwnTransport;
        return this;
    }

    public getTotalTransportationCard(): string {
        return this.totalTransportationCard;
    }

    public setTotalTransportationCard(totalTransportationCard: string): TotalTravelExpense {
        this.totalTransportationCard = totalTransportationCard;
        return this;
    }

    public getTotalSpentBasedOnTypeOfTransportation(): string {
        return this.totalSpentBasedOnTypeOfTransportation;
    }

    public setTotalSpentBasedOnTypeOfTransportation(totalSpentBasedOnTypeOfTransportation: string): TotalTravelExpense {
        this.totalSpentBasedOnTypeOfTransportation = totalSpentBasedOnTypeOfTransportation;
        return this;
    }
}