import {Route} from 'entity-manager';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {CommandOption, CommandArgument} from './entities';
import {CommandEntityRepository} from '../repositories/command-entity.repository';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';

@Route('command_entities')
@CrossDependency(() => {
    return {
        commandOptions: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: CommandOption
        },
        commandArguments: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: CommandArgument
        }
    };
})
@Repository(CommandEntityRepository)
export class CommandEntity {

    protected name: string;
    protected description: string;
    protected help: string;
    @AssociationMany(CommandOption)
    protected commandOptions: CommandOption[];
    @AssociationMany(CommandArgument)
    protected commandArguments: CommandArgument[];

    public getName() {
        return this.name;
    }

    public setName(name: string): this {
        this.name = name;
        return this;
    }

    public getDescription() {
        return this.description;
    }

    public setDescription(description: string): this {
        this.description = description;
        return this;
    }

    public getHelp() {
        return this.help;
    }

    public setHelp(help: string): this {
        this.help = help;
        return this;
    }

    public getCommandOptions(): CommandOption[] {
        return this.commandOptions;
    }

    public setCommandOptions(commandOptions: CommandOption[]): CommandEntity {
        this.commandOptions = commandOptions;
        return this;
    }

    public addCommandOption(commandOption: CommandOption) {
        this.commandOptions.push(commandOption);
    }

    public addCommandArgument(commandArgument: CommandArgument) {
        this.commandArguments.push(commandArgument);
    }

    public getCommandArguments(): CommandArgument[] {
        return this.commandArguments;
    }
    public setCommandArguments(commandArguments: CommandArgument[]): CommandEntity {
        this.commandArguments = commandArguments;
        return this;
    }
}
