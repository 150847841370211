import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {InvoiceType, InvoiceItem, CrmCompany, InvoiceTypeEnum, Order, Delivery, Vat, Associate} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {InvoiceStatus} from './invoice-status';
import {PaymentType} from './payment-type';
import {InvoiceRepository} from '../repositories/invoice.repository';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {BankAccount} from './bank-account';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('invoices')
@CrossDependency(() => {
    return {
        contact: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: CrmCompany
        },
        associate: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Associate
        },
        bankAccount: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: BankAccount
        },
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: InvoiceType
        },
        invoiceStatus: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: InvoiceStatus
        },
        paymentType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PaymentType
        },
        vat: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vat
        },
        invoice: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Invoice
        },
        invoices: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: Invoice
        },
        deliveries: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: Delivery
        },
        items: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: InvoiceItem
        }
    };
})
@Repository(InvoiceRepository)
export class Invoice {

    @AssociationOne(CrmCompany)
    protected contact;

    @AssociationOne(Associate)
    protected associate;

    @AssociationOne(InvoiceStatus)
    protected invoiceStatus;

    @AssociationOne(PaymentType)
    protected paymentType;

    @AssociationOne(InvoiceType)
    protected type;

    @AssociationOne(Invoice)
    protected invoice;

    @AssociationOne(BankAccount)
    protected bankAccount;

    @AssociationMany(InvoiceItem)
    protected items = [];

    @AssociationMany(Order)
    protected orders = [];

    @AssociationMany(Delivery)
    protected deliveries = [];

    @AssociationOne(Vat)
    protected vat;

    @AssociationMany(Invoice)
    protected invoices = [];

    protected invoiceNumber: string;
    protected externalInvoiceNumber: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected invoiceDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected currencyDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected vatDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected dateOfService: Date;

    protected hasVat: boolean;
    protected notice: string;
    protected additionalNotice: string;
    protected id: string;
    protected price: number;
    protected isAdvance: boolean;
    protected deliveryPlanned: string;
    protected optionPlanned: string;
    protected isDelivery: boolean;
    protected currencyExchangeRate: number;
    protected totalPrice: number;
    protected totalVat: number;
    protected remainingTotalVat: number;
    protected remainingTotalPrice: number;
    protected payedAmount: number;
    protected balance: number;

    public setContact(contact: CrmCompany): Invoice {
        this.contact = contact;
        return this;
    }

    public getContact(): CrmCompany|undefined {
        return this.contact;
    }

    public setAssociate(associate: Associate): Invoice {
        this.associate = associate;
        return this;
    }

    public getAssociate(): Associate|undefined {
        return this.associate;
    }

    public setInvoiceStatus(invoiceStatus: InvoiceStatus): Invoice {
        this.invoiceStatus = invoiceStatus;
        return this;
    }

    public getInvoiceStatus(): InvoiceStatus|undefined {
        return this.invoiceStatus;
    }

    public setPaymentType(paymentType: PaymentType): Invoice {
        this.paymentType = paymentType;
        return this;
    }

    public getPaymentType(): PaymentType|undefined {
        return this.paymentType;
    }

    public setType(type: InvoiceType): Invoice {
        this.type = type;
        return this;
    }

    public getType(): InvoiceType|undefined {
        return this.type;
    }

    public setInvoice(invoice: Invoice): Invoice {
        this.invoice = invoice;
        return this;
    }

    public getInvoice(): Invoice|undefined {
        return this.invoice;
    }

    public setBankAccount(bankAccount: BankAccount): Invoice {
        this.bankAccount = bankAccount;
        return this;
    }

    public getBankAccount(): BankAccount {
        return this.bankAccount;
    }

    public getVat(): Vat {
        return this.vat;
    }

    public setVat(vat: Vat): Invoice {
        this.vat = vat;
        return this;
    }

    public getInvoices(): Invoice[] {
        return this.invoices;
    }

    public setInvoices(invoices: Invoice[]): Invoice {
        this.invoices = invoices;
        return this;
    }

    public addInvoice(invoice: Invoice): Invoice {
        const index = this.getInvoices().findIndex(obj => obj['@id'] === invoice['@id']);

        if (index === -1) {
            this.invoices.push(invoice);
        }

        return this;
    }

    public setItems(items: InvoiceItem[] = []): Invoice {
        this.items = items;
        return this;
    }

    public getItems(): InvoiceItem[]|undefined {
        return this.items;
    }

    public getOrders(): Order[] {
        return this.orders || [];
    }

    public setOrders(orders: Order[] = []): Invoice {
        this.orders = orders;
        return this;
    }

    public getDeliveries(): Delivery[] {
        return this.deliveries || [];
    }

    public setDeliveries(deliveries: Delivery[] = []): Invoice {
        this.deliveries = deliveries;
        return this;
    }

    public setInvoiceNumber(invoiceNumber: string) {
        this.invoiceNumber = invoiceNumber;
        return this;
    }

    public getInvoiceNumber(): string {
        return this.invoiceNumber;
    }

    public setExternalInvoiceNumber(externalInvoiceNumber: string) {
        this.externalInvoiceNumber = externalInvoiceNumber;
        return this;
    }

    public getExternalInvoiceNumber(): string {
        return this.externalInvoiceNumber;
    }

    public setInvoiceDate(invoiceDate: Date) {
        this.invoiceDate = invoiceDate;
        return this;
    }

    public getInvoiceDate(): Date|null {
        return this.invoiceDate;
    }

    public setCurrencyDate(currencyDate: Date) {
        this.currencyDate = currencyDate;
        return this;
    }

    public getCurrencyDate(): Date|null {
        return this.currencyDate;
    }

    public setVatDate(vatDate: Date) {
        this.vatDate = vatDate;
        return this;
    }


    public getVatDate(): Date|null {
        return this.vatDate;
    }


    public getDateOfService(): Date|null {
        return this.dateOfService;
    }

    public setDateOfService(dateOfService: Date) {
        this.dateOfService = dateOfService;
        return this;
    }

    public setHasVat(hasVat: boolean) {
        this.hasVat = hasVat;
        return this;
    }

    public getHasVat(): boolean {
        return this.hasVat;
    }

    public setNotice(notice: string) {
        this.notice = notice;
        return this;
    }

    public getNotice(): string {
        return this.notice;
    }

    public getAdditionalNotice(): string {
        return this.additionalNotice;
    }

    public setAdditionalNotice(additionalNotice: string) {
        this.additionalNotice = additionalNotice;
        return this;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public getPrice(): number {
        return this.price;
    }

    public setPrice(price: number) {
        this.price = price;
        return this;
    }

    public getCurrencyExchangeRate(): number {
        return this.currencyExchangeRate;
    }

    public setCurrencyExchangeRate(currencyExchangeRate: number) {
        this.currencyExchangeRate = currencyExchangeRate;
        return this;
    }

    public getTotalPrice(): number | undefined {
        return this.totalPrice;
    }

    public setTotalPrice(totalPrice: number): Invoice {
        this.totalPrice = totalPrice;
        return this;
    }

    public getRemainingTotalPrice(): number | undefined {
        return this.remainingTotalPrice;
    }

    public setRemainingTotalPrice(remainingTotalPrice: number): Invoice {
        this.remainingTotalPrice = remainingTotalPrice;
        return this;
    }

    public getTotalVat(): number | undefined {
        return this.totalVat;
    }

    public setTotalVat(totalVat: number): Invoice {
        this.totalVat = totalVat;
        return this;
    }

    public getRemainingTotalVat(): number | undefined {
        return this.remainingTotalVat;
    }

    public setRemainingTotalVat(remainingTotalVat: number): Invoice {
        this.remainingTotalVat = remainingTotalVat;
        return this;
    }

    public getPayedAmount(): number | undefined {
        return this.payedAmount;
    }

    public setPayedAmount(payedAmount: number): Invoice {
        this.payedAmount = payedAmount;
        return this;
    }

    public getBalance(): number | undefined {
        return this.balance;
    }

    public setBalance(balance: number): Invoice {
        this.balance = balance;
        return this;
    }

    public setIsAdvance(isAdvance: boolean) {
        this.isAdvance = isAdvance;
        return this;
    }

    public getIsAdvance(): boolean {
        return this.isAdvance;
    }

    public getDeliveryPlanned(): string {
        return this.deliveryPlanned;
    }

    public setDeliveryPlanned(deliveryPlanned: string): Invoice {
        this.deliveryPlanned = deliveryPlanned;
        return this;
    }

    public getOptionPlanned(): string {
        return this.optionPlanned;
    }

    public setOptionPlanned(optionPlanned: string): Invoice {
        this.optionPlanned = optionPlanned;
        return this;
    }

    public getIsIncoming(): boolean {
        return this.getType() instanceof InvoiceType && this.getType().getCode() === InvoiceTypeEnum.Incoming;
    }

    public getIsOutgoing(): boolean {
        return this.getType() instanceof InvoiceType && this.getType().getCode() === InvoiceTypeEnum.Outgoing;
    }

    public setIsDelivery(isDelivery: boolean) {
        this.isDelivery = isDelivery;
        return this;
    }

    public getIsDelivery(): boolean {
        return this.isDelivery;
    }

    public getIsForeignIncoming(): boolean {
        return this.getType() instanceof InvoiceType && this.getType().getCode() === InvoiceTypeEnum.ForeignIncoming;
    }
}
