import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import * as moment from 'moment';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-monthly-filter',
    templateUrl: './monthly-filter.component.html',
    styleUrls: ['./monthly-filter.component.scss']
})
export class MonthlyFilterComponent {

    @Output() public filterChanged = new EventEmitter<{currentMonth: Date}>();
    public currentMonth = moment().toDate();

    public onPreviousMonth(): void {
        this.currentMonth = moment(this.currentMonth)
            .subtract('1', 'month')
            .toDate();

        this.filterChanged.emit({currentMonth: this.currentMonth});
    }

    public onNextMonth(): void {
        this.currentMonth = moment(this.currentMonth)
            .add('1', 'month')
            .toDate();

        this.filterChanged.emit({currentMonth: this.currentMonth});
    }
}
