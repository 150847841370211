import {Directive, Self, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {MenuItem, SplitButton} from 'primeng';
import {TableColumnMenuButton} from '../shared/table-column-menu';

@Directive({
    selector: '[appTableSplitButton]'
})
export class TableSplitButtonDirective implements OnInit {

    @Input() button: TableColumnMenuButton = null;
    @Output() public itemClicked = new EventEmitter<any>();
    private readonly splitButton: SplitButton = null;

    public constructor(@Self() splitButton: SplitButton) {
        this.splitButton = splitButton;

        if (!(this.splitButton instanceof SplitButton)) {
            console.error('This directive works only with splitButton component!');
        }
    }

    public ngOnInit(): void {
        const button = this.button;

        this.splitButton.itemClick = (event, item: MenuItem) => {
            this.itemClicked.emit({
                event,
                item,
                button
            });
        };
    }
}
