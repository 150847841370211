import {Component, HostListener, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {AppComponent} from '../../app.component';
import {NavigationEnd, Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {UUID} from '../../core/utils/uuid';
import {MainNavigationService} from '../../core/services/main-navigation.service';

@Component({
    selector: 'app-main-navigation',
    styleUrls: ['./app-main-navigation.component.scss'],
    template: `
        <p-tabMenu [model]="items" [activeItem]="activeItem">
            <ng-template pTemplate="item" let-item let-i="index">
                <div style="position: relative; text-align: center; min-width: 10em">
                    <div class="ui-menuitem-text">
                        {{item.label}}
                    </div>
                    <a
                        tabindex="0" class="ui-menuitem-icon" (click)="onCloseItem($event, item, i)"
                        style="position: absolute; right: -1em; top: -.5em; padding: 0"
                    >
                        <span *ngIf="items.length > 1" class="pi pi-times"></span>
                    </a>
                </div>
            </ng-template>
        </p-tabMenu>
    `
})
export class AppMainNavigationComponent implements OnInit {

    public activeItem: MenuItem;
    public items: MenuItem[] = [];
    public itemsRetrieved = false;

    @HostListener('window:beforeunload', ['$event'])
    public onWindowClose(event) {
        this.mainNavigationService.store(this.items);
    }

    public constructor(
        public app: AppComponent,
        protected router: Router,
        protected translate: TranslateService,
        protected location: Location,
        protected mainNavigationService: MainNavigationService
    ) {
    }

    public ngOnInit(): void {
        this.mainNavigationService.currentChangeLabel.subscribe((label: string) => {
            this.activeItem.label = label;
        });
        this.router.events.subscribe((event) => {
            this.translate.get('LOAD').subscribe(() => {
                if (event instanceof NavigationEnd) {
                    this.onRouteLoaded(event);
                }
            });
        });
    }

    public onRouteLoaded(event: NavigationEnd): void {
        this.activeItem = this.getOrCreateItem(event);

        if (!this.itemsRetrieved) {
            this.items = this.mainNavigationService.merge(this.activeItem);
            this.itemsRetrieved = true;
        }
    }

    public onCloseItem(event: any, item: MenuItem, index: number): void {
        this.items = this.items.filter((aItem, i) => i !== index);

        if (item === this.activeItem || this.items.length === 1) {
            const aItem = this.items[this.items.length - 1];

            this.router.navigate(
                [aItem.routerLink]
            ).then((isClosed) => {
                if (!isClosed) {
                    this.items.splice(index, 0, item);
                }
            });
        }

        event.preventDefault();
    }

    protected getOrCreateItem(event: NavigationEnd): MenuItem {
        const itemIndex = this.items.findIndex((aItem: MenuItem) => {
            return aItem.routerLink === event.url;
        });

        let item: MenuItem = null;

        if (itemIndex === -1) {
            item = this.createItem(event);

            if (this.doReplaceCurrent() && this.activeItem) {
                const currentItemIndex = this.items.findIndex((aItem: MenuItem) => {
                    return aItem.routerLink === this.activeItem.routerLink;
                });

                this.items[currentItemIndex] = item;
            } else {
                this.addItem(item);
            }
        } else {
            item = this.items[itemIndex];
        }

        return item;
    }

    protected createItem(event: NavigationEnd): MenuItem {
        return {
            routerLink: event.url,
            label: this.buildMenuLabel(event.url),
            routerLinkActiveOptions: {exact: true}
        };
    }

    protected addItem(item: MenuItem): void {
        this.items = [...this.items, item];

        if (this.items.length >= 8) {
            this.items.splice(0, 1);
        }
    }

    protected buildMenuLabel(url: string): string {
        let translateKey = url;

        if (translateKey.charAt( 0 ) === '/') {
            translateKey = translateKey.substr(1);
        }

        translateKey = translateKey.split('/').join('_');
        translateKey = translateKey.toUpperCase();

        const split = translateKey.split('_');

        if (split.length > 0) {
            const parts = [];
            for (let part of split) {
                if (UUID.isUUID(part)) {
                    part = 'ID';
                }

                parts.push(part);
            }

            translateKey = parts.join('_');
        }

        if (!translateKey) {
            translateKey = 'HOME';
        }

        return this.translate.instant(`MENU_NAVIGATION.${translateKey}`);
    }

    protected doReplaceCurrent(): boolean {
        const navigation = this.router.getCurrentNavigation();

        return navigation &&
            navigation.extras &&
            navigation.extras.state &&
            navigation.extras.state.replaceCurrent || false;
    }
}
