import {Observable, of} from 'rxjs';
import {WorkflowActionInterface} from './workflow-action.interface';
import {WorkflowActionStatus} from './workflow-action-status';
import {InjectorAwareInterface} from './injector-aware.interface';
import {Injector} from '@angular/core';

export class WorkflowAction implements WorkflowActionInterface, InjectorAwareInterface {

    protected injector: Injector = null;

    public setInjector(injector: Injector): InjectorAwareInterface {
        this.injector = injector;
        return this;
    }

    public getInjector(): Injector {
        return this.injector;
    }

    public run(): Observable<WorkflowActionStatus> {
        return of({
            success: true,
            stop: false,
            status: ''
        });
    }
}

