import {Route} from 'entity-manager';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {MediaObjectRepository} from '../repositories/media-object.repository';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

export enum MediaObjectClassName {
    Employee = 'EmployeeMediaObject',
    Employment = 'EmploymentMediaObject',
    DrivingLicence = 'DrivingLicenceMediaObject',
    BulletinBoardItemMediaObject = 'BulletinBoardItemMediaObject'
}

@Route('media_objects')
@Repository(MediaObjectRepository)
export class MediaObject {

    protected id: string;
    protected name: string;
    protected contentUrl: string;
    protected file: any;
    protected fileType: string;
    protected entity: any;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected createdAt: Date;
    protected className: string;
    protected context: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setContentUrl(contentUrl: string) {
        this.contentUrl = contentUrl;
        return this;
    }

    public getContentUrl(): string {
        return this.contentUrl;
    }

    public setFile(file: any) {
        this.file = file;
        return this;
    }

    public getFile(): any {
        return this.file;
    }

    public setFileType(fileType: any) {
        this.fileType = fileType;
        return this;
    }

    public getFileType(): any {
        return this.fileType;
    }

    public setEntity(entity: any): this {
        this.entity = entity;
        return this;
    }

    public getEntity(): any {
        return this.entity;
    }

    public setCreatedAt(createdAt: Date): this {
        this.createdAt = createdAt;
        return this;
    }

    public getCreatedAt(): Date|undefined {
        return this.createdAt;
    }

    public getContext(): string|undefined {
        return this.context;
    }

    public setContext(context: string) {
        this.context = context;
        return this;
    }

    public getClassName(): string|undefined {
        return this.className;
    }

    public setClassName(className: string) {
        this.className = className;
        return this;
    }
}
