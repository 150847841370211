import {InjectorAwareInterface} from '../../../workflow/services/injector-aware.interface';
import {WorkflowAction} from '../../../workflow/services/workflow-action';
import {WorkflowActionInterface} from '../../../workflow/services/workflow-action.interface';
import {WorkflowActionStatus} from '../../../workflow/services/workflow-action-status';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DefaultSetting} from '../../entities/default-setting';
import {Paginated} from '../../../shared/table/shared/paginated';
import {EntityManagerService} from '../../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {AuthenticationService} from '../../services/authentication.service';

export class LoadUserCompanySettingsAction extends WorkflowAction implements WorkflowActionInterface, InjectorAwareInterface {

    public run(): Observable<WorkflowActionStatus> {

        return this.injector.get(EntityManagerService).getRepository(DefaultSetting)
            .findMore({
                contextGroups: 'process'
            })
            .pipe(
                map((paginated: Paginated) => {
                    const user = this.injector.get(AuthenticationService).getUser()
                        .setSettings(paginated.data);

                    this.injector.get(AuthenticationService).setUser(user);

                    return {
                        success: true,
                        stop: false,
                        status: ''
                    };
                })
            );
    }
}
