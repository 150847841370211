import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input, OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Calendar} from 'primeng';
import {TableColumn} from '../../../shared/table-column';
import {AbstractFilter} from '../../../services/filter/abstract-filter';
import {TableFilterService} from '../../../services/table-filter.service';
import {DATE_FORMAT_DEFAULT, DatePipe} from '../../../../pipes/date.pipe';
import {DATE_FORMAT_SESSION_KEY} from '../../../../../core/workflow/user/set-locale.action';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TableAdvanceFilterService} from '../../../services/table-advance-filter.service';
import {TableAdvanceFilter} from '../../../shared/table-advance-filter';
import {AbstractAdvanceFilter} from '../../../services/advance-filter/abstract-advance-filter';
import {TableComponent} from '../../../table.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-calendar-range-filter',
    styleUrls: ['./calendar-range-filter.component.scss'],
    templateUrl: './calendar-range-filter.component.html'
})
export class CalendarRangeFilterComponent implements OnInit {

    @Input() filterService: TableFilterService;
    @Input() column: TableColumn;
    @Input() table: TableComponent = null;

    @Input() advancedFilterOptions: TableAdvanceFilter;
    @Input() advancedFilterService: TableAdvanceFilterService;

    @Output() filterChange = new EventEmitter<any>();
    private inputChanged: Subject<any> = new Subject<any>();

    @ViewChild('calendar') calendar: Calendar;
    @ViewChild('overlayPanel') overlayPanel: OverlayPanel;

    @ViewChild('calendarOverlayContainer') calendarOverlayContainer: ElementRef<HTMLDivElement>;
    @ViewChild('input') input: ElementRef<HTMLInputElement>;

    public filter: AbstractFilter = null;

    public advancedFilter: AbstractAdvanceFilter = null;

    public inputValue = '';

    private static isCalendarRangeValid(value): boolean {
        return typeof value === 'string' || (value instanceof Array && value.every(val => {
            return val !== null;
        }));
    }

    @HostListener('window:click', ['$event']) public onClick(event) {
        if (!event.target.closest('.ui-range-calendar')) {
            this.hideOverlay();
        }
    }

    public ngOnInit(): void {
        this.inputChanged
            .pipe(
                debounceTime(500),
                distinctUntilChanged())
            .subscribe(event => {
                this.onInputChanged(event);
            });

        if (this.column && this.table && this.table.getFilter().getFilterValue(this.column) instanceof Array) {
            const value = this.table.getFilter().getFilterValue(this.column);

            this.parseInputValue(value);
        }
    }

    public onInputFocus(event): void {
        if (!this.advancedFilterOptions) {
            this.filter = this.filterService.getOrCreateFilter(this.column);
        } else {
            this.advancedFilter = this.advancedFilterService.getOrCreateFilter(this.advancedFilterOptions);
        }
    }

    public onInputKeyUp(event): void {
        this.inputChanged.next(event);
    }

    public onInputChanged(event): void {
        const inputString = event.target.value,
            customDateFormat = !this.advancedFilterOptions ? this.column.filter.dateFormat : this.advancedFilterOptions.dateFormat,
            dateFormat = customDateFormat || sessionStorage.getItem(DATE_FORMAT_SESSION_KEY) || DATE_FORMAT_DEFAULT;

        if (inputString.includes('-')) {
            const splitDates = inputString.split('-'),
                firstDateString = splitDates[0],
                secondDateString = splitDates[1];

            try {
                const firstDate = this.calendar.parseDate(firstDateString, dateFormat),
                    secondDate = this.calendar.parseDate(secondDateString, dateFormat);

                this.onFilterChange([firstDate, secondDate]);
            } catch (e) {

            }
        } else {
            try {
                const date = this.calendar.parseDate(inputString, dateFormat);

                this.onFilterChange([date, date]);
            } catch (e) {

            }
        }

        if (inputString === '') {
            this.onFilterChange('clear');
        }
    }

    public showOverlay(event): void {
        this.overlayPanel.show(event, this.input.nativeElement);

        if (!this.advancedFilterOptions) {
            this.filter = this.filterService.getOrCreateFilter(this.column);
        } else {
            this.advancedFilter = this.advancedFilterService.getOrCreateFilter(this.advancedFilterOptions);
        }
    }

    public toggleOverlay(event): void {
        this.overlayPanel.toggle(event, this.input.nativeElement);


        if (!this.advancedFilterOptions) {
            this.filter = this.filterService.getOrCreateFilter(this.column);
        } else {
            this.advancedFilter = this.advancedFilterService.getOrCreateFilter(this.advancedFilterOptions);
        }
    }

    public hideOverlay(): void {
        this.overlayPanel.hide();
    }

    public toggleFocus(focus): void {
        if (focus) {
            this.input.nativeElement.focus();
        } else {
            this.input.nativeElement.blur();
        }
    }

    public onFilterChange(value: string|string[]) {
        if (CalendarRangeFilterComponent.isCalendarRangeValid(value)) {
            this.hideOverlay();

            this.parseInputValueFromChange(value);

            if (value === 'clear') {
                this.clearInputValue();
            }

            this.filterChange.emit(value);
        }
    }

    private parseInputValueFromChange(value: string|string[]): void {
        let filterValue = null;

        if (this.advancedFilter) {
            filterValue = this.advancedFilter.getValueFromChange(value, null);
        }

        if (this.filter) {
            filterValue = this.filter.getValueFromChange(value, null);
        }

        this.parseInputValue(filterValue);
    }

    private parseInputValue(filterValue: string|string[]): void {
        let inputValue = '';

        if (filterValue !== null) {
            const datePipe = new DatePipe();
            const from = datePipe.transform(filterValue[0]);
            const to = datePipe.transform(filterValue[1]);

            if (from === to) {
                inputValue = from;
            } else {
                inputValue = `${from}-${to}`;
            }
        }

        this.inputValue = inputValue;
    }

    private clearInputValue(): void {
        this.inputValue = '';
    }
}
