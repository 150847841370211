import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ComponentsModule} from './components/components.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {MainModule} from './main/main.module';
import {AuthenticationModifier} from './core/modifiers/authentication.modifier';
import {
  EntityManagerModule
} from '../../projects/entity-manager/src/lib/entity-manager.module'; // todo :: fix paths in library
import {environment} from '../environments/environment';
import {BaseConfigurator, EntityManagerConfigurator} from '../../projects/entity-manager/src/lib/entity-manager.configurator';
import {AuthenticationInterceptor} from './core/interceptors/authentication.interceptor';
import {PaginationLdJsonModifier} from './core/modifiers/pagination-ld-json.modifier';
import {LdJsonParser} from './core/parsers/ld-json-parser';
import {EntityLdJsonModifier} from './core/modifiers/entity-ld-json.modifier';
import {DashboardModule} from './dashboard/dashboard.module';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {XcentricModule} from '../../projects/xcentric/src/lib/xcentric.module';
import {FindOneLdJsonModifier} from './core/modifiers/find-one-ld-json.modifier';
import {EntityDefaultFiltersModifier} from './core/modifiers/entity-default-filters.modifier';
import { registerLocaleData } from '@angular/common';
import localeSr from '@angular/common/locales/sr-Latn';
import {UserSwitchInterceptor} from "./core/interceptors/user-switch.interceptor";
import {CacheInterceptor} from "./core/interceptors/cache.interceptor";
registerLocaleData(localeSr);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// AoT crap
export function EntityManagerFactory() {
  return new BaseConfigurator(environment.ENTITY_MANAGER_URL_PREFIX, [
      AuthenticationModifier,
      PaginationLdJsonModifier,
      FindOneLdJsonModifier,
      EntityLdJsonModifier,
      EntityDefaultFiltersModifier
  ], LdJsonParser);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    EntityManagerModule.forRoot({
      loader: {
        provide: EntityManagerConfigurator,
        useFactory: EntityManagerFactory
      }
    }),
    CoreModule,
    AppRoutingModule,
    ComponentsModule,
    DashboardModule,
    MainModule,
    XcentricModule,
    SharedModule // change to MainSharedModule -> less modules for faster page load
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserSwitchInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'sr-Latn'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
