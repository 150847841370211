import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {DateRange, extendMoment} from 'moment-range';

@Pipe({
    name: 'periodToTime'
})
export class ConvertPeriodToTimePipe implements PipeTransform {

    public constructor(
        protected translate: TranslateService
    ) {

    }

    transform(value: Date[]): string {
        if (value instanceof Array) {
            const fromDate = value[0],
                toDate = value[1];

            if (fromDate && toDate) {
                const range: DateRange = extendMoment(moment)
                    .range(moment(fromDate)
                            .toDate(),
                        moment(toDate)
                            .toDate()
                    );

                return this.doTransform(range);
            }
        }

        return '';
    }

    protected doTransform(range: DateRange): string {
        const days = moment.duration(range.duration()).days(),
            hours = moment.duration(range.duration()).hours(),
            minutes = moment.duration(range.duration()).minutes();

        let ret = '';

        if (days > 0) {
            ret += `${days}${this.translate.instant('TIME.DAYS')} `;
        }

        if (hours > 0) {
            ret += `${hours}${this.translate.instant('TIME.HOURS')} `;
        }

        if (minutes > 0) {
            ret += `${minutes}${this.translate.instant('TIME.MINUTES')}`;
        }

        if (!ret) {
            return `0 ${this.translate.instant('TIME.MINUTES')}`;
        }

        return ret;
    }
}
