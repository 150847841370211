import { AbstractFilter } from './abstract-filter';
import * as moment from 'moment';

export class CalendarRangeFilter extends AbstractFilter {

    public getValueFromChange(event, param) {
        if (event === 'clear') {
            event = null;
        } else if (event === 'today') {
            const after = moment()
                .startOf('day')
                .toDate();
            const before = moment()
                .endOf('day')
                .toDate();

            event = [after, before];
        } else if (event === 'thisWeek') {
            const after = moment()
                .startOf('week')
                .toDate();
            const before = moment()
                .endOf('week')
                .toDate();

            event = [after, before];
        } else if (event === 'thisMonth') {
            const after = moment()
                .startOf('month')
                .toDate();
            const before = moment()
                .endOf('month')
                .toDate();

            event = [after, before];
        } else if (event === 'lastWeek') {
            const after = moment()
                .subtract(1, 'week')
                .startOf('week')
                .toDate();
            const before = moment()
                .subtract(1, 'week')
                .endOf('week')
                .toDate();

            event = [after, before];
        } else if (event === 'lastMonth') {
            const after = moment()
                .subtract(1, 'month')
                .startOf('month')
                .toDate();
            const before = moment()
                .subtract(1, 'month')
                .endOf('month')
                .toDate();

            event = [after, before];
        } else if (event instanceof Array) {
            const after = moment(event[0])
                .startOf('day')
                .toDate();
            const before = moment(event[1])
                .endOf('day')
                .toDate();

            event = [after, before];
        }

        return event;
    }

    public getFilterParsedValue(): any {
        if (this.column && typeof this.column.filter.parseValue === 'function') {
            return this.column.filter.parseValue(this.filterValue);
        }

        let after = this.filterValue[0];
        let before = this.filterValue[0];

        if (after && after.after instanceof Date) {
            after = moment(after)
                .startOf('day')
                .toDate();
        }

        if (before && before instanceof Date) {
            before = moment(before)
                .endOf('day')
                .toDate();
        }

        return [after, before];
    }

    public buildFilterParams(params: any, filter): void {
        const filterValue = this.getFilterValue();

        if (filterValue) {
            params[`${filter}[after]`] = moment(filterValue[0]).format('YYYY-MM-DDTHH:mm:ss');
            params[`${filter}[before]`] = moment(filterValue[1]).format('YYYY-MM-DDTHH:mm:ss');
        }
    }
}
