import {EventEmitter, Injectable} from '@angular/core';
import {BootstrapService} from '../services/bootstrap.service';
import {AppComponent} from '../../app.component';
import {User} from '../entities/user';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../services/local-storage.service';
import {LdJsonParser} from '../parsers/ld-json-parser';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';

@Injectable({providedIn: 'root'})
export class UserSwitchService {
    public static readonly LOCAL_STORAGE_ORIGINAL_USER_KEY = 'original-user';
    public static readonly LOCAL_STORAGE_IMPERSONATE_USER_KEY = 'impersonate-user';

    private userSwitchedEmitter: EventEmitter<User> = new EventEmitter();

    public constructor(
        private bootstrap: BootstrapService,
        private entityManager: EntityManagerService,
        private authenticationService: AuthenticationService,
        private localStorage: LocalStorageService,
        private router: Router
    ) {
    }

    public switchOn(appComponent: AppComponent, user: User): void {
        this.entityManager.getRepository(User)
            .find(user.getId())
            .subscribe((aUser: User) => {
                this.localStorage.setItem(UserSwitchService.LOCAL_STORAGE_ORIGINAL_USER_KEY, this.authenticationService.getUser());
                this.localStorage.setItem(UserSwitchService.LOCAL_STORAGE_IMPERSONATE_USER_KEY, aUser.getUsername());
                this.authenticationService.setUser(aUser);
                this.bootstrap.bootstrap(appComponent)
                    .subscribe(() => {
                        this.userSwitchedEmitter.emit(this.authenticationService.getUser());
                        this.router.navigate(['home']);
                    });
            });
    }

    public switchOff(appComponent: AppComponent): void    {
        this.authenticationService.setUser(this.localStorage.getItem(UserSwitchService.LOCAL_STORAGE_ORIGINAL_USER_KEY));
        this.localStorage.removeItem(UserSwitchService.LOCAL_STORAGE_ORIGINAL_USER_KEY);
        this.localStorage.removeItem(UserSwitchService.LOCAL_STORAGE_IMPERSONATE_USER_KEY);
        this.bootstrap.bootstrap(appComponent)
            .subscribe(() => {
                this.userSwitchedEmitter.emit(this.authenticationService.getUser());
                this.router.navigate(['admin/user/list']);
        });
    }

    public getImpersonateUserName(): string {
        return this.localStorage.getItem(UserSwitchService.LOCAL_STORAGE_IMPERSONATE_USER_KEY);
    }

    public isImpersonateUser(): boolean {
        return this.localStorage.itemExists(UserSwitchService.LOCAL_STORAGE_IMPERSONATE_USER_KEY);
    }

    public getUserSwitchEmitter(): EventEmitter<User> {
        return this.userSwitchedEmitter;
    }

    public getOriginalUser(): User {
        return new LdJsonParser().parse(
            new User(),
            this.localStorage.getItem(UserSwitchService.LOCAL_STORAGE_ORIGINAL_USER_KEY)
        );
    }
}
