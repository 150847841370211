import {
  ChangeDetectorRef,
  Component, ComponentFactoryResolver, ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output, TemplateRef,
  ViewChild, ViewContainerRef
} from '@angular/core';
import {TableColumn} from './shared/table-column';
import {TableColumnMenuButton} from './shared/table-column-menu';
import {TableSelectionService} from './services/table-selection.service';
import {TableFilterService} from './services/table-filter.service';
import {TableColumnsVisibility} from './shared/table-columns-visibility';
import {TableHeader} from './shared/table-header';
import {TableFooter} from './shared/table-footer';
import {TableButton} from './shared/table-button';
import {TableButtonClickHandler} from './shared/table-button-click-handler';
import {ToolbarItemDropdown} from './shared/toolbar/toolbar-item-dropdown';
import {EMPTY, Subject, timer} from 'rxjs';
import {debounce, debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AbstractFilter} from './services/filter/abstract-filter';
import {ObjectUtils} from './overides/object-utils';
import {TableColumnService} from './services/table-column.service';
import {TableStateService} from './services/table-state.service';
import {Table} from 'primeng/table';
import {KeyboardShortcutContext, TableKeyboardShortcut} from './shared/table-keyboard-shortcut';
import {TableKeyboardShortcutService} from './services/table-keyboard-shortcut.service';
import {Entity} from '../../../../projects/entity-manager/src/lib/helper/entity';
import {TableInlineService} from './services/table-inline.service';
import {TableCell} from './shared/table-cell';
import {TableSelectionStateService} from './services/table-selection-state.service';
import {TableSelectionState} from './shared/table-selection-state';
import {TableAdvanceFilterService} from './services/table-advance-filter.service';
import {AbstractAdvanceFilter} from './services/advance-filter/abstract-advance-filter';
import {TableAdvanceFilter} from './shared/table-advance-filter';
import {TableTotal} from './shared/table-total';
import {TableTotalColumn} from './shared/table-total-column';
import {cloneDeep} from 'lodash';
import {TranslateService} from '@ngx-translate/core';

interface FilterChangeMeta {
  event: any;
  column: TableColumn;
  param: any;
  isOperatorEvent: any;
}

interface CellEditMeta {
  event: any;
  column: TableColumn;
  entity: any;
}

@Component({
  selector: 'app-table',
  styleUrls: ['./table.component.scss'],
  templateUrl: './table.component.html',
  providers: [TableSelectionService, TableFilterService, TableAdvanceFilterService, TableColumnService, TableInlineService]
})
export class TableComponent implements OnInit, OnDestroy {

  @ViewChild('dataTable', {static: true}) dataTable: Table;
  @ContentChild('expansion', {static: false}) expansionRef: TemplateRef<any>;

  @Input() useKeyboardShortcuts = true;
  @Input() selectFirstEntityWhenNothingIsSelected = false;
  @Input() currentlySelectedEntity = null;
  @Input() rows = 30;
  @Input() first = 0;
  @Input() sortField = '';
  @Input() sortOrder = 1;
  @Input() stateName = '';
  @Input() persistState = true;
  @Input() columnVisibilityDialog = false;
  @Input() totalCount = 0;
  @Input() isLoadingData = false;
  @Input() editMode = 'row';
  @Input() scrollable = true;
  @Input() headerVisible = true;
  @Input() sortVisible = true;
  @Input() tableFilters: 'table' | 'top' | false = 'table';
  @Input() paginator = true;
  @Input() singleEntityDelete = false;

  public entityHelper = Entity;
  public showAllTranslation = this.translate.instant('COMMON.ALL');

  @Input() set filters(filters: {[filterKey: string]: AbstractFilter }) {
    if (this.filter.getTable() === null) {
      this.filter.setTable(this);
    }

    const state = this.tableState.getState(this);

    if (state.filters) {
      for (const filterKey in filters) {
        if (filters.hasOwnProperty(filterKey) && filters[filterKey] instanceof AbstractFilter) {
          delete state.filters[filterKey];
        }
      }
    }

    this.filter.addFilters(filters);
  }

  @Input() set columns(columns: TableColumn[]) {
    if (this.column.getTable() === null) {
      this.column.setTable(this);
    }

    this.originalColumns = [];
    this.tableFrozenColumns = [];
    this.tableFrozenColumnsWidth = 0;
    this.isFilterActive = false;

    for (const column of columns) {
      column.style = column.style || {};
      column.style.width = column.style.width || '220px';

      if (column.frozen && column.visible !== false) {
        this.tableFrozenColumns = [...this.tableFrozenColumns, column];
        this.tableFrozenColumnsWidth += +column.style.width
            .replace('px', '')
            .replace('%', '') || 40;
      } else if (!column.frozen) {
        this.originalColumns = [...this.originalColumns, column];
      }

      if (column.reordable) {
        this.reordable = true;
      }

      if (column.filter) {
        this.isFilterActive = true;
      }

      if (column.rowExpansion) {
        this.rowExpansionColumn = column;
      }
    }

    this.tableColumns = this.getVisibleColumns();
    this.tableFilterColumns = [...this.tableFrozenColumns, ...this.tableColumns];
  }

  @Input() set entities(entities: object[]) {
    this.setEntities(entities);
  }

  @Input() set stickyRows(stickyRows: object[]) {
    this.tableStickyRows = stickyRows;
  }

  @Input() set cells(cells: TableCell[]) {
    this.tableCells = cells;
  }

  @Input() set header(header: TableHeader) {
    this.tableHeader = header;
  }

  @Input() set footer(footer: TableFooter) {
    this.tableFooter = footer;
  }

  @Input() set total(total: any) {
    this.tableTotal = total;
  }

  @Input() set advanceFilters(advanceFilters: TableAdvanceFilter[]) {
    this.tableAdvanceFilters = advanceFilters;
  }

  @Input() set summary(summary: any) {
    this.tableSummary = summary;
  }

  @Input() set height(height: number) {
    this.tableHeight = height;

    this.recalculateScrollHeight();
  }

  @Input() set width(width: number) {
    this.tableWidth = width;
  }

  @Input() rowGroupKey = null;
  @Input() heightCorrection: number = 0;
  @Input() set rowGroup(rowGroupKey: string) {
    this.rowGroupKey = rowGroupKey;
    this.updateRowGroup();
  }

  @Output() cellEdit = new EventEmitter<any>();
  @Output() cellSave = new EventEmitter<any>();
  @Output() cellFocusOut = new EventEmitter<any>();
  @Output() reorderEdit = new EventEmitter<any>();
  @Output() lazyLoad = new EventEmitter<any>();
  @Output() columnVisibilityChanged = new EventEmitter<TableColumnsVisibility>();
  @Output() rowDoubleClick = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() keyboardShortcut = new EventEmitter<TableKeyboardShortcut>();
  @Output() tableSelectionPaste = new EventEmitter<TableSelectionState>();

  public tableFilterColumns: TableColumn[] = [];
  public tableColumns: TableColumn[] = [];
  public tableFrozenColumns: TableColumn[] = [];
  public tableFrozenColumnsWidth = 0;
  public tableCells: TableCell[] = [];
  public tableCellsDictionary: { [cellKey: string]: TableCell|undefined} = {};
  public tableRowGroup: {} = null;
  public tableRowSpan: {} = null;
  public tableEntities: any[] = [];
  public tableStickyRows: any[] = [];
  public tableAdvanceFilters: TableAdvanceFilter[] = [];
  public tableHeader: TableHeader = null;
  public tableFooter: TableFooter = null;
  public tableTotal: TableTotal = null;
  public tableSummary: any = null;
  public isFilterActive = true;
  public reordable = false;
  public rowExpansionColumn: TableColumn = null;

  public tableWidth: number;
  public tableHeight: number;

  public originalColumns: TableColumn[] = [];

  private filterChanged: Subject<FilterChangeMeta> = new Subject<FilterChangeMeta>();
  private cellEdited: Subject<CellEditMeta> = new Subject<CellEditMeta>();

  @HostListener('window:keydown', ['$event']) public onKeyDown(event) {
    if (this.useKeyboardShortcuts && TableKeyboardShortcutService.getShortcutContext(event) !== null) {
      const context = TableKeyboardShortcutService.getShortcutContext(event);

      if (context === KeyboardShortcutContext.Copy) {
        this.tableSelectionState.setEntities(this.getSelectedEntities());
      }

      if (context === KeyboardShortcutContext.Paste) {
        this.tableSelectionPaste.emit({
          selection: this.tableSelectionState.getEntities()
        });
      }

      if (context === KeyboardShortcutContext.Save && this.cellSave.observers.length > 0) {
        this.cellSave.emit(event);
      }

      this.keyboardShortcut.emit({
        event,
        context
      });
    }
  }

  @HostListener('keydown', ['$event']) public onComponentKeyDown(event) {
    if (!this.dataTable.editingCell && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
      this.inline.switchToCurrentlyFocusedEntityCell();
    }
  }

  public constructor(
      public selection: TableSelectionService,
      public filter: TableFilterService,
      public advanceFilterService: TableAdvanceFilterService,
      public column: TableColumnService,
      public inline: TableInlineService,
      public cdr: ChangeDetectorRef,
      public elementRef: ElementRef,
      public tableSelectionState: TableSelectionStateService,
      private tableState: TableStateService,
      private injector: Injector,
      private componentFactoryResolver: ComponentFactoryResolver,
      private translate: TranslateService
  ) {

  }

  public ngOnInit(): void {
    this.selection.setTable(this);
    this.filter.setTable(this);
    this.column.setTable(this);
    this.inline.setTable(this);

    this.tableState.initState(this);

    this.filterChanged
      .pipe(
        debounce(ev => {
          return (event instanceof InputEvent || event instanceof KeyboardEvent) ? timer(700) : EMPTY;
        }),
        distinctUntilChanged())
      .subscribe(meta => {
        this.onFilterChanged(meta);
      });

    this.cellEdited
        .subscribe((event: CellEditMeta) => {
          this.onCellEditEmit(event.event, event.column, event.entity);
        });
  }

  public ngOnDestroy(): void {
    if (this.persistState) {
      this.tableState.saveState(this);
    }
  }

  @HostListener('window:keyup', ['$event'])
  public onKeyUp(event): void {
    const menuColumnsButtons = this.getMenuColumnsButtons();

    for (const menuColumnButton of menuColumnsButtons) {
      if (menuColumnButton.hotkey === event.code) {
        menuColumnButton.click(this.selection.lastSelectedOrUnselectedEntity);
      }
    }
  }

  public onLazyLoadEvent(tableEvent?: any): void {

    // until issue is resolved
    // https://github.com/primefaces/primeng/issues/7226
    this.dataTable.selectRange = (aEvent, rowIndex) => {
      let rangeStart = this.dataTable.anchorRowIndex - this.dataTable.first,
        rangeEnd = rowIndex - this.dataTable.first;

      if (rangeStart > rangeEnd) {
        rangeStart = rowIndex - this.dataTable.first;
        rangeEnd = this.dataTable.anchorRowIndex - this.dataTable.first;
      }

      this.selection.removeAll();

      for (let i = rangeStart; i <= rangeEnd; i++) {
        const rangeRowData = this.dataTable.filteredValue ? this.dataTable.filteredValue[i] : this.dataTable.value[i];
        this.dataTable._selection = [...this.dataTable.selection, rangeRowData];
        const dataKeyValue: string = this.dataTable.dataKey ?
            String(ObjectUtils.resolveFieldData(rangeRowData, this.dataTable.dataKey)) : null;

        if (dataKeyValue) {
          this.dataTable.selectionKeys[dataKeyValue] = 1;
        }

        this.selection.add(rangeRowData);
      }
    };

    const event = {
      filters: this.filter.getFilters(),
      advanceFilters: this.advanceFilterService.getFilters(),
      sortField: this.dataTable.sortField,
      sortOrder: this.dataTable.sortOrder,
      rows: this.dataTable.rows,
      first: this.dataTable.first,
      page: (this.dataTable.first + this.dataTable.rows) / this.dataTable.rows
    };

    this.lazyLoad.emit(event);
  }

  public setEntities(entities): void {
      this.tableEntities = entities;

      if (this.selection.getTable() === null) {
        this.selection.setTable(this);
      }

      this.selection.onEntitiesChange();

      this.selectFirstEntityIfPossible();

      if (this.rowGroupKey) {
        this.updateRowGroup();
      }

      this.cdr.detectChanges();
  }

  public getEntityRowGroupKey(entity): string|null {
    return Entity.getValue(entity, this.rowGroupKey);
  }

  public updateRowGroup(): void {
    this.tableRowGroup = null;
    if (this.tableEntities && this.rowGroupKey) {
      this.tableRowGroup = {};
      for (let i = 0; i < this.tableEntities.length; i++) {
        const entity = this.tableEntities[i],
            brand = Entity.getValue(entity, this.rowGroupKey);

        if (i === 0) {
          this.tableRowGroup[brand] = { index: 0, size: 1 };
        } else {
          const previousEntity = this.tableEntities[i - 1],
              previousRowGroup = Entity.getValue(previousEntity, this.rowGroupKey);
          if (brand === previousRowGroup) {
            this.tableRowGroup[brand].size++;
          } else {
            this.tableRowGroup[brand] = {index: i, size: 1};
          }
        }
      }
    }
  }

  public onFilterEvent(event: any, column: TableColumn, param?: any, isOperatorEvent?: any) {
    this.filterChanged.next({
      event,
      column,
      param,
      isOperatorEvent
    });
  }

  public getFilterValue(column: TableColumn, param?: any): any {
    return this.filter.getFilterValue(column, param);
  }

  public getFilterOperatorValue(column: TableColumn): any {
    return this.filter.getFilterOperatorValue(column);
  }

  public onFilterChanged(meta: FilterChangeMeta): void {
    const column: TableColumn = meta.column,
      filter = this.filter.getOrCreateFilter(column),
      filterKey = this.filter.getFilterKey(column);

    this.filter.addFilter(filterKey, filter);

    if (meta.isOperatorEvent) {
      const operatorValue = filter.getOperatorValueFromChange(meta.isOperatorEvent);

      filter.setFilterOperatorValue(operatorValue);
    } else {
      const value = filter.getValueFromChange(meta.event, meta.param);

      filter.setFilterValue(value);
    }

    if (filter.getFilterValue() === null && column.filter.allowNull !== true) {
      this.filter.removeFilter(filterKey);
    }

    this.selection.removeAll();

    this.resetPagination();

    if (column && typeof column.filter.beforeFilter === 'function') {
      column.filter.beforeFilter(filter);
    }

    this.onLazyLoadEvent();
  }

  public resetPagination(): void {
    this.dataTable.first = 0;
  }

  public onFilterOperatorEvent(event: any, column: TableColumn, param?: any) {
    this.onFilterEvent(null, column, param, event);
  }

  public onCellFocusOut(event: any, column: TableColumn, entity: object) {
    if (event.relatedTarget === null || !event.relatedTarget.classList.contains('btn-save-cell-value')) {
      this.cellFocusOut.emit({
        originalEvent: event,
        column,
        entity
      });
    }
  }

  public onCellEditEvent(event: any, column: TableColumn, entity: object) {
    if (column && column.edit.type === 'hoursAndMinutes') {
      entity[column.key] = event;
    }

    if (column && typeof column.edit.beforeValueEdit === 'function') {
      column.edit.beforeValueEdit(entity, event);
    }

    this.inline.getEditor(column).onEdit(event, column, entity);

    if (column && typeof column.edit.parseValue === 'function') {
      column.edit.parseValue(entity, event);
    }

    if (column && typeof column.edit.beforeValueEmit === 'function') {
      column.edit.beforeValueEmit(entity, event);
    }

    this.cellEdited.next({
      event,
      column,
      entity
    });
  }

  public onCellClickEvent(event: any, column: TableColumn, entity: object): void {
    if (column && typeof column.click === 'function') {
      column.click(entity, event);
    }
  }

  public onCellEditEmit(event: any, column: TableColumn, entity: object): void {
    const isEditFinished = this.inline.getEditor(column).isEditFinished(event, column, entity);

    this.cellEdit.emit({
      originalEvent: event,
      column,
      entity,
      isEditFinished
    });

    if (column && typeof column.edit.afterValueEdit === 'function') {
      column.edit.afterValueEdit(entity, event);
    }
  }

  public onButtonClick(event, entity, button: TableButton): void {
    this.currentlySelectedEntity = entity;
    this.selection.add(entity);

    button.click(entity);
  }

  public onSplitButtonItemClick(event, entity): void {

    if (event.button && event.button.itemClick) {
      event.button.itemClick(this.getCurrentlySelectedEntity(), event.item);
    }
  }

  public onSplitButtonClick(event, entity, button: TableButton): void {
    this.currentlySelectedEntity = entity;
    this.selection.add(entity);

    button.click(entity);
  }

  public onTableButtonClick(event, button: TableButton): void {
    const handler: TableButtonClickHandler = this.injector.get(button.click, null);

    if (handler === null) {
      button.click();
    } else {
      handler.table = this;
      handler.run();
    }
  }

  public onToolbarDropdownChange(event, dropdown: ToolbarItemDropdown): void {
    const handler: TableButtonClickHandler = this.injector.get(dropdown.change, null);

    if (handler === null) {
      dropdown.change();
    } else {
      handler.table = this;
      handler.event = event;
      handler.run();
    }
  }

  public onHeaderCheckboxChange(checked: boolean): void {
    this.selection.isHeaderCheckboxCheckedByUser = checked;
    this.selection.isHeaderCheckboxChecked = checked;

    if (checked) {
      this.selection.selectAll();
    } else {
      this.selection.removeAll();
    }
  }

  public isHeaderCheckboxChecked(): boolean {
    return this.selection.isHeaderCheckboxChecked;
  }

  public isRowCheckboxChecked(entity): boolean {
    return this.selection.isSelected(entity);
  }

  public onRowDoubleClick(event, entity): void {
    this.rowDoubleClick.emit({
      event,
      entity
    });
  }

  public onRowClick(event, entity): void {
    this.rowClick.emit({
      event,
      entity
    });
  }

  onRowCheckboxChecked(entity, event): void {
    if (event.target.checked) {
      this.selection.add(entity);
      this.currentlySelectedEntity = entity;
    } else {
      this.selection.remove(entity);
      // const index = this.entityIndex(entity);

      //
      // if (index !== -1) {
      //   this.selection.splice(index, 1);
      // }
    }
  }

  public onSelect(event: {originalEvent: any, data: typeof Object}): void {
    this.selection.add(event.data);
    this.currentlySelectedEntity = event.data;
  }

  public onUnselect(event: {originalEvent: any, data: typeof Object}): void {

  }

  public onReorder(event: {dragIndex: number, dropIndex: number}): void {
    this.reorderEdit.emit(event);
  }

  public getSelectedEntities(): any[] {
    return this.selection.selection;
  }

  public getValidForDeleteEntities(): any[] {
    let entities = [this.currentlySelectedEntity];

    if (!this.singleEntityDelete) {
      entities = this.getSelectedEntities();
    }

    return entities.filter(entity => {
      if (Entity.hasMethod(entity, 'isValidForDelete')) {
        return entity.isValidForDelete();
      }

      return true;
    });
  }

  public getCurrentlySelectedEntity(): any {
    return this.currentlySelectedEntity;
  }

  public getHiddenColumns(): TableColumn[] {
    const columns = [];

    for (const column of this.getColumns()) {
      if (column.visible === false) {
        columns.push(column);
      }
    }

    return columns;
  }

  public getHiddenColumnsKeys(): TableColumn[] {
    const columns = this.getHiddenColumns(),
      keys = [];

    for (const column of columns) {
      keys.push(column.key);
    }

    return keys;
  }

  public getVisibleColumns(): TableColumn[] {
    const columns = [];

    for (const column of this.getColumns()) {
      if (column.visible !== false) {
        columns.push(column);
      }
    }

    return columns;
  }

  public getFilters(): {[filterKey: string]: AbstractFilter|any } {
    return this.filter.getFilters();
  }

  public getAdvanceFilters(): {[filterKey: string]: AbstractAdvanceFilter|any } {
    return this.advanceFilterService.getFilters();
  }

  public getFilter(): TableFilterService {
    return this.filter;
  }

  public getPagination(): {rows: number, first: number} {
    return {
      rows: this.dataTable.rows || this.rows,
      first: this.dataTable.first
    };
  }

  public getOrder(): {sortField: any, sortOrder: number} {
    return {
      sortField: this.dataTable.sortField,
      sortOrder: this.dataTable.sortOrder
    };
  }

  public getColumns(): TableColumn[] {
    return this.column.getColumns();
  }

  public getFrozenColumns(): TableColumn[] {
    return this.tableFrozenColumns;
  }

  public getColumn(key: string): TableColumn|null {
    const index = this.column.getColumns().findIndex((aColumn: TableColumn) => {
      return key === aColumn.key;
    });

    if (index !== -1) {
      return this.column.getColumns()[index];
    }

    return null;
  }

  public getLastSelectedOrUnselectedEntity(): any|null {
    return this.selection.lastSelectedOrUnselectedEntity;
  }

  public isColumnEditDisabled(column: TableColumn): boolean {
    return typeof column.edit === 'undefined';
  }

  public onMouseEnter(rowData): void {
    rowData.hover = true;
  }

  public onMouseLeave(rowData): void {
    rowData.hover = false;
  }

  public getCellStyle(column: TableColumn, entity: {id: string, uniqueId: string}): any {
    // todo :: maybe optimise this
    const tableCellIndex = this.tableCells.findIndex((aCell: TableCell) => {
      let isEntity = entity && aCell.entity && aCell.entity.id === entity.id;

      if (entity && entity.uniqueId && aCell.entity) {
        isEntity = aCell.entity.uniqueId === entity.uniqueId;
      }

      return entity && aCell.column && aCell.column.key === column.key && isEntity;
    }),
      tableCell = this.tableCells[tableCellIndex];

    let style = {
      textAlign: column.style && column.style.textAlign ? column.style.textAlign : ''
    };

    if (tableCell && typeof tableCell.getClass !== 'undefined') {
      style = {...tableCell.getClass(), ...style};
    }

    return style;
  }

  public getHeaderStyle(column: TableColumn): any {
    const tableCellIndex = this.tableCells.findIndex((aCell: TableCell) => {
          return aCell.column.key === column.key;
        }),
        tableCell = this.tableCells[tableCellIndex];

    let style = {
      textAlign: column.style && column.style.headerTextAlign ? column.style.headerTextAlign : ''
    };

    if (tableCell && typeof tableCell.getHeaderClass !== 'undefined') {
      style = {...tableCell.getHeaderClass(), ...style};
    }

    return style;
  }

  public isColumnRendererDefined(column: TableColumn): boolean {
    return typeof column.renderer !== 'undefined';
  }

  public isColumnRowExpansionIsVisibleDefined(column: TableColumn): boolean {
    return typeof column.rowExpansionIsVisible !== 'undefined';
  }

  public onColumnsSaved(): void {
    this.columnVisibilityDialog = false;

    this.columnVisibilityChanged.emit({visible: this.getVisibleColumns(), hidden: this.getHiddenColumns()});
  }

  public onColumnsSaveCanceled(): void {
    this.columnVisibilityDialog = false;
  }

  public onColumnsReset(): void {
    this.columnVisibilityDialog = false;
  }

  public isSummaryActive(): boolean {
    return this.tableSummary !== null && typeof this.tableSummary !== 'undefined';
  }

  public isTableTotalActive(): boolean {
    return this.tableTotal !== null && typeof this.tableTotal !== 'undefined';
  }

  public isStickyRowsActive(): boolean {
    return this.tableStickyRows !== null && typeof this.tableStickyRows !== 'undefined';
  }

  public getStickyColumnValue(rowNumber: number, column: any): string {
    return (this.tableStickyRows && this.tableStickyRows[rowNumber][column]) ? this.tableStickyRows[rowNumber][column] : '';
  }

  private recalculateScrollHeight(): void {
    const tableBody = this.elementRef.nativeElement.querySelector('.ui-table-scrollable-wrapper');

    if (tableBody) {
      // header, filter, paginator
      // TODO :: calculate this
      let scrollHeight = this.tableHeight - 116;
      scrollHeight += this.heightCorrection;

      if (this.tableFooter !== null) {
        scrollHeight -= 38;
      }

      if (this.tableHeader !== null) {
        scrollHeight -= 38;
      }

      tableBody.style.maxHeight = `${scrollHeight + 80}px`;
      tableBody.style.height = `${scrollHeight + 80}px`;

      this.tableHeight = scrollHeight;
      this.cdr.detectChanges();
    }
  }

  private selectFirstEntityIfPossible(): void {
    if (this.selectFirstEntityWhenNothingIsSelected && this.tableEntities.length > 0) {
      this.selection.add(this.tableEntities[0]);

      this.selection.lastSelectedOrUnselectedEntity = this.tableEntities[0];
    }
  }

  private getMenuColumnsButtons(): TableColumnMenuButton[] {
    const menuColumns = this.getColumns().filter((aTableColumn: TableColumn) => aTableColumn.menu),
      menuColumnsButtons = [];

    for (const menuColumn of menuColumns) {
      if (menuColumn.menu.buttons instanceof Array) {
        for (const menuColumnButton of menuColumn.menu.buttons) {
          menuColumnsButtons.push(menuColumnButton);
        }
      }
    }

    return menuColumnsButtons;
  }

  public getTotalColumn(key: string): TableTotalColumn {
    return this.tableTotal.columns.filter(column => column.key === key).pop();
  }

  public getTotalColumnValue(key: string): number {
    const column = this.getTotalColumn(key);

    if (typeof column !== 'undefined' && typeof column.value === 'function') {
      return column.value();
    }

    let sum = 0.00;

    for (const entity of this.tableEntities) {
      sum += Number(Entity.getValue(entity, key));
    }

    return sum;
  }
}
