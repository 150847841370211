import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Estimate} from '../entities/estimate';
import {Observable} from 'rxjs';
import {Invoice} from '../entities/invoice';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {InvoiceType, InvoiceTypeEnum} from '../entities/invoice-type';

@Injectable({
    providedIn: 'root'
})
export class InvoiceRepository extends EntityRepository {

    public create(estimate: Estimate): Observable<Invoice> {
        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}estimates/generateinvoice/${estimate.getId()}`,
            {
                estimate
            }
        )
        .pipe(
            map((invoice: any) => {
                return this.parser.getParser().parse(new Invoice(), invoice);
            })
        );
    }

    public createFromDeliveries(deliveries: string[]): Observable<Invoice> {
        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}deliveries/generateinvoice`,
            deliveries
        ).pipe(
            map((invoice: any) => {
                return this.parser.getParser().parse(new Invoice(), invoice);
            })
        );
    }

    public createFromOrders(orders: string[]) {
        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}orders/generateinvoice`,
            orders
        ).pipe(
            map((invoice: any) => {
                return this.parser.getParser().parse(new Invoice(), invoice);
            })
        );
    }

    public generateNumber(invoiceType: InvoiceType): Observable<string> {
        let type = invoiceType.getCode();

        if (type === InvoiceTypeEnum.ForeignIncoming) {
            type = InvoiceTypeEnum.Incoming;
        }

        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}invoices/generatenumber`,
            {
                invoiceType: type
            }
        ).pipe(
            map((generatedNumber: {number: string}) => {
                return generatedNumber.number;
            })
        );
    }
}
