import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {ProductType} from './product-type';
import {CrmCompany} from './crm-company';
import {Vat} from './vat';
import {Unit} from './unit';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

@Route('products')
@CrossDependency(() => {
    return {
        productType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: ProductType
        },
        vat: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vat
        },
        supplier: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: CrmCompany
        },
        unit: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Unit
        }
    };
})
export class Product {
    @AssociationOne(ProductType)
    protected productType: ProductType;

    @AssociationOne(Vat)
    protected vat: Vat;

    @AssociationOne(CrmCompany)
    protected supplier: CrmCompany;

    protected rebate: number;

    @AssociationOne(Unit)
    protected unit: Unit;

    protected name: string;
    protected price: number;
    protected plannedPrice: number;
    protected finalProduct: boolean;
    protected catalogCode: string;
    protected tariffCode: string;
    protected criticalQuantity: number;
    protected quantity: number;
    protected hyperlink: string;
    protected notice: string;
    protected id: string;
    protected code: string;
    protected description: string;
    protected priceWithVat: number;
    protected priceWithRebate: number;
    protected priceWithVatAndRebate: number;
    protected hasNormative: boolean;

    public setName(name: string): Product {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setProductType(productType: ProductType): Product {
        this.productType = productType;
        return this;
    }

    public getProductType(): ProductType | undefined {
        return this.productType;
    }

    public setVat(vat: Vat) {
        this.vat = vat;
        return this;
    }

    public getVat(): Vat {
        return this.vat;
    }

    public setPrice(price: number) {
        this.price = price;
        return this;
    }

    public getPrice(): number {
        return this.price;
    }

    public setPlannedPrice(plannedPrice: number) {
        this.plannedPrice = plannedPrice;
        return this;
    }

    public getPlannedPrice(): number {
        return this.plannedPrice;
    }

    public setFinalProduct(finalProduct: boolean) {
        this.finalProduct = finalProduct;
        return this;
    }

    public getFinalProduct(): boolean {
        return this.finalProduct;
    }

    public setCatalogCode(catalogCode: string) {
        this.catalogCode = catalogCode;
        return this;
    }

    public getCatalogCode(): string {
        return this.catalogCode;
    }

    public setTariffCode(tariffCode: string) {
        this.tariffCode = tariffCode;
        return this;
    }

    public getTariffCode(): string {
        return this.tariffCode;
    }

    public setCriticalQuantity(criticalQuantity: number) {
        this.criticalQuantity = criticalQuantity;
        return this;
    }

    public getCriticalQuantity(): number {
        return this.criticalQuantity;
    }

    public setQuantity(quantity: number) {
        this.quantity = quantity;
        return this;
    }

    public getQuantity(): number {
        return this.quantity;
    }

    public setHyperlink(hyperlink: string) {
        this.hyperlink = hyperlink;
        return this;
    }

    public getHyperlink(): string {
        return this.hyperlink;
    }

    public setNotice(notice: string) {
        this.notice = notice;
        return this;
    }

    public getNotice(): string {
        return this.notice;
    }

    public setSupplier(supplier: CrmCompany) {
        this.supplier = supplier;
        return this;
    }

    public getSupplier(): CrmCompany {
        return this.supplier;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setRebate(rebate: number) {
        this.rebate = rebate;
        return this;
    }

    public getRebate(): number {
        return this.rebate;
    }

    public setPriceWithVat(priceWithVat: number) {
        this.priceWithVat = priceWithVat;
        return this;
    }

    public getPriceWithVat(): number {
        return this.priceWithVat;
    }

    public setPriceWithRebate(priceWithRebate: number) {
        this.priceWithRebate = priceWithRebate;
        return this;
    }

    public getPriceWithRebate(): number {
        return this.priceWithRebate;
    }

    public setPriceWithVatAndRebate(priceWithVatAndRebate: number) {
        this.priceWithVatAndRebate = priceWithVatAndRebate;
        return this;
    }

    public getPriceWithVatAndRebate(): number {
        return this.priceWithVatAndRebate;
    }

    public getUnit(): Unit {
        return this.unit;
    }

    public setUnit(unit: Unit) {
        this.unit = unit;
        return this;
    }

    public getHasNormative(): boolean {
        return this.hasNormative;
    }

    public setHasNormative(hasNormative: boolean): this {
        this.hasNormative = hasNormative;
        return this;
    }
}
