import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {PaymentDeductionContract} from '../entities/payment-deduction-contract';
import {PaymentDeductionTypeEnum} from '../entities/payment-deduction-type';
import {Employee} from '../entities/employee';

@Injectable({
    providedIn: 'root'
})
export class PaymentDeductionContractRepository extends EntityRepository {

    public getActive(employee: Employee, type: PaymentDeductionTypeEnum): Observable<PaymentDeductionContract|null> {
        return this.findOne({
            'employee.id': employee.getId(),
            'order[fromDate]': 'DESC',
            'type.code': type,
            isActive: true,
            itemsPerPage: 1
        });
    }
}
