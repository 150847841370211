import {Route} from 'entity-manager';

@Route('work_note_action_codes')
export class WorkNoteActionCode {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;
    protected value: number;
    protected numberOfMonths: number;

    public setId(id: string): WorkNoteActionCode {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string): WorkNoteActionCode {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }
    public setName(name: string): WorkNoteActionCode {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string): WorkNoteActionCode {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setValue(value: number): WorkNoteActionCode {
        this.value = value;
        return this;
    }

    public getValue(): number {
        return this.value;
    }

    public setNumberOfMonths(numberOfMonths: number): WorkNoteActionCode {
        this.numberOfMonths = numberOfMonths;
        return this;
    }

    public getNumberOfMonths(): number {
        return this.numberOfMonths;
    }
}
