import {Route} from 'entity-manager';
import {Employee} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('verifications')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class Verification {

    protected id: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected issueDate: Date;
    protected type: string;
    protected purpose: string;

    @AssociationOne(Employee)
    protected employee: Employee;

    public getId(): string {
        return this.id;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getEmployee(): Employee {
        return this.employee;
    }

    public setEmployee(employee: Employee) {
        this.employee = employee;
        return this;
    }

    public getIssueDate(): Date {
        return this.issueDate;
    }

    public setIssueDate(issueDate: Date) {
        this.issueDate = issueDate;
        return this;
    }

    public getType(): string {
        return this.type;
    }

    public setType(type: string) {
        this.type = type;
        return this;
    }

    public getPurpose(): string|undefined {
        return this.purpose;
    }

    public setPurpose(purpose: string): this {
        this.purpose = purpose;
        return this;
    }
}
