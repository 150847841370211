import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef, Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {of, Subject} from 'rxjs';
import {delay, takeUntil} from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-flip-panel',
    styleUrls: ['./flip-panel.component.scss'],
    templateUrl: './flip-panel.component.html'
})
export class FlipPanelComponent implements OnInit, OnDestroy {

    @Input() public header = '';
    @Input() public styleClass = '';
    public context: 'main' | 'secondary' = 'main';
    public unsubscribe$ = new Subject<void>();

    public constructor(
        private cdr: ChangeDetectorRef,
        private elementRef: ElementRef
    ) {

    }

    public ngOnInit(): void {
        this.openMain(false);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public openSecondary(): void {
        this.context = 'secondary';
        this.cdr.detectChanges();

        this.runFlipAnimation();
    }

    public openMain(flip: boolean = true): void {
        this.context = 'main';
        this.cdr.detectChanges();

        if (flip) {
            this.runFlipAnimation();
        }
    }

    public runFlipAnimation(): void {
        this.elementRef.nativeElement
            .querySelector('.container').classList
            .remove('flip');

        of(true).pipe(
            delay(10)
        )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.elementRef.nativeElement
                    .querySelector('.container').classList
                    .add('flip');
            });
    }
}
