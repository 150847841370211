import {
    ChangeDetectionStrategy,
    Component, EventEmitter, forwardRef, Input,
    OnInit, Output
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as moment from 'moment';
import {MathUtil} from '../../../core/utils/math-util';

interface ReturnValue {
    hours: number;
    minutes: number;
    duration: number;
}

const EXPANDED_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HourSpinnerComponent),
    multi: true,
};

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-hour-spinner',
    styleUrls: ['./hour-spinner.component.scss'],
    templateUrl: './hour-spinner.component.html',
    providers: [EXPANDED_VALUE_ACCESSOR]
})
export class HourSpinnerComponent implements OnInit, ControlValueAccessor {

    @Output() public valueChange = new EventEmitter<ReturnValue | number>();
    @Input() public context = 'duration';
    @Input() public step = 1;
    @Input() public maxHours = 1000;
    @Input() public maxMinutes = 59;
    @Input() public allowNull = false;
    @Input() public showClearButton = false;
    @Input() public hideDuration = false;
    @Input() public changeOnFocus = false;
    @Input() public changeOnBlur = false;

    @Input()
    public set maxDuration(maxDuration) {
        this.maxHours = 1000;
        this.maxMinutes = 59;
        if (maxDuration > 0) {
            this.maxHours = Math.floor(
                +MathUtil.round(moment.duration(maxDuration, 'hour').asHours(), 2)
            );

            if (this.isFullNumber(maxDuration)) {
                this.maxMinutes = Math.round(
                    +MathUtil.round(moment.duration(maxDuration, 'hour').asMinutes() % 60, 2)
                );
            }
        }
    }

    public realValue;
    public hours: number;
    public minutes: number;
    public onChange: any = () => {};
    public onTouched: any = () => {};

    get duration(): number {
        if (this.hours === null && this.minutes === null && this.allowNull) {
            return null;
        } else {
            return +MathUtil.round(moment.duration({
                hours: this.hours,
                minutes: this.minutes,
                seconds: 0
            }).asHours(), 4);
        }
    }

    public ngOnInit(): void {
        // todo :: edit is probably not gonna work, fix when you get edit form to work with...
    }

    get value() {
        return this.realValue;
    }

    @Input() set value(val) {
        this.realValue = val;
        if (this.realValue === null && this.allowNull) {
            this.hours = null;
            this.minutes = null;
        } else {
            if (val > 0) {
                this.hours = Math.floor(
                    +MathUtil.round(moment.duration(this.realValue, 'hour').asHours(), 2)
                );

                if (this.isFullNumber(val)) {
                    this.minutes = Math.floor(
                        +MathUtil.round(moment.duration(this.realValue, 'hour').asMinutes() % 60, 2)
                    );
                }
            }
        }

        this.onChange(val);
        this.onTouched();
    }

    public isFullNumber(maxDuration): boolean {
        return maxDuration % 1 !== 0;
    }

    public onValueChange(event): void {
        this.value = this.getChangeValue();
        this.valueChange.emit(this.getChangeValue());
    }

    public clearInput(): void {
        this.hours = null;
        this.minutes = null;
        this.value = this.getChangeValue();
        this.valueChange.emit(this.getChangeValue());
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public writeValue(value: any): void {
        this.value = value;
    }

    public setDisabledState(isDisabled: boolean): void {
    }

    protected getChangeValue(): ReturnValue | number {
        if (this.context === 'duration') {
            return this.duration;
        }

        return {
            hours: this.hours,
            minutes: this.minutes,
            duration: this.duration
        };
    }

}
