import {PropertyPayloadModifierInterface} from '../property-payload-modifier-interface';

export class StringPropertyPayloadModifier implements PropertyPayloadModifierInterface {

    public modify(property: string, entity: any) {
        if (typeof entity[property] !== 'undefined') {
            entity[property] = entity[property].toString();
        }
    }
}
