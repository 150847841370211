import {Injectable, Injector} from '@angular/core';
import {EntityManagerStateService, State} from './entity-manager-state.service';
import {forkJoin, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {EntityIdMissing} from '../error/entity-id-missing.error';
import {EntityManagerModifierService} from './entity-manager-modifier.service';
import {EntityManagerMetaDataService} from './meta/entity-manager-meta-data.service';
import {EntityManagerParserService} from './parser/entity-manager-parser.service';
import {Meta} from './meta/meta';
import {EntityManagerConfigurator} from '../entity-manager.configurator';
import {EntityPropertyModifierService} from './entity-property-modifier.service';

@Injectable({
  providedIn: 'root'
})
export class UnitOfWorkService {

  private configuration;

  public constructor(
    private connection: HttpClient,
    private state: EntityManagerStateService,
    private modifier: EntityManagerModifierService,
    private propertyModifier: EntityPropertyModifierService,
    private meta: EntityManagerMetaDataService,
    private parser: EntityManagerParserService,
    private injector: Injector
  ) {
    this.configuration = this.injector.get<EntityManagerConfigurator>(EntityManagerConfigurator);
  }

  public persist(entity: any): UnitOfWorkService {
    this.state.persist(entity);

    return this;
  }

  public remove(entity: any): UnitOfWorkService {
    this.state.remove(entity);

    return this;
  }

  public clear(): UnitOfWorkService {
    this.state.clear();

    return this;
  }

  public commit(entity: any = null): Observable<any> {
    if (entity === null) {
      return this.computeChangeSets();
    } else if (entity instanceof Array) {
      const flush = [];

      for (const e of entity) {
        flush.push(this.computeSingleEntityChangeSet(e));
      }

      if (flush.length === 0) {
        return of([]);
      }

      return forkJoin(flush);
    }

    return this.computeSingleEntityChangeSet(entity);
  }

  private computeChangeSets(): Observable<any> {
    const flush = [];

    for (const entity of this.state.getEntities(State.Create)) {
      flush.push(this.post(entity));
    }

    for (const entity of this.state.getEntities(State.Update)) {
      flush.push(this.put(entity));
    }

    for (const entity of this.state.getEntities(State.Delete)) {
      flush.push(this.delete(entity));
    }

    if (flush.length === 0) {
      return of([]);
    }

    return new Observable(observer => {
      forkJoin(flush)
          .subscribe(
              (flushed) => {
                this.state.clear();

                observer.next(flushed);
                observer.complete();
              });
    });
  }

  private computeSingleEntityChangeSet(entity): Observable<any> {
    const state = this.state.getEntityState(entity);

    if (state === State.Create) {
      return this.post(entity);
    }

    if (state === State.Update) {
      return this.put(entity);
    }

    if (state === State.Delete) {
      return this.delete(entity);
    }

    return of();
  }

  public post(toCreateEntity: any): Observable<any> {
    const request = this.getPostRequest(toCreateEntity);

    return this.connection
      .post(request.url, request.body, {
        headers: request.headers,
        params: request.params
      })
      .pipe(
          tap(() => {
            this.state.removeFromState(State.Create, toCreateEntity);
          }),
          map((data: any) => {
            return this.modifier.modifyResponse(
                toCreateEntity,
                request,
                data,
                'post'
            );
          })
      );
  }

  public put(toUpdateEntity: any): Observable<any> {
    const request = this.getPutRequest(toUpdateEntity);

    return this.connection
      .put(request.url, request.body, {
        headers: request.headers,
        params: request.params
      })
      .pipe(
          tap(() => {
            this.state.removeFromState(State.Update, toUpdateEntity);
          }),
          map((data: any) => {
            return this.modifier.modifyResponse(
                toUpdateEntity,
                request,
                data,
                'put'
            );
          })
      );
  }

  public delete(toDeleteEntity: any): Observable<any> {
    const request = this.getDeleteRequest(toDeleteEntity);

    return this.connection.delete(request.url)
        .pipe(
            tap(() => {
              this.state.removeFromState(State.Delete, toDeleteEntity);
            })
        );
  }

  private getPostRequest(toCreateEntity: any): HttpRequest<any> {
    const apiRoute = this.meta.getMetaDataProperty(toCreateEntity, Meta.META_ROUTE);

    this.propertyModifier.modifyPayload(toCreateEntity);

    return this.modifier.modifyRequest(
        toCreateEntity,
        new HttpRequest<any>('POST', this.configuration.urlPrefix + apiRoute, toCreateEntity));
  }

  private getPutRequest(toUpdateEntity: any): HttpRequest<any> {
    const id = toUpdateEntity.id,
      apiRoute = this.meta.getMetaDataProperty(toUpdateEntity, Meta.META_ROUTE);

    if (!id) {
      throw new EntityIdMissing(toUpdateEntity);
    }

    this.propertyModifier.modifyPayload(toUpdateEntity);

    return this.modifier.modifyRequest(
      toUpdateEntity,
      new HttpRequest<any>('PUT', this.configuration.urlPrefix + apiRoute + '/' + id, toUpdateEntity)
    );
  }

  private getDeleteRequest(toDeleteEntity: any): HttpRequest<any> {
    const id = toDeleteEntity.id,
      apiRoute = this.meta.getMetaDataProperty(toDeleteEntity, Meta.META_ROUTE);

    if (!id) {
      throw new EntityIdMissing(toDeleteEntity);
    }

    return this.modifier.modifyRequest(
      toDeleteEntity,
      new HttpRequest<any>('DELETE', this.configuration.urlPrefix + apiRoute + '/' + toDeleteEntity.id)
    );
  }

}
