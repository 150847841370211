import {Route} from 'entity-manager';
import {CalendarEntry, CalendarEntryItemType, Employee, User} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {StringPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';


@Route('calendar_entry_items')
@EntityDefaultFilters({
    disableVisibleFilter: 1,
    'exists[employee.employment.dismissalType]': false
})
@CrossDependency(() => {
    return {
        calendarEntry: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: CalendarEntry
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: CalendarEntryItemType
        },
        createdBy: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        }
    };
})
export class CalendarEntryItem {

    protected id: string;

    @AssociationOne(CalendarEntry)
    protected calendarEntry: CalendarEntry;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(User)
    protected createdBy;

    @AssociationOne(CalendarEntryItemType)
    protected type: CalendarEntryItemType;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected entryDate: Date;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected duration: string;

    protected notice: string;

    protected isNoticeRequired: boolean;

    protected approved: boolean;
    protected corrected: boolean;
    protected comment: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected createdAt: Date;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public getCalendarEntry(): CalendarEntry | undefined {
        return this.calendarEntry;
    }

    public setCalendarEntry(calendarEntry: CalendarEntry): this {
        this.calendarEntry = calendarEntry;
        return this;
    }

    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getCreatedBy(): User | undefined {
        return this.createdBy;
    }

    public setCreatedBy(createdBy: User): this {
        this.createdBy = createdBy;
        return this;
    }

    public getType(): CalendarEntryItemType | undefined {
        return this.type;
    }

    public setType(type: CalendarEntryItemType): this {
        this.type = type;
        return this;
    }

    public getEntryDate(): Date | undefined {
        return this.entryDate;
    }

    public setEntryDate(entryDate: Date): this {
        this.entryDate = entryDate;
        return this;
    }

    public setDuration(duration: string): this {
        this.duration = duration;
        return this;
    }

    public getDuration(): string | undefined {
        return this.duration;
    }

    public getNotice(): string | undefined {
        return this.notice;
    }

    public setNotice(notice: string): this {
        this.notice = notice;
        return this;
    }

    public getIsNoticeRequired(): boolean | undefined {
        return this.isNoticeRequired;
    }

    public setIsNoticeRequired(isNoticeRequired: boolean): this {
        this.isNoticeRequired = isNoticeRequired;
        return this;
    }

    public isApproved(): boolean | undefined {
        return this.approved;
    }

    public setApproved(approved: boolean): this {
        this.approved = approved;
        return this;
    }

    public setCreatedAt(createdAt: Date): this {
        this.createdAt = createdAt;
        return this;
    }

    public getCreatedAt(): Date|undefined {
        return this.createdAt;
    }

    public getCorrected(): boolean {
        return this.corrected;
    }

    public setCorrected(corrected: boolean): this {
        this.corrected = corrected;
        return this;
    }

    public getComment(): string|undefined {
        return this.comment;
    }

    public setComment(comment: string|undefined): this {
        this.comment = comment;
        return this;
    }
}
