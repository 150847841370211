<div class="layout-topbar">
    <a class="logo" routerLink="/home">
        <img src="{{logo}}" alt="{{companyCode}}"/>
    </a>
    <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
        <i class="fa fa-align-left"></i>
    </a>


    <app-notification></app-notification>

    <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
        <img src="{{profilePictureUrl}}">
    </a>
    <app-admin-menu></app-admin-menu>
</div>
