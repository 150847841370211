import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CalendarEntry, Department, Employee} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {TodoState} from './todo-state';

@Route('todos')
@CrossDependency(() => {
    return {
        calendarEntry: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: CalendarEntry
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        employees: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: Employee
        },
        departments: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: Department
        },
        todoStates: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: TodoState
        },
        currentEmployeeTodoState: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: TodoState
        }
    };
})

export class Todo {

    @AssociationOne(CalendarEntry)
    protected calendarEntry: CalendarEntry;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationMany(Employee)
    protected employees: Employee[];

    @AssociationMany(Department)
    protected departments: Department[];

    @AssociationMany(TodoState)
    protected todoStates: TodoState[];

    @AssociationOne(TodoState)
    protected currentEmployeeTodoState: TodoState;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected date: Date;

    protected completed: boolean;
    protected id: string;
    protected name: string;
    protected description: string;
    protected isAllEmployees: boolean;

    public setCalendarEntry(calendarEntry: CalendarEntry): this {
        this.calendarEntry = calendarEntry;
        return this;
    }

    public getCalendarEntry(): CalendarEntry | undefined {
        return this.calendarEntry;
    }

    public setEmployee(employee: Employee) {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee {
        return this.employee;
    }

    public getTodoStates(): TodoState[] | undefined {
        return this.todoStates;
    }

    public setTodoStates(todoStates: TodoState[] | undefined): Todo {
        this.todoStates = todoStates;
        return this;
    }

    public getCurrentEmployeeTodoState(): TodoState | undefined {
        return this.currentEmployeeTodoState;
    }

    public setCurrentEmployeeTodoState(currentEmployeeTodoState: TodoState | undefined): Todo {
        this.currentEmployeeTodoState = currentEmployeeTodoState;
        return this;
    }

    public setDate(date: Date) {
        this.date = date;
        return this;
    }

    public getDate(): Date | undefined {
        return this.date;
    }

    public setCompleted(completed: boolean): this {
        this.completed = completed;
        return this;
    }

    public getCompleted(): boolean {
        return this.completed;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setEmployees(employees: Employee[]): Todo {
        this.employees = employees;
        return this;
    }

    public getEmployees(): Employee[] | undefined {
        return this.employees;
    }

    public setDepartments(departments: Department[]): Todo {
        this.departments = departments;
        return this;
    }

    public getDepartments(): Department[] | undefined {
        return this.departments;
    }

    public setIsAllEmployees(isAllEmployees: boolean): this {
        this.isAllEmployees = isAllEmployees;
        return this;
    }

    public getIsAllEmployees(): boolean {
        return this.isAllEmployees;
    }
}
