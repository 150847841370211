import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'businessEmail'
})
export class BusinessEmailPipe implements PipeTransform {

    public constructor() {}

    transform(value: string, suffix: string, removeSuffix: boolean = true): string {
        if (!value) {
            return '';
        }

        if (removeSuffix && value.indexOf(suffix) !== -1) {
            return value.split(suffix).shift();
        }

        if (!removeSuffix && value.indexOf(suffix) === -1) {
            value += suffix;
        }

        return value;
    }
}
