import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Employee} from '../entities/employee';
import {EmployeeMonthlySalaryCalculation} from '../entities/employee-monthly-salary-calculation';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Entity} from '../../../../projects/entity-manager/src/lib/helper/entity';
import * as moment from 'moment';
import {HttpRequest} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EmployeeMonthlySalaryCalculationRepository extends EntityRepository {

    public sendEmails(entryYear: number, entryMonth: number): Observable<any> {

        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}employee_monthly_salary_calculations/send/email`,
            {
                entryYear,
                entryMonth
            }
        ).pipe(
            map(() => {
                return null;
            })
        );
    }

    public sendEmailsSelected(selectedIds: string[]): Observable<any> {
        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}employee_monthly_salary_calculations/send/email/selected`,
            {
                selectedIds
            }
        ).pipe(
            map(() => {
                return null;
            })
        );
    }

    public getLastXMonths(employee: Employee, dateTo: Date, lastX: number): Observable<any> {
        const route = this.meta.getMetaDataProperty(new EmployeeMonthlySalaryCalculation(), Meta.META_ROUTE),
            data = {
                employee: Entity.getValue(employee, '@id'),
                dateTo: moment(dateTo).format('YYYY-MM-DDTHH:mm:ss'),
                lastX
            };

        return this.connection.post(environment.ENTITY_MANAGER_URL_PREFIX + route + '/get_last_x', data)
            .pipe(map((response: any[]) => {
                return this.modifier.modifyResponse(
                    new EmployeeMonthlySalaryCalculation(),
                    new HttpRequest<any>('POST', environment.ENTITY_MANAGER_URL_PREFIX + route + '/get_last_x', data),
                    response,
                    'findMore'
                );
            }));
    }
}
