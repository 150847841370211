export abstract class AbstractSetting {

    protected id: string;
    protected area: string;
    protected code: string;
    protected name: string;
    protected value: any;
    protected owner: any;
    protected type: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setArea(area: string): this {
        this.area = area;
        return this;
    }

    public getArea(): string {
        return this.area;
    }

    public setCode(code: string): this {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string): this {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setValue(value: any): this {
        this.value = value;
        return this;
    }

    public getValue(): any|null {
        return this.value;
    }

    public setType(type: string): this {
        this.type = type;
        return this;
    }

    public getType(): string {
        return this.type;
    }

    public setOwner(owner): this {
        this.owner = owner;
        return this;
    }

    public getOwner(): any {
        return this.owner;
    }
}
