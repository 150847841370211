import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {
    AccordionModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    DropdownModule,
    FieldsetModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    EditorModule,
    PasswordModule,
    SelectButtonModule,
    TabViewModule,
    TreeModule,
    ToolbarModule,
    FileUploadModule,
    SpinnerModule,
    DragDropModule,
    MultiSelectModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    MessagesModule,
    MessageModule,
    BlockUIModule,
    ProgressBarModule,
    TreeTableModule,
    PanelModule,
    ToggleButtonModule,
    TooltipModule,
    OverlayPanelModule,
    SliderModule,
    RadioButtonModule,
    SplitButtonModule,
    OrderListModule,
    DialogModule,
    SidebarModule,
    ChartModule,
    PickListModule,
    ScrollPanelModule,
    MenuModule,
    ProgressSpinnerModule,
    ColorPickerModule,
    CardModule,
    DataViewModule,
    GMapModule,
    TriStateCheckboxModule

} from 'primeng';
import {FullCalendarModule} from 'primeng/fullcalendar';

import {ToastModule} from 'primeng/toast';
import {TableModule} from 'primeng/table';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {TabMenuModule} from 'primeng/tabmenu';
import {TranslateModule} from '@ngx-translate/core';
import {DragulaModule} from 'ng2-dragula';
import {PdfViewerModule} from 'ng2-pdf-viewer';

export const sharedExports = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    DropdownModule,
    FieldsetModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    EditorModule,
    PasswordModule,
    SelectButtonModule,
    TabViewModule,
    TreeModule,
    ToolbarModule,
    ToastModule,
    TableModule,
    ProgressSpinnerModule,
    FileUploadModule,
    SpinnerModule,
    DragDropModule,
    MultiSelectModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    ColorPickerModule,
    MessageModule,
    MessagesModule,
    BlockUIModule,
    ProgressBarModule,
    TreeTableModule,
    PanelModule,
    ToggleButtonModule,
    TooltipModule,
    OverlayPanelModule,
    SliderModule,
    ChartModule,
    TableModule,
    RadioButtonModule,
    SplitButtonModule,
    OrderListModule,
    SidebarModule,
    DialogModule,
    PickListModule,
    ScrollPanelModule,
    FullCalendarModule,
    TranslateModule,
    DynamicDialogModule,
    TabMenuModule,
    MenuModule,
    CardModule,
    DataViewModule,
    DragulaModule,
    PdfViewerModule,
    GMapModule,
    TriStateCheckboxModule
];
