import {AbstractAdvanceFilter} from './abstract-advance-filter';


export class AutocompleteAdvanceFilter extends AbstractAdvanceFilter {

    public getValueFromChange(event, param) {
        if (param && param.dataKey) {
            return event ? event[param.dataKey] : null;
        }

        return event ? event.id : null;
    }
}
