import {Repository, Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {WorkNoteType, WorkNoteSubType} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {WorkNoteActionRepository} from '../repositories/work-note-action.repository';
import {WorkNoteActionCode} from './work-note-action-code';

@Route('work_note_actions')
@CrossDependency(() => {
    return {
        workNoteType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteType
        },
        workNoteSubType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteSubType
        },
        workNoteActionCode: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteActionCode
        }
    };
})
@Repository(WorkNoteActionRepository)
export class WorkNoteAction {

    @AssociationOne(WorkNoteType)
    protected workNoteType: WorkNoteType;

    @AssociationOne(WorkNoteSubType)
    protected workNoteSubType;

    @AssociationOne(WorkNoteActionCode)
    protected workNoteActionCode: WorkNoteActionCode;

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;
    protected from: number;
    protected to: number;
    protected currentNumberOfPoints: number;
    protected newPoints: number;
    protected isDefault: boolean;
    protected sortPosition: number;

    public setId(id: string): WorkNoteAction {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string): WorkNoteAction {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string): WorkNoteAction {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string): WorkNoteAction {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public getWorkNoteType(): WorkNoteType {
        return this.workNoteType;
    }

    public setWorkNoteType(workNoteType: WorkNoteType): WorkNoteAction {
        this.workNoteType = workNoteType;
        return this;
    }

    public getFrom(): number {
        return this.from;
    }

    public setFrom(from: number): WorkNoteAction {
        this.from = from;
        return this;
    }

    public getTo(): number {
        return this.to;
    }

    public setTo(to: number): WorkNoteAction {
        this.to = to;
        return this;
    }

    public getCurrentNumberOfPoints(): number {
        return this.currentNumberOfPoints;
    }

    public setCurrentNumberOfPoints(currentNumberOfPoints: number): WorkNoteAction {
        this.currentNumberOfPoints = currentNumberOfPoints;
        return this;
    }

    public getNewPoints(): number {
        return this.newPoints;
    }

    public setNewPoints(newPoints: number): WorkNoteAction {
        this.newPoints = newPoints;
        return this;
    }

    public setWorkNoteSubType(workNoteSubType: WorkNoteSubType): WorkNoteAction {
        this.workNoteSubType = workNoteSubType;
        return this;
    }

    public getWorkNoteSubType(): WorkNoteSubType | undefined {
        return this.workNoteSubType;
    }

    public setWorkNoteActionCode(workNoteActionCode: WorkNoteActionCode): WorkNoteAction {
        this.workNoteActionCode = workNoteActionCode;
        return this;
    }

    public getWorkNoteActionCode(): WorkNoteActionCode {
        return this.workNoteActionCode;
    }

    public getIsDefault(): boolean {
        return this.isDefault;
    }

    public setIsDefault(isDefault: boolean): WorkNoteAction {
        this.isDefault = isDefault;
        return this;
    }

    public getSortPosition(): number {
        return this.sortPosition;
    }

    public setSortPosition(sortPosition: number): WorkNoteAction {
        this.sortPosition = sortPosition;
        return this;
    }
}
