import {Route} from 'entity-manager';

export enum PersonalDocumentTypeEnum {
    IdCard = 'idCard',
    Passport = 'passport'
}

const NON_DELETABLE_CODES: string[] = [
    PersonalDocumentTypeEnum.IdCard,
    PersonalDocumentTypeEnum.Passport
];

@Route('personal_document_types')
export class PersonalDocumentType {

    protected id: string;
    protected code: string;
    protected name: string;
    protected mask: string;
    protected description: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription() {
        return this.description;
    }

    public setMask(mask: string) {
        this.mask = mask;
        return this;
    }

    public getMask() {
        return this.mask;
    }

    public isValidForDelete(): boolean {
        return !NON_DELETABLE_CODES.includes(this.code);
    }
}
