import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Delivery, Unit, Product, Vat, ProductType} from './entities';
import {Route} from '../../../../projects/entity-manager/src/lib/decorator/route';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {DeliveryItemRepository} from '../repositories/delivery-item.repository';
import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';

@Route('delivery_items')
@CrossDependency(() => {
    return {
        delivery: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Delivery
        },
        product: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Product
        },
        productType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: ProductType
        },
        unit: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Unit
        },
        vat: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vat
        }
    };
})
@Repository(DeliveryItemRepository)
export class DeliveryItem {

    protected id: string;

    @AssociationOne(Delivery)
    protected delivery;

    @AssociationOne(Product)
    protected product;

    @AssociationOne(ProductType)
    protected productType;

    @AssociationOne(Unit)
    protected unit;

    @AssociationOne(Vat)
    protected vat;

    protected rebate: number;

    protected code: string;

    protected name: string;

    protected description: string;

    protected quantity: number;

    protected price: number;
    protected priceWithRebate: number;
    protected priceWithVat: number;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    protected totalPrice: number;

    protected productDescription: string;
    protected sortPosition: number;

    public getId(): string {
        return this.id;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getDelivery(): Delivery | undefined {
        return this.delivery;
    }

    public setDelivery(delivery: Delivery): DeliveryItem {
        this.delivery = delivery;
        return this;
    }

    public getProduct(): Product | undefined {
        return this.product;
    }

    public setProduct(product: Product): DeliveryItem {
        this.product = product;
        return this;
    }

    public getProductType(): ProductType | undefined {
        return this.productType;
    }

    public setProductType(productType: ProductType): DeliveryItem {
        this.productType = productType;
        return this;
    }

    public getUnit(): Unit | undefined {
        return this.unit;
    }

    public setUnit(unit: Unit): DeliveryItem {
        this.unit = unit;
        return this;
    }

    public getRebate(): number | undefined {
        return this.rebate;
    }

    public setRebate(rebate: number): DeliveryItem {
        this.rebate = rebate;
        return this;
    }

    public getVat(): Vat | undefined {
        return this.vat;
    }

    public setVat(vat: Vat): DeliveryItem {
        this.vat = vat;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setCode(code: string): DeliveryItem {
        this.code = code;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): DeliveryItem {
        this.name = name;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string): DeliveryItem {
        this.description = description;
        return this;
    }

    public getQuantity(): number | undefined {
        return this.quantity;
    }

    public setQuantity(quantity: number): DeliveryItem {
        this.quantity = quantity;
        return this;
    }

    public getPrice(): number | undefined {
        return this.price;
    }

    public setPrice(price: number): DeliveryItem {
        this.price = price;
        return this;
    }

    public getTotalPrice(): number | undefined {
        return this.totalPrice;
    }

    public setTotalPrice(totalPrice: number): DeliveryItem {
        this.totalPrice = totalPrice;
        return this;
    }

    public getProductDescription(): string {
        return this.productDescription;
    }

    public setProductDescription(productDescription: string): DeliveryItem {
        this.productDescription = productDescription;
        return this;
    }

    public getSortPosition(): number {
        return this.sortPosition;
    }

    public setSortPosition(sortPosition: number): DeliveryItem {
        this.sortPosition = sortPosition;
        return this;
    }

    public setMaster(master: Delivery) {
        this.delivery = master;
        return this;
    }

    public setPriceWithRebate(priceWithRebate: number): DeliveryItem {
        this.priceWithRebate = priceWithRebate;
        return this;
    }

    public getPriceWithRebate(): number | undefined {
        return this.priceWithRebate;
    }

    public setPriceWithVat(priceWithVat: number): DeliveryItem {
        this.priceWithVat = priceWithVat;
        return this;
    }

    public getPriceWithVat(): number | undefined {
        return this.priceWithVat;
    }
}
