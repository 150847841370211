export class Meta {
  public static META = 'meta';
  public static META_UNIQUE_ID = 'UNIQUE_ID';
  public static IS_DIRTY = 'IS_DIRTY';
  public static META_ROUTE = 'route';
  public static META_ENTITY_DEFAULT_FILTERS = 'entityDefaultFilters';
  public static META_REPOSITORY = 'repository';
  public static META_ASSOCIATIONS = 'associations';
  public static META_ASSOCIATIONS_MANY = 'associationsMany';
  public static META_ASSOCIATIONS_MANY_TO_MANY = 'associationsManyToMany';
  public static META_PROPERTY_PAYLOAD_MODIFIER = 'propertyPayloadModifier';
  public static META_PROPERTY_RESPONSE_MODIFIER = 'propertyResponseModifier';
  public static META_MAX_DEPTH = 'maxDepth';
}
