import {Route} from 'entity-manager';

export enum AbsenceTypeEnum {
    DayOff = 'dayOff',
    Vacation = 'vacation',
    SickLeave = 'sickLeave',
    LeasedEmployee = 'leasedEmployee',
    SeminarAtDistributor = 'seminarAtDistributor',
    Holiday = 'holiday',
    PatronSaintDay = 'patronSaintDay',
    Other = 'other'
}

@Route('absence_types')
export class AbsenceType {
    protected id: string;

    protected code: string;
    protected name: string;
    protected description: string;

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }
}
