import {Directive, EventEmitter, HostListener, Input, OnInit, Output, Self} from '@angular/core';
import {Calendar} from 'primeng';
import {CalendarLocaleService} from '../services/calendar-locale.service';
import {DATE_FORMAT_SESSION_KEY} from '../../core/workflow/user/set-locale.action';
import {DATE_FORMAT_DEFAULT} from '../pipes/date.pipe';

@Directive({
    selector: '[appCalendar]',
    providers: [CalendarLocaleService]
})
export class CalendarDirective implements OnInit {
    public readonly calendar: Calendar = null;
    private yearRange = `1950:${new Date(new Date().setFullYear(new Date().getFullYear() + 20)).getFullYear()}`;

    @Output() public valueChange = new EventEmitter<any>();
    @Input() public dateFormat = '';
    @Input() public rangeBothMandatory = true;
    @HostListener('keyup', ['$event']) public onKeyUp(event) {
        this.onInputKeyUp(event);
    }

    constructor(
        @Self() calendar: Calendar,
        private calendarLocale: CalendarLocaleService
    ) {
        this.calendar = calendar;
        this.initDefaultYearRange();
        this.initLocale();
    }

    public ngOnInit(): void {
        this.initDefaultYearRange();
        this.initLocale();

        this.calendar.onSelect.subscribe(() => {
            if (this.calendar.selectionMode === 'range' && this.calendar.value instanceof Array && this.calendar.value[0] && this.calendar.value[1]
                && this.rangeBothMandatory
            ) {
                this.calendar.hideOverlay();
            }
        });
    }

    public onInputKeyUp(event: any): void {
        const inputString = event.target.value;

        const date = this.getDate(inputString);

        // skip backspace, space, delete InputEvent codes
        if (date !== null && event.data !== null && event.data !== ' ') {

            if (!this.calendar.showTime) {
                this.emitValueChange(date);
            }

            if (this.calendar.showTime && this.getTime(inputString) !== null) {
                const time = this.getTime(inputString);

                date.setHours(time.hour);
                date.setMinutes(time.minute);

                this.emitValueChange(date);
            }
        }

        if (this.calendar.timeOnly && inputString.length === 5) {
            let time = null;

            try {
                time = this.calendar.parseTime(inputString);
            } catch (e) {

            } finally {
                if (time !== null) {
                    const timeDate = new Date();

                    timeDate.setHours(time.hour);
                    timeDate.setMinutes(time.minute);

                    this.emitValueChange(timeDate);
                }
            }
        }

        if (inputString === '') {
            this.emitValueChange(null);
        }
    }

    protected emitValueChange(value: Date|null): void {
        this.valueChange.emit(value);
    }

    private getTime(dateString: string): {hour: number, minute: number, second: number} | null {
        let time = null;

        const splitDate = dateString.split(' ');

        if (splitDate[0] && splitDate[1]) {
            try {
                time = this.calendar.parseTime(splitDate[1]);
            } catch (e) {

            }
        }

        return time;
    }

    private getDate(dateString: string): Date|null {
        let date = null;

        if (typeof dateString !== 'undefined' && dateString.indexOf(' ') !== -1) {
            dateString = dateString.split(' ')[0];
        }

        try {
            date = this.calendar.parseDate(dateString, this.calendar.dateFormat);
        } catch (e) {

        }

        return date;
    }

    private initDefaultYearRange(): void {
        if (this.calendar && this.calendar.yearNavigator === true && !this.calendar.yearRange) {
            this.calendar.yearRange = this.yearRange;
        }
    }

    private initLocale(): void {
        this.calendar.locale = this.calendarLocale.getLocaleSettings();
        this.calendar.dateFormat = this.dateFormat || sessionStorage.getItem(DATE_FORMAT_SESSION_KEY) || DATE_FORMAT_DEFAULT;
    }
}


