import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Vehicle} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('vehicle_registrations')
@CrossDependency(() => {
    return {
        vehicle: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vehicle
        }
    };
})
export class VehicleRegistration {

    @AssociationOne(Vehicle)
    protected vehicle: Vehicle;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected vehicleRegistrationDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected vehicleRegistrationExpirationDate: Date;

    protected id: string;

    public setVehicle(vehicle: Vehicle): this {
        this.vehicle = vehicle;
        return this;
    }

    public getVehicle(): Vehicle|undefined {
        return this.vehicle;
    }

    public setVehicleRegistrationDate(vehicleRegistrationDate: Date) {
        this.vehicleRegistrationDate = vehicleRegistrationDate;
        return this;
    }

    public getVehicleRegistrationDate() {
        return this.vehicleRegistrationDate;
    }

    public setVehicleRegistrationExpirationDate(vehicleRegistrationExpirationDate: Date) {
        this.vehicleRegistrationExpirationDate = vehicleRegistrationExpirationDate;
        return this;
    }

    public getVehicleRegistrationExpirationDate() {
        return this.vehicleRegistrationExpirationDate;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }
}
