import {AbstractParser} from '../../../../projects/entity-manager/src/lib/parser/abstract-parser';

export class LdJsonParser extends AbstractParser {

    public parseEntity(instance: any, data: any): any {
        const parsed = Object.assign(instance, data);

        if (data && data['@id']) {
            parsed.id = data['@id'].slice(data['@id'].lastIndexOf('/') + 1);
        }

        return parsed;
    }
}
