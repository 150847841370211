import {Route} from 'entity-manager';

@Route('work_note_measure_proposals')

export class WorkNoteMeasureProposal {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;
    protected points: number;
    protected sortPosition: number;

    public setId(id: string): WorkNoteMeasureProposal {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string): WorkNoteMeasureProposal {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string): WorkNoteMeasureProposal {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string): WorkNoteMeasureProposal {
        this.description = description;
        return this;
    }

    public getDescription() {
        return this.description;
    }

    public setPoints(counter: number): WorkNoteMeasureProposal {
        this.points = counter;
        return this;
    }

    public getPoints(): number {
        return this.points;
    }

    public getSortPosition(): number {
        return this.sortPosition;
    }

    public setSortPosition(sortPosition: number): WorkNoteMeasureProposal {
        this.sortPosition = sortPosition;
        return this;
    }
}
