import {
    AfterViewChecked,
    ChangeDetectorRef,
    Directive,
    OnDestroy,
    Self
} from '@angular/core';
import {ConfirmationService, ConfirmDialog} from 'primeng';
import {delay, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Directive({
    selector: '[appConfirmDialog]'
})
export class ConfirmDialogDirective implements AfterViewChecked, OnDestroy {

    protected confirmDialog: ConfirmDialog = null;
    protected confirmDialogConfirmation = null;
    protected unsubscribe$ = new Subject<void>();

    constructor(
        @Self() confirmDialog: ConfirmDialog,
        protected cdr: ChangeDetectorRef,
        protected confirmation: ConfirmationService
    ) {
        this.confirmDialog = confirmDialog;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public ngAfterViewChecked(): void {
        if (this.confirmDialog && this.confirmDialog.confirmation &&
            this.confirmDialog.confirmation !== this.confirmDialogConfirmation
        ) {
            this.confirmDialogConfirmation = this.confirmDialog.confirmation;

            if (this.confirmDialog.confirmation.acceptEvent) {
                this.confirmDialog.confirmation.acceptEvent.pipe(
                    takeUntil(this.unsubscribe$),
                    delay(200)
                ).subscribe(() => {
                    this.cdr.detectChanges();
                });
            }

            if (this.confirmDialog.confirmation.rejectEvent) {
                this.confirmDialog.confirmation.rejectEvent.pipe(
                    takeUntil(this.unsubscribe$),
                    delay(200)
                ).subscribe(() => {
                    this.cdr.detectChanges();
                });
            }
        }
    }

}
