import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AppComponent} from '../../app.component';
import {AuthenticationService} from '../../core/services/authentication.service';
import {environment} from '../../../environments/environment';
import {MediaObject} from '../../core/entities/media-object';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {BootstrapService} from '../../core/services/bootstrap.service';

@Component({
    selector: 'app-topbar',
    styleUrls: ['./app.topbar.component.scss'],
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    public userFullName = '';
    public companyCode = '';
    protected medias: any;
    public logo = '/assets/layout/images/logo/bizhub.svg';
    public profilePictureUrl = 'assets/layout/images/placeholder-profile.jpg';


    public constructor(
        public app: AppComponent,
        public authentication: AuthenticationService,
        protected entityManager: EntityManagerService,
        protected cdr: ChangeDetectorRef,
        public bootstrapService: BootstrapService
    ) {
    }

    public ngOnInit(): void {
        this.userFullName = this.authentication.getUser().getFirstName();
        this.companyCode = this.authentication.getUser().getCompany().getCode();
        this.getLogo(this.authentication.getUser().getCompany());

        this.bootstrapService.bootstrapped.subscribe(() => {
            this.initUser();
        });

        this.initUser();
    }
    protected initUser(): void {
        const profilePicture = this.authentication.getUser().getMedia('profilePicture');

        this.userFullName = this.authentication.getUser().getFullName();

        if (profilePicture instanceof MediaObject) {
            this.profilePictureUrl = environment.BACKEND_ROOT_URL + profilePicture.getContentUrl();
        }
    }

    public getLogo(entity) {
        const params = {
            mediaObjectClassName: 'CompanyMediaObject',
            mediaObjectOwnerId: entity.getId(),
            mediaObjectContext: 'logo'
        };

        this.entityManager.getRepository(MediaObject)
            .findOne(params)
            .subscribe((mediaObject: MediaObject) => {
                if (mediaObject) {
                    this.logo = environment.BACKEND_ROOT_URL + mediaObject.getContentUrl();
                }
            });
    }

}
