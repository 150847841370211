import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PermissionRepository} from '../../repositories/permission.repository';
import {ObjectPermission} from '../../entities/permission/object-permission';

@Injectable({
    providedIn: 'root'
})
export class AuthorizerService {

    public constructor(
        private permissionRepository: PermissionRepository
    ) {

    }

    public static isGranted(mask: string, object: any): boolean {
        return object && object.isGranted && object.isGranted[mask];
    }

    public grantOrRevokeObject(grantOrRevoke: boolean, mask: string[]|string, object: any): Observable<ObjectPermission> {
        return this.permissionRepository
            .grantOrRevokeObject(
                grantOrRevoke,
                mask,
                object
            );
    }
}
