import {TableAdvanceFilter} from '../../shared/table-advance-filter';

export abstract class AbstractAdvanceFilter {

    public advanceFilter: TableAdvanceFilter = null;

    protected operatorValue: string = null;
    protected filterValue: any = null;

    public buildFilterParams?(params: any, filter: string): void;

    public abstract getValueFromChange(event, param): any;

    public getOperatorValueFromChange(event): any {
      return event.value;
    }

    public getFilterValue(param?: any): any {
      return this.filterValue;
    }

    public getFilterParsedValue(): any {
      if (this.advanceFilter && typeof this.advanceFilter.parseValue === 'function') {
        return this.advanceFilter.parseValue(this.filterValue);
      }

      return  this.filterValue;
    }

    public getFilterParsedOperator(): any {
      if (this.advanceFilter && typeof this.advanceFilter.parseOperator === 'function') {
        return this.advanceFilter.parseOperator(this.filterValue);
      }

      return  this.operatorValue;
    }

    public setFilterValue(filterValue: any): this {
      this.filterValue = filterValue;
      return this;
    }

    public setFilterOperatorValue(operatorValue: string): this {
      this.operatorValue = operatorValue;
      return this;
    }

    public getFilterOperatorValue(): string {
        return this.operatorValue;
    }
}
