import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Product, Invoice, ProductType, Unit, Vat} from './entities';
import {Route} from '../../../../projects/entity-manager/src/lib/decorator/route';
import {
    CrossDependency
} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {InvoiceItemRepository} from '../repositories/invoice-item.repository';

import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('invoice_items')
@CrossDependency(() => {
    return {
        product: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Product
        },
        productType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: ProductType
        },
        master: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Invoice
        },
        unit: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Unit
        },
        vat: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vat
        }
    };
})
@Repository(InvoiceItemRepository)
export class InvoiceItem {

    protected id: string;

    @AssociationOne(Product)
    protected product;

    @AssociationOne(ProductType)
    protected productType;

    @AssociationOne(Invoice)
    protected master;

    @AssociationOne(Unit)
    protected unit;

    protected rebate: number;

    @AssociationOne(Vat)
    protected vat;

    protected totalVat: number;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected entryDate: Date;

    protected name;
    protected quantity;
    protected price;
    protected foreignPrice: number;
    protected priceWithRebate: number;
    protected foreignPriceWithRebate: number;
    protected priceWithVat: number;
    protected totalPrice: number;
    protected foreignTotalPrice: number;
    protected productDescription;
    protected sortPosition;
    protected duty: number;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setEntryDate(entryDate: Date): this {
        this.entryDate = entryDate;
        return this;
    }

    public getEntryDate(): Date | undefined {
        return this.entryDate;
    }

    public setProduct(product: Product): InvoiceItem {
        this.product = product;
        return this;
    }

    public getProduct(): Product | undefined {
        return this.product;
    }

    public setProductType(productType: ProductType): InvoiceItem {
        this.productType = productType;
        return this;
    }

    public getProductType(): ProductType | undefined {
        return this.productType;
    }

    public getUnit(): Unit {
        return this.unit;
    }

    public setUnit(unit: Unit) {
        this.unit = unit;
        return this;
    }

    public setMaster(master: Invoice): InvoiceItem {
        this.master = master;
        return this;
    }

    public getMaster(): Invoice | undefined {
        return this.master;
    }

    public setVat(vat: Vat): InvoiceItem {
        this.vat = vat;
        return this;
    }

    public getVat(): Vat | undefined {
        return this.vat;
    }

    public getTotalVat(): number | undefined {
        return this.totalVat;
    }

    public setTotalVat(totalVat: number): InvoiceItem {
        this.totalVat = totalVat;
        return this;
    }

    public setRebate(rebate: number): InvoiceItem {
        this.rebate = rebate;
        return this;
    }

    public getRebate(): number | undefined {
        return this.rebate;
    }

    public setQuantity(quantity: number): InvoiceItem {
        this.quantity = quantity;
        return this;
    }

    public getQuantity(): number | undefined {
        return this.quantity;
    }

    public setPrice(price: number): InvoiceItem {
        this.price = price;
        return this;
    }

    public getPrice(): number | undefined {
        return this.price;
    }

    public setForeignPrice(foreignPrice: number): InvoiceItem {
        this.foreignPrice = foreignPrice;
        return this;
    }

    public getForeignPrice(): number | undefined {
        return this.foreignPrice;
    }

    public setPriceWithRebate(priceWithRebate: number): InvoiceItem {
        this.priceWithRebate = priceWithRebate;
        return this;
    }

    public getPriceWithRebate(): number | undefined {
        return this.priceWithRebate;
    }

    public setForeignPriceWithRebate(foreignPriceWithRebate: number): InvoiceItem {
        this.foreignPriceWithRebate = foreignPriceWithRebate;
        return this;
    }

    public getForeignPriceWithRebate(): number | undefined {
        return this.foreignPriceWithRebate;
    }

    public setPriceWithVat(priceWithVat: number): InvoiceItem {
        this.priceWithVat = priceWithVat;
        return this;
    }

    public getPriceWithVat(): number | undefined {
        return this.priceWithVat;
    }

    public setForeignTotalPrice(foreignTotalPrice: number): InvoiceItem {
        this.foreignTotalPrice = foreignTotalPrice;
        return this;
    }

    public getForeignTotalPrice(): number | undefined {
        return this.foreignTotalPrice;
    }

    public getTotalPrice(): number|undefined {
        return this.totalPrice;
    }

    public setTotalPrice(totalPrice: number): InvoiceItem {
        this.totalPrice = totalPrice;
        return this;
    }

    public setProductDescription(productDescription: string): InvoiceItem {
        this.productDescription = productDescription;
        return this;
    }

    public getProductDescription(): string {
        return this.productDescription;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): InvoiceItem {
        this.name = name;
        return this;
    }

    public getSortPosition(): number {
        return this.sortPosition;
    }

    public setSortPosition(sortPosition: number): InvoiceItem {
        this.sortPosition = sortPosition;
        return this;
    }

    public getDuty(): number {
        return this.duty;
    }

    public setDuty(duty: number) {
        this.duty = duty;
        return this;
    }
}
