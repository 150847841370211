import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociateMonthlyCalculation} from '../entities/associate-monthly-calculation';
import {AssociateMonthlyPayment} from '../entities/associate-monthly-payment';

@Injectable({
    providedIn: 'root'
})
export class AssociateMonthlyCalculationRepository extends EntityRepository {

    public generateMonthlyPayment(entryMonth: number, entryYear: number): Observable<boolean> {
        const route = this.meta.getMetaDataProperty(new AssociateMonthlyCalculation(), Meta.META_ROUTE);

        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}${route}/generate_monthly_payments/year/${entryYear}/month/${entryMonth}`,
            {}
        ).pipe(
            map((response: {success: boolean}) => {
                return response && response.success;
            })
        );
    }

    public getTotal(params: any = {}): Observable<any[]> {
        const route = this.meta.getMetaDataProperty(new AssociateMonthlyCalculation(), Meta.META_ROUTE);

        return this.connection.get(environment.ENTITY_MANAGER_URL_PREFIX + route + '/total', {params})
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }
}
