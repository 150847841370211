import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ThemeService} from './theme/theme.service';
import {AuthenticationService} from './authentication.service';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {AppComponent} from '../../app.component';
import {WorkflowRunner} from '../../workflow/services/workflow-runner';
import {WorkflowBuilder} from '../../workflow/services/workflow.builder';
import {BootstrapTranslateAction} from '../workflow/bootstrap/bootstrap-translate.action';
import {BootstrapThemeAction} from '../workflow/bootstrap/bootstrap-theme.action';
import {IsUserLoggedInCondition} from '../workflow/user/is-user-logged-in.condition';
import {LoadUserCompanySettingsAction} from '../workflow/user/load-user-company-settings.action';
import {BootstrapAppComponentLayoutAction} from '../workflow/bootstrap/bootstrap-app-component-layout.action';
import {SetLocaleAction} from '../workflow/user/set-locale.action';
import {LoadAndSetUserProfilePictureAction} from '../workflow/user/load-and-set-user-profile-picture.action';
import {map} from 'rxjs/operators';
import {UserRerouteAction} from '../workflow/user/user-reroute.action';

@Injectable({
    providedIn: 'root'
})
export class BootstrapService {

    public bootstrapped: EventEmitter<string> = new EventEmitter();

    public constructor(
        protected translate: TranslateService,
        protected theme: ThemeService,
        protected authentication: AuthenticationService,
        protected entityManager: EntityManagerService,
        protected workflowBuilder: WorkflowBuilder
    ) {

    }

    public bootstrap(appComponent: AppComponent): Observable<any> {

        const workflow = this.workflowBuilder.create();

        workflow
            .startWith(new BootstrapTranslateAction())
            .then(new BootstrapThemeAction())
            .if(new IsUserLoggedInCondition())
                .isTrue(
                    this.workflowBuilder.create()
                        .startWith(new LoadUserCompanySettingsAction())
                        .then(new LoadAndSetUserProfilePictureAction())
                        .then(new SetLocaleAction())
                        .then(new BootstrapAppComponentLayoutAction(
                             appComponent
                        ))
                        .then(new UserRerouteAction())
                );

        return new WorkflowRunner(workflow).run().pipe(
            map((response) => {
                this.bootstrapped.emit();

                return response;
            })
        );
    }
}
