import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Employee, EmployeeTravelExpenseType, TotalTravelExpense} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {
    PropertyPayloadModifier
} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {
    DecimalPropertyPayloadModifier
} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';

@Route('employee_travel_expenses')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        totalTravelExpense: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: TotalTravelExpense
        },
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeTravelExpenseType
        }
    };
})
export class EmployeeTravelExpense {

    @AssociationOne(Employee)
    protected employee;

    protected id: string;
    protected entryYear: string;
    protected entryMonth: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected travelExpense;
    protected hasAccount: boolean;

    @AssociationOne(TotalTravelExpense)
    protected totalTravelExpense;

    @AssociationOne(EmployeeTravelExpenseType)
    protected type;

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setEmployee(employee: Employee): EmployeeTravelExpense {
        this.employee = employee;
        return this;
    }

    public getTotalTravelExpense(): TotalTravelExpense | undefined {
        return this.totalTravelExpense;
    }

    public setTotalTravelExpense(totalTravelExpense: TotalTravelExpense): EmployeeTravelExpense {
        this.totalTravelExpense = totalTravelExpense;
        return this;
    }

    public getEntryYear(): string {
        return this.entryYear;
    }

    public setEntryYear(entryYear: string): EmployeeTravelExpense {
        this.entryYear = entryYear;
        return this;
    }

    public getEntryMonth(): string {
        return this.entryMonth;
    }

    public setEntryMonth(entryMonth: string): EmployeeTravelExpense {
        this.entryMonth = entryMonth;
        return this;
    }

    public geTravelExpense(): string {
        return this.travelExpense;
    }

    public setTravelExpense(travelExpense: string): EmployeeTravelExpense {
        this.travelExpense = travelExpense;
        return this;
    }

    public getType(): EmployeeTravelExpenseType | undefined {
        return this.type;
    }

    public setType(type: EmployeeTravelExpenseType): EmployeeTravelExpense {
        this.type = type;
        return this;
    }

    public getHasAccount(): boolean|undefined {
        return this.hasAccount;
    }

    public setHasAccount(hasAccount: boolean): EmployeeTravelExpense {
        this.hasAccount = hasAccount;
        return this;
    }
}