import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {AbsenceType, CalendarEntry, CalendarEntryTypeEnum, Employee} from '../entities/entities';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Entity} from '../../../../projects/entity-manager/src/lib/helper/entity';
import {CalendarEntryGenerator} from '../entities/calendar-entry-generator';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import moment from 'moment';
import {Paginated} from '../../shared/table/shared/paginated';

@Injectable({
    providedIn: 'root'
})
export class CalendarEntryRepository extends EntityRepository {

    protected dateModifier = new DatetimeWithoutTimezonePropertyPayloadModifier();

    public generateWorkingSaturday(data: CalendarEntryGenerator): Observable<CalendarEntry> {
        this.dateModifier.modify('dateFrom', data);
        this.dateModifier.modify('dateTo', data);

        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}calendar_entries/generate`,
            {
                type: data.getType(),
                removeCurrent: data.getRemoveCurrent(),
                employee: data.getEmployee()['@id'],
                dateFrom: data.getDateFrom(),
                dateTo: data.getDateTo(),
                workShift: data.getWorkShift()['@id']
            }
        ).pipe(map((res) => {
            return this.parser.getParser().parse(new CalendarEntry(), res);
        }));
    }

    public generateCalendarEntry(data: CalendarEntryGenerator): Observable<CalendarEntry>[] {
        const result: Observable<CalendarEntry>[] = [];

        this.dateModifier.modify('dateFrom', data);
        this.dateModifier.modify('dateTo', data);

        if (data.getType() === 'workShift') {
            for (const anEmployee of data.getEmployees()) {
                result.push(
                    this.connection.post(
                        `${environment.ENTITY_MANAGER_URL_PREFIX}calendar_entries/generate`,
                        {
                            type: data.getType(),
                            workShift: data.getWorkShift()['@id'],
                            employee: anEmployee['@id'],
                            dateFrom: data.getDateFrom(),
                            dateTo: data.getDateTo(),
                            removeCurrent: data.getRemoveCurrent()
                        }
                    ).pipe(map((res) => {
                        return this.parser.getParser().parse(new CalendarEntry(), res);
                    }))
                );
            }
        }

        if (data.getType() === 'workingSaturday') {
            for (const anEmployee of data.getEmployees()) {
                result.push(
                    this.connection.post(
                        `${environment.ENTITY_MANAGER_URL_PREFIX}calendar_entries/generate`,
                        {
                            type: data.getType(),
                            removeCurrent: data.getRemoveCurrent(),
                            employee: anEmployee['@id'],
                            dateFrom: data.getDateFrom(),
                            dateTo: data.getDateTo(),
                        }
                    ).pipe(map((res) => {
                        return this.parser.getParser().parse(new CalendarEntry(), res);
                    }))
                );
            }
        }

        if (data.getType() === 'absence') {
            for (const anAbsence of data.getAbsences()) {
                result.push(
                    this.connection.post(
                        `${environment.ENTITY_MANAGER_URL_PREFIX}calendar_entries/generate`,
                        {
                            type: data.getType(),
                            absence: anAbsence['@id'],
                            employee: anAbsence.getEmployee()['@id'],
                            dateFrom: data.getDateFrom(),
                            dateTo: data.getDateTo(),
                        }
                    ).pipe(map((res) => {
                        return this.parser.getParser().parse(new CalendarEntry(), res);
                    }))
                );
            }
        }

        return result;
    }

    public findOneByType(employee: Employee, entryDate: Date, type: CalendarEntryTypeEnum): Observable<CalendarEntry|null> {
        return this.findOne({
            employee: employee.getId(),
            entryYear: moment(entryDate).format('YYYY'),
            entryMonth: moment(entryDate).format('MM'),
            entryDay: moment(entryDate).format('DD'),
            'type.code': type,
            itemsPerPage: 1
        });
    }

    public findMoreByDate(employee: Employee, entryDate: Date): Observable<Paginated|any> {
        return this.findMore({
            employee: employee.getId(),
            entryYear: moment(entryDate).format('YYYY'),
            entryMonth: moment(entryDate).format('MM'),
            entryDay: moment(entryDate).format('DD')
        });
    }

    public getWorkDaysCount(employee: Employee, fromDate: Date, toDate: Date): Observable<number> {
        return this.getDaysCount(
            employee,
            [
                CalendarEntryTypeEnum.WorkDay
            ],
            fromDate,
            toDate
        );
    }

    public getWorkAndAbsenceDaysCount(employee: Employee, fromDate: Date, toDate: Date, absenceType?): Observable<number> {
        return this.getDaysCount(
            employee,
            [
                CalendarEntryTypeEnum.WorkDay,
                CalendarEntryTypeEnum.Absence
            ],
            fromDate,
            toDate,
            absenceType
        );
    }

    public getDaysCount(employee: Employee, types: CalendarEntryTypeEnum[], fromDate: Date, toDate: Date, absenceType?): Observable<number> {
        const route = this.meta.getMetaDataProperty(new CalendarEntry(), Meta.META_ROUTE),
            data = {
                employee: Entity.getValue(employee, '@id'),
                fromDate,
                toDate,
                types,
                absenceType
            };

        this.dateModifier.modify('fromDate', data);
        this.dateModifier.modify('toDate', data);

        return this.connection.post(
            environment.ENTITY_MANAGER_URL_PREFIX + route + '/days_count',
            data
        )
            .pipe(
                map((response: {result: number}) => {
                    return response.result;
                })
            );
    }

    public getDaysList(params: Record<string, string>): Observable<Paginated> {
        const route = this.meta.getMetaDataProperty(new CalendarEntry(), Meta.META_ROUTE);

        return this.connection.get(
            environment.ENTITY_MANAGER_URL_PREFIX + route + '/day_list',
            {
                params
            }
        )
            .pipe(
                map((response: Paginated) => {
                    return response;
                })
            );
    }

    public getList(params: Record<string, string>): Observable<Paginated> {
        const route = this.meta.getMetaDataProperty(new CalendarEntry(), Meta.META_ROUTE);

        return this.connection.get(
            environment.ENTITY_MANAGER_URL_PREFIX + route + '/list',
            {
                params
            }
        )
            .pipe(
                map((response: Paginated) => {
                    return response;
                })
            );
    }
}
