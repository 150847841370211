import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    public constructor(
        protected translate: TranslateService
    ) {

    }
    transform(value: any, args?: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29) {
                return this.translate.instant('DATE_AGO.JUST_NOW');
            }
            const intervals = {
                [this.translate.instant('DATE_AGO.YEAR')]: 31536000,
                [this.translate.instant('DATE_AGO.MONTH')]: 2592000,
                [this.translate.instant('DATE_AGO.WEEK')]: 604800,
                [this.translate.instant('DATE_AGO.DAY')]: 86400,
                [this.translate.instant('DATE_AGO.HOUR')]: 3600,
                [this.translate.instant('DATE_AGO.MINUTE')]: 60,
                [this.translate.instant('DATE_AGO.SECOND')]: 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0) {
                    return this.translate.instant('DATE_AGO.BEFORE') + ' ' + counter + ' ' + i ;
                }
            }
        }
        return value;
    }
}
