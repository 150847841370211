import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';


@Component({
    selector: 'app-home-count-box',
    templateUrl: 'count-box.component.html',
    styleUrls: ['count-box.component.scss']
})
export class CountBoxComponent implements OnInit {
    cols: any[];
    @Input() public title = '';
    @Input() public number = '';
    @Input() public subtitle = '';
    @Input() public icon = 'fa fa-clock-o';
    constructor(
    ) {
    }

    ngOnInit() {
    }
}
