import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {EntityManagerService} from '../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {MessageService} from 'primeng';
import {TranslateService} from '@ngx-translate/core';
import {ChangeDetectorRef, EventEmitter, Input, Output} from '@angular/core';
import {MediaObjectRepository} from '../core/repositories/media-object.repository';
import {MediaObject} from '../core/entities/media-object';


export abstract class AbstractUploadButtonComponent {

    @Output() public uploadSuccess = new EventEmitter<MediaObject[]>();
    @Input() disabled = false;
    @Input() set entity(entity: any) {
        if (entity && entity.id) {
            this.mediaEntity = entity;
            this.loadMedias().subscribe();
        }
    }
    @Input() mediaObjectClassName: string;
    @Input() mediaObjectContext = 'default';

    @Input() autoUpload = false;
    @Input() mode = 'basic';
    @Input() name = 'file';
    @Input() accept = 'image/jpeg,image/gif,image/png,application/pdf,image/x-eps, image/svg+xml';
    @Input() url = '';

    public mediaEntity = null;
    public selectedFiles: any[] = [];
    public changedFileNames: string[] = [];

    public abstract loadMedias(): Observable<any>;

    protected constructor(
        protected entityManager: EntityManagerService,
        protected message: MessageService,
        protected translate: TranslateService,
        protected cdr: ChangeDetectorRef
    ) {
    }

    public isDirty(): boolean {
        return this.selectedFiles.length > 0;
    }

    public upload(): Observable<any> {
        const observables = [],
            repo: MediaObjectRepository = this.entityManager.getRepository(MediaObject);

        if (this.selectedFiles.length === 0) {
            console.error('No files set for upload!');
            return of(null);
        }

        let i = 0;
        for (const file of this.selectedFiles) {
            const mediaObject = new MediaObject()
                .setName(this.changedFileNames[i] || file.name)
                .setFile(file)
                .setFileType(file.type)
                .setContentUrl('')
                .setEntity(this.mediaEntity);

            observables.push(repo.upload(mediaObject, this.mediaObjectClassName, this.mediaObjectContext));
            i++;
        }

        return forkJoin(observables)
            .pipe(
                map((mediaObjects: MediaObject[] = []) => {
                    this.loadMedias().subscribe();

                    this.selectedFiles = [];

                    this.message.add({
                        severity: 'info',
                        detail: this.translate.instant('FILE.FILE_UPLOADED')
                    });

                    this.uploadSuccess.emit(mediaObjects);
                }));
    }

    public setEntity(entity): this {
        this.mediaEntity = entity;
        return this;
    }
}
