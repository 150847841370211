import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {
    CrmCompany,
    Country,
    Invoice,
    OrderItem,
    OrderType
} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {OrderRepository} from '../repositories/order.repository';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('orders')
@CrossDependency(() => {
    return {
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: OrderType
        },
        contact: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: CrmCompany
        },
        country: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Country
        },
        invoice: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Invoice
        }
    };
})
@Repository(OrderRepository)
export class Order {

    @AssociationOne(OrderType)
    protected type;

    @AssociationOne(CrmCompany)
    protected contact;

    @AssociationOne(Invoice)
    protected invoice;

    @AssociationOne(Country)
    protected country: Country;

    @AssociationMany(OrderItem)
    protected orderItems;

    protected id: string;

    private orderNumber: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected orderDate: Date;

    private postalCode: string;

    private city: string;

    private address: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setOrderNumber(orderNumber: string) {
        this.orderNumber = orderNumber;
        return this;
    }

    public getOrderNumber(): string {
        return this.orderNumber;
    }

    public setOrderDate(orderDate: Date) {
        this.orderDate = orderDate;
        return this;
    }

    public getOrderDate(): Date|null {
        return this.orderDate;
    }

    public setOrderItems(orderItems: OrderItem[] = []): Order {
        this.orderItems = orderItems;
        return this;
    }

    public getOrderItems(): OrderItem[]|undefined {
        return this.orderItems;
    }

    public setContact(contact: CrmCompany): Order {
        this.contact = contact;
        return this;
    }

    public getContact(): CrmCompany|undefined {
        return this.contact;
    }

    public getInvoice(): Invoice|undefined {
        return this.invoice;
    }

    public setInvoice(invoice: Invoice): Order {
        this.invoice = invoice;
        return this;
    }

    public setType(type: OrderType): Order {
        this.type = type;
        return this;
    }

    public getType(): OrderType|undefined {
        return this.type;
    }

    public getCountry(): Country | undefined {
        return this.country;
    }

    public setCountry(country: Country): Order | undefined {
        this.country = country;
        return this;
    }

    public getPostalCode(): string | undefined {
        return this.postalCode;
    }

    public setPostalCode(postalCode: string): Order {
        this.postalCode = postalCode;
        return this;
    }

    public getCity(): string | undefined {
        return this.city;
    }

    public setCity(city: string): Order {
        this.city = city;
        return this;
    }

    public getAddress(): string | undefined {
        return this.address;
    }

    public setAddress(address: string): Order {
        this.address = address;
        return this;
    }
}
