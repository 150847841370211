import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {PaymentDeduction} from '../entities/payment-deduction';
import {Department} from '../entities/department';
import {Employee} from '../entities/employee';
import {PaymentDeductionTypeEnum} from '../entities/payment-deduction-type';

@Injectable({
    providedIn: 'root'
})
export class PaymentDeductionRepository extends EntityRepository {

    public regulate(paymentDeduction: PaymentDeduction|any): Observable<PaymentDeduction> {
        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}payment_deductions/${paymentDeduction.getId()}/regulate`,
            {
                disableVisibleFilter: 1
            }
        ).pipe(map((aPaymentDeduction: PaymentDeduction) => {
            return this.parser.getParser().parse(new PaymentDeduction(), aPaymentDeduction);
        }));
    }
}
