import {Injectable, ComponentRef, ComponentFactoryResolver, ViewContainerRef, Renderer2, RendererFactory2} from '@angular/core';
import {DashboardComponent} from '../../component/dashboard/dashboard.component';
import {DashboardBoxWrapperComponent} from '../../component/dashboard-box-wrapper/dashboard-box-wrapper.component';
import {DashboardBox} from '../dashboard-box';
import {DashboardSettingLayout} from '../dashboard-setting';
import {LayoutChangedAware} from '../interfaces/layout-changed-aware';
import {DashboardBoxAware} from '../interfaces/dashboard-box-aware';
import {DashboardBoxComponentAware} from '../interfaces/dashboard-box-component-aware';
import {DashboardBoxWrapperComponentAware} from '../interfaces/dashboard-box-wrapper-component-aware';
import {DashboardBoxLayout} from '../dashboard-box-layout';
import {DashboardBoxTileWrapperComponent} from '../../component/dashboard-box-tile-wrapper/dashboard-box-tile-wrapper.component';
import {DashboardBoxNoneWrapperComponent} from '../../component/dashboard-box-none-wrapper/dashboard-box-none-wrapper.component';

@Injectable()
export class DashboardRendererService {

  private dashboardComponent: DashboardComponent = null;
  private renderer: Renderer2;

  private dashboardBoxWrapperRefs: ComponentRef<DashboardBoxWrapperComponent>[] = [];

  public constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public renderBoxComponents(dashboardBoxes: DashboardBox[] = []) {
    for (const dashboardBox of dashboardBoxes) {
      this.renderBoxComponent(dashboardBox, this.getDashboardComponent().getDashboardBoxesContainer());
    }
  }

  public renderBoxComponentInWrapperComponent(dashboardBox: DashboardBox, dashboardBoxWrapperComponent: DashboardBoxWrapperComponent): DashboardBoxWrapperComponent {
    const dashboardBoxRef = this.removeViewContainerRefContent(dashboardBoxWrapperComponent.getBoxViewContainerRef())
      .renderComponent(dashboardBox.getComponent(),
        dashboardBoxWrapperComponent.getBoxViewContainerRef());

    const dashboardBoxComponent = dashboardBoxRef.instance;

    if (this.isDashboardBoxAware(dashboardBoxComponent)) {
      dashboardBoxComponent.setDashboardBox(dashboardBox);
    }

    if (this.isDashboardBoxWrapperComponentAware(dashboardBoxComponent)) {
      dashboardBoxComponent.setDashboardBoxWrapperComponent(dashboardBoxWrapperComponent);
    }

    if (null !== dashboardBox.getEditComponent()) {
      const dashboardEditRef = this.removeViewContainerRefContent(dashboardBoxWrapperComponent.getEditViewContainerRef())
        .renderComponent(dashboardBox.getEditComponent(),
          dashboardBoxWrapperComponent.getEditViewContainerRef());
      const dashboardEditComponent = dashboardEditRef.instance;

      dashboardBoxWrapperComponent.setEditComponent(dashboardEditComponent);

      if (this.isDashboardBoxComponentAware(dashboardEditComponent)) {
        dashboardEditComponent.setDashboardBoxComponent(dashboardBoxComponent);
      }
    }

    dashboardBoxWrapperComponent
      .setDashboardComponent(this.getDashboardComponent())
      .setBoxComponent(dashboardBoxRef.instance)
      .setDashboardBox(dashboardBox);

    return dashboardBoxWrapperComponent;
  }

  public renderBoxComponent(dashboardBox: DashboardBox, renderTo: ViewContainerRef) {
    const wrapperComponentType = this.getDashboardBoxWrapperType(dashboardBox);

    // first render wrapper
    const dashboardBoxWrapperRef = this.renderComponent(wrapperComponentType, renderTo),
      dashboardBoxWrapperComponent = dashboardBoxWrapperRef.instance;

    if (this.getDashboardComponent()) {
      const layoutWidth = dashboardBox.getParameterValue('layoutWidth') || 3;
      this.renderer.addClass(
        dashboardBoxWrapperRef.location.nativeElement,
        `ui-g-${layoutWidth}`
      );
    }

    // then render component itself inside of the wrapper
    if (dashboardBoxWrapperComponent instanceof DashboardBoxWrapperComponent) {
      this.renderBoxComponentInWrapperComponent(dashboardBox, dashboardBoxWrapperComponent);

      this.dashboardBoxWrapperRefs.push(dashboardBoxWrapperRef);
    }
  }

  public removeBoxComponents(): DashboardRendererService {
    this.getDashboardComponent().getDashboardBoxesContainer().clear();
    return this;
  }

  public removeBoxComponent(index: number): DashboardRendererService {
    this.getDashboardComponent().getDashboardBoxesContainer().remove(index);
    this.dashboardBoxWrapperRefs.splice(index, 1);

    return this;
  }

  public getBoxComponents(): ComponentRef<DashboardBoxWrapperComponent>[] {
    return this.dashboardBoxWrapperRefs;
  }

  public getDashboardBoxWrapperType(dashboardBox: DashboardBox): any {
    const layout = dashboardBox.getLayout();
    let wrapperComponentType = null;

    switch (layout) {
      case DashboardBoxLayout.Normal:
        wrapperComponentType = DashboardBoxWrapperComponent;
        break;
      case DashboardBoxLayout.Tile:
        wrapperComponentType = DashboardBoxTileWrapperComponent;
        break;
      case DashboardBoxLayout.None:
        wrapperComponentType = DashboardBoxNoneWrapperComponent;
        break;
    }

    return wrapperComponentType;
  }

  public renderComponent(component, renderTo: ViewContainerRef): ComponentRef<any> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    return renderTo.createComponent(componentFactory);
  }

  public setDashboardComponent(dashboardComponent: DashboardComponent): DashboardRendererService {
    this.dashboardComponent = dashboardComponent;
    return this;
  }

  public getDashboardComponent(): DashboardComponent {
    return this.dashboardComponent;
  }

  private removeViewContainerRefContent(viewContainerRef: ViewContainerRef): DashboardRendererService {
    viewContainerRef.clear();
    return this;
  }

  private isDashboardBoxAware(component: any): component is DashboardBoxAware {
    return (<any>component).setDashboardBox !== undefined && (<any>component).getDashboardBox !== undefined;
  }

  private isDashboardBoxComponentAware(component: any): component is DashboardBoxComponentAware {
    return (<any>component).setDashboardBoxComponent !== undefined && (<any>component).getDashboardBoxComponent !== undefined;
  }

  private isDashboardBoxWrapperComponentAware(component: any): component is DashboardBoxWrapperComponentAware {
    return (<any>component).setDashboardBoxWrapperComponent !== undefined
      && (<any>component).getDashboardBoxWrapperComponent !== undefined;
  }
}
