import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'daysMonthsYears'
})
export class DaysMonthsYearsPipe implements PipeTransform {

    public constructor(protected translate: TranslateService) {}

    transform(value: any): string {

        let ret = '';

        if (value !== null) {
            if (value.years > 0) {
                ret += `${value.years}${this.translate.instant('HR.EMPLOYMENT.EMPLOYMENT_YEARS')} `;
            }

            if (value.months > 0) {
                ret += `${value.months}${this.translate.instant('HR.EMPLOYMENT.EMPLOYMENT_MONTHS')} `;
            }

            ret += `${value.days}${this.translate.instant('HR.EMPLOYMENT.EMPLOYMENT_DAYS')}`;
        }

        return ret;
    }
}
