import {Pipe, PipeTransform} from '@angular/core';
import {Calendar} from 'primeng';
import {cloneDeep} from 'lodash';
import {DATE_FORMAT_SESSION_KEY} from '../../core/workflow/user/set-locale.action';

export const DATE_FORMAT_DEFAULT = 'dd.mm.yy.';

@Pipe({
    name: 'appDate'
})
export class DatePipe implements PipeTransform {

    public static dateToString(calendar: Calendar, dateValue: Date|string): string {
        let dateString = '';

        let date = cloneDeep(dateValue);

        if (typeof date === 'string' && date !== '' && date !== null) {
            date = new Date(date);
        }

        if (calendar.dateFormat) {
            dateString = calendar.formatDate(date, calendar.dateFormat);
        }

        if (calendar.dateFormat && calendar.showTime) {
            dateString = calendar.formatDateTime(date);
        }

        if (calendar.timeOnly) {
            dateString = calendar.formatTime(date);
        }

        return dateString;
    }

    public transform(value: string|Date|undefined, format = '', showTime = false, timeOnly = false): string {

        if (!format) {
            format = sessionStorage.getItem(DATE_FORMAT_SESSION_KEY) || DATE_FORMAT_DEFAULT;
        }

        const calendar = new Calendar(null, null, null, null);
        calendar.showTime = showTime;
        calendar.dateFormat = format;
        calendar.timeOnly = timeOnly;

        return DatePipe.dateToString(calendar, value);
    }
}
