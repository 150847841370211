    <div class="ui-fluid">
        <div class="ui-g">
            <div *ngFor="let advanceFilter of advanceFilters" class="ui-g ui-g-2" style="padding-top: 0; padding-bottom: 0">
                <span class="ui-g ui-g-12" style="padding-bottom: 0">
                    <label for="{{advanceFilter.key}}">{{ advanceFilter.label }}</label>
                </span>
                <span [ngSwitch]="true" class="ui-g ui-g-12">

                    <p-dropdown
                        *ngIf="advanceFilter.operator"
                        [ngModel]="getFilterOperatorValue(advanceFilter)"
                        [options]="advanceFilter.operator.options | async"
                        placeholder="---"
                        (onChange)="onFilterOperatorEvent($event, advanceFilter)"
                        class="ui-g ui-g-4"
                        [style]="{'padding-right': '6px'}"
                        [disabled]="getFilterValue(advanceFilter) === null"
                        appendTo="body"
                        id="{{advanceFilter.key}}"
                    ></p-dropdown>

                    <p-checkbox
                        *ngSwitchCase="advanceFilter.type === 'checkbox'"
                        [ngModel]="getFilterValue(advanceFilter)"
                        (onChange)="onFilterEvent($event, advanceFilter)"
                        binary="true"
                        id="{{advanceFilter.key}}"
                    ></p-checkbox>

                    <p-triStateCheckbox
                            *ngSwitchCase="advanceFilter.type === 'triStateCheckbox'"
                            [ngModel]="getFilterValue(advanceFilter)"
                            (onChange)="onFilterEvent($event, advanceFilter)"
                            binary="true"
                            id="{{advanceFilter.key}}"
                    ></p-triStateCheckbox>

                    <p-calendar
                        *ngSwitchCase="advanceFilter.type === 'calendar'"
                        appCalendar
                        name="{{advanceFilter.key}}"
                        [ngModel]="getFilterValue(advanceFilter)"
                        [showIcon]="true"
                        (onSelect)="onFilterEvent($event, advanceFilter)"
                        (valueChange)="onFilterEvent($event, advanceFilter)"
                        inputStyleClass="ui-filter-calendar-input"
                        class="ui-filter-calendar"
                        showButtonBar="true"
                        [panelStyle]="{'width':'100px'}"
                        (onTodayClick)="onFilterEvent($event, advanceFilter, 'today')"
                        (onClearClick)="onFilterEvent($event, advanceFilter, 'clear')"
                        appendTo="body"
                        id="{{advanceFilter.key}}"
                    ></p-calendar>

                    <app-calendar-range-filter
                            *ngSwitchCase="advanceFilter.type === 'calendar-range' || advanceFilter.type === 'calendar-multi-date-range'"
                            (filterChange)="onFilterEvent($event, advanceFilter)"
                            [advancedFilterService]="tableComponent.advanceFilterService"
                            [advancedFilterOptions]="advanceFilter"
                    ></app-calendar-range-filter>

                    <p-dropdown
                        *ngSwitchCase="advanceFilter.type === 'dropdown'"
                        [options]="advanceFilter.options | async"
                        (onChange)="onFilterEvent($event.value, advanceFilter)"
                        [ngModel]="getFilterValue(advanceFilter)"
                        appendTo="body"
                        [style]="{'width': '100%'}"
                        [panelStyle]="{'width':'150px'}"
                        class="ui-filter-dropdown"
                        id="{{advanceFilter.key}}"
                        [showClear]="true"
                    ></p-dropdown>

                     <p-autoComplete
                             appAutoComplete
                             class="form-control"
                             *ngSwitchCase="advanceFilter.type === 'autocomplete'"
                             size="30"
                             appendTo="body"
                             [style]="{'width': '100%'}"
                             [field]="advanceFilter.autocompleteFieldName"
                             [handler]="advanceFilter.autocompleteHandler"
                             [queryField]="advanceFilter.autocompleteQueryField"
                             [params]="advanceFilter.autocompleteParams"
                             [forceSelectedValue]="true"
                             [selectedValue]="getFilterValue(advanceFilter)"
                             [dropdown]="true"
                             [forceSelection]="true"
                             (onSelect)="onFilterEvent($event, advanceFilter, {dataKey: advanceFilter.autocompleteDataKey || 'id'})"
                             [panelStyle]="{'width':'150px'}"
                             id="{{advanceFilter.key}}"
                             [showClear]="true"
                     ></p-autoComplete>

                    <p-spinner
                        *ngSwitchCase="advanceFilter.type === 'number'"
                        appSpinner
                        size="30"
                        [step]="advanceFilter.step || 1"
                        [ngModel]="getFilterValue(advanceFilter)"
                        (valueChange)="onFilterEvent($event, advanceFilter)"
                        id="{{advanceFilter.key}}"
                        [style]="{'width': '100%'}"
                    ></p-spinner>

                    <input
                        *ngSwitchDefault
                        pInputText
                        [ngModel]="getFilterValue(advanceFilter)"
                        type="text"
                        [style]="{'width': '100%'}"
                        (input)="onFilterEvent($event, advanceFilter)"
                        id="{{advanceFilter.key}}"
                    >

                    <p-multiSelect
                            *ngSwitchCase="advanceFilter.type === 'multiselect'"
                            id="{{advanceFilter.key}}"
                            (onChange)="onFilterEvent($event.value, advanceFilter)"
                            [ngModel]="getFilterValue(advanceFilter)"
                            [options]="advanceFilter.options | async"
                            appendTo="body"
                            class="ui-filter-dropdown"
                            defaultLabel="{{ 'COMMON.CHOOSE' | translate }}"
                    ></p-multiSelect>
                </span>
            </div>
        </div>
    </div>
