import {Employee} from './entities';
import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('employee_children')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class EmployeeChild {

    @AssociationOne(Employee)
    protected employee: Employee;

    protected firstName: string;
    protected lastName: string;
    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected birthDate: Date;
    protected sex: string;
    protected id: string;

    public setEmployee(employee: Employee): EmployeeChild {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }


    public setFirstName(firstName: string): EmployeeChild {
        this.firstName = firstName;
        return this;
    }

    public getFirstName(): string | undefined {
        return this.firstName;
    }

    public setLastName(lastName: string): EmployeeChild {
        this.lastName = lastName;
        return this;
    }

    public getLastName(): string | undefined {
        return this.lastName;
    }

    public setBirthDate(birthDate: Date) {
        this.birthDate = birthDate;
        return this;
    }

    public getBirthDate(): Date|null {
        return this.birthDate;
    }

    public setSex(sex: string): EmployeeChild {
        this.sex = sex;
        return this;
    }

    public getSex(): string | undefined {
        return this.sex;
    }

    public setId(id: string): EmployeeChild {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }
}
