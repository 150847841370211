import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {AuthenticationService, JwtLoginResponse} from '../../../core/services/authentication.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Route} from '../../../shared/enums/route';
import {BootstrapService} from '../../../core/services/bootstrap.service';
import {AppComponent} from '../../../app.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    public form: FormGroup;

    public constructor(
        private fb: FormBuilder,
        private router: Router,
        private authentication: AuthenticationService,
        private bootstrap: BootstrapService,
        private appComponent: AppComponent,
        private cdr: ChangeDetectorRef,
        private translate: TranslateService
    ) {
    }

    public ngOnInit() {
        if (this.authentication.isLoggedIn()) {
            this.router.navigate([Route.HOME]).then();
        }

        this.form = this.fb.group({
            username: this.fb.control('', [Validators.required]),
            password: this.fb.control('', [Validators.required])
        });
    }

    public ngOnDestroy() {

    }

    public doLogin(): void {
        const formValues = this.form.value;

        this.form.setErrors(null);

        this.authentication
            .login(formValues.username, formValues.password)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    this.onLoginError(response);

                    return throwError(response);
                }))
            .subscribe((response: JwtLoginResponse) => {
                this.onLoginSuccess(response);
            });
    }

    private onLoginError(response: HttpErrorResponse): void {
        this.form.setErrors({
            loginError: response.error['hydra:description'] || this.translate.instant('LOGIN.WRONG_CREDENTIALS')
        });

        this.cdr.detectChanges();
    }

    private onLoginSuccess(response: JwtLoginResponse): void {
        this.bootstrap.bootstrap(this.appComponent)
            .pipe(
                catchError((error) => {
                    return error;
                })
            ).subscribe(() => {

        });
    }
}
