import {Route} from 'entity-manager';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {PatronSaintDay} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

@Route('religions')
@CrossDependency(() => {
    return {
        patronSaintDays: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: PatronSaintDay
        }
    };
})
export class Religion {

    @AssociationMany(PatronSaintDay)
    protected patronSaintDays: PatronSaintDay[];

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;
    protected hasPatronSaintDay: boolean;

    public setPatronSaintDays(patronSaintDays: PatronSaintDay[]): Religion {
        this.patronSaintDays = patronSaintDays;
        return this;
    }

    public getPatronSaintDays(): PatronSaintDay[] | undefined {
        return this.patronSaintDays;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setHasPatronSaintDay(hasPatronSaintDay: boolean): Religion {
        this.hasPatronSaintDay = hasPatronSaintDay;
        return this;
    }

    public getHasPatronSaintDay(): boolean {
        return this.hasPatronSaintDay;
    }
}
