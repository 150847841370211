import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {AuthenticationService, JwtLoginResponse} from '../../../core/services/authentication.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Route} from '../../../shared/enums/route';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-login',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnDestroy {

  public form: FormGroup;

  public constructor(
      private router: Router,
      private authentication: AuthenticationService) {
  }

  public ngOnInit() {
    if (this.authentication.isLoggedIn()) {
      this.authentication.logout();
      this.router.navigate([Route.LOGIN]).then();
    }
  }

  public ngOnDestroy() {

  }
}
