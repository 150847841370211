import {Injectable} from '@angular/core';
import {TableComponent} from '../table.component';
import {DomHandler} from 'primeng/dom';
import {TableEditableColumnDirective} from '../directives/table-editable-column.directive';
import {TableColumn} from '../shared/table-column';
import {AbstractEditor} from './editor/abstract-editor';
import {NumberEditor} from './editor/number-editor';
import {AutocompleteEditor} from './editor/autocomplete-editor';
import {DefaultEditor} from './editor/default-editor';

@Injectable()
export class TableInlineService {

    protected table: TableComponent = null;
    protected directive: TableEditableColumnDirective = null;

    protected static findNextEntitySameEditableColumn(editingCell): any {
        let nextEditingCell = null;

        const tr = editingCell.closest('tr'),
            nextTr = tr.nextElementSibling;

        const currentTds = Array.prototype.slice.call( tr.getElementsByTagName('td') ),
            columnIndex = currentTds.indexOf( editingCell );

        if (nextTr && nextTr.getElementsByTagName('td')) {
            nextEditingCell = nextTr.getElementsByTagName('td')[columnIndex];
        }

        return nextEditingCell;
    }

    protected static findPreviousEntitySameEditableColumn(editingCell): any {
        let nextEditingCell = null;

        const tr = editingCell.closest('tr'),
            nextTr = tr.previousElementSibling;

        const currentTds = Array.prototype.slice.call( tr.getElementsByTagName('td') ),
            columnIndex = currentTds.indexOf( editingCell );

        if (nextTr && nextTr.getElementsByTagName('td')) {
            nextEditingCell = nextTr.getElementsByTagName('td')[columnIndex];
        }

        return nextEditingCell;
    }

    public setTable(table: TableComponent): this {
        this.table = table;
        return this;
    }

    public getTable(): TableComponent {
        return this.table;
    }

    public setDirective(directive: TableEditableColumnDirective): this {
        this.directive = directive;
        return this;
    }

    public getDirective(): TableEditableColumnDirective {
        return this.directive;
    }

    public switchToCurrentlyFocusedEntityCell(): void {
        const currentlySelectedEntity = this.table.getCurrentlySelectedEntity(),
            tr = this.getTr(currentlySelectedEntity);

        if (tr && tr.cells instanceof HTMLCollection) {
            const cells = Array.prototype.slice.call(tr.cells);

            for (const cell of cells) {
                if (cell.classList.contains('ui-editable-column') && !cell.classList.contains('ui-edit-disabled')) {
                    DomHandler.invokeElementMethod(cell, 'click');
                    break;
                }
            }
        }
    }

    public switchToFirstEntityCell(): void {
        let cell = document.getElementsByClassName('ui-editable-column')[0];

        if (cell && cell.classList.contains('ui-edit-disabled')) {
            cell = this.findNextEditableColumn(cell);
        }

        if (cell) {
            DomHandler.invokeElementMethod(cell, 'click');
        }
    }

    public findPreviousEditableColumn(cell: Element) {
        let prevCell = cell.previousElementSibling;

        if (!prevCell) {
            const previousRow = cell.parentElement.previousElementSibling;
            if (previousRow) {
                prevCell = previousRow.lastElementChild;
            }
        }

        if (prevCell && prevCell.classList.contains('ui-edit-disabled')) {
            return this.findPreviousEditableColumn(prevCell);
        }

        return prevCell || null;
    }

    public findNextEditableColumn(cell: Element) {
        let nextCell = cell.nextElementSibling;

        if (!nextCell) {
            const nextRow = cell.parentElement.nextElementSibling;
            if (nextRow) {
                nextCell = nextRow.firstElementChild;
            }
        }

        if (nextCell && nextCell.classList.contains('ui-edit-disabled')) {
            return this.findNextEditableColumn(nextCell);
        }

        return nextCell || null;
    }

    public moveToNextCell(event: KeyboardEvent): void {
        const currentCell = this.directive.findCell(event.target);
        const targetCell = this.findNextEditableColumn(currentCell);

        if (targetCell) {
            DomHandler.invokeElementMethod(event.target, 'blur');
            DomHandler.invokeElementMethod(targetCell, 'click');
            event.preventDefault();
        } else {
            this.switchToNextEntityCell(event);
        }
    }

    public moveToPreviousCell(event: KeyboardEvent): void {
        const currentCell = this.directive.findCell(event.target);
        const targetCell = this.findPreviousEditableColumn(currentCell);
        if (targetCell) {
            DomHandler.invokeElementMethod(event.target, 'blur');
            DomHandler.invokeElementMethod(targetCell, 'click');
            event.preventDefault();
        } else {
            this.switchToPreviousEntityCell(event);
        }
    }

    public findNextEntityFirstEditableColumn(editingCell): any {
        let nextEditingCell = null;

        const tr = editingCell.closest('tr'),
            nextTr = tr.nextElementSibling;

        const columnIndex = 0;

        if (nextTr && nextTr.getElementsByTagName('td')) {
            nextEditingCell = nextTr.getElementsByTagName('td')[columnIndex];

            if (nextEditingCell && nextEditingCell.classList.contains('ui-edit-disabled')) {
                return this.findNextEditableColumn(nextEditingCell);
            }
        }

        return nextEditingCell;
    }

    public switchToNextEntityCell(event): void {
        const currentCell = this.directive.findCell(event.target);

        if (currentCell) {
            const targetCell = TableInlineService.findNextEntitySameEditableColumn(currentCell);

            if (targetCell) {
                DomHandler.invokeElementMethod(targetCell, 'click');
            }
        }
    }

    public switchToNextEntityFirstCell(event): void {
        const currentCell = this.directive.findCell(event.target);

        if (currentCell) {
            const targetCell = this.findNextEntityFirstEditableColumn(currentCell);

            if (targetCell) {
                DomHandler.invokeElementMethod(targetCell, 'click');
            }
        }
    }

    public switchToPreviousEntityCell(event): void {
        const currentCell = this.directive.findCell(event.target);

        if (currentCell) {
            const targetCell = TableInlineService.findPreviousEntitySameEditableColumn(currentCell);

            if (targetCell) {
                DomHandler.invokeElementMethod(targetCell, 'click');
            }
        }
    }

    public getTr(entity: any|null): any|null {
        let tr = null;

        if (entity && entity.getId()) {
            tr = this.table.elementRef.nativeElement.querySelector(`tr[data-entity-id="${entity.getId()}"]`);
        }

        if (entity && entity.uniqueId) {
            tr = this.table.elementRef.nativeElement.querySelector(`tr[data-entity-unique-id="${entity.uniqueId}"]`);
        }

        return tr;
    }

    public handleEnterPressed(event): void {
        if (this.directive.exitInlineEditOnEnter === true) {
            DomHandler.removeClass(this.directive.dt.editingCell, 'ui-editing-cell');

            this.directive.dt.editingCell = null;
            this.directive.dt.onEditCancel.emit({ field: this.directive.field, data: this.directive.data });

            return;
        }

        if (this.directive.column.edit &&
            ['autocomplete', 'dropdown', 'number'].includes(this.directive.column.edit.type)
        ) {
            this.moveToNextCell(event);
        } else if (
            this.directive.column.edit &&
            this.directive.column.edit.type === 'invoicingItem' &&
            event.target.type === 'textarea'
        ) {
            // do nothingz
        } else {
            this.switchToNextEntityFirstCell(event);
        }
    }

    public handleTabPressed(event): void {
        if (event.shiftKey && this.canTabPrevious(event)) {
            this.moveToPreviousCell(event);
        }

        if (!event.shiftKey && this.canTabNext(event)) {
            this.moveToNextCell(event);
        }
    }

    protected canTabNext(event): boolean {
        if (this.directive.column.edit && this.directive.column.edit.type === 'invoicingItem') {
            return event.target.type === 'textarea';
        }

        return true;
    }

    protected canTabPrevious(event): boolean {
        if (this.directive.column.edit && this.directive.column.edit.type === 'invoicingItem') {
            return event.target.type === 'text';
        }

        return true;
    }

    public getEditor(column: TableColumn): AbstractEditor {
        let editor = null;

        switch (column.edit.type) {
            case 'number':
                editor = new NumberEditor();
                break;
            case 'autocomplete':
                editor = new AutocompleteEditor();
                break;
            default:
                editor = new DefaultEditor();
        }

        return editor;
    }
}
