import {CalendarEntry, Employee, EmployeeRequestState, EmployeeRequestType} from './entities';
import {AssociationMany, Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {MediaObject} from './media-object';
import {User} from './user';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {EmployeeRequestRepository} from '../repositories/employee-request.repository';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {StringPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';

@Route('employee_requests')
@CrossDependency(() => {
    return {
        assignee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        employeeRequestState: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeRequestState
        },
        employeeRequestType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeRequestType
        }
        ,
        medias: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: MediaObject
        },
        createdBy: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        },
        updatedBy: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        },
        calendarEntries: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: CalendarEntry
        }
    };
})
@EntityDefaultFilters({
    'exists[employee.employment.dismissalType]': false,
    disableVisibleFilter: 1
})
@Repository(EmployeeRequestRepository)
export class EmployeeRequest {

    protected id: string;

    protected hourType: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected fromDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected toDate: Date;
    protected notice: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected createdAt: Date;
    protected numberOfDays: number;
    protected numberOfHours: string;
    protected isNoticeRequired: boolean;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected hourCost: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected hourCostDate;

    @AssociationOne(User)
    protected assignee: User;

    @AssociationOne(Employee)
    protected employee: Employee;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationOne(User)
    protected createdBy: User;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationOne(User)
    protected updatedBy: User;

    @AssociationOne(EmployeeRequestType)
    protected employeeRequestType;

    @AssociationOne(EmployeeRequestState)
    protected employeeRequestState;

    @AssociationOne(EmployeeRequest)
    protected editRequest;

    @AssociationMany(MediaObject)
    protected medias: any;

    @AssociationMany(CalendarEntry)
    protected calendarEntries: CalendarEntry[];

    public noticeForRejectingRequest: string;
    public isChangeToDateVacation:boolean;

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public getHourType(): string | undefined {
        return this.hourType;
    }

    public setHourType(hourType: string): this {
        this.hourType = hourType;
        return this;
    }

    public getFromDate(): Date|undefined {
        return this.fromDate;
    }

    public setFromDate(fromDate: Date): this {
        this.fromDate = fromDate;
        return this;
    }

    public getToDate(): Date|undefined {
        return this.toDate;
    }

    public setToDate(toDate: Date): this {
        this.toDate = toDate;
        return this;
    }

    public getNotice(): string|undefined {
        return this.notice;
    }

    public setNotice(notice: string): this {
        this.notice = notice;
        return this;
    }

    public getAssignee(): User | undefined {
        return this.assignee;
    }

    public setAssignee(assignee: User): this {
        this.assignee = assignee;
        return this;
    }


    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getEmployeeRequestState(): EmployeeRequestState | undefined {
        return this.employeeRequestState;
    }

    public setEmployeeRequestState(employeeRequestState: EmployeeRequestState): this {
        this.employeeRequestState = employeeRequestState;
        return this;
    }

    public getEmployeeRequestType(): EmployeeRequestType | undefined {
        return this.employeeRequestType;
    }

    public setEmployeeRequestType(employeeRequestType: EmployeeRequestType): this {
        this.employeeRequestType = employeeRequestType;
        return this;
    }

    public getEditRequest(): EmployeeRequest | undefined {
        return this.editRequest;
    }

    public setEditRequest(editRequest: EmployeeRequest): this {
        this.editRequest = editRequest;
        return this;
    }

    public setCreatedAt(createdAt: Date): this {
        this.createdAt = createdAt;
        return this;
    }

    public getCreatedAt(): Date|undefined {
        return this.createdAt;
    }

    public setNumberOfDays(numberOfDays: number): this {
        this.numberOfDays = numberOfDays;
        return this;
    }

    public getNumberOfDays(): number|undefined {
        return this.numberOfDays;
    }

    public setNumberOfHours(numberOfHours: string): this {
        this.numberOfHours = numberOfHours;
        return this;
    }

    public getNumberOfHours(): string|undefined {
        return this.numberOfHours;
    }

    public setIsNoticeRequired(isNoticeRequired: boolean): this {
        this.isNoticeRequired = isNoticeRequired;
        return this;
    }

    public getIsNoticeRequired(): boolean|undefined {
        return this.isNoticeRequired;
    }

    public setMedias(medias: any) {
        this.medias = medias;
        return this;
    }

    public getMedias(): any {
        return this.medias;
    }

    public setCalendarEntries(calendarEntries: CalendarEntry[]): EmployeeRequest {
        this.calendarEntries = calendarEntries;
        return this;
    }

    public getCalendarEntries(): CalendarEntry[] | undefined {
        return this.calendarEntries;
    }

    public getHourCost(): string | undefined {
        return this.hourCost;
    }

    public setHourCost(hourCost: string): this {
        this.hourCost = hourCost;
        return this;
    }

    public getHourCostDate(): Date | undefined {
        return this.hourCostDate;
    }

    public setHourCostDate(hourCostDate: Date): this {
        this.hourCostDate = hourCostDate;
        return this;
    }

    public getCreatedBy(): User | undefined {
        return this.createdBy;
    }

    public setCreatedBy(createdBy: User): this {
        this.createdBy = createdBy;
        return this;
    }

    public getUpdatedBy(): User | undefined {
        return this.updatedBy;
    }

    public setUpdatedBy(updatedBy: User): this {
        this.updatedBy = updatedBy;
        return this;
    }

    public getNoticeForRejectingRequest(): string|undefined {
        return this.noticeForRejectingRequest;
    }

    public setNoticeForRejectingRequest(noticeForRejectingRequest: string): this {
        this.noticeForRejectingRequest = noticeForRejectingRequest;
        return this;
    }

    public setIsChangeToDateVacation(isChangeToDateVacation: boolean): this {
        this.isChangeToDateVacation = isChangeToDateVacation;
        return this;
    }

    public getIsChangeToDateVacation(): boolean|undefined {
        return this.isChangeToDateVacation;
    }
}
