import {InjectorAwareInterface} from '../../../workflow/services/injector-aware.interface';
import {WorkflowAction} from '../../../workflow/services/workflow-action';
import {WorkflowActionInterface} from '../../../workflow/services/workflow-action.interface';
import {WorkflowActionStatus} from '../../../workflow/services/workflow-action-status';
import {Observable, of} from 'rxjs';
import * as moment from 'moment';
import 'moment/locale/sr';
import {User} from '../../entities/user';
import {AuthenticationService} from '../../services/authentication.service';
import {DATE_FORMAT_DEFAULT} from '../../../shared/pipes/date.pipe';

export const DATE_FORMAT_SESSION_KEY = 'dateFormat';

export class SetLocaleAction extends WorkflowAction implements WorkflowActionInterface, InjectorAwareInterface {

    public run(): Observable<WorkflowActionStatus> {
        const user: User = this.injector.get(AuthenticationService).getUser();

        const format = user instanceof User &&
            user.getSettingValue(DATE_FORMAT_SESSION_KEY) ?
                user.getSettingValue(DATE_FORMAT_SESSION_KEY) :
                DATE_FORMAT_DEFAULT;

        moment.locale('sr');
        sessionStorage.setItem(DATE_FORMAT_SESSION_KEY, format);

        return of({
            success: true,
            stop: false,
            status: ''
        });
    }
}
