import {Route} from 'entity-manager';
import {
    Absence,
    AbsenceType,
    AbsenceTypeEnum, CalendarEntryItem,
    CalendarEntryType,
    CalendarEntryTypeEnum,
    Employee,
    EmployeeRequest, Todo,
    WorkShift
} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {CalendarEntryRepository} from '../repositories/calendar-entry.repository';
import {AssociationManyToMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many-to-many';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {BookedTimeAbsence} from './booked-time-absence';

@Route('calendar_entries')
@EntityDefaultFilters({
    disableVisibleFilter: 1
})
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: CalendarEntryType
        },
        absence: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Absence
        },
        workShift: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkShift
        },
        employeeRequests: {
            mapping: Meta.META_ASSOCIATIONS_MANY_TO_MANY,
            entity: EmployeeRequest
        },
        calendarEntryItems: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: CalendarEntryItem
        },
        bookedTimeAbsences: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: BookedTimeAbsence
        },
        todos: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: Todo
        }
    };
})
@EntityDefaultFilters({
    'exists[employee.employment.dismissalType]': false
})
@Repository(CalendarEntryRepository)
export class CalendarEntry {

    protected id: string;

    @AssociationOne(CalendarEntryType)
    protected type: CalendarEntryType;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected entryDate: Date;
    protected entryDay: number;
    protected entryMonth: number;
    protected entryYear: number;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(Absence)
    protected absence: Absence;
    protected isAbsenceShorterThanFourHours: boolean;

    @AssociationOne(WorkShift)
    protected workShift: WorkShift;

    @AssociationManyToMany(EmployeeRequest)
    protected employeeRequests: EmployeeRequest[];

    @AssociationManyToMany(CalendarEntryItem)
    protected calendarEntryItems: CalendarEntryItem[];

    @AssociationManyToMany(BookedTimeAbsence)
    protected bookedTimeAbsences: BookedTimeAbsence[];

    @AssociationManyToMany(Todo)
    protected todos: Todo[];

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected startsAt: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected endsAt: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected break: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected startsAt2: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected endsAt2: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected startsAt3: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected endsAt3: Date;
    protected duration: number;
    protected textValue: string;
    protected colorValue: string;
    protected icon: string;
    protected isHalfIcon: boolean;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setType(type: CalendarEntryType): this {
        this.type = type;
        return this;
    }

    public getType(): CalendarEntryType|undefined {
        return this.type;
    }

    public setEntryDate(entryDate: Date): this {
        this.entryDate = entryDate;
        return this;
    }

    public getEntryDate(): Date|undefined {
        return this.entryDate;
    }

    public setEntryDay(entryDay: number): this {
        this.entryDay = entryDay;
        return this;
    }

    public getEntryDay(): number|undefined {
        return this.entryDay;
    }

    public setEntryMonth(entryMonth: number): this {
        this.entryMonth = entryMonth;
        return this;
    }

    public getEntryMonth(): number|undefined {
        return this.entryMonth;
    }

    public setEntryYear(entryYear: number): this {
        this.entryYear = entryYear;
        return this;
    }

    public getEntryYear(): number|undefined {
        return this.entryYear;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public setAbsence(absence: Absence): this {
        this.absence = absence;
        return this;
    }

    public getAbsence(): Absence|undefined {
        return this.absence;
    }

    public setWorkShift(workShift: WorkShift): this {
        this.workShift = workShift;
        return this;
    }

    public getWorkShift(): WorkShift|undefined {
        return this.workShift;
    }

    public setIsAbsenceShorterThanFourHours(isAbsenceShorterThanFourHours: boolean): this {
        this.isAbsenceShorterThanFourHours = isAbsenceShorterThanFourHours;
        return this;
    }

    public getIsAbsenceShorterThanFourHours(): boolean|undefined {
        return this.isAbsenceShorterThanFourHours;
    }

    public setStartsAt(startsAt: Date) {
        this.startsAt = startsAt;
        return this;
    }

    public getStartsAt() {
        return this.startsAt;
    }

    public setEndsAt(endsAt: Date) {
        this.endsAt = endsAt;
        return this;
    }

    public getEndsAt() {
        return this.endsAt;
    }

    public setBreak(breakAt: Date) {
        this.break = breakAt;
        return this;
    }

    public getBreak() {
        return this.break;
    }

    public setStartsAt2(startsAt2: Date) {
        this.startsAt2 = startsAt2;
        return this;
    }

    public getStartsAt2() {
        return this.startsAt2;
    }

    public setEndsAt2(endsAt2: Date) {
        this.endsAt2 = endsAt2;
        return this;
    }

    public getEndsAt2() {
        return this.endsAt2;
    }

    public setStartsAt3(startsAt3: Date) {
        this.startsAt3 = startsAt3;
        return this;
    }

    public getStartsAt3() {
        return this.startsAt3;
    }

    public setEndsAt3(endsAt3: Date) {
        this.endsAt3 = endsAt3;
        return this;
    }

    public getEndsAt3() {
        return this.endsAt3;
    }

    public setDuration(duration: number): this {
        this.duration = duration;
        return this;
    }

    public getDuration(): number|undefined {
        return this.duration;
    }

    public setEmployeeRequests(employeeRequests: EmployeeRequest[]): this {
        this.employeeRequests = employeeRequests;
        return this;
    }

    public getEmployeeRequests(): EmployeeRequest[]|undefined {
        return this.employeeRequests;
    }

    public getCalendarEntryItems(): CalendarEntryItem[]|undefined {
        return this.calendarEntryItems;
    }

    public setCalendarEntryItems(calendarEntryItems: CalendarEntryItem[]): this {
        this.calendarEntryItems = calendarEntryItems;
        return this;
    }

    public getBookedTimeAbsences(): BookedTimeAbsence[]|undefined {
        return this.bookedTimeAbsences;
    }

    public setBookedTimeAbsences(bookedTimeAbsences: BookedTimeAbsence[]): this {
        this.bookedTimeAbsences = bookedTimeAbsences;
        return this;
    }

    public getTodos(): Todo[]|undefined {
        return this.todos;
    }

    public setTodos(todos: Todo[]): this {
        this.todos = todos;
        return this;
    }

    public isAbsence(): boolean {
        return this.getAbsence() instanceof Absence && this.getType() instanceof CalendarEntryType && this.getType().getCode() === CalendarEntryTypeEnum.Absence;
    }

    public isWorkDay(): boolean {
        return this.getWorkShift() instanceof WorkShift && this.getType() instanceof CalendarEntryType && this.getType().getCode() === CalendarEntryTypeEnum.WorkDay;
    }

    public isWeekend(): boolean {
        return this.getType() instanceof CalendarEntryType && this.getType().getCode() === CalendarEntryTypeEnum.Weekend;
    }

    public isSickLeave(): boolean {
        return this.getAbsence() instanceof Absence && this.getAbsence().getType() instanceof AbsenceType && this.getAbsence().getType().getCode() === AbsenceTypeEnum.SickLeave;
    }

    public setTextValue(textValue: string) {
        this.textValue = textValue;
        return this;
    }

    public getTextValue(): string {
        return this.textValue;
    }

    public setColorValue(colorValue: string) {
        this.colorValue = colorValue;
        return this;
    }

    public getColorValue(): string {
        return this.colorValue;
    }

    public setIcon(icon: string) {
        this.icon = icon;
        return this;
    }

    public getIcon(): string {
        return this.icon;
    }

    public setIsHalfIcon(isHalfIcon: boolean): CalendarEntry {
        this.isHalfIcon = isHalfIcon;
        return this;
    }

    public getIsHalfIcon(): boolean {
        return this.isHalfIcon;
    }
}
