import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {TableComponent} from '../table.component';
import {TranslateService} from '@ngx-translate/core';
import {TableAdvanceFilter} from '../shared/table-advance-filter';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

interface AdvanceFilterChangeMeta {
  event: any;
  advanceFilter: TableAdvanceFilter;
  param: any;
  isOperatorEvent: any;
}

@Component({
  selector: 'app-table-advance-filters',
  templateUrl: './table-advance-filters.component.html',
  styleUrls: ['./table-advance-filters.component.scss'],
})
export class TableAdvanceFiltersComponent implements OnInit {

  @Input() public tableComponent: TableComponent = null;
  @Input() public advanceFilters: TableAdvanceFilter[] = [];

  private filterChanged: Subject<AdvanceFilterChangeMeta> = new Subject<AdvanceFilterChangeMeta>();

  public constructor(
    private translateService: TranslateService,
  ) {

  }

  public ngOnInit(): void {
    this.filterChanged
        .pipe(
            debounceTime(500),
            distinctUntilChanged())
        .subscribe(meta => {
          this.onFilterChanged(meta);
        });
  }

  public onFilterEvent(event: any, advanceFilter: TableAdvanceFilter, param?: any, isOperatorEvent?: any) {
    this.filterChanged.next({
      event,
      advanceFilter,
      param,
      isOperatorEvent
    });
  }

  public getFilterValue(filter: TableAdvanceFilter, param?: any): any {
    return this.tableComponent.advanceFilterService.getFilterValue(filter, param);
  }

  public getFilterOperatorValue(filter: TableAdvanceFilter): any {
    return this.tableComponent.advanceFilterService.getFilterOperatorValue(filter);
  }

  public onFilterOperatorEvent(event: any, filter: TableAdvanceFilter, param?: any) {
    this.onFilterEvent(null, filter, param, event);
  }

  public onFilterChanged(meta: AdvanceFilterChangeMeta): void {
    const advanceFilter: TableAdvanceFilter = meta.advanceFilter,
        filter = this.tableComponent.advanceFilterService.getOrCreateFilter(advanceFilter),
        filterKey = this.tableComponent.advanceFilterService.getFilterKey(advanceFilter);

    this.tableComponent.advanceFilterService.addFilter(filterKey, filter);

    if (meta.isOperatorEvent) {
      const operatorValue = filter.getOperatorValueFromChange(meta.isOperatorEvent);

      filter.setFilterOperatorValue(operatorValue);
    } else {
      const value = filter.getValueFromChange(meta.event, meta.param);

      filter.setFilterValue(value);

    }

    if (filter.getFilterValue() === null && advanceFilter.allowNull !== true) {
      this.tableComponent.advanceFilterService.removeFilter(filterKey);
    }

    this.tableComponent.selection.removeAll();

    this.tableComponent.resetPagination();

    if (advanceFilter && typeof advanceFilter.beforeFilter === 'function') {
      advanceFilter.beforeFilter(filter);
    }

    this.tableComponent.onLazyLoadEvent();
  }
}
