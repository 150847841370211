<div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
        <div class="ui-grid-col-12">
            <div class="ui-grid ui-grid-responsive ui-fluid">

                <div class="ui-grid-row" *ngIf="mode === 'basic' && selectedFiles.length === 0">
                    <div class="ui-grid-col-12">
                        <p-fileUpload
                                #form
                                chooseLabel="{{ 'FILE.CHOOSE_ONE' | translate }}"
                                cancelLabel="{{ 'FILE.CANCEL' | translate }}"
                                [showUploadButton]="false"
                                mode="{{mode}}"
                                name="{{name}}"
                                url="{{url}}"
                                accept="{{accept}}"
                                maxFileSize="10000000000"
                                class="single-upload"
                                fileLimit="1"
                                (onSelect)="onSelect(form)"
                                [disabled]="disabled"
                        ></p-fileUpload>
                    </div>
                </div>

                <div class="ui-grid-row" *ngIf="mode === 'basic' && selectedFiles.length === 1">
                    <div class="ui-grid-col-12">
                        <button
                            pButton
                            type="button"
                            [disabled]="disabled"
                            label="{{selectedFiles[0].name}}"
                            icon="fa fa-trash"
                            iconPos="right"
                            (click)="onRemove($event)"
                        >
                        </button>
                    </div>
                </div>

                <div class="ui-grid-row" *ngIf="mode === 'basic' && mediaObject">
                    <app-media-object-preview
                        [mediaObject]="mediaObject"
                    ></app-media-object-preview>
                    <i *ngIf="mediaObjectClassName === 'HealthStatusMediaObject'"
                       (click)="onItemRemove()"
                       class="fa fa-trash-o"
                       style="margin-left: 40px; font-size: 17px; cursor: pointer"
                    ></i>
                    <i *ngIf="mediaObjectClassName === 'BulletinBoardItemMediaObject'"
                       (click)="onItemRemove()"
                       class="fa fa-trash-o"
                       style="margin-left: 40px; font-size: 17px; cursor: pointer"
                    ></i>
                </div>
            </div>
        </div>
    </div>
</div>
