import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {EstimateItem} from '../entities/estimate-item';

@Injectable({
    providedIn: 'root'
})
export class EstimateItemRepository extends EntityRepository {

    public sortEntities(entities: EstimateItem[] = []): Observable<any> {
        const ids = [];

        for (const entity of entities) {
            ids.push(entity.getId());
        }

        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}estimate_items/sort`,
            ids
        ).pipe(
            map(() => {
                return null;
            })
        );
    }
}
