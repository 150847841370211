import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {MoneyTransaction} from '../entities/money-transaction';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MoneyTransactionRepository extends EntityRepository {

    public getLast(): Observable<MoneyTransaction> {
        return this.entityManager.getRepository(MoneyTransaction).findOne({
            'order[createdAt]': 'DESC',
            itemsPerPage: 1,
        });
    }

    public getTotal(field: string, params: any = {}): Observable<number> {
        const route = this.meta.getMetaDataProperty(new MoneyTransaction(), Meta.META_ROUTE);

        return this.connection.get(
            environment.ENTITY_MANAGER_URL_PREFIX + route + '/' + field + '/total', {params})
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }
}
