import {NgModule} from '@angular/core';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {CommonModule} from '@angular/common';
import {DashboardBoxWrapperComponent} from './component/dashboard-box-wrapper/dashboard-box-wrapper.component';
import {DashboardBoxListComponent} from './component/dashboard/dashboard-box-list/dashboard-box-list.component';
import {DashboardBoxTileWrapperComponent} from './component/dashboard-box-tile-wrapper/dashboard-box-tile-wrapper.component';
import {DashboardBoxNoneWrapperComponent} from './component/dashboard-box-none-wrapper/dashboard-box-none-wrapper.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    DashboardComponent,
    DashboardBoxWrapperComponent,
    DashboardBoxTileWrapperComponent,
    DashboardBoxNoneWrapperComponent,
    DashboardBoxListComponent
  ],
  providers: [],
  exports: [
    DashboardComponent,
    DashboardBoxWrapperComponent,
    DashboardBoxTileWrapperComponent,
    DashboardBoxNoneWrapperComponent,
    DashboardBoxListComponent
  ],
  entryComponents: [
    DashboardBoxWrapperComponent,
    DashboardBoxTileWrapperComponent,
    DashboardBoxNoneWrapperComponent
  ]
})
export class DashboardModule {

}
