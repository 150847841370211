import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {BankAccount, CrmCompany, EstimateItem, EstimateType, Invoice} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {PaymentType} from './payment-type';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {EstimateRepository} from '../repositories/estimate.repository';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('estimates')
@CrossDependency(() => {
    return {
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EstimateType
        },
        invoice: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Invoice
        },
        contact: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: CrmCompany
        },
        paymentType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PaymentType
        },
        bankAccount: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: BankAccount
        }
    };
})
@Repository(EstimateRepository)
export class Estimate {

    @AssociationOne(EstimateType)
    protected type;

    @AssociationOne(Invoice)
    protected invoice;

    @AssociationOne(CrmCompany)
    protected contact;

    @AssociationMany(EstimateItem)
    protected estimateItems;

    @AssociationOne(PaymentType)
    protected paymentType;

    @AssociationOne(BankAccount)
    protected bankAccount;

    protected invoiceNumber: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected invoiceDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected currencyDate: Date;
    protected vatDate: string;
    protected hasVat: boolean;
    protected invoiced = false;
    protected notice: string;
    protected additionalNotice: string;
    protected id: string;
    protected price: number;
    protected deliveryPlanned: string;
    protected optionPlanned: string;

    public setType(type: EstimateType): Estimate {
        this.type = type;
        return this;
    }

    public getType(): EstimateType|undefined {
        return this.type;
    }

    public setInvoice(invoice: Invoice): Estimate {
        this.invoice = invoice;
        return this;
    }

    public getInvoice(): Invoice|undefined {
        return this.invoice;
    }

    public setContact(contact: CrmCompany): Estimate {
        this.contact = contact;
        return this;
    }

    public getContact(): CrmCompany|undefined {
        return this.contact;
    }

    public setEstimateItems(estimateItems: EstimateItem[] = []): Estimate {
        this.estimateItems = estimateItems;
        return this;
    }

    public getEstimateItems(): EstimateItem[]|undefined {
        return this.estimateItems;
    }

    public setInvoiceNumber(invoiceNumber: string) {
        this.invoiceNumber = invoiceNumber;
        return this;
    }

    public getInvoiceNumber(): string {
        return this.invoiceNumber;
    }

    public setInvoiceDate(invoiceDate: Date) {
        this.invoiceDate = invoiceDate;
        return this;
    }

    public getInvoiceDate(): Date|null {
        return this.invoiceDate;
    }

    public setCurrencyDate(currencyDate: Date) {
        this.currencyDate = currencyDate;
        return this;
    }

    public getCurrencyDate(): Date|null {
        return this.currencyDate;
    }

    public setVatDate(vatDate: string) {
        this.vatDate = vatDate;
        return this;
    }

    public getVatDate(): string {
        return this.vatDate;
    }

    public setHasVat(hasVat: boolean) {
        this.hasVat = hasVat;
        return this;
    }

    public getHasVat(): boolean {
        return this.hasVat;
    }

    public setInvoiced(invoiced: false) {
        this.invoiced = invoiced;
        return this;
    }

    public getInvoiced(): boolean {
        return this.invoiced;
    }

    public getNotice(): string {
        return this.notice;
    }

    public setNotice(notice: string) {
        this.notice = notice;
        return this;
    }

    public getAdditionalNotice(): string {
        return this.additionalNotice;
    }

    public setAdditionalNotice(additionalNotice: string) {
        this.additionalNotice = additionalNotice;
        return this;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }


    public getPrice(): number {
        return this.price;
    }

    public setPrice(price: number) {
        this.price = price;
        return this;
    }

    public getPaymentType(): PaymentType|undefined {
        return this.paymentType;
    }

    public setPaymentType(paymentType: PaymentType): Estimate {
        this.paymentType = paymentType;
        return this;
    }

    public getDeliveryPlanned(): string {
        return this.deliveryPlanned;
    }

    public setDeliveryPlanned(deliveryPlanned: string): Estimate {
        this.deliveryPlanned = deliveryPlanned;
        return this;
    }

    public getOptionPlanned(): string {
        return this.optionPlanned;
    }

    public setOptionPlanned(optionPlanned: string): Estimate {
        this.optionPlanned = optionPlanned;
        return this;
    }

    public setBankAccount(bankAccount: BankAccount): Estimate {
        this.bankAccount = bankAccount;
        return this;
    }

    public getBankAccount(): BankAccount {
        return this.bankAccount;
    }
}
