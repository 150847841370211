import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Employee, User} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Route} from 'entity-manager';
import {TransactionInterface} from './transaction.interface';

@Route('employee_salary_calculation_transactions')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        createdBy: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        }
    };
})
export class EmployeeSalaryCalculationTransaction implements TransactionInterface {
    @AssociationOne(Employee)
    protected employee;

    @AssociationOne(User)
    protected createdBy;

    protected id: string;
    protected field: string;
    protected oldValue: string;
    protected newValue: string;
    protected createdAt: Date;

    public getId(): string {
        return this.id;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getEmployee(): Employee {
        return this.employee;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getCreatedBy(): User {
        return this.createdBy;
    }

    public setCreatedBy(createdBy: User): this {
        this.createdBy = createdBy;
        return this;
    }

    public getField(): string {
        return this.field;
    }

    public setField(field: string): this {
        this.field = field;
        return this;
    }

    public getOldValue(): string {
        return this.oldValue;
    }

    public setOldValue(oldValue: string): this {
        this.oldValue = oldValue;
        return this;
    }

    public getNewValue(): string {
        return this.newValue;
    }

    public setNewValue(newValue: string): this {
        this.newValue = newValue;
        return this;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(createdAt: Date): this {
        this.createdAt = createdAt;
        return this;
    }
}
