import {InjectorAwareInterface} from '../../../workflow/services/injector-aware.interface';
import {WorkflowAction} from '../../../workflow/services/workflow-action';
import {WorkflowActionInterface} from '../../../workflow/services/workflow-action.interface';
import {WorkflowActionStatus} from '../../../workflow/services/workflow-action-status';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EntityManagerService} from '../../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {AuthenticationService} from '../../services/authentication.service';
import {MediaObject} from '../../entities/media-object';
import {MediaObjectRepository} from '../../repositories/media-object.repository';

export class LoadAndSetUserProfilePictureAction extends WorkflowAction implements WorkflowActionInterface, InjectorAwareInterface {

    public run(): Observable<WorkflowActionStatus> {

        const repo: MediaObjectRepository = this.injector.get(EntityManagerService).getRepository(MediaObject);

        let user = this.injector.get(AuthenticationService).getUser();

        return repo.findOne({
                mediaObjectClassName: 'UserMediaObject',
                mediaObjectOwnerId: user.getId(),
                mediaObjectContext: 'profilePicture'
            })
            .pipe(
                catchError((error) => {
                    return of(error);
                }),
                map((mediaObject: MediaObject|null) => {
                    if (mediaObject instanceof MediaObject) {
                        user = this.injector.get(AuthenticationService).getUser()
                            .removeMedia(new MediaObject().setContext('profilePicture'))
                            .addMedia(mediaObject);
                    }

                    this.injector.get(AuthenticationService).setUser(user);

                    return {
                        success: true,
                        stop: false,
                        status: ''
                    };
                })
            );
    }
}
