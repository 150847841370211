<p-table
    #dataTable
    appTableClickHandler
    appTableOverrideBindDocumentEditListener
    [component]="this"
    id="generic-table"
    tableStyleClass="simple-table"
    [columns]="tableColumns"
    [frozenColumns]="tableFrozenColumns"
    [value]="tableEntities"
    [customSort]="true"
    [lazy]="true"
    [paginator]="true" [rows]="rows" [first]="first" [sortField]="sortField" [sortOrder]="sortOrder" [totalRecords]="totalCount"
    (onLazyLoad)="onLazyLoadEvent($event)"
    [reorderableColumns]="reordable"
    (onRowSelect)="onSelect($event)"
    (onRowUnselect)="onUnselect($event)"
    (onRowReorder)="onReorder($event)"
    [loading]="isLoadingData"
    selectionMode="multiple"
    [(selection)]="selection.selection"
    [metaKeySelection]="true"
    [resizableColumns]="false"
    columnResizeMode="expand"
    [scrollable]="scrollable"
    scrollHeight="{{tableHeight + 'px'}}"
    [style]="{width: tableWidth + 'px' }"
    frozenWidth="{{tableFrozenColumnsWidth + 'px'}}"
>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length + 1">
        {{ 'COMMON.NO_RECORDS_FOUND'  | translate }}
      </td>
    </tr>
  </ng-template>

    <ng-template *ngIf="tableHeader && headerVisible" pTemplate="caption">

        <div class="ui-g">
            <div class="ui-g-12 ui-md-12 ui-lg-12" style="text-align:right">
                <span *ngFor="let button of tableHeader.buttons" class="toolbar-item">
                    <button
                      pButton
                      *ngIf="button.visible === undefined || button.visible()"
                      (click)="onTableButtonClick($event, button)"
                      [disabled]="button.disabled !== undefined && button.disabled()"
                      type="button"
                      icon="{{button.icon}}"
                      pTooltip="{{button.tooltip | translate}}"
                      class="{{button.class}}"
                      [attr.data-selenium-id]="button.seleniumId||''"
                    ></button>
                </span>

                <span *ngFor="let dropdown of tableHeader.dropdowns" class="toolbar-item">
                  <p-dropdown
                    [options]="dropdown.options"
                    placeholder="---"
                    [showClear]="true"
                    (onChange)="onToolbarDropdownChange($event, dropdown)"
                    class="{{dropdown.class}}"
                    [style]="{'width': '200px'}"
                    [autoWidth]="false"
                  ></p-dropdown>
                </span>
            </div>
        </div>

    </ng-template>

    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let column of columns" [ngStyle]="{width: column.style.width}">
        </colgroup>
    </ng-template>
    <ng-template *ngIf="headerVisible" pTemplate="header" let-columns>
        <tr>
            <th pResizableColumn *ngFor="let column of columns" [pSortableColumn]="column.sort ? ( column.sort.key ? column.sort.key : column.key ) : undefined " pTooltip="{{column.header | translate}}" [attr.data-selenium-id]="'table-column-' + column.key">
                <p-sortIcon *ngIf="column.sort" [field]="column.sort && column.sort.key ? column.sort.key : column.key"></p-sortIcon>
                <span [innerHtml]="column.header | translate"></span>
            </th>
        </tr>
        <tr>
            <th *ngFor="let column of columns" class="ui-table-filter-header">

                <p-checkbox
                        *ngIf="column.selection"
                        (onChange)="onHeaderCheckboxChange($event)"
                        binary="true"
                        [ngModel]="isHeaderCheckboxChecked()"
                ></p-checkbox>

                <ng-template [ngIf]="column.filter">
                    <span [ngSwitch]="true" class="ui-filter ui-g ui-g-12" >

                        <p-dropdown
                            *ngIf="column.filter.operator"
                            [ngModel]="getFilterOperatorValue(column)"
                            [options]="column.filter.operator.options | async"
                            placeholder="---"
                            (onChange)="onFilterOperatorEvent($event, column)"
                            class="ui-g ui-g-4"
                            [style]="{'padding-right': '6px'}"
                            [disabled]="getFilterValue(column) === null"
                            appendTo="body"
                        ></p-dropdown>

                        <p-checkbox
                            *ngSwitchCase="column.filter.type === 'checkbox'"
                            [ngModel]="getFilterValue(column)"
                            (onChange)="onFilterEvent($event, column)"
                            binary="true"
                        ></p-checkbox>

                        <span *ngSwitchCase="column.filter.type === 'calendar'" [style.marginTop.px]="2" class="ui-g ui-g-8 ui-filter-calendar-container">
                          <p-calendar
                              appCalendar
                              name="{{column.key}}"
                              [ngModel]="getFilterValue(column)"
                              [showIcon]="true"
                              (valueChange)="onFilterEvent($event, column)"
                              (onSelect)="onFilterEvent($event, column)"
                              [dateFormat]="column.filter.dateFormat"
                              [showTime]="column.filter.showTime"
                              inputStyleClass="ui-filter-calendar-input"
                              class="ui-filter-calendar"
                              showButtonBar="true"
                              [panelStyle]="{'width':'100px'}"
                              (onTodayClick)="onFilterEvent($event, column, 'today')"
                              (onClearClick)="onFilterEvent($event, column, 'clear')"
                              appendTo="body"
                          ></p-calendar>
                        </span>

                        <app-calendar-range-filter
                                *ngSwitchCase="column.filter.type === 'calendar-range' || column.filter.type === 'calendar-multi-date-range'"
                                (filterChange)="onFilterEvent($event, column)"
                                [filterService]="filter"
                                [column]="column"
                                [table]="this"
                        ></app-calendar-range-filter>

                        <p-dropdown
                            *ngSwitchCase="column.filter.type === 'dropdown'"
                            [options]="column.filter.options | async"
                            (onChange)="onFilterEvent($event.value, column)"
                            [ngModel]="getFilterValue(column)"
                            appendTo="body"
                            [style]="{'width': '100%'}"
                            [panelStyle]="{'width':'150px'}"
                            class="ui-filter-dropdown"
                        ></p-dropdown>

                        <p-autoComplete
                            appAutoComplete
                            class="form-control"
                            *ngSwitchCase="column.filter.type === 'autocomplete'"
                            size="30"
                            appendTo="body"
                            [style]="{'width': '100%'}"
                            [field]="column.filter.fieldName"
                            [handler]="column.filter.autocompleteHandler"
                            [queryField]="column.filter.queryField"
                            [dropdown]="true"
                            [forceSelection]="true"
                            (onSelect)="onFilterEvent($event, column)"
                            [panelStyle]="{'width':'300px'}"
                            [showClear]="true"
                        ></p-autoComplete>

                        <span *ngSwitchCase="column.filter.type === 'number'" class="ui-g ui-g-8">
                            <p-spinner
                                appSpinner
                                size="30"
                                [step]="column.filter.step || 1"
                                [ngModel]="getFilterValue(column)"
                                (valueChange)="onFilterEvent($event, column)"
                            ></p-spinner>
                        </span>

                        <span *ngSwitchCase="column.filter.type === 'none'" class="ui-g ui-g-8">
                        </span>


                        <ng-template [ngSwitchDefault]>
                            <input
                                pInputText
                                [ngModel]="getFilterValue(column)"
                                type="text"
                                (input)="onFilterEvent($event, column)"
                            >
                        </ng-template>
                    </span>
                </ng-template>

            </th>
        </tr>
        <tr *ngIf="isSummaryActive()">
          <th pResizableColumn></th>
          <th pResizableColumn *ngFor="let column of columns">
            <b
              pTooltip="{{tableSummary[column.key] ? tableSummary[column.key].tooltip : ''}}"
            >
              {{ tableSummary[column.key] ? tableSummary[column.key].value : '' }}
            </b>
          </th>
        </tr>
        <ng-container *ngIf="isStickyRowsActive()">
          <tr *ngFor="let row of tableStickyRows; let rowNumber = index">
            <th pResizableColumn></th>
            <th pResizableColumn *ngFor="let column of columns" style="font-weight:bold"
                [innerHtml]="getStickyColumnValue(rowNumber, column.key)" ></th>
          </tr>
        </ng-container>

    </ng-template>
    <ng-template pTemplate="body" let-entity let-columns="columns" let-rowIndex="rowIndex">
        <tr
            *ngIf="tableRowGroup && tableRowGroup[getEntityRowGroupKey(entity)] && tableRowGroup[getEntityRowGroupKey(entity)].index === rowIndex"
            [ngClass]="'ui-edit-disabled'"
            class="ui-widget-header"
        >
            <td
               [ngClass]="'ui-edit-disabled'"
                colspan="3"
            >
                <span style="font-weight:bold">{{getEntityRowGroupKey(entity)}}</span>
            </td>
        </tr>
        <tr
            [ngClass]="isRowCheckboxChecked(entity) ? 'ui-state-selected' : null"
            [attr.data-entity-id]="entity.id"
            [attr.data-entity-unique-id]="entity.uniqueId"
            [attr.data-row-index]="rowIndex"
            [attr.data-selenium-id]="rowIndex"
            [pSelectableRow]="entity"
            [pSelectableRowIndex]="rowIndex"
            [pReorderableRow]="rowIndex"
            [pSelectableRowDblClick]="entity"
            (dblclick)="onRowDoubleClick($event, entity)"
            (click)="onRowClick($event, entity)"
        >
            <td
                *ngFor="let column of columns;"
                [appTableEditableColumn]="entity"
                [table]="this"
                [column]="column"
                [data]="entity"
                [style.overflow]="'hidden'"
                [style.textOverflow]="'ellipsis'"
                [style.whiteSpace]="'nowrap'"
                [attr.data-selenium-id]="'column-value-' + column.key + '-' + rowIndex"
                [ngClass]="isColumnEditDisabled(column) ? 'ui-edit-disabled' : null"
                [ngStyle]="getCellStyle(column, entity)"
                (click)="onCellClickEvent($event, column, entity)"
                [escape]="false"
                [exitInlineEditOnEnter]="column?.exitInlineEditOnEnter"
                pTooltip="{{column.renderTooltip && column.renderTooltip(entity) ? column.renderTooltip(entity) : '' }}"
            >
                <ng-template [ngIf]="column.reordable">
                    <i class="fa fa-bars" pReorderableRowHandle></i>
                </ng-template>

                <ng-template [ngIf]="column.selection">
                    <i
                        class="fa {{ isRowCheckboxChecked(entity) ? 'ui-icon-check-box' : 'ui-icon-check-box-outline-blank' }}"
                        aria-hidden="true"
                        [attr.data-selenium-id]="'column-checkbox-' + rowIndex"
                    ></i>
                </ng-template>

                <ng-template [ngIf]="column.rowNumber">
                    <span>{{ rowIndex + 1 }}.</span>
                </ng-template>

                <ng-template [ngIf]="!column.edit">
                        <ng-template [ngIf]="isColumnRendererDefined(column)">

                          <ng-template [ngIf]="column.renderType === 'link'">
                            <a [innerHTML]="column.renderer(entity)"></a>
                          </ng-template>

                          <ng-template [ngIf]="column.renderType === 'number'">
                            <span [innerHTML]="column.renderer(entity)" pTooltip="{{column.tooltip}}"></span>
                          </ng-template>

                          <ng-template [ngIf]="column.renderType !== 'number' && column.renderType !== 'link'">
                            <span [innerHTML]="column.renderer(entity)"></span>
                          </ng-template>

                        </ng-template>
                        <ng-template [ngIf]="!isColumnRendererDefined(column)">
                            <span [ngSwitch]="column.renderType" >

                                <p-checkbox
                                    *ngSwitchCase="'checkbox'"
                                    [(ngModel)]="entity[column.key]"
                                    binary="true"
                                ></p-checkbox>

                                <ng-template [ngSwitchCase]="'date'" >
                                    {{ entity[column.key] | appDate:column.dateFormat }}
                                </ng-template>

                              <ng-template [ngSwitchCase]="'number'" >
                                    {{ entity[column.key] }}
                                </ng-template>

                                <ng-template [ngSwitchDefault]>
                                    {{entity[column.key] }}
                                </ng-template>
                            </span>
                        </ng-template>
                </ng-template>
                <span *ngIf="column.edit">
                    <p-cellEditor>
                        <ng-template pTemplate="input">

                                <span [ngSwitch]="column.edit.type">

                                    <p-dropdown
                                        *ngSwitchCase="'dropdown'"
                                        placeholder="---"
                                        [(ngModel)]="entity[column.key]"
                                        [options]="column.edit.options | async"
                                        (onChange)="onCellEditEvent($event, column, entity)"
                                        appendTo="body"
                                        [style]="{'width': '100%','min-width':'100%'}"
                                        [panelStyle]="{'width':'100px'}"
                                    ></p-dropdown>

                                    <p-calendar
                                        appTableEditCalendar
                                        [column]="column"
                                        [entity]="entity"
                                        [dateFormat]="column.edit.dateFormat"
                                        [timeOnly]="column.edit.timeOnly || false"
                                        *ngSwitchCase="'calendar'"
                                        [ngModel]="entity[column.key] | stringToDate"
                                        [showIcon]="true"
                                        (valueChange)="onCellEditEvent($event, column, entity)"
                                        appendTo="body"
                                        inputStyleClass="ui-filter-calendar-input"
                                        class="ui-filter-calendar"
                                    ></p-calendar>

                                    <p-spinner
                                        appSpinner
                                        *ngSwitchCase="'number'"
                                        size="30"
                                        [step]="column.edit.step"
                                        [(ngModel)]="+entity[column.key]"
                                        (valueChange)="onCellEditEvent($event, column, entity)"
                                        [max]="column.edit.max"
                                        [min]="column.edit.min"
                                    ></p-spinner>

                                    <p-checkbox
                                        *ngSwitchCase="'checkbox'"
                                        [(ngModel)]="entity[column.key]"
                                        binary="true"
                                        (onChange)="onCellEditEvent($event, column, entity)"
                                    ></p-checkbox>

                                    <p-autoComplete
                                      appAutoComplete
                                      class="form-control"
                                      *ngSwitchCase="'autocomplete'"
                                      size="30"
                                      appendTo="body"
                                      [style]="{'width': '100%'}"
                                      [panelStyle]="{'width':'300px'}"
                                      [(ngModel)]="entity[column.key]"
                                      [field]="column.edit.fieldName"
                                      [handler]="column.edit.autocompleteHandler"
                                      [params]="column.edit.autocompleteHandlerParams"
                                      [dropdown]="true"
                                      [forceSelection]="true"
                                      (onSelect)="onCellEditEvent($event, column, entity)"
                                    ></p-autoComplete>

                                    <p-colorPicker
                                        appTableColorPicker
                                        *ngSwitchCase="'color'"
                                        [(ngModel)]="entity[column.key]"
                                        [inline]="true"
                                        (onChange)="onCellEditEvent($event, column, entity)"
                                    ></p-colorPicker>

                                    <div
                                        *ngSwitchCase="'invoicingItem'"
                                    >
                                        <p-autoComplete
                                            appAutoComplete
                                            class="form-control"
                                            size="30"
                                            appendTo="body"
                                            [style]="{'width': '100%'}"
                                            [panelStyle]="{'width':'300px'}"
                                            [(ngModel)]="entity[column.key]"
                                            [field]="column.edit.fieldName"
                                            [handler]="column.edit.autocompleteHandler"
                                            [dropdown]="true"
                                            [forceSelection]="true"
                                            (onSelect)="onCellEditEvent($event, column, entity)"
                                        ></p-autoComplete>

                                        <span class="md-inputfield">
                                            <textarea
                                                rows="3"
                                                cols="30"
                                                pInputTextarea
                                                id="name"
                                                [(ngModel)]="entity[column.key]"
                                                class="ui-g-12 ui-inputtext ui-corner-all ui-state-default ui-widget"
                                                (keyup)="onCellEditEvent($event, column, entity)"
                                            ></textarea>
                                        </span>
                                    </div>

                                    <ng-template [ngSwitchDefault]>
                                        <input
                                          pInputText
                                          class="ui-edit-input-text"
                                          type="text"
                                          [style]="{'width': '100%'}"
                                          (keyup)="onCellEditEvent($event, column, entity)"
                                          [(ngModel)]="entity[column.key]"
                                        >
                                    </ng-template>

                                </span>
                        </ng-template>
                        <ng-template pTemplate="output">
                                <ng-template [ngIf]="isColumnRendererDefined(column)">

                                  <ng-template [ngIf]="column.renderType === 'number'">
                                    <span [innerHTML]="column.renderer(entity)"></span>
                                  </ng-template>

                                  <ng-template [ngIf]="column.renderType !== 'number'">
                                    <span [innerHTML]="column.renderer(entity)"></span>
                                  </ng-template>

                                </ng-template>

                                <ng-template [ngIf]="!isColumnRendererDefined(column)">
                                    <span [ngSwitch]="column.renderType"  >

                                        <p-checkbox
                                            *ngSwitchCase="'checkbox'"
                                            [(ngModel)]="entity[column.key]"
                                            binary="true"
                                            (onChange)="onCellEditEvent($event, column, entity)"
                                        ></p-checkbox>

                                        <ng-template [ngSwitchCase]="'date'">
                                            {{ entity[column.key] | appDate:column.dateFormat }}
                                        </ng-template>

                                        <ng-template [ngSwitchCase]="'color'">
                                            {{ entity[column.key] }}

                                            <p-colorPicker
                                                appTableColorPicker
                                                [(ngModel)]="entity[column.key]"
                                            ></p-colorPicker>
                                        </ng-template>

                                        <ng-template [ngSwitchCase]="'number'">
                                            {{ entity[column.key] }}
                                        </ng-template>

                                        <ng-template [ngSwitchDefault]>
                                            {{ entity[column.key] }}
                                        </ng-template>
                                    </span>
                                </ng-template>
                        </ng-template>
                    </p-cellEditor>
                </span>
                <div *ngIf="column.menu" [style.whiteSpace]="'normal'">
                    <span *ngFor="let icon of column.menu.icons;" style="padding-left: 5px;">
                        <i
                            *ngIf="icon.condition === undefined || icon.condition(entity)"
                            (click)="onButtonClick($event, entity, icon)"
                            pTooltip="{{icon.tooltip | translate}}"
                            class="{{icon.class}}"
                            [attr.data-selenium-id]="icon.seleniumId + '-' + rowIndex ||''"
                        ></i>
                    </span>
                  <span *ngFor="let button of column.menu.buttons;" style="padding-left: 5px;">
                    <button
                      *ngIf="!button.options && (button.condition === undefined || button.condition(entity))"
                      (click)="onButtonClick($event, entity, button)"
                      pButton
                      type="button"
                      label="{{button.text}}"
                      icon="{{button.icon}}"
                      pTooltip="{{button.tooltip | translate}}"
                      class="{{button.class}}"
                      [attr.data-selenium-id]="button.seleniumId + '-' + rowIndex ||''"
                    ></button>

                    <p-splitButton
                      *ngIf="button.options"
                      appSplitButtonAsDropdown
                      appTableSplitButton
                      [button]="button"
                      appendTo="body"
                      (itemClicked)="onSplitButtonItemClick($event, entity)"
                      (onClick)="onSplitButtonClick($event, entity, button)"
                      (onDropdownClick)="onSplitButtonClick($event, entity, button)"
                      [menuStyle]="{'width':'200px'}"
                      [style]="{'width':'100%'}"
                      icon="{{button.icon}}"
                      label="{{button.text}}"
                      [model]="button.options | async"
                      styleClass="ui-button-info"
                    ></p-splitButton>
                  </span>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template *ngIf="tableFooter" pTemplate="summary">
        <div class="row">
            <div class="col-lg-12">
                <span *ngFor="let button of tableFooter.buttons">
                    <button
                        pButton
                        *ngIf="button.condition === undefined || button.condition()"
                        (click)="onTableButtonClick($event, button)"
                        [disabled]="button.disabled !== undefined && button.disabled()"
                        type="button"
                        icon="{{button.icon}}"
                        pTooltip="{{button.tooltip | translate}}"
                        class="{{button.class}}"
                    ></button>
                </span>
            </div>
        </div>
    </ng-template>
</p-table>

<!-- use new primeng dynamic dialogs -->
<p-dialog
  header="{{ 'COMMON.EDIT_COLUMNS' | translate }}"
  *ngIf="columnVisibilityDialog"
  [(visible)]="columnVisibilityDialog"
>
  <app-shared-table-column-reorder
    *ngIf="columnVisibilityDialog"
    [tableComponent]="this"
    (columnsSaved)="onColumnsSaved()"
    (columnsSaveCanceled)="onColumnsSaveCanceled()"
    (columnsReset)="onColumnsReset()"
  ></app-shared-table-column-reorder>
</p-dialog>
