export class Entity {

    public static getValue(entity: any, propertyName: string): any|null {
        let value = null;

        if (typeof entity === 'undefined' || typeof propertyName === 'undefined' || entity === null) {
            return null;
        }

        if (propertyName.indexOf('.') === -1) {
            value = entity[propertyName];

            if (typeof value === 'undefined') {
                value = null;
            }

            return value;
        }

        const splitParts = propertyName.split('.');

        value = entity;

        for (const part of splitParts) {
            const propertyPart = value[part];

            if (propertyPart) {
                value = propertyPart;
            }

            if (!propertyPart) {
                value = null;
                break;
            }
        }

        if (typeof value === 'undefined') {
            value = null;
        }

        return value;
    }

    public static hasMethod(entity, implementingMethod: string): boolean {
        return (entity as any)[implementingMethod] !== undefined;
    }
}
