import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Vehicle, Employee} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('vehicle_rides')
@CrossDependency(() => {
    return {
        vehicle: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vehicle
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class VehicleRide {

    @AssociationOne(Vehicle)
    protected vehicle: Vehicle;

    @AssociationOne(Employee)
    protected employee: Employee;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected vehicleRideDate: Date;

    protected id: string;
    protected vehicleRidePlace: string;
    protected vehicleRideMileage: string;
    protected vehicleRideStartMileage: string;
    protected vehicleRideEndMileage: string;
    protected totalMileage: string;
    protected totalCurrentYearMileage: string;

    public setVehicle(vehicle: Vehicle): this {
        this.vehicle = vehicle;
        return this;
    }

    public getVehicle(): Vehicle|undefined {
        return this.vehicle;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public setVehicleRideDate(vehicleRideDate: Date) {
        this.vehicleRideDate = vehicleRideDate;
        return this;
    }

    public getVehicleRideDate() {
        return this.vehicleRideDate;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setVehicleRidePlace(vehicleRidePlace: string): this {
        this.vehicleRidePlace = vehicleRidePlace;
        return this;
    }

    public getVehicleRidePlace(): string {
        return this.vehicleRidePlace;
    }

    public setVehicleRideMileage(vehicleRideMileage: string): this {
        this.vehicleRideMileage = vehicleRideMileage;
        return this;
    }

    public getVehicleRideMileage(): string {
        return this.vehicleRideMileage;
    }

    public setVehicleRideStartMileage(vehicleRideStartMileage: string): this {
        this.vehicleRideStartMileage = vehicleRideStartMileage;
        return this;
    }

    public getVehicleRideStartMileage(): string {
        return this.vehicleRideStartMileage;
    }

    public setVehicleRideEndMileage(vehicleRideEndMileage: string): this {
        this.vehicleRideEndMileage = vehicleRideEndMileage;
        return this;
    }

    public getVehicleRideEndMileage(): string {
        return this.vehicleRideEndMileage;
    }

    public setTotalMileage(totalMileage: string): this {
        this.totalMileage = totalMileage;
        return this;
    }

    public getTotalMileage(): string {
        return this.totalMileage;
    }

    public setTotalCurrentYearMileage(totalCurrentYearMileage: string): this {
        this.totalCurrentYearMileage = totalCurrentYearMileage;
        return this;
    }

    public getTotalCurrentYearMileage(): string {
        return this.totalCurrentYearMileage;
    }
}
