import {Directive, Input, OnInit} from '@angular/core';
import {CalendarDirective} from '../../directives/calendar.directive';
import {TableColumn} from '../shared/table-column';
import {CalendarLocaleService} from '../../services/calendar-locale.service';

@Directive({
    selector: '[appTableEditCalendar]',
    providers: [CalendarLocaleService]
})
export class TableEditCalendarHandlerDirective extends CalendarDirective implements OnInit {

    @Input() public entity = null;
    @Input() public column: TableColumn = null;

    public ngOnInit(): void {
        super.ngOnInit();

        this.calendar.onSelect.subscribe((date: Date|null) => {
            this.emitValueChange(date);
        });
    }

    protected emitValueChange(value: Date|null): void {
        super.emitValueChange(value);

        this.changeEntityValue(value);
    }

    protected changeEntityValue(value: Date|null): void {
        if (this.entity && this.column) {
            this.entity[this.column.key] = value;
        }
    }
}
