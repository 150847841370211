import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';
import {Route} from '../../shared/enums/route';
import {throwError} from 'rxjs';
import {UserSwitchService} from "../user-switch/user-switch.service";
import {AppComponent} from "../../app.component";
import {LocalStorageService} from "../services/local-storage.service";

@Injectable()
export class UserSwitchInterceptor implements HttpInterceptor {

    constructor(
        private userSwitchService: UserSwitchService,
        private router: Router,
        private authenticationService: AuthenticationService,
        private localStorage: LocalStorageService
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.userSwitchService.isImpersonateUser()) {
            let params = req.params;
            params = params.set('_su', this.userSwitchService.getImpersonateUserName());
            req = req.clone({
                params: params
            });

            return next.handle(req).pipe(
                catchError((response: any, caught: Observable<HttpEvent<any>>) => {
                    if (response.status === 403) {
                        this.authenticationService.setUser(this.localStorage.getItem(UserSwitchService.LOCAL_STORAGE_ORIGINAL_USER_KEY));
                        this.localStorage.removeItem(UserSwitchService.LOCAL_STORAGE_ORIGINAL_USER_KEY);
                        this.localStorage.removeItem(UserSwitchService.LOCAL_STORAGE_IMPERSONATE_USER_KEY);
                    }

                    return throwError(response);
                }),
                tap(event => {
                }));
        }

        return next.handle(req);
    }
}
