import { AbstractActionHandler } from './abstract-action-handler';
import { DashboardRendererService } from '../renderer/dashboard-renderer.service';
import {LayoutChangedAware} from '../interfaces/layout-changed-aware';

export class LayoutChangedActionHandler extends AbstractActionHandler {

    public handleAction() {
        const newLayout = this.getParameterValue('layout'),
            dashboard = this.dashboardComponent.getDashboardService(),
            dashboardRendererService = this.dashboardComponent.getDashboardRendererService();

        const oldLayout = dashboard.getSetting().getLayout();

        if (dashboardRendererService instanceof DashboardRendererService) {
            for (const componentRef of dashboardRendererService.getBoxComponents()) {
              if (this.isLayoutChangedAware(componentRef.instance)) {
                componentRef.instance.onLayoutChanged(oldLayout, newLayout);
              }
            }
        }
    }

    private isLayoutChangedAware(component: any): component is LayoutChangedAware {
      return (component as any).onLayoutChanged !== undefined;
    }
}
