<div class="ui-g">
    <div class="ui-g-{{ showClearButton ? '5' : '6' }} hours">
        <p-spinner
            appSpinner
            id="hours"
            [(ngModel)]="hours"
            [min]="0"
            [max]="maxHours"
            [step]="step"
            [changeOnFocus]="changeOnFocus"
            [changeOnBlur]="changeOnBlur"
            [formatInput]="true"
            (valueChange)="onValueChange($event)"
            decimalSeparator="."
            formatDecimalPlaces="0"
        ></p-spinner>
    </div>
    <div class="ui-g-{{ showClearButton ? '5' : '6' }}">
        <p-spinner
            appSpinner
            id="minutes"
            [min]="0"
            [max]="maxMinutes"
            [step]="step"
            [changeOnFocus]="changeOnFocus"
            [changeOnBlur]="changeOnBlur"
            [(ngModel)]="minutes"
            [formatInput]="true"
            (valueChange)="onValueChange($event)"
            decimalSeparator="."
            formatDecimalPlaces="0"
        ></p-spinner>
    </div>

    <p-button (onClick)="clearInput()" icon="fa fa-close" class="ui-g-2" *ngIf="showClearButton"></p-button>

    <div class="ui-g-12" *ngIf="!hideDuration">
        {{ duration | decimalToWorkTime }}
    </div>
</div>
