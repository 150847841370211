import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Message} from '../../../../core/entities/message';
import {Paginated} from '../../../../shared/table/shared/paginated';
import {EntityManagerService} from '../../../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {FlipPanelComponent} from '../../../../shared/flip-panel/flip-panel.component';
import {MessageNotificatorService} from '../../../../core/services/message/message-notificator.service';

@Component({
    selector: 'app-home-messages-counter',
    templateUrl: 'messages-counter.component.html',
    styleUrls: ['messages-counter.component.scss']
})
export class MessagesCounterComponent implements OnInit {
    cols: any[];

    messages: any[];
    totalMessages = 0;
    unreadMessages = 0;
    @ViewChild(FlipPanelComponent) public flipPanel: FlipPanelComponent;
    @ViewChild('messageBox', {read: ElementRef, static: false}) messageBox: ElementRef;

    constructor(
        protected entityManager: EntityManagerService,
        public authentication: AuthenticationService,
        protected cdr: ChangeDetectorRef,
        protected messageNotificator: MessageNotificatorService
    ) {
    }

    ngOnInit() {
        this.entityManager.getRepository(Message)
            .findMore({toUser: this.authentication.getUser().getId()})
            .subscribe((pagination: Paginated) => {
                const messages: Message[] = pagination.data;
                this.totalMessages = pagination.total;
                this.messages = [];
                for (const message of messages) {
                    if (!message.getIsRead()) {
                        this.unreadMessages++;
                    }
                }
                this.cdr.detectChanges();
            });

        this.messageNotificator.messageRead.subscribe((message: Message) => {
            this.decreaseUnreadCount();
            this.cdr.detectChanges();
        });
    }

    private decreaseUnreadCount() {
        if (this.unreadMessages > 0) {
            this.unreadMessages--;
        }
    }

}
