import {Employee, MonthlyWage} from './entities';
import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';
import {EmployeeMonthlySalaryCalculationRepository} from '../repositories/employee-monthly-salary-calculation.repository';
import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {MediaObject} from './media-object';
import {DecimalPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';

@Route('employee_monthly_salary_calculations')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        monthlyWage: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: MonthlyWage
        },
        medias: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: MediaObject
        }
    };
})
@EntityDefaultFilters({
    'exists[employee.employment.dismissalType]': false
})
@Repository(EmployeeMonthlySalaryCalculationRepository)
export class EmployeeMonthlySalaryCalculation {
    protected id: string;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(MonthlyWage)
    protected monthlyWage: MonthlyWage;

    protected paymentType: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected total: string;

    protected entryYear: string;

    protected entryMonth: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected baseSalary;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    private baseWorkPositionSalary;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    private baseWorkPositionSalaryAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    private baseWorkPositionSalarySickLeaveAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    private baseWorkPositionSalaryHolidayAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected baseSalaryGross;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected mealAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected holidayAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected regressAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected overtimeAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected previousWorkAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected taxFreeAmount;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected taxableAmount;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected baseTax;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected socialSecurityTax;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected healthInsuranceTax;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected unemploymentTax;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected employerSocialSecurityTax;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected employerHealthInsuranceTax;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalNetSalary;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalEmployerPayout;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected baseSalaryDeduction;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalEmployerSalaryDeduction;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalSalaryDeduction;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected bonus: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected nightShift: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected stimulus: string;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationMany(MediaObject)
    protected medias: MediaObject[];
    protected isChangeValueMealAddition: boolean;
    protected isChangeValueRegressAddition: boolean;
    protected isChangeValueTaxFreeAmount: boolean;
    protected isEmailSend: boolean;

    public getId() {
        return this.id;
    }

    public setId(id: string): EmployeeMonthlySalaryCalculation {
        this.id = id;
        return this;
    }

    public getEmployee(): Employee {
        return this.employee;
    }

    public setEmployee(employee: Employee): EmployeeMonthlySalaryCalculation {
        this.employee = employee;
        return this;
    }

    public getMonthlyWage(): MonthlyWage {
        return this.monthlyWage;
    }

    public setMonthlyWage(monthlyWage: MonthlyWage): EmployeeMonthlySalaryCalculation {
        this.monthlyWage = monthlyWage;
        return this;
    }

    public getPaymentType(): string {
        return this.paymentType;
    }

    public setPaymentType(paymentType: string): EmployeeMonthlySalaryCalculation {
        this.paymentType = paymentType;
        return this;
    }

    public getTotal(): string {
        return this.total;
    }

    public setTotal(total: string): EmployeeMonthlySalaryCalculation {
        this.total = total;
        return this;
    }

    public getEntryYear(): string {
        return this.entryYear;
    }

    public setEntryYear(entryYear: string): EmployeeMonthlySalaryCalculation {
        this.entryYear = entryYear;
        return this;
    }

    public getEntryMonth(): string {
        return this.entryMonth;
    }

    public setEntryMonth(entryMonth: string): EmployeeMonthlySalaryCalculation {
        this.entryMonth = entryMonth;
        return this;
    }

    public getBaseSalary(): string {
        return this.baseSalary;
    }

    public setBaseSalary(baseSalary: string): EmployeeMonthlySalaryCalculation {
        this.baseSalary = baseSalary;
        return this;
    }

    public getBaseSalaryGross(): string {
        return this.baseSalaryGross;
    }

    public setBaseSalaryGross(baseSalaryGross: string): EmployeeMonthlySalaryCalculation {
        this.baseSalaryGross = baseSalaryGross;
        return this;
    }

    public getMealAddition(): string {
        return this.mealAddition;
    }

    public setMealAddition(mealAddition: string): EmployeeMonthlySalaryCalculation {
        this.mealAddition = mealAddition;
        return this;
    }

    public getHolidayAddition(): string {
        return this.holidayAddition;
    }

    public setHolidayAddition(holidayAddition: string): EmployeeMonthlySalaryCalculation {
        this.holidayAddition = holidayAddition;
        return this;
    }

    public getRegressAddition(): string {
        return this.regressAddition;
    }

    public setRegressAddition(regressAddition: string): EmployeeMonthlySalaryCalculation {
        this.regressAddition = regressAddition;
        return this;
    }

    public getOvertimeAddition(): string {
        return this.overtimeAddition;
    }

    public setOvertimeAddition(overtimeAddition: string): EmployeeMonthlySalaryCalculation {
        this.overtimeAddition = overtimeAddition;
        return this;
    }

    public getPreviousWorkAddition(): string {
        return this.previousWorkAddition;
    }

    public setPreviousWorkAddition(previousWorkAddition: string): EmployeeMonthlySalaryCalculation {
        this.previousWorkAddition = previousWorkAddition;
        return this;
    }

    public getTaxFreeAmount(): string {
        return this.taxFreeAmount;
    }

    public setTaxFreeAmount(taxFreeAmount: string): EmployeeMonthlySalaryCalculation {
        this.taxFreeAmount = taxFreeAmount;
        return this;
    }

    public getTaxableAmount(): string {
        return this.taxableAmount;
    }

    public setTaxableAmount(taxableAmount: string): EmployeeMonthlySalaryCalculation {
        this.taxableAmount = taxableAmount;
        return this;
    }

    public getBaseTax(): string {
        return this.baseTax;
    }

    public setBaseTax(baseTax: string): EmployeeMonthlySalaryCalculation {
        this.baseTax = baseTax;
        return this;
    }

    public getSocialSecurityTax(): string {
        return this.socialSecurityTax;
    }

    public setSocialSecurityTax(socialSecurityTax: string): EmployeeMonthlySalaryCalculation {
        this.socialSecurityTax = socialSecurityTax;
        return this;
    }

    public getHealthInsuranceTax(): string {
        return this.healthInsuranceTax;
    }

    public setHealthInsuranceTax(healthInsuranceTax: string): EmployeeMonthlySalaryCalculation {
        this.healthInsuranceTax = healthInsuranceTax;
        return this;
    }

    public getUnemploymentTax(): string {
        return this.unemploymentTax;
    }

    public setUnemploymentTax(unemploymentTax: string): EmployeeMonthlySalaryCalculation {
        this.unemploymentTax = unemploymentTax;
        return this;
    }

    public getEmployerSocialSecurityTax(): string {
        return this.employerSocialSecurityTax;
    }

    public setEmployerSocialSecurityTax(employerSocialSecurityTax: string): EmployeeMonthlySalaryCalculation {
        this.employerSocialSecurityTax = employerSocialSecurityTax;
        return this;
    }

    public getEmployerHealthInsuranceTax(): string {
        return this.employerHealthInsuranceTax;
    }

    public setEmployerHealthInsuranceTax(employerHealthInsuranceTax: string): EmployeeMonthlySalaryCalculation {
        this.employerHealthInsuranceTax = employerHealthInsuranceTax;
        return this;
    }

    public getTotalNetSalary(): string {
        return this.totalNetSalary;
    }

    public setTotalNetSalary(totalNetSalary: string): EmployeeMonthlySalaryCalculation {
        this.totalNetSalary = totalNetSalary;
        return this;
    }

    public getTotalEmployerPayout(): string {
        return this.totalEmployerPayout;
    }

    public setTotalEmployerPayout(totalEmployerPayout: string): EmployeeMonthlySalaryCalculation {
        this.totalEmployerPayout = totalEmployerPayout;
        return this;
    }

    public getBaseSalaryDeduction(): string {
        return this.baseSalaryDeduction;
    }

    public setBaseSalaryDeduction(baseSalaryDeduction: string): EmployeeMonthlySalaryCalculation {
        this.baseSalaryDeduction = baseSalaryDeduction;
        return this;
    }

    public getTotalEmployerSalaryDeduction(): string {
        return this.totalEmployerSalaryDeduction;
    }

    public setTotalEmployerSalaryDeduction(totalEmployerSalaryDeduction: string): EmployeeMonthlySalaryCalculation {
        this.totalEmployerSalaryDeduction = totalEmployerSalaryDeduction;
        return this;
    }

    public getTotalSalaryDeduction(): string {
        return this.totalSalaryDeduction;
    }

    public setTotalSalaryDeduction(totalSalaryDeduction: string): EmployeeMonthlySalaryCalculation {
        this.totalSalaryDeduction = totalSalaryDeduction;
        return this;
    }

    public setMedias(medias: any) {
        this.medias = medias;
        return this;
    }

    public getMedias(): any {
        return this.medias;
    }

    public setIsChangeValueMealAddition(isChangeValueMealAddition: boolean) {
        this.isChangeValueMealAddition = isChangeValueMealAddition;
        return this;
    }

    public getIsChangeValueMealAddition(): boolean|undefined {
        return this.isChangeValueMealAddition;
    }

    public setIsChangeValueRegressAddition(isChangeValueRegressAddition: boolean) {
        this.isChangeValueRegressAddition = isChangeValueRegressAddition;
        return this;
    }

    public getIsChangeValueRegressAddition(): boolean|undefined {
        return this.isChangeValueRegressAddition;
    }

    public setIsChangeValueTaxFreeAmount(isChangeValueTaxFreeAmount: boolean) {
        this.isChangeValueTaxFreeAmount = isChangeValueTaxFreeAmount;
        return this;
    }

    public getIsChangeValueTaxFreeAmount(): boolean|undefined {
        return this.isChangeValueTaxFreeAmount;
    }

    public getBaseWorkPositionSalaryAddition(): string|undefined {
        return this.baseWorkPositionSalaryAddition;
    }

    public setBaseWorkPositionSalaryAddition(value) {
        this.baseWorkPositionSalaryAddition = value;
        return this;
    }

    public getBaseWorkPositionSalarySickLeaveAddition(): string|undefined {
        return this.baseWorkPositionSalarySickLeaveAddition;
    }

    public setBaseWorkPositionSalarySickLeaveAddition(value) {
        this.baseWorkPositionSalarySickLeaveAddition = value;
        return this;
    }

    public getBaseWorkPositionSalaryHolidayAddition(): string|undefined {
        return this.baseWorkPositionSalaryHolidayAddition;
    }

    public setBaseWorkPositionSalaryHolidayAddition(value) {
        this.baseWorkPositionSalaryHolidayAddition = value;
        return this;
    }

    public setBonus(bonus: string): EmployeeMonthlySalaryCalculation {
        this.bonus = bonus;
        return this;
    }

    public getBonus(): string {
        return this.bonus;
    }

    public setNightShift(nightShift: string): EmployeeMonthlySalaryCalculation {
        this.nightShift = nightShift;
        return this;
    }

    public getNightShift(): string {
        return this.nightShift;
    }

    public setStimulus(stimulus: string): EmployeeMonthlySalaryCalculation {
        this.stimulus = stimulus;
        return this;
    }

    public getStimulus(): string {
        return this.stimulus;
    }

    public setIsEmailSend(isEmailSend: boolean) {
        this.isEmailSend = isEmailSend;
        return this;
    }

    public getIsEmailSend(): boolean|undefined {
        return this.isEmailSend;
    }
}
