import {AssociationMany, Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {
    Employee,
    WorkNoteMeasureProposal,
    WorkNoteState,
    WorkNoteType,
    WorkNoteInstalment,
    WorkNoteSubType,
    WorkNoteAction,
    WorkNoteDeduction,
    WorkNoteStatistic
} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {User} from './user';
import {EmployeeWorkNoteStatistic} from './employee-work-note-statistic';
import {WorkNoteSubTypeCategory} from './work-note-sub-type-category';

import {MediaObject} from './media-object';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';

import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {StringPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier';
import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {AssociationManyToMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many-to-many';
import {DecimalPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';

@Route('work_notes')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        workNoteType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteType
        },
        workNoteSate: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteState
        },
        measureProposal: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteMeasureProposal
        },
        assignee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        },
        instalments: {
            mapping: Meta.META_ASSOCIATIONS_MANY_TO_MANY,
            entity: WorkNoteInstalment
        },
        deductions: {
            mapping: Meta.META_ASSOCIATIONS_MANY_TO_MANY,
            entity: WorkNoteDeduction
        },
        workNoteSubType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteSubType
        },
        workNoteAction: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteAction
        },
        employeeWorkNoteStatisticByType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeWorkNoteStatistic
        },
        workNoteSubTypeCategory: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteSubTypeCategory
        },
        medias: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: MediaObject
        }
    };
})
@EntityDefaultFilters({
    'exists[employee.employment.dismissalType]': false
})
export class WorkNote {

    @AssociationOne(Employee)
    protected employee;

    @AssociationOne(WorkNoteType)
    protected workNoteType;

    @AssociationOne(WorkNoteState)
    private workNoteState;

    @AssociationOne(WorkNoteMeasureProposal)
    protected measureProposal;

    @AssociationOne(User)
    protected assignee;

    @AssociationManyToMany(WorkNoteInstalment)
    protected instalments: WorkNoteInstalment[];

    @AssociationManyToMany(WorkNoteDeduction)
    protected deductions: WorkNoteDeduction[];

    @AssociationOne(WorkNoteSubType)
    protected workNoteSubType;

    @AssociationOne(WorkNoteAction)
    protected workNoteAction;

    @AssociationOne(EmployeeWorkNoteStatistic)
    protected employeeWorkNoteStatisticByType;

    @AssociationOne(WorkNoteSubTypeCategory)
    protected workNoteSubTypeCategory: WorkNoteSubTypeCategory;

    protected id: string;
    protected description: string;
    protected rewardProposal: string;
    protected createdAt: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected date: Date;
    protected isCausedMaterialCosts: boolean;
    protected isExpressedMonetaryly: boolean;
    protected isEmployeeAgreeWithMeasure: boolean;
    protected isDisciplinaryAction: boolean;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected paymentDeductionAbsolute: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected costs: string;
    protected isApproved: boolean;
    protected hours: number;
    protected minutes: number;
    protected seconds: number;
    protected isManuallyActionSet: boolean;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationMany(MediaObject)
    protected medias: any;

    @AssociationOne(WorkNoteStatistic)
    protected workNoteStatistic;

    protected countBySameWorkNote: number;

    public constructor() {
        this.instalments = [];
        this.deductions = [];
    }

    public setEmployee(employee: Employee): WorkNote {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setWorkNoteType(workNoteType: WorkNoteType): WorkNote {
        this.workNoteType = workNoteType;
        return this;
    }

    public getWorkNoteType(): WorkNoteType | undefined {
        return this.workNoteType;
    }

    public setId(id: string): WorkNote {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setDescription(description: string): WorkNote {
        this.description = description;
        return this;
    }

    public getDescription(): string | undefined {
        return this.description;
    }

    public setRewardProposal(rewardProposal: string): WorkNote {
        this.rewardProposal = rewardProposal;
        return this;
    }

    public getRewardProposal(): string | undefined {
        return this.rewardProposal;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(createdAt: Date): WorkNote {
        this.createdAt = createdAt;
        return this;
    }

    public setDate(date: Date) {
        this.date = date;
        return this;
    }

    public getDate(): Date | undefined {
        return this.date;
    }

    public getWorkNoteState(): WorkNoteState | undefined {
        return this.workNoteState;
    }

    public setWorkNoteState(value: WorkNoteState) {
        this.workNoteState = value;
        return this;
    }

    public getMeasureProposal(): WorkNoteMeasureProposal | undefined {
        return this.measureProposal;
    }

    public setMeasureProposal(value: WorkNoteMeasureProposal) {
        this.measureProposal = value;
        return this;
    }

    public getInstalments(): WorkNoteInstalment[] | undefined {
        return this.instalments;
    }

    public setInstalments(value: WorkNoteInstalment[]) {
        this.instalments = value;
        return this;
    }

    public addInstalment(value: WorkNoteInstalment) {
        this.instalments.push(value);
        return this;
    }

    public getDeductions(): WorkNoteDeduction[] | undefined {
        return this.deductions;
    }

    public setDeductions(deductions: WorkNoteDeduction[]) {
        this.deductions = deductions;
        return this;
    }

    public addDeduction(deduction: WorkNoteDeduction) {
        this.deductions.push(deduction);
        return this;
    }

    public getIsCausedMaterialCosts(): boolean | undefined {
        return this.isCausedMaterialCosts;
    }

    public setIsCausedMaterialCosts(value: boolean) {
        this.isCausedMaterialCosts = value;
        return this;
    }

    public getIsExpressedMonetaryly(): boolean | undefined {
        return this.isExpressedMonetaryly;
    }

    public setIsExpressedMonetaryly(value: boolean) {
        this.isExpressedMonetaryly = value;
        return this;
    }

    public getIsEmployeeAgreeWithMeasure(): boolean | undefined {
        return this.isEmployeeAgreeWithMeasure;
    }

    public setIsEmployeeAgreeWithMeasure(value: boolean) {
        this.isEmployeeAgreeWithMeasure = value;
        return this;
    }

    public getIsDisciplinaryAction(): boolean | undefined {
        return this.isDisciplinaryAction;
    }

    public setIsDisciplinaryAction(value: boolean) {
        this.isDisciplinaryAction = value;
        return this;
    }

    public getCosts(): string | undefined {
        return this.costs;
    }

    public setCosts(value: string) {
        this.costs = value;
        return this;
    }

    public getPaymentDeductionAbsolute(): string | undefined {
        return this.paymentDeductionAbsolute;
    }

    public setPaymentDeductionAbsolute(paymentDeductionAbsolute: string) {
        this.paymentDeductionAbsolute = paymentDeductionAbsolute;
        return this;
    }

    public setWorkNoteSubType(workNoteSubType: WorkNoteSubType): WorkNote {
        this.workNoteSubType = workNoteSubType;
        return this;
    }

    public getWorkNoteSubType(): WorkNoteSubType | undefined {
        return this.workNoteSubType;
    }

    public getAssignee(): User | undefined {
        return this.assignee;
    }

    public setAssignee(assignee: User): this {
        this.assignee = assignee;
        return this;
    }

    public setWorkNoteAction(workNoteAction: WorkNoteAction): WorkNote {
        this.workNoteAction = workNoteAction;
        return this;
    }

    public getWorkNoteAction(): WorkNoteAction | undefined {
        return this.workNoteAction;
    }

    public getIsApproved(): boolean | undefined {
        return this.isApproved;
    }

    public setIsApproved(value: boolean) {
        this.isApproved = value;
        return this;
    }

    public setEmployeeWorkNoteStatisticByType(employeeWorkNoteStatisticByType: EmployeeWorkNoteStatistic): WorkNote {
        this.employeeWorkNoteStatisticByType = employeeWorkNoteStatisticByType;
        return this;
    }

    public getEmployeeWorkNoteStatisticByType(): EmployeeWorkNoteStatistic | undefined {
        return this.employeeWorkNoteStatisticByType;
    }

    public getHours(): number | undefined {
        return this.hours;
    }

    public setHours(value: number) {
        this.hours = value;
        return this;
    }

    public getMinutes(): number | undefined {
        return this.minutes;
    }

    public setMinutes(value: number) {
        this.minutes = value;
        return this;
    }

    public getSeconds(): number | undefined {
        return this.seconds;
    }

    public setSeconds(seconds: number) {
        this.seconds = seconds;
        return this;
    }

    public setWorkNoteSubTypeCategory(workNoteSubTypeCategory: WorkNoteSubTypeCategory): WorkNote {
        this.workNoteSubTypeCategory = workNoteSubTypeCategory;
        return this;
    }

    public getWorkNoteSubTypeCategory(): WorkNoteSubTypeCategory {
        return this.workNoteSubTypeCategory;
    }

    public setMedias(medias: any) {
        this.medias = medias;
        return this;
    }

    public getMedias(): any {
        return this.medias;
    }

    public setIsManuallyActionSet(isManuallyActionSet: boolean) {
        this.isManuallyActionSet = isManuallyActionSet;
        return this;
    }

    public getIsManuallyActionSet(): boolean {
        return this.isManuallyActionSet;
    }

    public getWorkNoteStatistic(): WorkNoteStatistic {
        return this.workNoteStatistic;
    }

    public setWorkNoteStatistic(workNoteStatistic: WorkNoteStatistic): WorkNote {
        this.workNoteStatistic = workNoteStatistic;
        return this;
    }

    public getCountBySameWorkNote(): number {
        return this.countBySameWorkNote;
    }

    public setCountBySameWorkNote(countBySameWorkNote: number) {
        this.countBySameWorkNote = countBySameWorkNote;
        return this;
    }
}
