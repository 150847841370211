import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostListener,
    Input, OnDestroy,
    OnInit, Renderer2,
    Self
} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {AutoComplete} from 'primeng';
import {Entity} from '../../../../projects/entity-manager/src/lib/helper/entity';
import {AbstractControl} from '@angular/forms';
import { filter, map } from 'rxjs/operators';

@Directive({
    selector: '[appAutoComplete]'
})
export class AutoCompleteDirective implements OnInit, AfterViewInit, OnDestroy {

    @Input() handler: {
        getData(query, params, queryField, properties): Observable<any[]>
    } = null;
    @Input() params: any = {};
    @Input() omitSelectedValue = true;
    @Input() forceSelectedValue = false;
    @Input() selectedValue: any = null;
    @Input() queryField: string;
    @Input() properties: string[] = [];
    @Input() showClear = false;
    @Input() control: AbstractControl = null;
    @Input() ngTemplateValue = null;

    public subscriptions: Subscription[] = [];

    protected readonly autocomplete: AutoComplete = null;

    @HostListener('keydown', ['$event'])
    public onKeyDown(event) {
        if (event.code === 'ArrowDown') {
            if (this.autocomplete.overlayVisible === false && this.autocomplete.dropdownButton) {
                this.autocomplete.dropdownButton.nativeElement.click();
            }
        }
    }

    public constructor(
        @Self() autocomplete: AutoComplete,
        protected cdr: ChangeDetectorRef,
        protected elementRef: ElementRef,
        protected renderer: Renderer2
    ) {
        this.autocomplete = autocomplete;
        this.cdr = cdr;

        if (!(this.autocomplete instanceof AutoComplete)) {
            console.error('AutoCompleteDirective works only with p-autocomplete component!');
        }
    }

    public ngOnInit(): void {
        this.autocomplete.completeMethod.subscribe((event: any) => {
            this.onComplete(event);
        });
        this.autocomplete.onFocus.subscribe((value) => {
            if (this.showClear && this.autocomplete.value) {
                this.handleShowClear();
            }
        });
        this.autocomplete.onSelect.subscribe((value) => {
            if (this.showClear && value !== null) {
                this.handleShowClear();
            }
        });

        if (this.forceSelectedValue && this.selectedValue && this.autocomplete.multiple !== true) {
            this.subscriptions.push(
                this.handler
                    .getData('', {
                        'id': this.selectedValue
                    }, this.queryField, this.parseProperties())
                    .pipe(
                        filter((data) => data.length > 0),
                        map((data) => data[0])
                    )
                    .subscribe((entity) => {
                        this.autocomplete.suggestions = [entity];
                        this.autocomplete.writeValue(entity);

                        this.cdr.detectChanges();
                    })
            );
        }
    }

    public ngOnDestroy(): void {
        const clearIcon = this.elementRef.nativeElement.querySelector('.ui-dropdown-clear-icon');

        if (clearIcon) {
            clearIcon.remove();
        }
    }

    public ngAfterViewInit(): void {
        const dropdownButton = this.elementRef.nativeElement.querySelector('.ui-autocomplete-dropdown');

        if (dropdownButton) {
            __ngRendererSetElementAttributeHelper(this.renderer, dropdownButton, 'tabIndex', '-1');
        }

    }

    public onComplete(event): void {
        const query = event.query;

        if (this.handler === null) {
            console.error('Handler missing in Host component, pass it as @Input()!');
        }

        const params = this.params || {};

        if (this.omitSelectedValue === true && this.autocomplete.value && Entity.hasMethod(this.autocomplete.value, 'getId') && this.autocomplete.value.getId()) {
            params['id[notEqual]'] = this.autocomplete.value.getId();
        }

        this.subscriptions.push(
            this.handler
                .getData(query, params, this.queryField, this.parseProperties())
                .subscribe((data: any[]) => {
                    this.autocomplete.suggestions = data;

                    this.cdr.detectChanges();
                    this.onItemsLoaded();
                })
        );
    }

    private parseProperties(): any {
        if (this.properties.length === 0) {
            return null;
        }

        let propertiesFormatted = {}, index = 0;
        for (const property of this.properties) {
            propertiesFormatted['properties[' + index + ']'] = property;
            index++;
        }
        return propertiesFormatted;
    }

    protected handleShowClear(): void {
        const showClearHtml = '<i style="position: absolute; right: 36px; top: 8px" class="ui-dropdown-clear-icon pi pi-times"></i>',
            input = this.elementRef.nativeElement.querySelector('.ui-autocomplete-dd');

        const clearIcon = this.elementRef.nativeElement.querySelector('.ui-dropdown-clear-icon');

        if (!clearIcon) {
            input.insertAdjacentHTML('beforeend', showClearHtml);
            this.elementRef.nativeElement.querySelector('.ui-dropdown-clear-icon').addEventListener('click', () => {
                if (this.control) {
                    this.control.setValue(null);
                }
                this.autocomplete.onSelect.emit(null);
                this.elementRef.nativeElement.querySelector('.ui-dropdown-clear-icon').remove();
                this.autocomplete.writeValue('');
            });
        }
    }

    protected onItemsLoaded(): void {}
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ':') {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ['', name];
}

function __ngRendererSetElementAttributeHelper(renderer: AnyDuringRendererMigration, element: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    if (value != null) {
        renderer.setAttribute(element, name, value, namespace);
    } else {
        renderer.removeAttribute(element, name, namespace);
    }
}
