import {CalendarRangeAdvanceFilter} from './calendar-range-advance-filter';
import * as moment from 'moment';

export class CalendarMultiDateRangeAdvanceFilter extends CalendarRangeAdvanceFilter {

    public buildFilterParams(params: any, filter): void {
        const filterValue = this.getFilterValue();

        params[`${filter}[${this.advanceFilter.index}][from][${this.advanceFilter.fromFieldKey}]`] = moment(filterValue[0]).format('YYYY-MM-DDTHH:mm:ss');
        params[`${filter}[${this.advanceFilter.index}][to][${this.advanceFilter.toFieldKey}]`] = moment(filterValue[1]).format('YYYY-MM-DDTHH:mm:ss');
    }
}
