import {Route} from 'entity-manager';
import {
    Associate,
    Employee,
    PaymentDeduction,
    PaymentDeductionType,
    WorkNoteInstalment,
    WorkNoteSubType,
    WorkShift
} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {PaymentDeductionContractRepository} from '../repositories/payment-deduction-contract.repository';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {StringPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';


@Route('payment_deduction_contracts')
@CrossDependency(() => {
    return {
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PaymentDeductionType
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        associate: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Associate
        },
        paymentDeductions: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: PaymentDeduction
        }
    };
})
@EntityDefaultFilters({
    'exists[employee.employment.dismissalType]': false
})
@Repository(PaymentDeductionContractRepository)
export class PaymentDeductionContract {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected amount: string;
    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected remainingAmount: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected fromDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected toDate: Date;
    protected isActive: boolean;
    protected instalmentCount: number;
    protected remainingInstalmentCount: number;
    protected numberOfHolidayDays: number;
    protected pastHolidayDays: number;
    protected holidayDays: number;
    protected availablePastHolidayDays: number;
    protected availableHolidayDays: number;
    protected phoneNumber: string;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(Associate)
    protected associate: Associate;

    @AssociationOne(PaymentDeductionType)
    protected type: PaymentDeductionType;

    @AssociationMany(PaymentDeduction)
    protected paymentDeductions: PaymentDeduction[];

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setAmount(amount: string) {
        this.amount = amount;
        return this;
    }

    public getAmount(): string {
        return this.amount;
    }

    public setRemainingAmount(remainingAmount: string) {
        this.remainingAmount = remainingAmount;
        return this;
    }

    public getRemainingAmount(): any {
        return this.remainingAmount;
    }

    public setFromDate(fromDate: Date) {
        this.fromDate = fromDate;
        return this;
    }

    public getFromDate(): Date {
        return this.fromDate;
    }

    public setToDate(toDate: Date) {
        this.toDate = toDate;
        return this;
    }

    public getToDate(): Date {
        return this.toDate;
    }

    public setEmployee(employee: Employee) {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee {
        return this.employee;
    }

    public setAssociate(associate: Associate) {
        this.associate = associate;
        return this;
    }

    public getAssociate(): Associate {
        return this.associate;
    }

    public setType(type: PaymentDeductionType) {
        this.type = type;
        return this;
    }

    public getType(): PaymentDeductionType {
        return this.type;
    }

    public setIsActive(isActive: boolean) {
        this.isActive = isActive;
        return this;
    }

    public getIsActive(): boolean {
        return this.isActive;
    }

    public setInstalmentCount(instalmentCount: number) {
        this.instalmentCount = instalmentCount;
        return this;
    }

    public getInstalmentCount(): number|undefined {
        return this.instalmentCount;
    }

    public setRemainingInstalmentCount(remainingInstalmentCount: number) {
        this.remainingInstalmentCount = remainingInstalmentCount;
        return this;
    }

    public getRemainingInstalmentCount(): number|undefined {
        return this.remainingInstalmentCount;
    }

    public setNumberOfHolidayDays(numberOfHolidayDays: number) {
        this.numberOfHolidayDays = numberOfHolidayDays;
        return this;
    }

    public getNumberOfHolidayDays(): number|undefined {
        return this.numberOfHolidayDays;
    }

    public setPastHolidayDays(pastHolidayDays: number) {
        this.pastHolidayDays = pastHolidayDays;
        return this;
    }

    public getPastHolidayDays(): number|undefined {
        return this.pastHolidayDays;
    }

    public setHolidayDays(holidayDays: number) {
        this.holidayDays = holidayDays;
        return this;
    }

    public getHolidayDays(): number|undefined {
        return this.holidayDays;
    }

    public setAvailablePastHolidayDays(availablePastHolidayDays: number) {
        this.availablePastHolidayDays = availablePastHolidayDays;
        return this;
    }

    public getAvailablePastHolidayDays(): number|undefined {
        return this.availablePastHolidayDays;
    }

    public setAvailableHolidayDays(availableHolidayDays: number) {
        this.availableHolidayDays = availableHolidayDays;
        return this;
    }

    public getAvailableHolidayDays(): number|undefined {
        return this.availableHolidayDays;
    }

    public setPhoneNumber(phoneNumber: string) {
        this.phoneNumber = phoneNumber;
        return this;
    }

    public getPhoneNumber(): string|undefined {
        return this.phoneNumber;
    }

    public setPaymentDeductions(paymentDeductions: PaymentDeduction[]): PaymentDeductionContract {
        this.paymentDeductions = paymentDeductions;
        return this;
    }

    public getPaymentDeductions(): PaymentDeduction[] | undefined {
        return this.paymentDeductions;
    }

    public addPaymentDeduction(value: PaymentDeduction) {
        this.paymentDeductions.push(value);
        return this;
    }
}
