import {Route} from 'entity-manager';
import {DeletePermissionAwareInterface} from './delete-permission-aware.interface';

export enum WorkNoteTypeEnum {
    TimeWorxViolation = 'timeWorxViolation',
    WorkViolation = 'workViolation',
    CommendationOfSuperior = 'commendationOfSuperior',
    BehaviorWorkplace = 'behaviorWorkplace'
 }

@Route('work_note_types')
export class WorkNoteType implements DeletePermissionAwareInterface {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;
    protected predefined: boolean;
    protected points: number;

    public setId(id: string): WorkNoteType {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string): WorkNoteType {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string): WorkNoteType {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string): WorkNoteType {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public isPredefined(): boolean {
        return this.predefined;
    }

    public setPredefined(): boolean {
        return this.predefined;
    }

    public getPoints(): number {
        return this.points;
    }

    public setPoints(points: number): WorkNoteType {
        this.points = points;
        return this;
    }

    public isValidForDelete(): boolean {
        return !this.isPredefined();
    }
}
