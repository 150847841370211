import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Product, Order, ProductType, Unit, Vat} from './entities';
import {Route} from '../../../../projects/entity-manager/src/lib/decorator/route';
import {
    CrossDependency
} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {OrderItemRepository} from '../repositories/order-item.repository';

import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';

@Route('order_items')
@CrossDependency(() => {
    return {
        product: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Product
        },
        productType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: ProductType
        },
        order: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Order
        },
        unit: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Unit
        },
        vat: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vat
        }
    };
})
@Repository(OrderItemRepository)
export class OrderItem {

    protected id: string;

    @AssociationOne(Product)
    protected product;

    @AssociationOne(ProductType)
    protected productType;

    @AssociationOne(Order)
    protected order;

    @AssociationOne(Unit)
    protected unit: Unit;

    protected rebate: number;

    @AssociationOne(Vat)
    protected vat: Vat;

    protected name;

    protected quantity;

    protected price;
    protected priceWithRebate: number;
    protected priceWithVat: number;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    protected totalPrice;

    protected sortPosition;

    public getId(): string {
        return this.id;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getProduct(): Product|undefined {
        return this.product;
    }

    public setProduct(product: Product): OrderItem {
        this.product = product;
        return this;
    }

    public getOrder(): Order|undefined {
        return this.order;
    }

    public setOrder(order: Order): OrderItem {
        this.order = order;
        return this;
    }

    public getProductType(): ProductType | undefined {
        return this.productType;
    }

    public setProductType(productType: ProductType): OrderItem {
        this.productType = productType;
        return this;
    }

    public getUnit(): Unit {
        return this.unit;
    }

    public setUnit(unit: Unit) {
        this.unit = unit;
        return this;
    }

    public getRebate(): number | undefined {
        return this.rebate;
    }

    public setRebate(rebate: number): OrderItem {
        this.rebate = rebate;
        return this;
    }

    public getVat(): Vat | undefined {
        return this.vat;
    }

    public setVat(vat: Vat): OrderItem {
        this.vat = vat;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): OrderItem {
        this.name = name;
        return this;
    }

    public getQuantity(): number|undefined {
        return this.quantity;
    }

    public setQuantity(quantity: number): OrderItem {
        this.quantity = quantity;
        return this;
    }

    public getPrice(): number | undefined {
        return this.price;
    }

    public setPrice(price: number): OrderItem {
        this.price = price;
        return this;
    }

    public setTotalPrice(totalPrice: number): OrderItem {
        this.totalPrice = totalPrice;
        return this;
    }

    public getTotalPrice(): number|undefined {
        return this.totalPrice;
    }

    public getSortPosition(): number {
        return this.sortPosition;
    }

    public setSortPosition(sortPosition: number): OrderItem {
        this.sortPosition = sortPosition;
        return this;
    }

    public setMaster(master: Order) {
        this.order = master;
        return this;
    }

    public setPriceWithRebate(priceWithRebate: number): OrderItem {
        this.priceWithRebate = priceWithRebate;
        return this;
    }

    public getPriceWithRebate(): number | undefined {
        return this.priceWithRebate;
    }

    public setPriceWithVat(priceWithVat: number): OrderItem {
        this.priceWithVat = priceWithVat;
        return this;
    }

    public getPriceWithVat(): number | undefined {
        return this.priceWithVat;
    }
}
