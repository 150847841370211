import {Route} from 'entity-manager';
import {Workplace} from './workplace';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {Employee} from "./employee";
import {AssociationManyToMany} from "../../../../projects/entity-manager/src/lib/decorator/associations/association-many-to-many";

@Route('departments')
@CrossDependency(() => {
    return {
        workplaces: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: Workplace
        },
        headOfDepartments: {
            mapping: Meta.META_ASSOCIATIONS_MANY_TO_MANY,
            entity: Employee
        }
    };
})
export class Department {

    @AssociationMany(Workplace)
    protected workplaces: Workplace[];

    @AssociationManyToMany(Employee)
    protected headOfDepartments: Employee[];

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;


    public setWorkplaces(workplaces: Workplace[]): Department {
        this.workplaces = workplaces;
        return this;
    }

    public getWorkplaces(): Workplace[] | undefined {
        return this.workplaces;
    }

    public setHeadOfDepartments(headOfDepartments: Employee[]): Department {
        this.headOfDepartments = headOfDepartments;
        return this;
    }

    public getHeadOfDepartments(): Employee[] | undefined {
        return this.headOfDepartments;
    }

    public setId(id: string): Department {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setCode(code: string): Department {
        this.code = code;
        return this;
    }

    public getCode(): string | undefined {
        return this.code;
    }

    public setName(name: string): Department {
        this.name = name;
        return this;
    }

    public getName(): string | undefined {
        return this.name;
    }

    public setDescription(description: string): Department {
        this.description = description;
        return this;
    }

    public getDescription(): string | undefined {
        return this.description;
    }
}
