import {Directive, Self, OnInit, Output, EventEmitter} from '@angular/core';
import {SplitButton} from 'primeng';

@Directive({
    selector: '[appSplitButtonAsDropdown]'
})
export class SplitButtonAsDropdownDirective implements OnInit {

    @Output() valueChange = new EventEmitter<any>();

    private readonly splitButton: SplitButton = null;

    public constructor(@Self() splitButton: SplitButton) {
        this.splitButton = splitButton;

        if (!(this.splitButton instanceof SplitButton)) {
            console.error('This directive works only with splitButton component!');
        }
    }

    public ngOnInit(): void {
        this.splitButton.onClick.subscribe((event: any) => {
            this.splitButton.onDropdownButtonClick(event);
            return false;
        });
    }
}
