import {Injectable, EventEmitter} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {LocalStorageService} from './local-storage.service';
import {AuthenticationService} from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class MainNavigationService {

    public currentChangeLabel: EventEmitter<string> = new EventEmitter();

    public constructor(
        protected localStorage: LocalStorageService,
        protected authentication: AuthenticationService
    ) {

    }

    public changeCurrentLabel(label: string): void {
        this.currentChangeLabel.emit(label);
    }

    public store(items: MenuItem[] = []): void {
        const aItems = items.filter((aItem: MenuItem) => {
            return aItem.routerLink !== null;
        });

        this.localStorage.setItem(`${this.authentication.getUser().getId()}-mainNavigation`, aItems);
    }

    public merge(activeItem: MenuItem): MenuItem[] {
        const items = [activeItem],
            storageItems = this.localStorage.getItem(`${this.authentication.getUser().getId()}-mainNavigation`);

        if (storageItems instanceof Array) {
            for (const storageItem of storageItems) {
                if (storageItem.routerLink && storageItem.routerLink !== activeItem.routerLink) {
                    items.push(storageItem);
                }
            }
        }

        return items;
    }
}
