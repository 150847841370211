import {TableComponent} from './table/table.component';
import {TableColumnReorderComponent} from './table/column-reorder/table-column-reorder.component';
import {TableClickHandlerDirective} from './table/directives/table-click-handler.directive';
import {AutoCompleteDirective} from './directives/autocomplete.directive';
import {SpinnerDirective} from './directives/spinner.directive';
import {SplitButtonAsDropdownDirective} from './directives/split-button-as-dropdown.directive';
import {TableEditableColumnDirective} from './table/directives/table-editable-column.directive';
import {TableSplitButtonDirective} from './table/directives/table-split-button.directive';
import {CalendarDirective} from './directives/calendar.directive';
import {InvoiceAutocompleteDirective} from './directives/invoice-autocomplete.directive';
import {TableStringToDatePipe} from './table/pipes/table-string-to-date.pipe';
import {TableEditCalendarHandlerDirective} from './table/directives/table-edit-calendar-handler.directive';
import {TableAdvanceFiltersComponent} from './table/advance-filters/table-advance-filters.component';
import {TableColorPickerDirective} from './table/directives/table-color-picker.directive';
import {CalendarRangeFilterComponent} from './table/components/filter/calendar-range/calendar-range-filter.component';
import {TableOverrideBindDocumentEditListenerDirective} from './table/directives/table-override-bind-document-edit-listener.directive';
import {DisableAutofillDirective} from './directives/disable-autofill.directive';
import {BusinessEmailPipe} from './pipes/business-email.pipe';
import {FlipCardComponent} from './flip-card/flip-card.component';
import {AppTemplateDirective} from './directives/app-template.directive';
import {DatePipe} from './pipes/date.pipe';
import {UnauthorizedComponent} from './components/unauthorized/unauthorized.component';
import {UnderConstructionComponent} from './components/under-construction/under-construction.component';
import {FlipPanelComponent} from './flip-panel/flip-panel.component';
import {CountBoxComponent} from '../components/common/home/count-box/count-box.component';
import {MessagesCounterComponent} from '../components/common/home/messages-counter/messages-counter.component';
import {ConvertDecimalToTimePipe} from './pipes/convert-decimal-to-time.pipe';
import {ConvertPeriodToTimePipe} from './pipes/convert-period-to-time.pipe';
import {ConvertDecimalToWorkTimePipe} from './pipes/convert-decimal-to-work-time.pipe';
import {ConfirmDialogDirective} from './directives/confirm-dialog.directive';
import {NumberFilterComponent} from './table/components/filter/number/number-filter.component';
import {SimpleTableComponent} from './table/simple-table/simple-table.component';
import {CurrencyPipe} from './pipes/currency.pipe';
import {HourSpinnerComponent} from './form/hour-spinner/hour-spinner.component';
import {MonthlyFilterComponent} from './components/monthly-filter/monthly-filter.component';
import {DecimalPipe} from './pipes/decimal.pipe';
import {DateAgoPipe} from './pipes/date-ago.pipe';
import {DaysMonthsYearsPipe} from "./pipes/days-months-years.pipe";
import {SplashScreenComponent} from './components/splash-screen/splash-screen-component';

export const sharedDeclarations = [
    AppTemplateDirective,
    TableComponent,
    SimpleTableComponent,
    TableColumnReorderComponent,
    TableClickHandlerDirective,
    TableEditableColumnDirective,
    TableSplitButtonDirective,
    AutoCompleteDirective,
    DisableAutofillDirective,
    InvoiceAutocompleteDirective,
    SpinnerDirective,
    SplitButtonAsDropdownDirective,
    TableEditCalendarHandlerDirective,
    TableColorPickerDirective,
    TableOverrideBindDocumentEditListenerDirective,
    CalendarDirective,
    ConfirmDialogDirective,
    TableStringToDatePipe,
    TableAdvanceFiltersComponent,
    DaysMonthsYearsPipe,
    ConvertDecimalToTimePipe,
    ConvertPeriodToTimePipe,
    CurrencyPipe,
    DecimalPipe,
    ConvertDecimalToWorkTimePipe,
    CalendarRangeFilterComponent,
    FlipCardComponent,
    FlipPanelComponent,
    HourSpinnerComponent,
    NumberFilterComponent,
    BusinessEmailPipe,
    DatePipe,
    DateAgoPipe,
    UnauthorizedComponent,
    UnderConstructionComponent,
    MonthlyFilterComponent,
    SplashScreenComponent,
];
