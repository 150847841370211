import {AbstractActionHandler} from './abstract-action-handler';
import {DashboardBox} from '../dashboard-box';
import {DashboardAction} from '../dashboard-action';
import {Parameter} from '../parameter';

export class AddBoxActionHandler extends AbstractActionHandler {

    public handleAction() {
        const newDashboardBox = this.getParameterValue('dashboardBox');

        this.dashboardComponent.getDashboardService().addBox(newDashboardBox);
        this.dashboardComponent.getDashboardRendererService().renderBoxComponent(newDashboardBox, this.getDashboardComponent().getDashboardBoxesContainer());

        this.onBoxAdded(newDashboardBox);
    }

    private onBoxAdded(dashboardBox: DashboardBox) {
        this.dashboardActionService.on(DashboardAction.BoxAdded, [new Parameter('dashboardBox', dashboardBox)]);
    }
}
