import {Component, EventEmitter, AfterViewInit, ViewContainerRef, Input, Output, ViewChild} from '@angular/core';
import {DashboardRendererService} from '../../service/renderer/dashboard-renderer.service';
import {DashboardActionService} from '../../service/renderer/dashboard-action.service';
import {DashboardService} from '../../service/dashboard-service';
import {DashboardSettingLayout} from '../../service/dashboard-setting';
import {DashboardAction} from '../../service/dashboard-action';
import {Parameter} from '../../service/parameter';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [
        DashboardRendererService,
        DashboardActionService
    ]
})
export class DashboardComponent implements AfterViewInit {

    @ViewChild('dashboardBoxesContainer', {read: ViewContainerRef, static: true}) dashboardBoxesContainer: ViewContainerRef;

    @Input() set service(dashboardService: DashboardService) {
        if (dashboardService) {
            this.setDashboardService(dashboardService);

            if (this.getDashboardService() !== null && this.getDashboardService().getBoxes().length !== 0) {
                this.setBoxesAndRender();
            }
        }
    }

    @Output() actionDone = new EventEmitter<any>(); // any, i'm lazy

    public isBoxListModalVisible = false;

    public dashboardService: DashboardService = null;

    public constructor(
        protected dashboardRendererService: DashboardRendererService,
        protected dashboardActionService: DashboardActionService
    ) {
        this.dashboardActionService.setDashboardComponent(this);
        this.dashboardRendererService.setDashboardComponent(this);
    }

    public getDashboardService(): DashboardService|null {
        return this.dashboardService;
    }

    public setDashboardService(dashboard: DashboardService): DashboardComponent {
        this.dashboardService = dashboard;
        return this;
    }

    public ngAfterViewInit() {
    }

    public onAddBox(): void {
        this.isBoxListModalVisible = true;
    }

    public onChangeLayout(layout: DashboardSettingLayout) {
        this.dashboardActionService.on(DashboardAction.LayoutChanged, [
            new Parameter('layout', layout)
        ]);
    }

    public getDashboardBoxesContainer(): ViewContainerRef {
        return this.dashboardBoxesContainer;
    }

    private setBoxesAndRender(): DashboardComponent {
        this.dashboardRendererService.renderBoxComponents(this.getDashboardService().getBoxes());
        return this;
    }

    public getDashboardRendererService(): DashboardRendererService {
        return this.dashboardRendererService;
    }

    public getDashboardActionService(): DashboardActionService {
        return this.dashboardActionService;
    }
}
