export class MathUtil {

    public static round(value: number|string, places = 2): string {
        const delimiter = +MathUtil.appendZeros(1, places);

        return (Math.round(+value * delimiter) / delimiter).toFixed(places);
    }

    private static appendZeros(padTo: number, length: number) {
        let zerosString = padTo.toString();

        while (zerosString.length < length + 1) {
            zerosString += '0';
        }

        return zerosString;
    }
}
