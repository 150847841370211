import {Pipe, PipeTransform} from '@angular/core';
import {Currency} from '../../core/entities/currency';

@Pipe({
    name: 'appCurrency'
})
export class CurrencyPipe implements PipeTransform {

    public transform(value: string|number|undefined, currency: Currency|string): string {
        const code = currency instanceof Currency ?
            currency.getCode() :
            currency;

        if (!value) {
            return '0.00' + ' ' + code;
        }

        return value + ' ' + code;
    }
}
