import {InjectorAwareInterface} from '../../../workflow/services/injector-aware.interface';
import {WorkflowAction} from '../../../workflow/services/workflow-action';
import {WorkflowActionInterface} from '../../../workflow/services/workflow-action.interface';
import {WorkflowActionStatus} from '../../../workflow/services/workflow-action-status';
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';

export class BootstrapTranslateAction extends WorkflowAction implements WorkflowActionInterface, InjectorAwareInterface {

    public run(): Observable<WorkflowActionStatus> {

        return this.injector.get(TranslateService).get('rs')
            .pipe(
                map(() => {
                    return {
                        success: true,
                        stop: false,
                        status: ''
                    };
                })
            );
    }
}
