import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import {Modifier} from 'entity-manager';
import {EntityManagerParserService} from '../../../../projects/entity-manager/src/lib/service/parser/entity-manager-parser.service';
import {EntityManagerMetaDataService} from '../../../../projects/entity-manager/src/lib/service/meta/entity-manager-meta-data.service';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {cloneDeep} from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class EntityLdJsonModifier implements Modifier {

    public constructor(
        protected parser: EntityManagerParserService,
        protected meta: EntityManagerMetaDataService
    ) {

    }

    public modifyRequest(entity: any, request: HttpRequest<any>): any {
        const isPersistRequest = request.method === 'POST' || request.method === 'PUT';

        // todo :: create clone, do not change existing entity
        if (isPersistRequest && this.meta.hasMetaDataProperty(entity, Meta.META_ROUTE)) {
            const associations = this.meta.getAssociations(entity);

            for (const associationKey in associations) {
                if (associations.hasOwnProperty(associationKey)) {
                    const associationEntityRoute = this.meta.getMetaDataProperty(new associations[associationKey](), Meta.META_ROUTE);

                    if (this.meta.hasAssociation(entity, associationKey)) {
                        if (entity[associationKey] && entity[associationKey].id) {
                            entity[associationKey] = '/backend/api/' + associationEntityRoute + '/' + entity[associationKey].id;
                        } else if (entity[associationKey] === null) {
                            entity[associationKey] = null;
                        } else {
                            delete entity[associationKey];
                        }
                    }
                }
            }

            const associationsMany = this.meta.getAssociationsMany(entity);

            for (const associationKey in associationsMany) {
                if (associationsMany.hasOwnProperty(associationKey)) {
                    if (this.meta.hasAssociationMany(entity, associationKey) && entity[associationKey]) {
                        if (entity[associationKey] instanceof Array) {
                            if (entity[associationKey].length === 0) {
                                delete entity[associationKey];
                            } else {
                                const ids = [];
                                const route = this.meta.getMetaDataProperty(new associationsMany[associationKey](), Meta.META_ROUTE);

                                for (const associationEntity of entity[associationKey]) {
                                    if (associationEntity.id) {
                                        ids.push('/backend/api/' + route + '/' + associationEntity.id);
                                    } else {
                                        ids.push(associationEntity);
                                    }
                                }

                                entity[associationKey] = ids;
                            }
                        }
                    }
                }
            }

            const associationsManyToMany = this.meta.getAssociationsManyToMany(entity);

            for (const associationKey in associationsManyToMany) {
                if (associationsManyToMany.hasOwnProperty(associationKey)) {
                    if (this.meta.hasAssociationManyToMany(entity, associationKey) && entity[associationKey]) {
                        if (entity[associationKey] instanceof Array) {
                            if (entity[associationKey].length > 0) {
                                const ids = [];
                                const route = this.meta.getMetaDataProperty(new associationsManyToMany[associationKey](), Meta.META_ROUTE);

                                for (const associationEntity of entity[associationKey]) {
                                    if (associationEntity.id) {
                                        ids.push('/backend/api/' + route + '/' + associationEntity.id);
                                    } else {
                                        ids.push(associationEntity);
                                    }
                                }

                                entity[associationKey] = ids;
                            }
                        }
                    }
                }
            }
        }

        request = request.clone({
            body: entity
        });

        return request;
    }

    public modifyResponse(entity: any, request: HttpRequest<any>, responseBody: any, caller: string): any  {

        if (responseBody && responseBody['@context'] && responseBody['@id'] && responseBody['@type'] !== 'hydra:Collection') {
            responseBody = this.parser.getParser().parse(entity, responseBody);
        }

        return responseBody;
    }
}
