<p-panel class="filter-panel" *ngIf="tableAdvanceFilters?.length > 0 || tableFilters==='top'" header="Filteri" [toggleable]="true" [collapsed]="true">
    <div class="ui-g" *ngIf="tableFilters==='top'">
        <ng-container *ngFor="let column of tableFilterColumns">
            <div class="ui-g-2 simple-filter" *ngIf="column.filter">
                <div class="ui-g-12 label">
                    <span [innerHtml]="column.header | translate"></span>
                </div>
                <div>
                    <ng-template [ngIf]="column.filter">
                        <div [ngSwitch]="true" class="ui-filter ui-g ui-g-12">

                            <div
                                    *ngIf="column.filter.operator"
                                    class="ui-g-5"
                            >
                                <p-dropdown
                                        [ngModel]="getFilterOperatorValue(column)"
                                        [options]="column.filter.operator.options | async"
                                        placeholder="---"
                                        (onChange)="onFilterOperatorEvent($event, column)"
                                        [style]="{'min-width': '100%'}"
                                        [panelStyle]="{'min-width': '100%'}"
                                        [disabled]="getFilterValue(column) === null"
                                        appendTo="body"
                                ></p-dropdown>
                            </div>

                            <p-checkbox
                                    *ngSwitchCase="column.filter.type === 'checkbox'"
                                    [ngModel]="getFilterValue(column)"
                                    (onChange)="onFilterEvent($event, column)"
                                    binary="true"
                            ></p-checkbox>

                            <span *ngSwitchCase="column.filter.type === 'calendar'"
                                  class="ui-filter-calendar-container">
                          <p-calendar
                                  appCalendar
                                  name="{{column.key}}"
                                  [ngModel]="getFilterValue(column)"
                                  [showIcon]="true"
                                  (valueChange)="onFilterEvent($event, column)"
                                  (onSelect)="onFilterEvent($event, column)"
                                  [dateFormat]="column.filter.dateFormat"
                                  [showTime]="column.filter.showTime"
                                  inputStyleClass="ui-filter-calendar-input"
                                  class="ui-filter-calendar ui-g-12"
                                  showButtonBar="true"
                                  [panelStyle]="{'width':'100px'}"
                                  (onTodayClick)="onFilterEvent($event, column, 'today')"
                                  (onClearClick)="onFilterEvent($event, column, 'clear')"
                                  appendTo="body"
                          ></p-calendar>
                        </span>

                            <app-calendar-range-filter
                                    *ngSwitchCase="column.filter.type === 'calendar-range' || column.filter.type === 'calendar-multi-date-range'"
                                    (filterChange)="onFilterEvent($event, column)"
                                    [filterService]="filter"
                                    [column]="column"
                                    [table]="this"
                                    [style]="{'min-width': '100%'}"
                                    class="ui-g-12"
                            ></app-calendar-range-filter>

                            <app-number-filter
                                    class="ui-filter ui-g-{{ column.filter.operator ? '7' : '12' }}"
                                    *ngSwitchCase="column.filter.type === 'number'"
                                    (filterChange)="onFilterEvent($event, column)"
                                    [column]="column"
                                    [table]="this"
                            ></app-number-filter>

                            <p-dropdown
                                    *ngSwitchCase="column.filter.type === 'dropdown'"
                                    [options]="column.filter.options | async"
                                    (onChange)="onFilterEvent($event.value, column)"
                                    [ngModel]="getFilterValue(column)"
                                    appendTo="body"
                                    [style]="{'min-width': '100%'}"
                                    [panelStyle]="{'width':'100px'}"
                                    class="ui-filter-dropdown ui-g-12"
                            ></p-dropdown>

                            <p-autoComplete
                                    appAutoComplete
                                    class="form-control"
                                    *ngSwitchCase="column.filter.type === 'autocomplete'"
                                    size="30"
                                    appendTo="body"
                                    [style]="{'width': '100%'}"
                                    [field]="column.filter.fieldName"
                                    [handler]="column.filter.autocompleteHandler"
                                    [queryField]="column.filter.queryField"
                                    [dropdown]="true"
                                    [forceSelection]="true"
                                    (onSelect)="onFilterEvent($event, column)"
                                    [panelStyle]="{'width':'300px'}"
                                    [showClear]="true"
                            ></p-autoComplete>

                            <span *ngSwitchCase="column.filter.type === 'none'" class="ui-g ui-g-8">
                        </span>


                            <ng-template [ngSwitchDefault]>
                                <div class="ui-g-12">
                                    <input
                                            *ngIf="!column.filter.mask"
                                            pInputText
                                            [ngModel]="getFilterValue(column)"
                                            type="text"
                                            (input)="onFilterEvent($event, column)"
                                    >

                                    <p-inputMask
                                            *ngIf="column.filter.mask"
                                            appDisableAutofill
                                            mask="{{column.filter.mask}}"
                                            placeholder="{{column.filter.mask}}"
                                            [ngModel]="getFilterValue(column)"
                                            (keyup)="onFilterEvent($event, column)"
                                    ></p-inputMask>
                                </div>

                            </ng-template>
                        </div>
                    </ng-template>
                </div>

            </div>
        </ng-container>
    </div>

    <app-table-advance-filters
            [tableComponent]="this"
            [advanceFilters]="tableAdvanceFilters"
    ></app-table-advance-filters>
</p-panel>
<p-table
    #dataTable
    appTableClickHandler
    appTableOverrideBindDocumentEditListener
    [component]="this"
    id="generic-table"
    [columns]="tableColumns"
    [frozenColumns]="tableFrozenColumns"
    [value]="tableEntities"
    [customSort]="true"
    [lazy]="true"
    [paginator]="paginator" [rows]="rows" [first]="first" [sortField]="sortField" [sortOrder]="sortOrder" [totalRecords]="totalCount"
    [rowsPerPageOptions]="[10, 20, 30, 60, 120, 240, 1000]"
    (onLazyLoad)="onLazyLoadEvent($event)"
    [reorderableColumns]="true"
    (onRowSelect)="onSelect($event)"
    (onRowUnselect)="onUnselect($event)"
    (onRowReorder)="onReorder($event)"
    [loading]="isLoadingData"
    selectionMode="multiple"
    [(selection)]="selection.selection"
    [metaKeySelection]="true"
    [resizableColumns]="false"
    columnResizeMode="expand"
    [scrollable]="scrollable"
    scrollHeight="{{tableHeight + 'px'}}"
    [style]="{width: tableWidth + 'px' }"
    frozenWidth="{{tableFrozenColumnsWidth + 'px'}}"
    dataKey="id"
>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length + 1">
        {{ 'COMMON.NO_RECORDS_FOUND'  | translate }}
      </td>
    </tr>
  </ng-template>

    <ng-template *ngIf="tableHeader && headerVisible" pTemplate="caption">

        <div class="ui-g">
            <div class="ui-g-12 ui-md-12 ui-lg-12" style="text-align:right">
                <span *ngFor="let button of tableHeader.buttons" class="toolbar-item">
                    <button
                      pButton
                      *ngIf="button.visible === undefined || button.visible()"
                      (click)="onTableButtonClick($event, button)"
                      [disabled]="button.disabled !== undefined && button.disabled()"
                      type="button"
                      icon="{{button.icon}}"
                      pTooltip="{{button.tooltip | translate}}"
                      tooltipPosition="{{button.tooltipPosition || 'right'}}"
                      class="{{button.class}}"
                      [attr.data-selenium-id]="button.seleniumId||''"
                      label="{{button.label || '&nbsp;'}}"
                    ></button>
                </span>

                <span *ngFor="let dropdown of tableHeader.dropdowns" class="toolbar-item">
                  <p-dropdown
                    [options]="dropdown.options"
                    placeholder="---"
                    [showClear]="true"
                    (onChange)="onToolbarDropdownChange($event, dropdown)"
                    class="{{dropdown.class}}"
                    [style]="{'width': '200px'}"
                    [autoWidth]="false"
                  ></p-dropdown>
                </span>
            </div>
        </div>

    </ng-template>

    <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
            <col *ngFor="let column of columns" [ngStyle]="{width: column.style.width}">
        </colgroup>
    </ng-template>
    <ng-template *ngIf="headerVisible" pTemplate="header" let-columns>
        <tr *ngIf="sortVisible">
            <th
                [ngStyle]="getHeaderStyle(column)"
                pResizableColumn pReorderableColumn *ngFor="let column of columns" [pSortableColumn]="column.sort ? ( column.sort.key ? column.sort.key : column.key ) : undefined " [pTooltip]="column.tooltip ? column.tooltip : column.header" [attr.data-selenium-id]="'table-column-' + column.key">
                <p-sortIcon *ngIf="column.sort" [field]="column.sort && column.sort.key ? column.sort.key : column.key"></p-sortIcon>
                <span [innerHtml]="column.header | translate"></span>
            </th>
        </tr>
        <tr *ngIf="tableFilters === 'table' && isFilterActive">
            <th *ngFor="let column of columns" class="ui-table-filter-header">

                <p-checkbox
                        *ngIf="column.selection"
                        (onChange)="onHeaderCheckboxChange($event)"
                        binary="true"
                        [ngModel]="isHeaderCheckboxChecked()"
                ></p-checkbox>

                <ng-template [ngIf]="column.filter">
                    <div [ngSwitch]="true" class="ui-filter ui-g ui-g-12" >

                        <div
                            *ngIf="column.filter.operator"
                            class="ui-g-5"
                        >
                            <p-dropdown
                                [ngModel]="getFilterOperatorValue(column)"
                                [options]="column.filter.operator.options | async"
                                placeholder="---"
                                (onChange)="onFilterOperatorEvent($event, column)"
                                [style]="{'min-width': '100%'}"
                                [panelStyle]="{'min-width': '100%'}"
                                [disabled]="getFilterValue(column) === null"
                                appendTo="body"
                            ></p-dropdown>
                        </div>

                        <p-checkbox
                            *ngSwitchCase="column.filter.type === 'checkbox'"
                            [ngModel]="getFilterValue(column)"
                            (onChange)="onFilterEvent($event, column)"
                            binary="true"
                        ></p-checkbox>

                        <span *ngSwitchCase="column.filter.type === 'calendar'" [style.marginTop.px]="2" class="ui-g ui-g-8 ui-filter-calendar-container">
                          <p-calendar
                              appCalendar
                              name="{{column.key}}"
                              [ngModel]="getFilterValue(column)"
                              [showIcon]="true"
                              (valueChange)="onFilterEvent($event, column)"
                              (onSelect)="onFilterEvent($event, column)"
                              [dateFormat]="column.filter.dateFormat"
                              [showTime]="column.filter.showTime"
                              [yearNavigator]="column.filter.yearNavigator"
                              [monthNavigator]="column.filter.monthNavigator"
                              inputStyleClass="ui-filter-calendar-input"
                              class="ui-filter-calendar"
                              showButtonBar="true"
                              [panelStyle]="{'width':'100px'}"
                              (onTodayClick)="onFilterEvent($event, column, 'today')"
                              (onClearClick)="onFilterEvent($event, column, 'clear')"
                              appendTo="body"
                          ></p-calendar>
                        </span>

                        <app-calendar-range-filter
                                *ngSwitchCase="column.filter.type === 'calendar-range' || column.filter.type === 'calendar-multi-date-range'"
                                (filterChange)="onFilterEvent($event, column)"
                                [filterService]="filter"
                                [column]="column"
                                [table]="this"
                                [style]="{'min-width': '100%'}"
                        ></app-calendar-range-filter>

                        <app-number-filter
                            class="ui-filter ui-g-{{ column.filter.operator ? '7' : '12' }}"
                            *ngSwitchCase="column.filter.type === 'number'"
                            (filterChange)="onFilterEvent($event, column)"
                            [column]="column"
                            [table]="this"
                        ></app-number-filter>

                        <app-hour-spinner
                                [allowNull]="true"
                                class="ui-filter"
                                style="padding: 0;"
                                *ngSwitchCase="column.filter.type === 'hoursAndMinutes'"
                                context="duration"
                                [value]="getFilterValue(column)"
                                (valueChange)="onFilterEvent($event, column)"
                                [hideDuration]="true"
                                [showClearButton]="true"
                        ></app-hour-spinner>

                        <p-dropdown
                            *ngSwitchCase="column.filter.type === 'dropdown'"
                            [options]="column.filter.options | async"
                            (onChange)="onFilterEvent($event.value, column)"
                            [ngModel]="getFilterValue(column)"
                            appendTo="body"
                            [style]="{'min-width': '100%'}"
                            [panelStyle]="{'width':'100px'}"
                            class="ui-filter-dropdown"
                        ></p-dropdown>

                        <p-autoComplete
                            appAutoComplete
                            class="form-control"
                            *ngSwitchCase="column.filter.type === 'autocomplete'"
                            size="30"
                            appendTo="body"
                            [style]="{'width': '100%'}"
                            [field]="column.filter.fieldName"
                            [handler]="column.filter.autocompleteHandler"
                            [queryField]="column.filter.queryField"
                            [dropdown]="true"
                            [forceSelection]="true"
                            (onSelect)="onFilterEvent($event, column)"
                            [panelStyle]="{'width':'300px'}"
                            [showClear]="true"
                        ></p-autoComplete>

                        <span *ngSwitchCase="column.filter.type === 'none'" class="ui-g ui-g-8">
                        </span>


                        <ng-template [ngSwitchDefault]>
                            <input
                                *ngIf="!column.filter.mask"
                                pInputText
                                [ngModel]="getFilterValue(column)"
                                type="text"
                                (input)="onFilterEvent($event, column)"
                            >

                            <p-inputMask
                                *ngIf="column.filter.mask"
                                appDisableAutofill
                                mask="{{column.filter.mask}}"
                                placeholder="{{column.filter.mask}}"
                                [ngModel]="getFilterValue(column)"
                                (keyup)="onFilterEvent($event, column)"
                            ></p-inputMask>
                        </ng-template>
                    </div>
                </ng-template>

            </th>
        </tr>
        <tr *ngIf="isSummaryActive()">
          <th pResizableColumn></th>
          <th pResizableColumn *ngFor="let column of columns">
            <b
              pTooltip="{{tableSummary[column.key] ? tableSummary[column.key].tooltip : ''}}"
            >
              {{ tableSummary[column.key] ? tableSummary[column.key].value : '' }}
            </b>
          </th>
        </tr>
        <ng-container *ngIf="isStickyRowsActive()">
          <tr *ngFor="let row of tableStickyRows; let rowNumber = index">
            <th pResizableColumn></th>
            <th pResizableColumn *ngFor="let column of columns" style="font-weight:bold"
                [innerHtml]="getStickyColumnValue(rowNumber, column.key)" ></th>
          </tr>
        </ng-container>

    </ng-template>
    <ng-template pTemplate="body" let-entity let-expanded="expanded" let-columns="columns" let-rowIndex="rowIndex" let-rowData>
        <tr
            *ngIf="tableRowGroup && tableRowGroup[getEntityRowGroupKey(entity)] && tableRowGroup[getEntityRowGroupKey(entity)].index === rowIndex"
            [ngClass]="'ui-edit-disabled'"
            class="ui-widget-header"
        >
            <td
               [ngClass]="'ui-resizable-column ui-edit-disabled'"
                colspan="3"
            >
                <span style="font-weight:bold">{{getEntityRowGroupKey(entity)}}</span>
            </td>
        </tr>
        <tr
            [ngClass]="isRowCheckboxChecked(entity) ? 'ui-state-selected' : null"
            [attr.data-entity-id]="entity.id"
            [attr.data-entity-unique-id]="entity.uniqueId"
            [attr.data-row-index]="rowIndex"
            [attr.data-selenium-id]="rowIndex"
            [pSelectableRow]="entity"
            [pSelectableRowIndex]="rowIndex"
            [pReorderableRow]="rowIndex"
            [pSelectableRowDblClick]="entity"
            (dblclick)="onRowDoubleClick($event, entity)"
            (click)="onRowClick($event, entity)"
            (mouseenter)="onMouseEnter(rowData)"
            (mouseleave)="onMouseLeave(rowData)"
            [class.row-hover]="rowData.hover"
        >
            <ng-template ngFor let-column [ngForOf]="columns">
                <td
                    [appTableEditableColumn]="entity"
                    [table]="this"
                    [column]="column"
                    [data]="entity"
                    [style.overflow]="'hidden'"
                    [style.textOverflow]="'ellipsis'"
                    [style.whiteSpace]="'nowrap'"
                    [attr.data-selenium-id]="'column-value-' + column.key + '-' + rowIndex"
                    [ngClass]="isColumnEditDisabled(column) ? 'ui-edit-disabled' : null"
                    class="ui-resizable-column"
                    [ngStyle]="getCellStyle(column, entity)"
                    (click)="onCellClickEvent($event, column, entity)"
                    pTooltip="{{column.renderTooltip && column.renderTooltip(entity) ? column.renderTooltip(entity) : '' }}"
                    tooltipPosition="{{column.tooltipPosition || 'right'}}"
                    [escape]="false"
                    [exitInlineEditOnEnter]="column?.exitInlineEditOnEnter"
                >
                    <ng-template [ngIf]="column.rowExpansion">
                        <span [pRowToggler]="entity" *ngIf="!isColumnRowExpansionIsVisibleDefined(column) || column.rowExpansionIsVisible(entity)">
                            <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
                        </span>
                    </ng-template>

                    <ng-template [ngIf]="column.reordable">
                        <i class="fa fa-bars" pReorderableRowHandle></i>
                    </ng-template>

                    <ng-template [ngIf]="column.selection">
                        <input
                                type="checkbox"
                                class="ui-chkbox"
                                id="{{entity.getId()}}"
                                (change)="onRowCheckboxChecked(entity, $event)"
                                checked="{{ isRowCheckboxChecked(entity) ? 'checked' : '' }}"
                        >
                    </ng-template>

                    <ng-template [ngIf]="column.rowNumber">
                        <span>{{ rowIndex + 1 }}.</span>
                    </ng-template>

                    <ng-template [ngIf]="!column.edit">
                        <ng-template [ngIf]="isColumnRendererDefined(column)">
                            <ng-template [ngIf]="column.renderType === 'link'">
                                <a [innerHTML]="column.renderer(entity)"></a>
                            </ng-template>

                            <ng-template [ngIf]="column.renderType === 'number'">
                                <span [innerHTML]="column.renderer(entity)" pTooltip="{{column.tooltip}}"></span>
                            </ng-template>

                            <ng-template [ngIf]="column.renderType !== 'number' && column.renderType !== 'link'">
                                <span [innerHTML]="column.renderer(entity)"></span>
                            </ng-template>

                        </ng-template>
                        <ng-template [ngIf]="!isColumnRendererDefined(column)">
                            <span [ngSwitch]="column.renderType" >

                                <ng-template [ngSwitchCase]="'medias'">
                                    <div style="white-space: pre-wrap">
                                        <ng-template ngFor let-media [ngForOf]="entity.getMedias()">
                                            <span style="padding-left: 5px;" (click)="column.click(media)"  pTooltip="{{ media.getName() }}">
                                                <i class="fa fa-file-pdf-o"></i>
                                            </span>
                                        </ng-template>
                                    </div>
                                </ng-template>

                                <p-checkbox
                                        *ngSwitchCase="'checkbox'"
                                        [(ngModel)]="entity[column.key]"
                                        binary="true"
                                ></p-checkbox>

                                <ng-template [ngSwitchCase]="'date'" >
                                    {{ entity[column.key] | appDate:column.dateFormat }}
                                </ng-template>

                              <ng-template [ngSwitchCase]="'number'" >
                                    {{ entity[column.key] }}
                                </ng-template>

                                <ng-template [ngSwitchDefault]>
                                    {{entity[column.key] }}
                                </ng-template>
                            </span>
                        </ng-template>
                    </ng-template>
                    <span *ngIf="column.edit">
                    <p-cellEditor>
                        <ng-template pTemplate="input">

                                <span [ngSwitch]="column.edit.type">

                                    <p-dropdown
                                            *ngSwitchCase="'dropdown'"
                                            placeholder="---"
                                            [(ngModel)]="entity[column.key]"
                                            [options]="column.edit.options | async"
                                            (onChange)="onCellEditEvent($event, column, entity)"
                                            appendTo="body"
                                            [style]="{'width': '100%','min-width':'100%'}"
                                            [panelStyle]="{'width':'100px'}"
                                            [disabled]="column.edit.disabled !== undefined && column.edit.disabled(entity)"
                                    ></p-dropdown>

                                    <p-calendar
                                            appTableEditCalendar
                                            [column]="column"
                                            [entity]="entity"
                                            [dateFormat]="column.edit.dateFormat"
                                            [timeOnly]="column.edit.timeOnly || false"
                                            *ngSwitchCase="'calendar'"
                                            [ngModel]="entity[column.key] | stringToDate"
                                            [showIcon]="true"
                                            (valueChange)="onCellEditEvent($event, column, entity)"
                                            appendTo="body"
                                            inputStyleClass="ui-filter-calendar-input"
                                            class="ui-filter-calendar"
                                    ></p-calendar>

                                    <p-spinner
                                            appSpinner
                                            *ngSwitchCase="'number'"
                                            size="30"
                                            [step]="column.edit.step || 1"
                                            [ngModel]="+entityHelper.getValue(entity, column.key)"
                                            (valueChange)="onCellEditEvent($event, column, entity)"
                                            [disabled]="column.edit.disabled !== undefined && column.edit.disabled(entity)"
                                            [max]="column.edit.max"
                                            [min]="column.edit.min"
                                            [formatIfZero]="false"
                                            [changeOnBlur]="true"
                                    ></p-spinner>

                                    <div class="ui-inputgroup"
                                         *ngSwitchCase="'text-button'"
                                    >
                                        <input
                                                pInputText
                                                class="ui-edit-input-text"
                                                type="text"
                                                [disabled]="column.edit.disabled !== undefined && column.edit.disabled(entity)"
                                                [style]="{'width': '100%'}"
                                                (keyup)="onCellEditEvent($event, column, entity)"
                                                [(ngModel)]="entity[column.key]"
                                                (focusout)="onCellFocusOut($event, column, entity)"
                                        >

                                        <button
                                                *ngIf="column.edit.button && column.edit.button && !column.edit.button.options && (column.edit.button.condition === undefined || column.edit.button.condition(entity))"
                                                (click)="onButtonClick($event, entity, column.edit.button)"
                                                pButton
                                                [disabled]="column.edit.disabled !== undefined && column.edit.disabled(entity)"
                                                type="button"
                                                label="{{column.edit.button.text}}"
                                                icon="{{column.edit.button.icon}}"
                                                pTooltip="{{column.edit.button.tooltip | translate}}"
                                                class="{{column.edit.button.class}} btn-save-cell-value"
                                        ></button>
                                    </div>

                                    <ng-template [ngSwitchCase]="'mediGroupCheckbox'">

                                         <p-checkbox
                                             *ngIf="entity.associate?.hasActivePaymentDeductionMediGroupContract"
                                             [(ngModel)]="entity[column.key]"
                                             binary="true"
                                             (onChange)="onCellEditEvent($event, column, entity)"
                                         ></p-checkbox>

                                        <span *ngIf="!entity.associate?.hasActivePaymentDeductionMediGroupContract">
                                            {{ 'HR.ASSOCIATE.HAS_NO_MEDI_GROUP_PAYMENT_DEDUCTION_CONTRACT' | translate }}
                                        </span>
                                    </ng-template>

                                    <p-checkbox
                                            *ngSwitchCase="'checkbox'"
                                            [(ngModel)]="entity[column.key]"
                                            binary="true"
                                            (onChange)="onCellEditEvent($event, column, entity)"
                                    ></p-checkbox>

                                    <app-hour-spinner
                                            *ngSwitchCase="'hoursAndMinutes'"
                                            context="duration"
                                            [value]="entity[column.key]"
                                            (valueChange)="onCellEditEvent($event, column, entity)"
                                            [hideDuration]="true"
                                            [changeOnFocus]="true"
                                            [changeOnBlur]="true"
                                    ></app-hour-spinner>

                                    <p-autoComplete
                                            appAutoComplete
                                            class="form-control"
                                            *ngSwitchCase="'autocomplete'"
                                            size="30"
                                            appendTo="body"
                                            [style]="{'width': '100%'}"
                                            [showClear]="true"
                                            [panelStyle]="{'width':'300px'}"
                                            [(ngModel)]="entity[column.key]"
                                            [field]="column.edit.fieldName"
                                            [handler]="column.edit.autocompleteHandler"
                                            [params]="column.edit.autocompleteHandlerParams"
                                            [dropdown]="true"
                                            [forceSelection]="true"
                                            (onSelect)="onCellEditEvent($event, column, entity)"
                                    ></p-autoComplete>

                                    <p-colorPicker
                                            appTableColorPicker
                                            *ngSwitchCase="'color'"
                                            [(ngModel)]="entity[column.key]"
                                            [inline]="true"
                                            (onChange)="onCellEditEvent($event, column, entity)"
                                    ></p-colorPicker>

                                    <div
                                            *ngSwitchCase="'invoicingItem'"
                                    >
                                        <p-autoComplete
                                                appAutoComplete
                                                class="form-control"
                                                size="30"
                                                appendTo="body"
                                                [style]="{'width': '100%'}"
                                                [panelStyle]="{'width':'300px'}"
                                                [(ngModel)]="entity[column.key]"
                                                [field]="column.edit.fieldName"
                                                [handler]="column.edit.autocompleteHandler"
                                                [dropdown]="true"
                                                [forceSelection]="true"
                                                (onSelect)="onCellEditEvent($event, column, entity)"
                                        ></p-autoComplete>

                                        <span class="md-inputfield">
                                            <textarea
                                                    rows="3"
                                                    cols="30"
                                                    pInputTextarea
                                                    id="name"
                                                    [(ngModel)]="entity[column.key]"
                                                    class="ui-g-12 ui-inputtext ui-corner-all ui-state-default ui-widget"
                                                    (keyup)="onCellEditEvent($event, column, entity)"
                                            ></textarea>
                                        </span>
                                    </div>

                                    <ng-template [ngSwitchDefault]>
                                        <input
                                                pInputText
                                                class="ui-edit-input-text"
                                                type="text"
                                                [style]="{'width': '100%'}"
                                                (keyup)="onCellEditEvent($event, column, entity)"
                                                [(ngModel)]="entity[column.key]"
                                        >
                                    </ng-template>

                                </span>
                        </ng-template>
                        <ng-template pTemplate="output">
                                <ng-template [ngIf]="isColumnRendererDefined(column)">
                                  <span [innerHTML]="column.renderer(entity)"></span>
                                </ng-template>

                                <ng-template [ngIf]="!isColumnRendererDefined(column)">
                                    <span [ngSwitch]="column.renderType"  >

                                        <p-checkbox
                                                *ngSwitchCase="'checkbox'"
                                                [(ngModel)]="entity[column.key]"
                                                binary="true"
                                                (onChange)="onCellEditEvent($event, column, entity)"
                                        ></p-checkbox>

                                        <ng-template [ngSwitchCase]="'mediGroupCheckbox'">
                                            <p-checkbox
                                                    *ngIf="entity.associate?.hasActivePaymentDeductionMediGroupContract"
                                                    [(ngModel)]="entity[column.key]"
                                                    binary="true"
                                                    (onChange)="onCellEditEvent($event, column, entity)"
                                            ></p-checkbox>

                                            <span *ngIf="!entity.associate?.hasActivePaymentDeductionMediGroupContract">
                                                {{ 'HR.ASSOCIATE.HAS_NO_MEDI_GROUP_PAYMENT_DEDUCTION_CONTRACT' | translate }}
                                            </span>
                                        </ng-template>

                                        <ng-template [ngSwitchCase]="'date'">
                                            {{ entity[column.key] | appDate:column.dateFormat }}
                                        </ng-template>

                                        <ng-template [ngSwitchCase]="'color'">
                                            {{ entity[column.key] }}

                                            <p-colorPicker
                                                    appTableColorPicker
                                                    [(ngModel)]="entity[column.key]"
                                            ></p-colorPicker>
                                        </ng-template>

                                        <ng-template [ngSwitchCase]="'number'">
                                            {{ entity[column.key] }}
                                        </ng-template>

                                        <ng-template [ngSwitchDefault]>
                                            {{ entity[column.key] }}
                                        </ng-template>
                                    </span>
                                </ng-template>
                        </ng-template>
                    </p-cellEditor>
                </span>
                    <div *ngIf="column.menu" [style.whiteSpace]="'normal'">
                    <span *ngFor="let icon of column.menu.icons;" style="padding-left: 5px;">
                        <i
                                *ngIf="icon.condition === undefined || icon.condition(entity)"
                                (click)="onButtonClick($event, entity, icon)"
                                pTooltip="{{icon.tooltip | translate}}"
                                class="{{icon.class}}"
                                [attr.data-selenium-id]="icon.seleniumId + '-' + rowIndex ||''"
                        ></i>
                    </span>
                        <span *ngFor="let button of column.menu.buttons;" style="padding-left: 5px;">
                    <button
                            *ngIf="!button.options && (button.condition === undefined || button.condition(entity))"
                            (click)="onButtonClick($event, entity, button)"
                            pButton
                            type="button"
                            label="{{button.text}}"
                            icon="{{button.icon}}"
                            pTooltip="{{button.tooltip | translate}}"
                            class="{{button.class}}"
                            [attr.data-selenium-id]="button.seleniumId + '-' + rowIndex ||''"
                    ></button>

                    <p-splitButton
                            *ngIf="button.options"
                            appSplitButtonAsDropdown
                            appTableSplitButton
                            [button]="button"
                            appendTo="body"
                            (itemClicked)="onSplitButtonItemClick($event, entity)"
                            (onClick)="onSplitButtonClick($event, entity, button)"
                            (onDropdownClick)="onSplitButtonClick($event, entity, button)"
                            [menuStyle]="{'width':'200px'}"
                            [style]="{'width':'100%'}"
                            icon="{{button.icon}}"
                            label="{{button.text}}"
                            [model]="button.options | async"
                            styleClass="ui-button-info"
                    ></p-splitButton>
                  </span>
                    </div>
                </td>
            </ng-template>
        </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr>
            <td
                [attr.colspan]="columns.length"
                [ngStyle]="rowExpansionColumn?.rowExpansion?.ngClass"
            >
                <ng-container
                    *ngIf="expansionRef"
                    [ngTemplateOutlet]="expansionRef"
                    [ngTemplateOutletContext]="{ $implicit: rowData }">
                </ng-container>
            </td>
        </tr>
    </ng-template>

    <ng-template *ngIf="isTableTotalActive()" pTemplate="footer" let-columns>
        <tr>
            <td *ngFor="let column of columns" [style.textAlign]="'right'" >
                <span *ngIf="column.key === tableTotal.labelColumn">
                    <b>{{ 'COMMON.TOTAL' | translate }}:</b>
                </span>
                <span *ngIf="column.key && getTotalColumn(column.key)"
                      >
                    <b *ngIf="getTotalColumn(column.key).type === 'percentage'"
                       pTooltip="{{getTotalColumnValue(column.key) ? (getTotalColumnValue(column.key) | appDecimal) : ''}}">
                        {{ getTotalColumnValue(column.key) | appDecimal}} %
                    </b>
                    <b *ngIf="getTotalColumn(column.key).type === 'currency'"
                       pTooltip="{{getTotalColumnValue(column.key) ? (getTotalColumnValue(column.key) | appDecimal) : ''}}">
                        {{ getTotalColumnValue(column.key) | appDecimal  | appCurrency:'RSD'}}
                    </b>
                    <b *ngIf="getTotalColumn(column.key).type === 'km'"
                       pTooltip="{{getTotalColumnValue(column.key) ? getTotalColumnValue(column.key) : ''}}">
                        {{ getTotalColumnValue(column.key)}} km
                    </b>
                    <b *ngIf="getTotalColumn(column.key).type === 'liter'"
                       pTooltip="{{getTotalColumnValue(column.key) ? getTotalColumnValue(column.key) : ''}}">
                        {{ getTotalColumnValue(column.key)}} L
                    </b>
                </span>
            </td>
        </tr>
    </ng-template>

    <ng-template *ngIf="tableFooter" pTemplate="summary">
        <div class="row">
            <div class="col-lg-12">
                <span *ngFor="let button of tableFooter.buttons">
                    <button
                        pButton
                        *ngIf="button.condition === undefined || button.condition()"
                        (click)="onTableButtonClick($event, button)"
                        [disabled]="button.disabled !== undefined && button.disabled()"
                        type="button"
                        icon="{{button.icon}}"
                        pTooltip="{{button.tooltip | translate}}"
                        class="{{button.class}}"
                    ></button>
                </span>
            </div>
        </div>
    </ng-template>
</p-table>

<!-- use new primeng dynamic dialogs -->
<p-dialog
  header="{{ 'COMMON.EDIT_COLUMNS' | translate }}"
  *ngIf="columnVisibilityDialog"
  [(visible)]="columnVisibilityDialog"
>
  <app-shared-table-column-reorder
    *ngIf="columnVisibilityDialog"
    [tableComponent]="this"
    (columnsSaved)="onColumnsSaved()"
    (columnsSaveCanceled)="onColumnsSaveCanceled()"
    (columnsReset)="onColumnsReset()"
  ></app-shared-table-column-reorder>
</p-dialog>
