import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Absence, AbsenceRegulateTypeEnum} from '../entities/entities';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';

@Injectable({
    providedIn: 'root'
})
export class AbsenceRepository extends EntityRepository {

    protected dateModifier = new DatetimeWithoutTimezonePropertyPayloadModifier();

    public regulateSickLeave(absence: Absence, regulators: {fromDate: Date, toDate: Date, type: AbsenceRegulateTypeEnum}[]): Observable<Absence> {
        for (const regulator of regulators) {
            this.dateModifier.modify('fromDate', regulator);
            this.dateModifier.modify('toDate', regulator);
            this.dateModifier.modify('month', regulator);
        }

        const data = {
            disableVisibleFilter: 1,
            regulators,
            absence: absence['@id']
        };

        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}absences/regulatesickleave`,
            data
        ).pipe(map((res) => {
            return this.parser.getParser().parse(new Absence(), res);
        }));
    }

    public regulate(absence: Absence|any): Observable<Absence> {
        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}absences/${absence.getId()}/regulate`,
            {
                disableVisibleFilter: 1
            }
        ).pipe(map((aAbsence: Absence) => {
            return this.parser.getParser().parse(new Absence(), aAbsence);
        }));
    }

    public delete(absence: Absence|any) {
        return this.connection.delete(
            `${environment.ENTITY_MANAGER_URL_PREFIX}absences/${absence.getId()}`
        );
    }
}
