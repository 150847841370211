import {AutoCompleteDirective} from './autocomplete.directive';
import {Directive} from '@angular/core';

@Directive({
    selector: '[appInvoiceAutoComplete]'
})
export class InvoiceAutocompleteDirective extends AutoCompleteDirective {

    protected onItemsLoaded(): void {
        const autocompletePanel = this.elementRef.nativeElement.querySelector('.ui-autocomplete-panel');
        const autocompletePanelUl = this.elementRef.nativeElement.querySelector('.ui-autocomplete-list');
        const autocompletePanelHeading = this.elementRef.nativeElement.querySelector('.ui-autocomplete-list-heading');
        if (autocompletePanel && !autocompletePanelHeading) {
            const div = this.renderer.createElement('div');
            this.renderer.addClass(div, 'ui-helper-clearfix');
            this.renderer.addClass(div, 'ui-autocomplete-list-heading');

            this.renderer.appendChild(div, this.generateColHeaderElement('Broj', 3));
            this.renderer.appendChild(div, this.generateColHeaderElement('Dokument', 3));
            this.renderer.appendChild(div, this.generateColHeaderElement('Vrednost', 2));
            this.renderer.appendChild(div, this.generateColHeaderElement('Uplaceno', 2));
            this.renderer.appendChild(div, this.generateColHeaderElement('Saldo', 2));

            this.renderer.insertBefore(autocompletePanel, div, autocompletePanelUl);
        }
    }

    private generateColHeaderElement(text: string, colWidth: number, additionalClass: string = ''): any {
        const divCol = this.renderer.createElement('div');
        this.renderer.addClass(divCol, 'ui-g');
        this.renderer.addClass(divCol, 'ui-g-' + colWidth);
        if (additionalClass) {
            this.renderer.addClass(divCol, additionalClass);
        }
        this.renderer.appendChild(divCol, this.renderer.createText(text));

        return divCol;
    }
}
