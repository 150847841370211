import {Route} from 'entity-manager';
import {
    Associate,
    AssociateMonthlyCalculation,
    Employee,
    EmployeeMonthlyPayment,
    PaymentDeductionContract,
    PaymentDeductionType
} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {PaymentDeductionRepository} from '../repositories/payment-deduction.repository';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {StringPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier';

@Route('payment_deductions')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        associate: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Associate
        },
        contract: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PaymentDeductionContract
        },
        associateMonthlyCalculation: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: AssociateMonthlyCalculation
        },
        previousPaymentDeduction: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PaymentDeduction
        },
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PaymentDeductionType
        },
        employeeMonthlyPayment: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeMonthlyPayment
        }
    };
})
@EntityDefaultFilters({
    'exists[employee.employment.dismissalType]': false
})
@Repository(PaymentDeductionRepository)
export class PaymentDeduction {

    protected id: string;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(Associate)
    protected associate: Associate;

    @AssociationOne(PaymentDeductionType)
    protected type: PaymentDeductionType;

    @AssociationOne(PaymentDeductionContract)
    protected contract: PaymentDeductionContract;

    @AssociationOne(AssociateMonthlyCalculation)
    protected associateMonthlyCalculation: AssociateMonthlyCalculation;

    @AssociationOne(EmployeeMonthlyPayment)
    protected employeeMonthlyPayment: EmployeeMonthlyPayment;

    protected entryMonth: number;
    protected entryYear: number;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected instalment: string;
    protected instalmentCount: number;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected amount: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected eligibleAmount: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected deduction: string;

    @AssociationOne(PaymentDeduction)
    protected previousPaymentDeduction: PaymentDeduction;

    protected isRegulated: boolean;


    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setEmployee(employee: Employee) {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public setAssociate(associate: Associate) {
        this.associate = associate;
        return this;
    }

    public getAssociate(): Associate|undefined {
        return this.associate;
    }

    public setType(type: PaymentDeductionType) {
        this.type = type;
        return this;
    }

    public getType(): PaymentDeductionType|undefined {
        return this.type;
    }

    public setContract(contract: PaymentDeductionContract) {
        this.contract = contract;
        return this;
    }

    public getContract(): PaymentDeductionContract|undefined {
        return this.contract;
    }

    public setAssociateMonthlyCalculation(associateMonthlyCalculation: AssociateMonthlyCalculation) {
        this.associateMonthlyCalculation = associateMonthlyCalculation;
        return this;
    }

    public getAssociateMonthlyCalculation(): AssociateMonthlyCalculation|undefined {
        return this.associateMonthlyCalculation;
    }

    public setEmployeeMonthlyPayment(employeeMonthlyPayment: EmployeeMonthlyPayment) {
        this.employeeMonthlyPayment = employeeMonthlyPayment;
        return this;
    }

    public getEmployeeMonthlyPayment(): EmployeeMonthlyPayment|undefined {
        return this.employeeMonthlyPayment;
    }

    public setEntryMonth(entryMonth: number) {
        this.entryMonth = entryMonth;
        return this;
    }

    public getEntryMonth(): number|undefined {
        return this.entryMonth;
    }

    public setEntryYear(entryYear: number) {
        this.entryYear = entryYear;
        return this;
    }

    public getEntryYear(): number|undefined {
        return this.entryYear;
    }

    public setInstalment(instalment: string) {
        this.instalment = instalment;
        return this;
    }

    public getInstalment(): string|undefined {
        return this.instalment;
    }

    public setInstalmentCount(instalmentCount: number) {
        this.instalmentCount = instalmentCount;
        return this;
    }

    public getInstalmentCount(): number|undefined {
        return this.instalmentCount;
    }

    public setAmount(amount: string) {
        this.amount = amount;
        return this;
    }

    public getAmount(): string|undefined {
        return this.amount;
    }

    public setPreviousPaymentDeduction(previousPaymentDeduction: PaymentDeduction) {
        this.previousPaymentDeduction = previousPaymentDeduction;
        return this;
    }

    public getPreviousPaymentDeduction(): PaymentDeduction|undefined {
        return this.previousPaymentDeduction;
    }

    public setEligibleAmount(eligibleAmount: string) {
        this.eligibleAmount = eligibleAmount;
        return this;
    }

    public getEligibleAmount(): string|undefined {
        return this.eligibleAmount;
    }

    public setDeduction(deduction: string) {
        this.deduction = deduction;
        return this;
    }

    public getDeduction(): string|any|undefined {
        return this.deduction;
    }

    public getIsRegulated(): boolean|undefined {
        return this.isRegulated;
    }

    public setIsRegulated(isRegulated: boolean): this {
        this.isRegulated = isRegulated;
        return this;
    }
}
