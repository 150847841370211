import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {EmployeeRequest} from './entities';

export enum EmployeeRequestStateEnum {
    Approved = 'approved',
    Declined = 'declined',
    PendingApproval = 'pendingApproval'
}

@Route('employee_request_states')
@CrossDependency(() => {
    return {
        employeeRequest: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeRequest
        }
    };
})
export class EmployeeRequestState {

    protected id: string;
    protected name: string;
    protected code: string;
    protected description: string;

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }
}
