import {Route} from 'entity-manager';

export enum EmploymentTypeEnum {
    Permanent = 'permanent',
    Temporary = 'temporary',
    Cooperation = 'cooperation'
}

@Route('employment_types')
export class EmploymentType {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;

    public getId(): string {
        return this.id;
    }

    public setId(id: string): EmploymentType {
        this.id = id;
        return this;
    }

    public getCode(): string {
        return this.code;
    }


    public setCode(code: string): EmploymentType {
        this.code = code;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): EmploymentType {
        this.name = name;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string): EmploymentType {
        this.description = description;
        return this;
    }
}
