import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {EmployeeRequest} from '../entities/entities';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EmployeeRequestRepository extends EntityRepository {

    public approve(employeeRequest: EmployeeRequest): Observable<EmployeeRequest|null> {
        const route = this.meta.getMetaDataProperty(new EmployeeRequest(), Meta.META_ROUTE);

        return this.connection.post(
            environment.ENTITY_MANAGER_URL_PREFIX + route + `/${employeeRequest.getId()}/approve`,
            {}
        ).pipe(map((aEmployeeRequest: any) => {
            return this.parser.getParser().parse(new EmployeeRequest(), aEmployeeRequest);
        }));
    }

    public decline(employeeRequest: EmployeeRequest): Observable<EmployeeRequest|null> {
        const route = this.meta.getMetaDataProperty(new EmployeeRequest(), Meta.META_ROUTE);

        return this.connection.post(
            environment.ENTITY_MANAGER_URL_PREFIX + route + `/${employeeRequest.getId()}/decline`,
            {
                noticeForRejectingRequest: employeeRequest.getNoticeForRejectingRequest()
            }
        ).pipe(map((aEmployeeRequest: any) => {
            return this.parser.getParser().parse(new EmployeeRequest(), aEmployeeRequest);
        }));
    }

    public delete(employeeRequest: EmployeeRequest): Observable<EmployeeRequest|null> {
        const route = this.meta.getMetaDataProperty(new EmployeeRequest(), Meta.META_ROUTE);

        return this.connection.delete(
            environment.ENTITY_MANAGER_URL_PREFIX + route + `/${employeeRequest.getId()}`,
        ).pipe(map((aEmployeeRequest: any) => {
            return this.parser.getParser().parse(new EmployeeRequest(), aEmployeeRequest);
        }));
    }
}
