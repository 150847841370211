import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {LoginComponent} from './components/common/login/login.component';
import {UnauthorizedComponent} from './shared/components/unauthorized/unauthorized.component';
import {LogoutComponent} from './components/common/logout/logout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'crm',
        loadChildren: () => import('./crm/crm.module').then(m => m.CrmModule)
    },
    {
        path: 'scm',
        loadChildren: () => import('./scm/scm.module').then(m => m.ScmModule)
    },
    {
        path: 'sourcing',
        loadChildren: () => import('./sourcing/sourcing.module').then(m => m.SourcingModule)
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'hr',
        loadChildren: () => import('./hr/hr.module').then(m => m.HrModule)
    },
    {
        path: 'messages',
        loadChildren: () => import('./message/message.module').then(m => m.MessageModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
