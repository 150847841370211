import {Route} from '../../../../projects/entity-manager/src/lib/decorator/route';

@Route('software_accesses')
export class SoftwareAccess {

    protected id: string;

    protected name: string;

    protected username = '';

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): SoftwareAccess {
        this.id = id;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): SoftwareAccess {
        this.name = name;
        return this;
    }

    public getUsername(): string {
        return this.username;
    }

    public setUsername(username: string): SoftwareAccess {
        this.username = username;
        return this;
    }
}
