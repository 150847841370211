import {Route} from 'entity-manager';
import {Employee} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

@Route('health_statuses')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class HealthStatus {

    protected id: string;
    protected isDisabled: boolean;
    private healthAbility: string;

    @AssociationOne(Employee)
    protected employee: Employee;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setIsDisabled(isDisabled: boolean) {
        this.isDisabled = isDisabled;
        return this;
    }

    public getIsDisabled(): boolean|undefined {
        return this.isDisabled;
    }

    public setEmployee(employee: Employee) {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public getHealthAbility(): string {
        return this.healthAbility;
    }

    public setHealthAbility(healthAbility: string) {
        this.healthAbility = healthAbility;
    }
}
