import {Route} from 'entity-manager';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {CompanyRepository} from '../repositories/company.repository';

@Route('companies')
@Repository(CompanyRepository)
export class Company {

    protected id: string;
    protected name: string;
    protected name2: string;
    protected pib: string;
    protected identificationNumber: string;
    protected postalCode: string;
    protected city: string;
    protected address: string;
    protected phoneNumber: string;
    protected faxNumber: string;
    protected bankAccount: string;
    protected contactInfo: string;
    protected contactMemo: string;
    protected comment: string;
    protected code: string;
    protected companyEmail: string;
    protected municipality: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setName(name: string): Company {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public getName2(): string {
        return this.name2;
    }

    public setName2(name2: string): Company {
        this.name2 = name2;
        return this;
    }

    public getPib(): string {
        return this.pib;
    }

    public setPib(pib: string): Company {
        this.pib = pib;
        return this;
    }

    public getIdentificationNumber(): string {
        return this.identificationNumber;
    }

    public setIdentificationNumber(identificationNumber: string): Company {
        this.identificationNumber = identificationNumber;
        return this;
    }

    public getPostalCode(): string {
        return this.postalCode;
    }

    public setPostalCode(postalCode: string): Company {
        this.postalCode = postalCode;
        return this;
    }

    public getCity(): string {
        return this.city;
    }

    public setCity(city: string): Company {
        this.city = city;
        return this;
    }

    public getAddress(): string {
        return this.address;
    }

    public setAddress(address: string): Company {
        this.address = address;
        return this;
    }

    public getPhoneNumber(): string {
        return this.phoneNumber;
    }

    public setPhoneNumber(phoneNumber: string): Company {
        this.phoneNumber = phoneNumber;
        return this;
    }

    public getFaxNumber(): string {
        return this.faxNumber;
    }

    public setFaxNumber(faxNumber: string): Company {
        this.faxNumber = faxNumber;
        return this;
    }

    public getBankAccount(): string {
        return this.bankAccount;
    }

    public setBankAccount(bankAccount: string): Company {
        this.bankAccount = bankAccount;
        return this;
    }

    public getContactInfo(): string {
        return this.contactInfo;
    }

    public setContactInfo(contactInfo: string): Company {
        this.contactInfo = contactInfo;
        return this;
    }

    public getContactMemo(): string {
        return this.contactMemo;
    }

    public setContactMemo(contactMemo: string): Company {
        this.contactMemo = contactMemo;
        return this;
    }

    public getComment(): string {
        return this.comment;
    }

    public setComment(comment: string): Company {
        this.comment = comment;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setCode(code: string): Company {
        this.code = code;
        return this;
    }

    public setCompanyEmail(companyEmail: string): Company {
        this.companyEmail = companyEmail;
        return this;
    }

    public getCompanyEmail(): string {
        return this.companyEmail;
    }

    public setMunicipality(municipality: string): Company {
        this.municipality = municipality;
        return this;
    }

    public getMunicipality(): string {
        return this.municipality;
    }
}
