import {Route} from 'entity-manager';
import {Associate, Bank, Employee} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';

@Route('bank_accounts')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        associate: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Associate
        },
        bank: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Bank
        }
    };
})
export class BankAccount {
    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;
    protected accountNumber: string;
    protected iban: string;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(Associate)
    protected associate: Associate;

    @AssociationOne(Bank)
    protected bank: Bank;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public getAccountNumber(): string {
        return this.accountNumber;
    }

    public setAccountNumber(accountNumber: string) {
        this.accountNumber = accountNumber;
        return this;
    }

    public getIban(): string {
        return this.iban;
    }

    public setIban(iban: string) {
        this.iban = iban;
        return this;
    }

    public getEmployee(): Employee {
        return this.employee;
    }

    public setEmployee(employee: Employee) {
        this.employee = employee;
        return this;
    }

    public getAssociate(): Associate {
        return this.associate;
    }

    public setAssociate(associate: Associate) {
        this.associate = associate;
        return this;
    }

    public getBank(): Bank {
        return this.bank;
    }

    public setBank(bank: Bank) {
        this.bank = bank;
        return this;
    }
}
