import {Directive, Self, AfterViewInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {ColorPicker} from 'primeng';
import {of, Subject} from 'rxjs';
import {delay, takeUntil} from 'rxjs/operators';

@Directive({
    selector: '[appTableColorPicker]'
})
export class TableColorPickerDirective implements AfterViewInit, OnDestroy {

    public unsubscribe$ = new Subject<void>();
    private readonly colorPicker: ColorPicker = null;

    public constructor(@Self() colorPicker: ColorPicker, public cdr: ChangeDetectorRef) {
        this.colorPicker = colorPicker;

        if (!(this.colorPicker instanceof ColorPicker)) {
            console.error('Table works only with p-colorPicker component!');
        }
    }

    public ngAfterViewInit(): void {
        of(true).pipe(
            delay(10)
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
            this.cdr.detectChanges();
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
