import {Route} from 'entity-manager';

export enum InvoiceTypeEnum {
    Advance = 'advance',
    Incoming = 'incoming',
    Outgoing = 'outgoing',
    ForeignIncoming = 'foreignIncoming',
    DomesticDependentCost = 'domesticDependentCost',
    ForeignDependentCost = 'foreignDependentCost'
}

export enum InvoiceContext {
    Incoming = 'incoming',
    Outgoing = 'outgoing'
}

@Route('invoice_types')
export class InvoiceType {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }
}
