import { AbstractActionHandler } from './abstract-action-handler';

export class BoxRemovedActionHandler extends AbstractActionHandler {

    public handleAction() {}

    public getPayload() {
        return this.getParameterValue('dashboardBox');
    }
}
