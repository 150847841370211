<span class="ui-range-calendar ui-g ui-g-8 ui-filter-calendar-container">
    <span class="ui-calendar ui-calendar-w-btn">
        <label>
            <input
                (click)="showOverlay($event)"
                (focus)="onInputFocus($event)"
                #input
                type="text"
                class="ui-filter-calendar-input ui-inputtext ui-widget ui-state-default ui-corner-all"
                [ngModel]="inputValue"
                (keyup)="onInputKeyUp($event)"
            >
        </label>

        <button
            (click)="toggleOverlay($event)"
            type="button"
            class="ui-datepicker-trigger ui-calendar-button ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only"
        >
            <span aria-hidden="true" class="ui-button-icon-left ui-clickable pi pi-calendar"></span>
            <span class="ui-button-text ui-clickable">ui-btn</span>
        </button>
    </span>
</span>

<p-overlayPanel
    #overlayPanel
    appendTo="body"
    class="ui-range-calendar"
    styleClass="calendar-overlay"
    [dismissable]="false"
    (onHide)="toggleFocus(false)"
    (onShow)="toggleFocus(true)">
    <div #calendarOverlayContainer id="calendar-overlay-container">
        <p-calendar
                *ngIf="filter || advancedFilter"
                appCalendar
                #calendar
                [appendTo]="calendarOverlayContainer"
                [inline]="true"
                selectionMode="range"
                name="{{ (!advancedFilter ? filter.column.key : advancedFilterOptions.key) }}"
                showButtonBar="true"
                showOtherMonths="true"
                [dateFormat]="(!advancedFilter ? filter.column.filter.dateFormat : advancedFilterOptions.dateFormat)"
                [ngModel]="(!advancedFilter ? filter.getFilterValue() : advancedFilter.getFilterValue())"
                (valueChange)="onFilterChange(calendar.value)"
                (onSelect)="onFilterChange(calendar.value)"
                (onTodayClick)="onFilterChange('today')"
                (onClearClick)="onFilterChange('clear')"
                class="ui-range-calendar"
        ></p-calendar>

        <div class="calendar-additional-buttons">
            <div class="ui-datepicker-buttonbar ui-widget-header" *ngIf="filter|| advancedFilter">
                <div class="ui-g">
                    <div class="ui-g-6">
                        <button
                                class="ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
                                (click)="onFilterChange('thisWeek')"
                                style="width: 100%"
                        >
                            <span class="ui-button-text ui-clickable">{{ 'CALENDAR.BUTTON.THIS_WEEK' | translate }}</span>
                        </button>
                    </div>
                    <div class="ui-g-6">
                        <button
                                class="ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
                                (click)="onFilterChange('thisMonth')"
                                style="width: 98%; margin-left: 5px"
                        >
                            <span class="ui-button-text ui-clickable">{{ 'CALENDAR.BUTTON.THIS_MONTH' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="ui-datepicker-buttonbar ui-widget-header" *ngIf="filter|| advancedFilter">
                <div class="ui-g">
                    <div class="ui-g-6">
                        <button
                                class="ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
                                (click)="onFilterChange('lastWeek')"
                                style="width: 100%"
                        >
                            <span class="ui-button-text ui-clickable">{{ 'CALENDAR.BUTTON.LAST_WEEK' | translate }}</span>
                        </button>
                    </div>
                    <div class="ui-g-6">
                        <button
                                class="ui-button-secondary ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
                                (click)="onFilterChange('lastMonth')"
                                style="width: 98%; margin-left: 5px"
                        >
                            <span class="ui-button-text ui-clickable">{{ 'CALENDAR.BUTTON.LAST_MONTH' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-overlayPanel>
