import { Component } from '@angular/core';
import { DashboardBoxWrapperComponent } from '../dashboard-box-wrapper/dashboard-box-wrapper.component';

@Component({
    selector: 'app-dashboard-box-none-wrapper',
    templateUrl: './dashboard-box-none-wrapper.component.html',
    styleUrls: ['./dashboard-box-none-wrapper.component.scss']
})
export class DashboardBoxNoneWrapperComponent extends DashboardBoxWrapperComponent {

}
