import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {AppProfileComponent} from './profile/app.profile.component';
import {AppMenuComponent, AppSubMenuComponent} from './menu/app.menu.component';
import {AppBreadcrumbComponent} from './breadcrumb/app.breadcrumb.component';
import {AppTopBarComponent} from './topbar/app.topbar.component';
import {AppFooterComponent} from './footer/app.footer.component';
import {BreadcrumbService} from './breadcrumb/breadcrumb.service';
import {AppMainNavigationComponent} from './navigation/app-main-navigation.component';
import {AppAdminMenuComponent, AppAdminSubMenuComponent} from './menu/app.admin-menu.component';
import {AppNotificationComponent} from './notification/app.notification.component';
import {FileModule} from '../file/file.module';

@NgModule({
  declarations: [
    AppMenuComponent,
    AppAdminMenuComponent,
    AppSubMenuComponent,
    AppAdminSubMenuComponent,
    AppBreadcrumbComponent,
    AppTopBarComponent,
    AppNotificationComponent,
    AppFooterComponent,
    AppProfileComponent,
    AppMainNavigationComponent
  ],
  exports: [
    AppMenuComponent,
    AppSubMenuComponent,
    AppBreadcrumbComponent,
    AppTopBarComponent,
    AppNotificationComponent,
    AppFooterComponent,
    AppProfileComponent,
    AppMainNavigationComponent
  ],
  imports: [
    SharedModule,
    FileModule
  ],
  providers: [
    BreadcrumbService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainModule { }
