import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Employee, Vehicle} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {VehicleServiceType} from './vehicle-service-type';
import {DecimalPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';

@Route('vehicle_services')
@CrossDependency(() => {
    return {
        vehicle: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vehicle
        },
        vehicleServiceType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: VehicleServiceType
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class VehicleService {
    @AssociationOne(Vehicle)
    protected vehicle: Vehicle;

    @AssociationOne(VehicleServiceType)
    protected vehicleServiceType: VehicleServiceType;

    @AssociationOne(Employee)
    protected employee: Employee;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected vehicleServiceDate: Date;

    protected id: string;
    protected vehicleMileage: string;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier, {
        decimalPlaces: 4
    })
    protected amountOfService: string;
    protected serviceDescription: string;

    public setVehicle(vehicle: Vehicle): this {
        this.vehicle = vehicle;
        return this;
    }

    public getVehicle(): Vehicle|undefined {
        return this.vehicle;
    }

    public setVehicleServiceType(vehicleServiceType: VehicleServiceType): this {
        this.vehicleServiceType = vehicleServiceType;
        return this;
    }

    public getVehicleServiceType(): VehicleServiceType|undefined {
        return this.vehicleServiceType;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public setVehicleServiceDate(vehicleServiceDate: Date) {
        this.vehicleServiceDate = vehicleServiceDate;
        return this;
    }

    public getVehicleServiceDate() {
        return this.vehicleServiceDate;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setVehicleMileage(vehicleMileage: string): this {
        this.vehicleMileage = vehicleMileage;
        return this;
    }

    public getVehicleMileage(): string {
        return this.vehicleMileage;
    }

    public setAmountOfService(amountOfService: string): this {
        this.amountOfService = amountOfService;
        return this;
    }

    public getAmountOfService(): string {
        return this.amountOfService;
    }

    public setServiceDescription(serviceDescription: string): this {
        this.serviceDescription = serviceDescription;
        return this;
    }

    public getServiceDescription(): string {
        return this.serviceDescription;
    }
}
