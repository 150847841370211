import {TranslateService} from '@ngx-translate/core';
import {Component, AfterViewInit, ViewChild, OnInit, NgZone, ElementRef, ChangeDetectorRef} from '@angular/core';
import { ScrollPanel} from 'primeng';
import {AuthenticationService} from './core/services/authentication.service';
import {Router} from '@angular/router';
import {BootstrapService} from './core/services/bootstrap.service';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  public isAppLoaded = false;
  public isLoggedIn = true;
  public isMainNavigationVisible = true;
  public layoutMode = 'horizontal';
  public menuMode = 'light';
  public profileMode = 'inline';
  public topbarMenuActive: boolean;
  public notificationMenuActive: boolean;
  public overlayMenuActive: boolean;
  public staticMenuDesktopInactive: boolean;
  public staticMenuMobileActive: boolean;
  public menuClick: boolean;
  public topbarItemClick: boolean;
  public activeTopbarItem: any;
  public resetMenu: boolean;
  public menuHoverActive: boolean;
  public rightPanelActive: boolean;
  public rightPanelClick: boolean;
  public megaMenuActive: boolean;
  public megaMenuClick: boolean;
  public usermenuActive: boolean;
  public usermenuClick: boolean;
  public activeProfileItem: any;

  @ViewChild('layoutMenuScroller', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;
  @ViewChild('layoutMainContent', {static: true}) layoutMainContent: ElementRef;

  public constructor(
      public zone: NgZone,
      protected authentication: AuthenticationService,
      protected translate: TranslateService,
      protected router: Router,
      protected bootstrap: BootstrapService,
      protected cdr: ChangeDetectorRef
  ) {
    this.translate.setDefaultLang('rs');
    this.translate.use('rs');

    this.isLoggedIn = this.authentication.isLoggedIn();
  }

  public ngOnInit() {
    this.router.events.subscribe(() => {
      this.isLoggedIn = this.authentication.isLoggedIn();
    });

    this.bootstrap.bootstrap(this)
      .pipe(
          catchError((error) => {
            this.isAppLoaded = true;
            return error;
          })
      )
      .subscribe(() => {
        this.isAppLoaded = true;
      });

    this.authentication.loggedOut.subscribe(() => {
      this.isLoggedIn = false;
      this.isAppLoaded = true;
      this.cdr.detectChanges();
    });
  }

  public ngAfterViewInit() {

    setTimeout(() => {
      if (this.layoutMenuScrollerViewChild) {
        this.layoutMenuScrollerViewChild.moveBar();
      }
    }, 100);
  }

  public onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
    }

    if (!this.rightPanelClick) {
      this.rightPanelActive = false;
    }

    if (!this.megaMenuClick) {
      this.megaMenuActive = false;
    }

    if (!this.usermenuClick && this.isSlim()) {
      this.usermenuActive = false;
      this.activeProfileItem = null;
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.resetMenu = true;
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
    }

    this.topbarItemClick = false;
    this.menuClick = false;
    this.rightPanelClick = false;
    this.megaMenuClick = false;
    this.usermenuClick = false;
  }

  public onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarMenuActive = false;

    if (this.layoutMode === 'overlay') {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }
    }

    event.preventDefault();
  }

  public onMenuClick($event) {
    this.menuClick = true;
    this.resetMenu = false;
  }

  public onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;
    this.notificationMenuActive = false;
    this.hideOverlayMenu();

    event.preventDefault();
  }

  public onNotificationButtonClick(event) {
    this.notificationMenuActive = !this.notificationMenuActive;
    this.topbarMenuActive = false;
  }

  public hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  public isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  public isDesktop() {
    return window.innerWidth > 1024;
  }

  public isMobile() {
    return window.innerWidth <= 640;
  }

  public isHorizontal() {
    return this.layoutMode === 'horizontal';
  }

  public isSlim() {
    return this.layoutMode === 'slim';
  }

  public isOverlay() {
    return this.layoutMode === 'overlay';
  }
}
