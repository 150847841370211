import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';

@Route('employee_professional_qualifications')
@CrossDependency( () => {
    return {};
})
export class EmployeeProfessionalQualification {

    protected id: string;
    protected name: string;
    protected code: string;
    protected description: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setName(name: string): this {
        this.name = name;
        return this;
    }

    public getName(): string | undefined {
        return this.name;
    }

    public setCode(code: string): this {
        this.code = code;
        return this;
    }

    public getCode(): string | undefined {
        return this.code;
    }

    public setDescription(description: string): this {
        this.description = description;
        return this;
    }

    public getDescription(): string | undefined {
        return this.description;
    }
}
