import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import {Modifier} from 'entity-manager';
import {EntityManagerParserService} from '../../../../projects/entity-manager/src/lib/service/parser/entity-manager-parser.service';
import {EntityManagerMetaDataService} from '../../../../projects/entity-manager/src/lib/service/meta/entity-manager-meta-data.service';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Http} from '../../../../projects/entity-manager/src/lib/helper/http';

@Injectable({
    providedIn: 'root'
})
export class EntityDefaultFiltersModifier implements Modifier {

    public constructor(
        protected parser: EntityManagerParserService,
        protected meta: EntityManagerMetaDataService
    ) {

    }

    public modifyRequest(entity: any, request: HttpRequest<any>): any {
        const hasDefaultFilters = this.meta.hasMetaDataProperty(entity, Meta.META_ENTITY_DEFAULT_FILTERS),
            defaultFilters = hasDefaultFilters ? this.meta.getMetaDataProperty(entity, Meta.META_ENTITY_DEFAULT_FILTERS) : {},
            defaultFiltersKeys = Object.keys(defaultFilters);

        if (hasDefaultFilters && defaultFiltersKeys.length > 0) {
            let params = request.params;

            for (const key of defaultFiltersKeys) {
                if (defaultFilters.hasOwnProperty(key) && !params.has(key)) {
                    params = params.set(key, defaultFilters[key]);
                }
            }

            request = request.clone({params});
        }

        return request;
    }

    public modifyResponse(entity: any, request: HttpRequest<any>, responseBody: any, caller: string): any {
        return responseBody;
    }
}
