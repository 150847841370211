import {ChangeDetectorRef, Component} from '@angular/core';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {MessageService} from 'primeng/api';
import {MediaObject} from '../../core/entities/media-object';
import {MediaObjectRepository} from '../../core/repositories/media-object.repository';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AbstractUploadButtonComponent} from '../abstract-upload-button.component';

@Component({
    selector: 'app-single-upload-button',
    templateUrl: './single-upload-button.component.html',
    styleUrls: ['./single-upload-button.component.scss']
})
export class SingleUploadButtonComponent extends AbstractUploadButtonComponent {

    public mediaObject: MediaObject = null;

    public constructor(
        protected entityManager: EntityManagerService,
        protected message: MessageService,
        protected translate: TranslateService,
        protected cdr: ChangeDetectorRef
    ) {
        super(entityManager, message, translate, cdr);
    }

    public onSelect(event) {
        this.selectedFiles = [event.files[0]];

        if (this.autoUpload) {
            this.upload().subscribe();
        }
    }

    public isDirty(): boolean {
        return this.selectedFiles.length > 0;
    }

    public onRemove(event): void {
        this.selectedFiles = [];
    }

    public loadMedias(): Observable<any> {
        const params = {
            mediaObjectClassName: this.mediaObjectClassName,
            mediaObjectOwnerId: this.mediaEntity.getId(),
            mediaObjectContext: this.mediaObjectContext
        };

        const repo: MediaObjectRepository = this.entityManager.getRepository(MediaObject);

        return repo.findOne(params)
            .pipe(
                map((mediaObject: MediaObject) => {
                    this.mediaObject = mediaObject;
                    this.cdr.detectChanges();
                    return mediaObject;
                })
            );
    }

    public onItemRemove(): void {
        const repo: MediaObjectRepository = this.entityManager.getRepository(MediaObject);

        repo.remove(this.mediaObject, this.mediaObjectClassName).subscribe(() => {
            this.loadMedias().subscribe();
        });

        event.preventDefault();
        event.stopPropagation();
    }
}
