<div class="wrapper-box-container">
    <div class="pull-right">
        <button *ngIf="null !== getDashboardBox() && null !== getDashboardBox().getEditComponent() && !isInEditMode" (click)="onEditOpen()" class='btn btn-sm'>
            <div><i class="fa fa-pencil"></i></div>
        </button>
        <span *ngIf="!isInEditMode" (click)="onRemove()" class="close">&times;</span>
        <button *ngIf="isInEditMode" (click)="onEditFinish()" class='btn btn-info btn-sm'>
            <div><i class="fa fa-save"></i></div>
        </button>
        <button *ngIf="isInEditMode" (click)="onEditCancel()" class='btn btn-sm'>
            <div><i class="fa fa-rotate-left"></i></div>
        </button>
    </div>

    <div [hidden]="isInEditMode || isInLoadMode || errorMessage" class="card-block box-container">
        <!-- BoxComponent -->
        <ng-template #boxContainer></ng-template>
    </div>

    <div [hidden]="!isInEditMode || isInLoadMode" class="card-block edit-box-container">
        <!-- BoxEditComponent -->
        <ng-template #editBoxContainer></ng-template>
    </div>

    <div [hidden]="!errorMessage || isInLoadMode || isInEditMode" class="alert alert-danger box-error-container" role="alert">
        <strong>Error!</strong> {{errorMessage}}
    </div>

    <div [hidden]="!isInLoadMode" class="loader"></div>
</div>
