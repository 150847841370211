import {Route} from 'entity-manager';

export const UNNECESSARY_ARGUMENTS = [

];

@Route('command_arguments')
export class CommandArgument {

    protected name: string;
    protected description: string;
    protected value: string;

    public getName() {
        return this.name;
    }

    public setName(name: string): this {
        this.name = name;
        return this;
    }

    public getDescription() {
        return this.description;
    }

    public setDescription(description: string): this {
        this.description = description;
        return this;
    }

    public getValue() {
        return this.value;
    }

    public  setValue(value: string): this {
        this.value = value;
        return this;
    }
}
