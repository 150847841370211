import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {User} from './user';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {Employee} from "./entities";
import {Repository} from "../../../../projects/entity-manager/src/lib/decorator/repository";
import {AbsenceRepository} from "../repositories/absence.repository";
import {MessageRepository} from "../repositories/message.repository";

@Route('messages')
@CrossDependency(() => {
    return {
        fromUser: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        },
        toUser: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        },
        fromEmployee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
@Repository(MessageRepository)
export class Message {

    protected id: string;
    protected fromUser: User;

    @AssociationOne(User)
    protected toUser: User;

    @AssociationOne(Employee)
    protected fromEmployee: Employee;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected readAt: Date;
    protected isRead: boolean;
    protected subject: string;
    protected body: string;
    protected weight: number;
    protected createdAt: Date;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setFromUser(fromUser: User) {
        this.fromUser = fromUser;
        return this;
    }

    public getFromUser(): User {
        return this.fromUser;
    }

    public setFromEmployee(fromEmployee: Employee) {
        this.fromEmployee = fromEmployee;
        return this;
    }

    public getFromEmployee(): Employee {
        return this.fromEmployee;
    }

    public setToUser(toUser: User) {
        this.toUser = toUser;
        return this;
    }

    public getToUser(): User {
        return this.toUser;
    }

    public setReadAt(readAt: Date) {
        this.readAt = readAt;
        return this;
    }

    public getReadAt(): Date {
        return this.readAt;
    }

    public setIsRead(isRead: boolean) {
        this.isRead = isRead;
        return this;
    }

    public getIsRead(): boolean {
        return this.isRead;
    }

    public setSubject(subject: string) {
        this.subject = subject;
        return this;
    }

    public getSubject(): string {
        return this.subject;
    }

    public setBody(body: string) {
        this.body = body;
        return this;
    }

    public getBody(): string {
        return this.body;
    }

    public setWeight(weight: number) {
        this.weight = weight;
        return this;
    }

    public getWeight(): number {
        return this.weight;
    }

    public setCreatedAt(createdAt: Date) {
        this.createdAt = createdAt;
        return this;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }
}
