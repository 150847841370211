import {InjectorAwareInterface} from '../../../workflow/services/injector-aware.interface';
import {WorkflowAction} from '../../../workflow/services/workflow-action';
import {WorkflowActionInterface} from '../../../workflow/services/workflow-action.interface';
import {WorkflowActionStatus} from '../../../workflow/services/workflow-action-status';
import {Observable} from 'rxjs';
import {ThemeService} from '../../services/theme/theme.service';
import {map} from 'rxjs/operators';

export class BootstrapThemeAction extends WorkflowAction implements WorkflowActionInterface, InjectorAwareInterface {

    public run(): Observable<WorkflowActionStatus> {

        return this.injector.get(ThemeService).compileFromStorage()
            .pipe(
                map(() => {
                    return {
                        success: true,
                        stop: false,
                        status: ''
                    };
                })
            );
    }
}
