import {AbstractAdvanceFilter} from './abstract-advance-filter';
import * as moment from 'moment';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../../../../core/modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';

export class CalendarRangeAdvanceFilter extends AbstractAdvanceFilter {

    protected dateModifier = new DatetimeWithoutTimezonePropertyPayloadModifier();

    public getValueFromChange(event, param) {
        if (event === 'clear') {
            event = null;
        } else if (event === 'today') {
            const after = moment()
                .startOf('day')
                .toDate();
            const before = moment()
                .endOf('day')
                .toDate();

            event = [after, before];
        } else if (event === 'thisWeek') {
            const after = moment()
                .startOf('week')
                .toDate();
            const before = moment()
                .endOf('week')
                .toDate();

            event = [after, before];
        } else if (event === 'thisMonth') {
            const after = moment()
                .startOf('month')
                .toDate();
            const before = moment()
                .endOf('month')
                .toDate();

            event = [after, before];
        } else if (event === 'lastWeek') {
            const after = moment()
                .subtract(1, 'week')
                .startOf('week')
                .toDate();
            const before = moment()
                .subtract(1, 'week')
                .endOf('week')
                .toDate();

            event = [after, before];
        } else if (event === 'lastMonth') {
            const after = moment()
                .subtract(1, 'month')
                .startOf('month')
                .toDate();
            const before = moment()
                .subtract(1, 'month')
                .endOf('month')
                .toDate();

            event = [after, before];
        } else if (event instanceof Array) {
            const after = moment(event[0])
                .startOf('day')
                .toDate();
            const before = moment(event[1])
                .endOf('day')
                .toDate();

            event = [after, before];
        }

        return event;
    }

    public getFilterParsedValue(): any {
        if (this.advanceFilter && typeof this.advanceFilter.parseValue === 'function') {
            return this.advanceFilter.parseValue(this.filterValue);
        }

        let after = this.filterValue[0];
        let before = this.filterValue[0];

        if (after && after.after instanceof Date) {
            after = moment(after)
                .startOf('day')
                .toDate();
        }

        if (before && before instanceof Date) {
            before = moment(before)
                .endOf('day')
                .toDate();
        }

        return [after, before];
    }

    public buildFilterParams(params: any, filter): void {
        const filterValue = this.getFilterValue();

        if (filterValue) {
            const date = {
                after: filterValue[0].toISOString(),
                before: filterValue[1].toISOString()
            };

            this.dateModifier.modify('after', date);
            this.dateModifier.modify('before', date);

            params[`${filter}[after]`] = date.after;
            params[`${filter}[before]`] = date.before;
        }
    }
}
