import {Pipe} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep} from 'lodash';
import {ConvertDecimalToTimePipe} from './convert-decimal-to-time.pipe';

@Pipe({
    name: 'decimalToWorkTime'
})
export class ConvertDecimalToWorkTimePipe extends ConvertDecimalToTimePipe {

    private static convertValue(value: string|number) {
        if (typeof value === 'string') {
            value = +cloneDeep(value);
        }

        return value;
    }

    public constructor(
        protected translate: TranslateService
    ) {
        super(translate);
    }

    public transform(value: string|number): string {
        value = ConvertDecimalToWorkTimePipe.convertValue(value);

        return this.doTransform(value, 8);
    }
}
