import {Injectable, Injector} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable, of} from 'rxjs';
import {Employee} from '../entities/entities';
import {AuthenticationService} from '../services/authentication.service';
import {HttpClient} from '@angular/common/http';
import {EntityManagerMetaDataService} from '../../../../projects/entity-manager/src/lib/service/meta/entity-manager-meta-data.service';
import {EntityManagerModifierService} from '../../../../projects/entity-manager/src/lib/service/entity-manager-modifier.service';
import {EntityManagerParserService} from '../../../../projects/entity-manager/src/lib/service/parser/entity-manager-parser.service';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Entity} from '../../../../projects/entity-manager/src/lib/helper/entity';
import {Paginated} from '../../shared/table/shared/paginated';

@Injectable({
    providedIn: 'root'
})
export class EmployeeRepository extends EntityRepository {

    public constructor(
        protected connection: HttpClient,
        protected meta: EntityManagerMetaDataService,
        protected modifier: EntityManagerModifierService,
        protected parser: EntityManagerParserService,
        protected injector: Injector,
        protected entityManager: EntityManagerService,
        protected authentication: AuthenticationService
    ) {
        super(connection, meta, modifier, parser, injector, entityManager);
    }

    public getByCurrentUser(): Observable<Employee|null> {
        const route = this.meta.getMetaDataProperty(new Employee(), Meta.META_ROUTE);

        if (!this.authentication.isLoggedIn()) {
            return of(null);
        }

        return this.connection.get(
            environment.ENTITY_MANAGER_URL_PREFIX + route + '/get_current_user_related_employee'
        ).pipe(map((employee: any) => {
            if (!employee) {
                return null;
            }

            return this.parser.getParser().parse(new Employee(), employee);
        }));
    }

    public getPastHolidayDaysCount(employee: Employee): Observable<number> {
        const route = this.meta.getMetaDataProperty(new Employee(), Meta.META_ROUTE);

        return this.connection.get(
            environment.ENTITY_MANAGER_URL_PREFIX + route + `/${employee.getId()}/get_past_holiday_days_count`
            )
            .pipe(
                map((response: {result: number}) => {
                    return response.result;
                })
            );
    }

    public updateDays(employee: Employee): Observable<Employee|null> {
        const route = this.meta.getMetaDataProperty(new Employee(), Meta.META_ROUTE),
            data = {
                employee: Entity.getValue(employee, '@id'),
                pastHoliday: employee.getPastHoliday(),
                holiday: employee.getHoliday(),
                offDays: employee.getOffDays(),
                approvedOvertime: employee.getApprovedOvertime(),
                approvedTimeAbsent: employee.getApprovedTimeAbsent()
            };

        return this.connection.post(
                `${environment.ENTITY_MANAGER_URL_PREFIX}${route}/update_days`,
                data
            )
            .pipe(
                map((response: any) => {
                    if (!response) {
                        return null;
                    }

                    return this.parser.getParser().parse(new Employee(), response);
                })
            );
    }

    public getEmployeeYearsOfService(employee: Employee): Observable<{periods?: any[]}> {
        const route = this.meta.getMetaDataProperty(new Employee(), Meta.META_ROUTE);

        return this.connection.get(
            `${environment.ENTITY_MANAGER_URL_PREFIX}${route}/${employee.getId()}/get_years_of_service`
        )
            .pipe(
                map((response: {periods?: any[]}) => {
                    return response;
                })
            );
    }
}
