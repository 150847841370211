import { AbstractActionHandler } from './abstract-action-handler';

export class BoxAddedActionHandler extends AbstractActionHandler {

    public handleAction() {
        this.getDashboardComponent().isBoxListModalVisible = false;
    }

    public getPayload() {
        return this.getParameterValue('dashboardBox');
    }
}