import {Injectable, Injector} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {Associate} from '../entities/entities';
import {AuthenticationService} from '../services/authentication.service';
import {HttpClient} from '@angular/common/http';
import {EntityManagerMetaDataService} from '../../../../projects/entity-manager/src/lib/service/meta/entity-manager-meta-data.service';
import {EntityManagerModifierService} from '../../../../projects/entity-manager/src/lib/service/entity-manager-modifier.service';
import {EntityManagerParserService} from '../../../../projects/entity-manager/src/lib/service/parser/entity-manager-parser.service';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AssociateRepository extends EntityRepository {

    public constructor(
        protected connection: HttpClient,
        protected meta: EntityManagerMetaDataService,
        protected modifier: EntityManagerModifierService,
        protected parser: EntityManagerParserService,
        protected injector: Injector,
        protected entityManager: EntityManagerService,
        protected authentication: AuthenticationService
    ) {
        super(connection, meta, modifier, parser, injector, entityManager);
    }

    public dismiss(associate: Associate|any): Observable<Associate> {
        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}associates/${associate.getId()}/dismiss`,
            {
                disableVisibleFilter: 1
            }
        ).pipe(map((aAssociate: Associate) => {
            return this.parser.getParser().parse(new Associate(), aAssociate);
        }));
    }
}
