import {Route} from 'entity-manager';
import {Employee} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('driving_licences')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class DrivingLicence {

    protected id: string;
    protected categories: string[];

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected validTo: Date;

    protected isMedicalExaminationRequired = false;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected medicalExaminationIssuedAt: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected medicalExaminationValidTo: Date;

    @AssociationOne(Employee)
    protected employee: Employee;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public setCategories(categories: string[]) {
        this.categories = categories;
        return this;
    }

    public getCategories(): string[] {
        return this.categories || [];
    }

    public setValidTo(validTo: Date) {
        this.validTo = validTo;
        return this;
    }

    public getValidTo(): Date|undefined {
        return this.validTo;
    }

    public setIsMedicalExaminationRequired(isMedicalExaminationRequired: boolean) {
        this.isMedicalExaminationRequired = isMedicalExaminationRequired;
        return this;
    }

    public getIsMedicalExaminationRequired(): boolean {
        return this.isMedicalExaminationRequired;
    }

    public setMedicalExaminationIssuedAt(medicalExaminationIssuedAt: Date) {
        this.medicalExaminationIssuedAt = medicalExaminationIssuedAt;
        return this;
    }

    public getMedicalExaminationIssuedAt(): Date|undefined {
        return this.medicalExaminationIssuedAt;
    }

    public setMedicalExaminationValidTo(medicalExaminationValidTo: Date) {
        this.medicalExaminationValidTo = medicalExaminationValidTo;
        return this;
    }

    public getMedicalExaminationValidTo(): Date|undefined {
        return this.medicalExaminationValidTo;
    }
}
