import {Injectable, Injector} from '@angular/core';
import {RepositoryMustDeriveFromEntityRepository} from '../../error/repository-must-derive-from-entity-repository.error';
import {EntityRepository} from './entity-repository';
import {EntityManagerMetaDataService} from '../meta/entity-manager-meta-data.service';
import {Meta} from '../meta/meta';

@Injectable({
    providedIn: 'root'
})
export class EntityManagerRepositoryFactoryService {

    public constructor(
        private injector: Injector,
        private meta: EntityManagerMetaDataService
    ) {

    }

    public createRepository(entityType: any): EntityRepository|any {
        let repository = this.injector.get(EntityRepository);

        if (this.meta.hasMetaDataProperty(new entityType(), Meta.META_REPOSITORY)) {
            const entityRepository = this.meta.getMetaDataProperty(new entityType(), Meta.META_REPOSITORY);

            repository = this.injector.get(entityRepository);
        }

        if ( !(repository instanceof EntityRepository) ) {
           throw new RepositoryMustDeriveFromEntityRepository(repository);
        }

        repository.entityType = entityType;

        return repository;
    }
}
