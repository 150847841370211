import {Route} from 'entity-manager';
import {Company} from './company';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {UserRepository} from '../repositories/user.repository';
import {AbstractSetting} from './abstract-setting';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {UserSetting} from './user-setting';
import {MediaObject} from './media-object';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';

@Route('users')
@CrossDependency(() => {
    return {
        company: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Company
        },
        settings: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: UserSetting
        },
        medias: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: MediaObject
        }
    };
})
@Repository(UserRepository)
export class User {
    protected id: string;
    protected firstName: string;
    protected lastName: string;
    protected roles: string[];
    protected email: string;
    protected username: string;
    protected plainPassword: string;
    protected oldPassword: string;
    protected isUserBanned: boolean;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationMany(MediaObject)
    protected medias: MediaObject[];

    @AssociationMany(UserSetting)
    protected settings: AbstractSetting[];

    @AssociationOne(Company)
    protected company: Company;

    protected isDefaultPasswordChanged: boolean;
    protected password: string;

    public constructor() {
        this.medias = [];
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setFirstName(firstName: string) {
        this.firstName = firstName;
        return this;
    }

    public getFirstName(): string {
        return this.firstName;
    }

    public setLastName(lastName: string) {
        this.lastName = lastName;
        return this;
    }

    public getLastName(): string {
        return this.lastName;
    }

    public setEmail(email: string) {
        this.email = email;
        return this;
    }

    public getEmail(): string {
        return this.email;
    }

    public setUsername(username: string) {
        this.username = username;
        return this;
    }

    public getUsername(): string {
        return this.username;
    }


    public setPlainPassword(plainPassword: string) {
        this.plainPassword = plainPassword;
        return this;
    }

    public getPlainPassword(): string {
        return this.plainPassword;
    }

    public setOldPassword(oldPassword: string) {
        this.oldPassword = oldPassword;
        return this;
    }

    public getOldPassword(): string {
        return this.oldPassword;
    }
    public getFullName(): string {
        return this.getFirstName() + ' ' + this.getLastName();
    }

    public getCompany(): Company|undefined {
        return this.company;
    }

    public setCompany(company: Company) {
        this.company = company;
        return this;
    }

    public hasRole(role: string): boolean {
        return this.roles instanceof Array && this.roles.includes(role);
    }

    public getRoles(): string[] {
        return this.roles || [];
    }

    public setRoles(roles: string[]): this {
        this.roles = roles;
        return this;
    }

    public setSettings(settings: AbstractSetting[]): this {
        this.settings = settings;
        return this;
    }

    public getSettings(): AbstractSetting[]|undefined {
        return this.settings || [];
    }

    public getSetting(code: string): AbstractSetting|undefined {
        for (const setting of this.getSettings()) {
            if (setting.getCode() === code) {
                return setting;
            }
        }

        return undefined;
    }

    public getSettingValue(code: string): any|undefined {
        return this.getSetting(code) ? this.getSetting(code).getValue() : undefined;
    }

    public setMedias(medias: MediaObject[]): this {
        this.medias = medias;
        return this;
    }

    public getMedias(): MediaObject[]|undefined {
        return this.medias;
    }

    public removeMedia(mediaObject: MediaObject): this {
        const index = this.medias.findIndex((aMedia: MediaObject) => {
            return aMedia.getContext() === mediaObject.getContext();
        });

        this.medias.splice(index, 1);

        return this;
    }

    public addMedia(mediaObject: MediaObject): this {
        const index = this.medias.findIndex((aMedia: MediaObject) => {
            return aMedia.getContext() === mediaObject.getContext();
        });

        if (index === -1) {
            this.medias.push(mediaObject);
        }

        return this;
    }

    public getMedia(context: string): MediaObject|undefined {
        for (const mediaObject of this.getMedias()) {
            if (mediaObject instanceof MediaObject && mediaObject.getContext() === context) {
                return mediaObject;
            }
        }

        return undefined;
    }

    public setIsDefaultPasswordChanged(isDefaultPasswordChanged: boolean): this {
        this.isDefaultPasswordChanged = isDefaultPasswordChanged;
        return this;
    }

    public getIsDefaultPasswordChanged(): boolean|undefined {
        return this.isDefaultPasswordChanged;
    }

    public setPassword(password: string): this {
        this.password = password;
        return this;
    }

    public getPassword(): string|undefined {
        return this.password;
    }

    public setIsUserBanned(isUserBanned: boolean) {
        this.isUserBanned = isUserBanned;
        return this;
    }

    public getIsUserBanned(): boolean {
        return this.isUserBanned;
    }
}
