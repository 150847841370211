import {Route} from 'entity-manager';
import {MoneyTransaction} from './entities';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('money_transaction_book_transactions')
@CrossDependency(() => {
    return {
        moneyTransactions: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: MoneyTransaction
        }
    };
})
export class MoneyTransactionBookTransaction {

    @AssociationMany(MoneyTransaction)
    protected moneyTransactions: MoneyTransaction[];

    protected id: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected createdAt: Date;
    protected amount: string;

    public getId(): string {
        return this.id;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getMoneyTransactions(): MoneyTransaction[] {
        return this.moneyTransactions;
    }

    public setMoneyTransactions(moneyTransactions: MoneyTransaction[]): MoneyTransactionBookTransaction {
        this.moneyTransactions = moneyTransactions;
        return this;
    }

    public getCreatedAt(): Date {
        return this.createdAt;
    }

    public setCreatedAt(createdAt: Date): MoneyTransactionBookTransaction {
        this.createdAt = createdAt;
        return this;
    }

    public getAmount(): string {
        return this.amount;
    }

    public setAmount(amount: string): MoneyTransactionBookTransaction {
        this.amount = amount;
        return this;
    }
}
