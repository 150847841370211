<div class="ui-g filter-panel">
    <div class="ui-g-3 calendar">
        <div class="calendar-previous">
            <i (click)="onPreviousMonth()" class="fa fa-arrow-left"></i>
        </div>

        <div class="calendar-day">
            <b>{{ currentMonth | appDate:'yy MM'}}</b>
        </div>

        <div class="calendar-next">
            <i (click)="onNextMonth()" class="fa fa-arrow-right"></i>
        </div>
    </div>
</div>
