import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {EducationType} from './education-type';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';

@Route('educations')
@CrossDependency( () => {
    return {
        educationType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EducationType
        }
    };
} )
export class Education {

    protected id: string;
    protected code: string;
    protected name: string;
    @AssociationOne(EducationType)
    protected educationType: EducationType;
    protected description: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription() {
        return this.description;
    }

    public setEducationType(educationType: EducationType) {
        this.educationType = educationType;
        return this;
    }

    public getEducationType(): EducationType {
        return this.educationType;
    }
}
