import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {cloneDeep} from 'lodash';
import {MathUtil} from '../../core/utils/math-util';

@Pipe({
    name: 'decimalToTime'
})
export class ConvertDecimalToTimePipe implements PipeTransform {

    public constructor(
        protected translate: TranslateService
    ) {

    }

    public transform(value: string|number): string {
        return this.doTransform(value);
    }

    protected doTransform(value, hoursPerDay: number = 24): string {
        if (typeof value === 'string') {
            value = +cloneDeep(value);
        }
        const days = Math.floor(
                +MathUtil.round(moment.duration(Math.abs(value), 'hour').asHours() / hoursPerDay, 4)
            ),
            hours = Math.floor(
                +MathUtil.round(moment.duration(Math.abs(value), 'hour').asHours() % hoursPerDay, 4)
            ),
            minutes = +MathUtil.round(
                +MathUtil.round(moment.duration(Math.abs(value), 'hour').asMinutes() % 60, 4), 0
            );

        let ret = '';

        if (days > 0) {
            ret += `${days}${this.translate.instant('TIME.DAYS')} `;
        }

        if (hours > 0) {
            ret += `${hours}${this.translate.instant('TIME.HOURS')} `;
        }

        if (minutes > 0) {
            ret += `${minutes}${this.translate.instant('TIME.MINUTES')}`;
        }

        if (!ret) {
            return `0 ${this.translate.instant('TIME.MINUTES')}`;
        }

        const negative = +value >= 0 ? '' : '-';

        return `${negative}${ret}`;
    }
}
