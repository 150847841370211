import {Injectable} from '@angular/core';
import {EntityManagerMetaDataService} from './meta/entity-manager-meta-data.service';
import {PropertyResponseModifierInterface} from '../decorator/response-modifiers/property-response-modifier-interface';
import {PropertyPayloadModifierInterface} from '../decorator/payload-modifiers/property-payload-modifier-interface';

@Injectable({
    providedIn: 'root'
})
export class EntityPropertyModifierService {

    public constructor(
        private meta: EntityManagerMetaDataService
    ) {

    }

    public modifyPayload(entity: any): any {
        const modifiers = this.meta.getPropertyPayloadModifiers(entity);

        for (const modifierKey in modifiers) {
            if (modifiers.hasOwnProperty(modifierKey)) {
                this.modifyPropertyPayload(entity, modifiers[modifierKey]);
            }
        }

        return entity;
    }

    public modifyResponse(entity: any): any {
        const modifiers = this.meta.getPropertyResponseModifiers(entity);

        for (const modifierKey in modifiers) {
            if (modifiers.hasOwnProperty(modifierKey)) {
                this.modifyPropertyResponse(entity, modifiers[modifierKey]);
            }
        }

        return entity;
    }

    protected modifyPropertyPayload(entity: any, modifier: {modifier: any, properties: any}): void {
        const properties = modifier.properties,
            modifierClass: PropertyPayloadModifierInterface = new modifier.modifier();

        for (const property in properties) {
            if (properties.hasOwnProperty(property)) {
                modifierClass.modify(property, entity, properties[property]);
            }
        }
    }

    protected modifyPropertyResponse(entity: any, modifier: {modifier: any, properties: any}): void {
        const properties = modifier.properties,
            modifierClass: PropertyResponseModifierInterface = new modifier.modifier();

        for (const property in properties) {
            if (properties.hasOwnProperty(property)) {
                modifierClass.modify(property, entity);
            }
        }
    }
}
