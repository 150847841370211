import {Component, ViewContainerRef, ViewChild, Input, ComponentRef, ElementRef} from '@angular/core';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {AbstractDashboardBoxComponent} from '../../service/dashboard-box/abstract-dashboard-box.component';
import {AbstractDashboardEditComponent} from '../../service/dashboard-box/abstract-dashboard-edit.component';
import {DashboardBox} from '../../service/dashboard-box';
import {DashboardAction} from '../../service/dashboard-action';
import {Parameter} from '../../service/parameter';

@Component({
    selector: 'app-dashboard-box-wrapper',
    templateUrl: './dashboard-box-wrapper.component.html',
    styleUrls: ['./dashboard-box-wrapper.component.scss']
})
export class DashboardBoxWrapperComponent {

    @ViewChild('boxContainer', {static: true, read: ViewContainerRef}) boxContainer: ViewContainerRef;
    @ViewChild('editContainer', {static: true, read: ViewContainerRef}) editContainer: ViewContainerRef;
    @ViewChild('editBoxContainer', {static: true, read: ViewContainerRef}) editBoxContainer: ViewContainerRef;

    public isInEditMode = false;
    public isInLoadMode = true;

    public errorMessage = '';

    private dashboardComponent: DashboardComponent = null;
    private boxComponent: AbstractDashboardBoxComponent = null;
    private editComponent: AbstractDashboardEditComponent = null;
    private dashboardBox: DashboardBox = null;

    public constructor(
        public elementRef: ElementRef
    ) {
    }


    public onRemove() {
        this.getDashboardComponent().getDashboardActionService().on(DashboardAction.RemoveBox, [
            new Parameter('dashboardBoxWrapper', this)
        ]);
    }

    public onEditOpen(): void {
        this.isInEditMode = true;
        this.isInLoadMode = false;
        this.errorMessage = '';

        this.getEditComponent().onShow();
    }

    public onEditFinish(): void {
        this.startLoadingMode();
        this.isInEditMode = false;
        this.errorMessage = '';

        this.getDashboardComponent()
            .getDashboardActionService()
            .on(DashboardAction.BoxEdited, [
                new Parameter('dashboardBoxWrapper', this)
            ]);

        this.getEditComponent().onHide();
    }

    public onEditCancel(): void {
        this.isInEditMode = false;

        // onEditOpen save state, here reverse it

        this.getEditComponent().onHide();
    }

    public startLoadingMode(): DashboardBoxWrapperComponent {
        this.isInLoadMode = false;

        return this;
    }

    public stopLoadingMode(): DashboardBoxWrapperComponent {
        this.isInLoadMode = false;

        return this;
    }

    public showError(error: string): DashboardBoxWrapperComponent {
        this.errorMessage = error;

        return this;
    }

    public setBoxComponent(boxComponent: any): DashboardBoxWrapperComponent {
        this.boxComponent = boxComponent;
        return this;
    }

    public getBoxComponent(): AbstractDashboardBoxComponent {
        return this.boxComponent;
    }

    public setEditComponent(editComponent: any): DashboardBoxWrapperComponent {
        this.editComponent = editComponent;
        return this;
    }

    public getEditComponent(): AbstractDashboardEditComponent {
        return this.editComponent;
    }

    public setDashboardBox(dashboardBox: DashboardBox): DashboardBoxWrapperComponent {
        this.dashboardBox = dashboardBox;
        return this;
    }

    public getDashboardBox(): DashboardBox {
        return this.dashboardBox;
    }

    public setDashboardComponent(dashboardComponent: DashboardComponent): DashboardBoxWrapperComponent {
        this.dashboardComponent = dashboardComponent;
        return this;
    }

    public getDashboardComponent(): DashboardComponent {
        return this.dashboardComponent;
    }

    public getBoxViewContainerRef(): ViewContainerRef {
        return this.boxContainer;
    }

    public getEditViewContainerRef(): ViewContainerRef {
        return this.editContainer;
    }
}
