import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {NisInvoiceRepository} from '../repositories/nis-invoice.repository';
import {Employee, NisInvoice, Vehicle} from './entities';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {DecimalPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';

@Route('nis_invoice_items')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        vehicle: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vehicle
        },
        nisInvoice: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: NisInvoice
        }
    };
})
@Repository(NisInvoiceRepository)
export class NisInvoiceItem {

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(Vehicle)
    protected vehicle: Vehicle;

    @AssociationOne(NisInvoice)
    protected nisInvoice: NisInvoice;

    protected id: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected nisInvoiceDate: Date;

    protected nisInvoiceNotice: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected amountFuelConsumptionBefore15: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected amountFuelConsumptionAfter15: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected fuelConsumptionBefore15: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected fuelConsumptionAfter15: string;

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setVehicle(vehicle: Vehicle): this {
        this.vehicle = vehicle;
        return this;
    }

    public getVehicle(): Vehicle | undefined {
        return this.vehicle;
    }

    public setNisInvoice(nisInvoice: NisInvoice): this {
        this.nisInvoice = nisInvoice;
        return this;
    }

    public getNisInvoice(): NisInvoice | undefined {
        return this.nisInvoice;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setNisInvoiceDate(nisInvoiceDate: Date) {
        this.nisInvoiceDate = nisInvoiceDate;
        return this;
    }

    public getNisInvoiceDate() {
        return this.nisInvoiceDate;
    }

    public setNisInvoiceNotice(nisInvoiceNotice: string): this {
        this.nisInvoiceNotice = nisInvoiceNotice;
        return this;
    }

    public getNisInvoiceNotice(): string {
        return this.nisInvoiceNotice;
    }

    public setAmountFuelConsumptionBefore15(amountFuelConsumptionBefore15: string): this {
        this.amountFuelConsumptionBefore15 = amountFuelConsumptionBefore15;
        return this;
    }

    public getAmountFuelConsumptionBefore15(): string | undefined {
        return this.amountFuelConsumptionBefore15;
    }

    public setAmountFuelConsumptionAfter15(amountFuelConsumptionAfter15: string): this {
        this.amountFuelConsumptionAfter15 = amountFuelConsumptionAfter15;
        return this;
    }

    public getAmountFuelConsumptionAfter15(): string | undefined {
        return this.amountFuelConsumptionAfter15;
    }

    public setFuelConsumptionBefore15(fuelConsumptionBefore15: string): this {
        this.fuelConsumptionBefore15 = fuelConsumptionBefore15;
        return this;
    }

    public getFuelConsumptionBefore15(): string | undefined {
        return this.fuelConsumptionBefore15;
    }

    public setFuelConsumptionAfter15(fuelConsumptionAfter15: string): this {
        this.fuelConsumptionAfter15 = fuelConsumptionAfter15;
        return this;
    }

    public getFuelConsumptionAfter15(): string | undefined {
        return this.fuelConsumptionAfter15;
    }
}
