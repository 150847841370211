import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {StockInfo, InvoiceItem, DeliveryItem, OrderItem} from './entities';

@Route('stock_info_details')
@CrossDependency(() => {
    return {
        invoiceItem: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: InvoiceItem
        },
        stockInfo: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: StockInfo
        },
        deliveryItem: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: DeliveryItem
        },
        orderItem: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: OrderItem
        }
    };
})
export class StockInfoDetail {

    protected incomeQuantity: number;
    protected outcomeQuantity: number;

    @AssociationOne(StockInfo)
    protected stockInfo;

    @AssociationOne(InvoiceItem)
    protected invoiceItem;

    @AssociationOne(DeliveryItem)
    protected deliveryItem;

    @AssociationOne(OrderItem)
    protected orderItem;

    protected id: string;

    public setStockInfo(stockInfo: StockInfo): StockInfoDetail {
        this.stockInfo = stockInfo;
        return this;
    }

    public getStockInfo(): StockInfo | undefined {
        return this.stockInfo;
    }

    public setInvoiceItem(invoiceItem: InvoiceItem): StockInfoDetail {
        this.invoiceItem = invoiceItem;
        return this;
    }

    public getInvoiceItem(): InvoiceItem | undefined {
        return this.invoiceItem;
    }

    public setDeliveryItem(deliveryItem: DeliveryItem): StockInfoDetail {
        this.deliveryItem = deliveryItem;
        return this;
    }

    public getDeliveryItem(): DeliveryItem | undefined {
        return this.deliveryItem;
    }

    public setOrderItem(orderItem: OrderItem): StockInfoDetail {
        this.orderItem = orderItem;
        return this;
    }

    public getOrderItem(): OrderItem | undefined {
        return this.orderItem;
    }

    public getIncomeQuantity(): number {
        return this.incomeQuantity;
    }

    public setIncomeQuantity(incomeQuantity: number): StockInfoDetail {
        this.incomeQuantity = incomeQuantity;
        return this;
    }

    public getOutcomeQuantity(): number {
        return this.outcomeQuantity;
    }

    public setOutcomeQuantity(outcomeQuantity: number): StockInfoDetail {
        this.outcomeQuantity = outcomeQuantity;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setId(id: string): StockInfoDetail {
        this.id = id;
        return this;
    }
}
