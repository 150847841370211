import {Route} from 'entity-manager';

export enum ProductTypeEnum {
    Final = 'final',
    Material = 'material',
    Service = 'service',
    Semifinal = 'semifinal',
    SparePart = 'sparePart',
    Expense = 'expense',
    Estimate = 'estimate'
}

@Route('product_types')
export class ProductType {
    protected hasNormative: boolean;
    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;
    protected isDefault: boolean;

    public setHasNormative(hasNormative: boolean) {
        this.hasNormative = hasNormative;
        return this;
    }

    public getHasNormative(): boolean {
        return this.hasNormative;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public isFinal(): boolean {
        return this.code === ProductTypeEnum.Final;
    }

    public isMaterial(): boolean {
        return this.code === ProductTypeEnum.Material;
    }

    public isService(): boolean {
        return this.code === ProductTypeEnum.Service;
    }

    public setIsDefault(isDefault: boolean): this {
        this.isDefault = isDefault;
        return this;
    }

    public getIsDefault(): boolean|undefined {
        return this.isDefault;
    }
}
