import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {
    WorkNoteType,
    Employee,
    WorkNoteSubType,
    WorkNoteAction,
    WorkNoteSubTypeCategory,
    WorkNoteMeasureProposal, WorkNote
} from '../entities/entities';

@Injectable({
    providedIn: 'root'
})
export class WorkNoteActionRepository extends EntityRepository {

    public getByWorkNoteType(workNoteType: WorkNoteType, employee: Employee): Observable<any> {
        return this.connection.get(
            `${environment.ENTITY_MANAGER_URL_PREFIX}work_note_actions/work_note_type/${workNoteType.getId()}/employee/${employee.getId()}`
        ).pipe(
            map((chartData: any) => {
                return chartData;
            })
        );
    }
    public getByWorkNoteTypeAndMeasureProposal(workNoteType: WorkNoteType, employee: Employee, measureProposal: WorkNoteMeasureProposal): Observable<any> {
        return this.connection.get(
            `${environment.ENTITY_MANAGER_URL_PREFIX}work_note_actions/work_note_type/${workNoteType.getId()}/employee/${employee.getId()}/measure_proposal/${measureProposal.getId()}`
        ).pipe(
            map((chartData: any) => {
                return this.parser.getParser().parse(new WorkNoteAction(), chartData);
            })
        );
    }
    public getByWorkNoteTypeAndMeasureProposalAndWorkNote(workNoteType: WorkNoteType, employee: Employee, measureProposal: WorkNoteMeasureProposal, workNote: WorkNote): Observable<any> {
        return this.connection.get(
        `${environment.ENTITY_MANAGER_URL_PREFIX}work_note_actions/work_note_type/${workNoteType.getId()}/employee/${employee.getId()}/measure_proposal/${measureProposal.getId()}/work_note/${workNote.getId()}`
        ).pipe(
            map((chartData: any) => {
                return this.parser.getParser().parse(new WorkNoteAction(), chartData);
            })
        );
    }

    public getByWorkNoteSubType(workNoteSubType: WorkNoteSubType, employee: Employee, workNote: WorkNote): Observable<any> {
        const workNoteId = workNote instanceof WorkNote && workNote.getId() ? workNote.getId() : null;
        return this.connection.get(
            `${environment.ENTITY_MANAGER_URL_PREFIX}work_note_actions/work_note_sub_type/${workNoteSubType.getId()}/employee/${employee.getId()}/work_note/${workNoteId}`
        ).pipe(
            map((chartData: any) => {
                return this.parser.getParser().parse(new WorkNoteAction(), chartData);
            })
        );
    }

    public getByWorkNoteSubTypeCategory(workNoteSubTypeCategory: WorkNoteSubTypeCategory, employee: Employee, hours: number, minutes: number, workNoteId = null): Observable<any> {
        return this.connection.get(
        `${environment.ENTITY_MANAGER_URL_PREFIX}work_note_actions/work_note_sub_type_category/${workNoteSubTypeCategory.getId()}/employee/${employee.getId()}/hours/${hours}/minutes/${minutes}/${workNoteId}`
        ).pipe(
            map((chartData: any) => {
                return this.parser.getParser().parse(new WorkNoteAction(), chartData);
            })
        );
    }

}
