export enum Route {
    LOGIN = '/login',
    LOGOUT = '/logout',
    HOME = '/home',
    ADMIN = '/admin',
    ADMIN_UNITS = '/admin/unit/list',
    ADMIN_UNIT_CREATE = '/admin/unit/create',
    ADMIN_UNIT_EDIT = '/admin/unit/:unitId',
    ADMIN_BANK_ACCOUNT = '/admin/bank-account/list',
    ADMIN_BANK_ACCOUNT_CREATE = '/admin/bank-account/create',
    ADMIN_BANK_ACCOUNT_EDIT = '/admin/bank-account/:id',
    ADMIN_COMPANY_SETTING_CREATE = '/admin/company/setting/create',
    ADMIN_COMPANY_SETTING_EDIT = '/admin/company/setting/:id',
    ADMIN_USER_SETTING_CREATE = '/admin/user/setting/create',
    ADMIN_USER_SETTING_EDIT = '/admin/user/setting/:id',
    ADMIN_USER_EDIT = '/admin/user/:userId',
    ADMIN_USER_LIST = '/admin/user/list',
    ADMIN_DEBT_TYPE_CREATE = '/admin/debttype/create',
    ADMIN_DEBT_TYPE_EDIT = '/admin/debttype/:debtTypeId',
    CRM = '/crm',
    CRM_CONTACTS = '/crm/contact/list',
    CRM_CONTACT_CREATE = '/crm/contact/create',
    CRM_CONTACT_EDIT = '/crm/contact/:contactId',
    CRM_CONTACT_INVOICE_DETAILS = '/crm/contact/:contactId/invoice-details',
    CRM_PERSON_CREATE = '/crm/contact/:contactId/person/create',
    SCM = '/scm',
    SCM_DELIVERY_INCOMING = '/scm/delivery/incoming/list',
    SCM_DELIVERY_INCOMING_CREATE = '/scm/delivery/incoming/create',
    SCM_DELIVERY_INCOMING_EDIT = '/scm/delivery/incoming/:id',
    SCM_DELIVERY_OUTGOING = '/scm/delivery/outgoing/list',
    SCM_DELIVERY_OUTGOING_CREATE = '/scm/delivery/outgoing/create',
    SCM_DELIVERY_OUTGOING_EDIT = '/scm/delivery/outgoing/:id',
    SCM_ORDER_TYPES = '/scm/order-type/list',
    SCM_ORDER_TYPES_CREATE = '/scm/order-type/create',
    SCM_ORDER_TYPES_EDIT = '/scm/order-type/:orderTypeId',
    SCM_ORDER_TYPE_ID_LIST = '/scm/order/type/:orderTypeId/list',
    SCM_ORDERS_CREATE = '/scm/order/type/:orderTypeId/create',
    SCM_ORDERS_EDIT = '/scm/order/type/:orderTypeId/:id',
    SCM_INVOICE_TYPE = '/scm/invoice-type/list',
    SCM_INVOICE_TYPE_CREATE = '/scm/invoice-type/create',
    SCM_INVOICE_TYPE_EDIT = '/scm/invoice-type/:invoiceTypeId',
    SCM_INVOICE_INCOMING_TYPE_ID = '/scm/invoice/incoming/type/:invoiceTypeId',
    SCM_INVOICE_INCOMING_EDIT = '/scm/invoice/incoming/type/:invoiceTypeId/:id',
    SCM_INVOICE_INCOMING_CREATE = '/scm/invoice/incoming/type/:invoiceTypeId/create',
    SCM_INVOICE_OUTGOING_TYPE_ID = '/scm/invoice/outgoing/type/:invoiceTypeId',
    SCM_INVOICE_OUTGOING_EDIT = '/scm/invoice/outgoing/type/:invoiceTypeId/:id',
    SCM_INVOICE_OUTGOING_CREATE = '/scm/invoice/outgoing/type/:invoiceTypeId/create',
    SCM_INVOICE_INCOMING_CREATE_ADVANCE = '/scm/invoice/incoming/type/:invoiceTypeId/invoice/:invoiceId',
    SCM_ESTIMATE_INCOMING_TYPE_ID_LIST = '/scm/estimate/type/:invoiceTypeId',
    SCM_ESTIMATE_INCOMING_EDIT = '/scm/estimate/type/:invoiceTypeId/:id',
    SCM_ESTIMATE_INCOMING_CREATE = '/scm/estimate/type/:invoiceTypeId/create',
    SCM_BANK_STATEMENT_LIST = '/scm/bank-statement/list',
    SCM_BANK_STATEMENT_CREATE = '/scm/bank-statement/create',
    SCM_BANK_STATEMENT_EDIT = '/scm/bank-statement/:id',
    SOURCING = '/sourcing',
    SOURCING_PRODUCT_TYPE_PRODUCTS = '/sourcing/product/type/:typeId',
    SOURCING_PRODUCT_CREATE = '/sourcing/product/create',
    SOURCING_PRODUCT_EDIT = '/sourcing/product/:productId',
    SOURCING_PRODUCT_TYPES = '/sourcing/product-types/list',
    SOURCING_PRODUCT_TYPES_CREATE = '/sourcing/product-types/create',
    SOURCING_PRODUCT_TYPES_EDIT = '/sourcing/product-types/:productTypeId',
    SOURCING_STOCK_INFO_DETAILS = '/sourcing/stock/details/:productId',
    HR_CALENDAR = '/hr/calendar',
    HR_CALENDAR_LIST = '/hr/calendar/list',
    HR_ASSOCIATE_LIST = '/hr/associate/list',
    HR_ASSOCIATE_CREATE = '/hr/associate/create',
    HR_ASSOCIATE_EDIT_BASIC = '/hr/associate/:associateId/basic',
    HR_ASSOCIATE_BANK_ACCOUNT = '/hr/associate/:associateId/bank-account',
    HR_ASSOCIATE_SOFTWARE_ACCESS = '/hr/associate/:associateId/software-access',
    HR_ASSOCIATE_EDIT = '/hr/associate/:associateId',
    HR_EMPLOYEES = '/hr/employee',
    HR_EMPLOYEES_CREATE = '/hr/employee/create',
    HR_EMPLOYEES_EDIT = '/hr/employee/:employeeId',
    HR_EMPLOYEES_EDIT_BASIC = '/hr/employee/:employeeId/basic',
    HR_EMPLOYEES_EDIT_EMPLOYMENT = '/hr/employee/:employeeId/dismissal-activate',
    HR_EMPLOYEES_EDIT_EMPLOYMENT_ACTIVATE = '/hr/employee/:employeeId/dismissal-list/activate',
    HR_EMPLOYEES_EDIT_CALENDAR = '/hr/employee/:employeeId/calendar',
    HR_EMPLOYEES_ID_CHILDREN = 'hr/employee/:employeeId/children',
    HR_EMPLOYEES_ID_CHILDREN_CREATE = 'hr/employee/:employeeId/children/create',
    HR_EMPLOYEES_ID_CHILDREN_CHILD_ID = 'hr/employee/:employeeId/children/:employeeChildId',
    HR_EMPLOYEES_ID_EMPLOYEE_PRODUCT_SHARING = 'hr/employee/:employeeId/employee-product-sharing',
    HR_EMPLOYEES_ID_EMPLOYEE_BANK_ACCOUNT = 'hr/employee/:employeeId/bank-account',
    HR_EMPLOYEES_ID_EMPLOYEE_SOFTWARE_ACCESS = 'hr/employee/:employeeId/software-access',
    HR_EMPLOYEES_ID_EMPLOYEE_SKILL = 'hr/employee/:employeeId/skill',
    HR_EMPLOYEES_ID_EMPLOYEE_PRODUCT_SHARING_CREATE = 'hr/employee/:employeeId/employee-product-sharing/create',
    HR_EMPLOYEES_ID_EMPLOYEE_PRODUCT_SHARING_ID = 'hr/employee/:employeeId/employee-product-sharing/:employeeProductSharingId',
    HR_DEPARTMENT = '/hr/admin/department/list',
    HR_DEPARTMENT_CREATE = '/hr/admin/department/create',
    HR_DEPARTMENT_EDIT = '/hr/admin/department/:departmentId',
    HR_DEPARTMENT_ID_EMPLOYEE_LIST = '/hr/admin/department/:departmentId/employee/list',
    HR_DEPARTMENT_ID_WORKTITLE_LIST = '/hr/admin/department/:departmentId/work-title',
    HR_WORKPLACE = '/hr/admin/workplace/list',
    HR_WORKPLACE_CREATE = '/hr/admin/workplace/create',
    HR_WORKPLACE_EDIT = '/hr/admin/workplace/:workplaceId',
    HR_EMPLOYEE_EDUCATION = '/hr/admin/educations/list',
    HR_EMPLOYEE_KNOWLEDGE_AND_SKILLS = '/hr/employee/:employeeId/knowledge-and-skills',
    HR_EMPLOYEE_EDUCATION_CREATE = '/hr/employee/:employeeId/knowledge-and-skills/educations/create/:type',
    HR_EMPLOYEE_EDUCATION_EDIT = '/hr/employee/:employeeId/knowledge-and-skills/educations/:educationId/:type',
    HR_EDUCATION_LIST = '/hr/admin/educations/educations/list',
    HR_EDUCATION_TYPES_LIST = '/hr/admin/educations/types/list',
    HR_EMPLOYMENT = '/hr/admin/employment/list',
    HR_EMPLOYMENT_CREATE = '/hr/admin/employment/create',
    HR_EMPLOYMENT_EDIT = '/hr/admin/employment/:employmentId',
    HR_VACATION_ABSENCE_TICKET = '/hr/vacation-absence-ticket/list',
    HR_VACATION_ABSENCE_TICKET_CREATE = '/hr/vacation-absence-ticket/create',
    HR_VACATION_ABSENCE_TICKET_ID = '/hr/vacation-absence-ticket/:id',
    HR_CALENDAR_ENTRY_TAB = '/hr/employee/calendar/:tab',
    HR_CALENDAR_FREE_DAYS_AND_HOURS_TAB = '/hr/calendar/free-days-and-hours/:tab',
    HR_CALENDAR_OVERTIME_AND_TIME_ABSENCE_TAB = '/hr/calendar/work-hours-surplus-and-deficit/overtime-and-time-absence/:tab',
    HR_FINANCE_PAYMENT_DEDUCTION_TAB = '/hr/finance/payment-deduction/:tab',
    HR_FINANCE_EARNING_CALCULATION_TAB = '/hr/finance/earning-calculation/:tab',
    HR_WORK_NOTE_TYPE_ID_WORK_NOTE_ACTION_LIST = '/hr/admin/work-note-type/:workNoteTypeId/work-note-action',
    HR_WORK_NOTE_TYPE_ID_WORK_NOTE_MEASURE_PROPOSAL_LIST = '/hr/admin/work-note-type/:workNoteTypeId/work-note-measure-proposal',
    HR_WORK_NOTE_TYPE_ID_WORK_NOTE_ACTION_ID = '/hr/admin/work-note-type/:workNoteTypeId/:workNoteActionId',
    HR_WORK_NOTE_TYPE_LIST = '/hr/admin/work-note-type/list',
    HR_ISSUED_CERTIFICATE_TAB = '/hr/finance/issued-certificate/:activeTab',
    HR_MONEY_TRANSACTION_TAB = '/hr/finance/money-transactions/:tab',
    HR_MONEY_TRANSACTION_ARCHIVE_TAB = '/hr/finance/money-transactions/archive/:tab',
    MESSAGE_LIST = '/messages',
    MESSAGE_DETAILS = '/messages/:messageId',
    HR_CALENDAR_RULES_TAB = '/hr/calendar/rules/:activeTab',
    HR_FINANCE_LOGS_TAB = '/hr/finance/logs/:activeTab',
    HR_VEHICLE_ARCHIVED_TAB = '/hr/vehicle/archived-vehicle/:activeTab',
    HR_EMPLOYEES_ID_COVID_CREATE = 'hr/employee/:employeeId/covid/create',
    HR_EMPLOYEE_ID_EMPLOYMENT = 'hr/employee/:employeeId/employment/list',
    HR_EMPLOYEES_ID_EMPLOYMENT_CREATE = 'hr/employee/:employeeId/employment/create',
    HR_EMPLOYEES_ID_EMPLOYMENT_ID = 'hr/employee/:employeeId/employment/:employmentId',
}
