import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Vehicle} from './vehicle';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DecimalPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';
import {NisInvoiceRepository} from '../repositories/nis-invoice.repository';
import {NisInvoiceItem} from './nis-invoice-item';
import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';

@Route('nis_invoices')
@CrossDependency(() => {
    return {
        vehicle: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vehicle
        },
        nisInvoiceItems: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: NisInvoiceItem
        },
    };
})
@Repository(NisInvoiceRepository)
export class NisInvoice {

    @AssociationOne(Vehicle)
    protected vehicle: Vehicle;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationMany(NisInvoiceItem)
    protected nisInvoiceItems: NisInvoiceItem[];

    protected id: string;
    protected entryMonth: number;
    protected entryYear: number;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalFuelConsumptionBefore15: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalFuelConsumptionAfter15: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalNisInvoiceAmountFuelConsumptionBefore15: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalNisInvoiceAmountFuelConsumptionAfter15: string;

    public setVehicle(vehicle: Vehicle): this {
        this.vehicle = vehicle;
        return this;
    }

    public getVehicle(): Vehicle | undefined {
        return this.vehicle;
    }

    public setNisInvoiceItems(nisInvoiceItems: NisInvoiceItem[]): this {
        this.nisInvoiceItems = nisInvoiceItems;
        return this;
    }

    public getNisInvoiceItems(): NisInvoiceItem[] | undefined {
        return this.nisInvoiceItems;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setEntryMonth(entryMonth: number): this {
        this.entryMonth = entryMonth;
        return this;
    }

    public getEntryMonth(): number | undefined {
        return this.entryMonth;
    }

    public setEntryYear(entryYear: number): this {
        this.entryYear = entryYear;
        return this;
    }

    public getEntryYear(): number | undefined {
        return this.entryYear;
    }

    public setTotalFuelConsumptionBefore15(totalFuelConsumptionBefore15: string): this {
        this.totalFuelConsumptionBefore15 = totalFuelConsumptionBefore15;
        return this;
    }

    public getTotalFuelConsumptionBefore15(): string | undefined {
        return this.totalFuelConsumptionBefore15;
    }

    public setTotalFuelConsumptionAfter15(totalFuelConsumptionAfter15: string): this {
        this.totalFuelConsumptionAfter15 = totalFuelConsumptionAfter15;
        return this;
    }

    public getTotalFuelConsumptionAfter15(): string | undefined {
        return this.totalFuelConsumptionAfter15;
    }

    public setTotalNisInvoiceAmountFuelConsumptionBefore15(totalNisInvoiceAmountFuelConsumptionBefore15: string): this {
        this.totalNisInvoiceAmountFuelConsumptionBefore15 = totalNisInvoiceAmountFuelConsumptionBefore15;
        return this;
    }

    public getTotalNisInvoiceAmountFuelConsumptionBefore15(): string | undefined {
        return this.totalNisInvoiceAmountFuelConsumptionBefore15;
    }

    public setTotalNisInvoiceAmountFuelConsumptionAfter15(totalNisInvoiceAmountFuelConsumptionAfter15: string): this {
        this.totalNisInvoiceAmountFuelConsumptionAfter15 = totalNisInvoiceAmountFuelConsumptionAfter15;
        return this;
    }

    public getTotalNisInvoiceAmountFuelConsumptionAfter15(): string | undefined {
        return this.totalNisInvoiceAmountFuelConsumptionAfter15;
    }
}
