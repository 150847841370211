import {Route} from 'entity-manager';
import {Currency, Employee, MoneyTransactionStatus} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {StringPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';


@Route('payment_deduction_for_morons')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        currency: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Currency
        }
    };
})
export class PaymentDeductionForMoron {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;

    @AssociationOne(MoneyTransactionStatus)
    protected currency: Currency;

    @AssociationOne(Employee)
    protected employee: Employee;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected takingDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected returningDate: Date;
    protected isRegulated: boolean;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected amount: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setEmployee(employee: Employee) {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public setTakingDate(entryMonth: Date) {
        this.takingDate = entryMonth;
        return this;
    }

    public getTakingDate(): Date|undefined {
        return this.takingDate;
    }

    public setReturningDate(entryYear: Date) {
        this.returningDate = entryYear;
        return this;
    }

    public getReturningDate(): Date|undefined {
        return this.returningDate;
    }

    public setAmount(amount: string) {
        this.amount = amount;
        return this;
    }

    public getAmount(): string|undefined {
        return this.amount;
    }

    public getIsRegulated(): boolean|undefined {
        return this.isRegulated;
    }

    public setIsRegulated(isRegulated: boolean): this {
        this.isRegulated = isRegulated;
        return this;
    }

    public setCurrency(currency: Currency): this {
        this.currency = currency;
        return this;
    }

    public getCurrency(): Currency|undefined {
        return this.currency;
    }
}
