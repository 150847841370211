import {Route} from 'entity-manager';
import {DeletePermissionAwareInterface} from './delete-permission-aware.interface';

export enum CalendarEntryTypeEnum {
    Weekend = 'weekend',
    WorkDay = 'workDay',
    Absence = 'absence'
}

const NON_DELETABLE_CODES = [
    CalendarEntryTypeEnum.Weekend,
    CalendarEntryTypeEnum.WorkDay,
    CalendarEntryTypeEnum.Absence
];

@Route('calendar_entry_types')
export class CalendarEntryType implements DeletePermissionAwareInterface {

    protected id: string;
    protected code: CalendarEntryTypeEnum;
    protected color: string;
    protected name: string;
    protected description: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: CalendarEntryTypeEnum) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setColor(color: string) {
        this.color = color;
        return this;
    }

    public getColor(): string {
        return this.color;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public isValidForDelete(): boolean {
        return !NON_DELETABLE_CODES.includes(this.code);
    }
}
