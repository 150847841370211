import {Injectable} from '@angular/core';
import {HttpRequest} from '@angular/common/http';
import {Modifier} from 'entity-manager';
import {EntityManagerParserService} from '../../../../projects/entity-manager/src/lib/service/parser/entity-manager-parser.service';

@Injectable({
    providedIn: 'root'
})
export class PaginationLdJsonModifier implements Modifier {

    public constructor(
        protected parser: EntityManagerParserService
    ) {

    }

    public modifyRequest(entity: any, request: HttpRequest<any>): any {
        return request;
    }

    public modifyResponse(entity: any, request: HttpRequest<any>, responseBody, caller: string): any  {

        if (caller === 'findMore' && responseBody && responseBody['@type'] === 'hydra:Collection' &&
            responseBody['hydra:member'] instanceof Array
        ) {
            responseBody = {
                data: this.parser.getParser().parseArray(entity, responseBody['hydra:member']),
                total: responseBody['hydra:totalItems']
            };
        }

        if (caller === 'findOne' && responseBody && responseBody['@type'] === 'hydra:Collection' &&
            responseBody['hydra:member'] instanceof Array && responseBody['hydra:member'].length > 0
        ) {
            if (responseBody['hydra:member'].length > 1) {
                console.error('Searched for one but more than one entities found!');
            }

            responseBody = this.parser.getParser().parse(entity, responseBody['hydra:member'][0]);
        }

        return responseBody;
    }
}
