import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {sharedExports} from './shared.exports';
import {sharedDeclarations} from './shared.declarations';
import {ConfirmationService, SpinnerModule} from 'primeng';
import {DragulaService} from 'ng2-dragula';

@NgModule({
    imports: [
        ...sharedExports,
        SpinnerModule,
    ],
    declarations: [
        ...sharedDeclarations
    ],
    providers: [
        ConfirmationService,
        DragulaService
    ],
    exports: [
        ...sharedExports,
        ...sharedDeclarations
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [
    ]
})
export class SharedModule {

}
