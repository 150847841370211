import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {VehicleMonthlyConsumption} from '../entities/vehicle-monthly-consumption';

@Injectable({
    providedIn: 'root'
})
export class VehicleMonthlyConsumptionRepository extends EntityRepository {

    public getTotal(params: any = {}): Observable<any[]> {
        const route = this.meta.getMetaDataProperty(new VehicleMonthlyConsumption(), Meta.META_ROUTE);

        return this.connection.get(environment.ENTITY_MANAGER_URL_PREFIX + route + '/total', {params})
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }
}
