import { Component, Input, OnInit } from '@angular/core';
import {DashboardBox} from '../../../service/dashboard-box';
import {DashboardComponent} from '../dashboard.component';
import {DashboardAction} from '../../../service/dashboard-action';
import {Parameter} from '../../../service/parameter';

class DashboardBoxSelect {
    public isSelected = false;
    public dashboardBox: DashboardBox;

    public constructor(dashboardBox: DashboardBox, isSelected: boolean = false) {
        this.dashboardBox = dashboardBox;
        this.isSelected = isSelected;
    }
}

@Component({
    selector: 'app-dashboard-box-list',
    templateUrl: './dashboard-box-list.component.html',
    styleUrls: ['./dashboard-box-list.component.scss']
})
export class DashboardBoxListComponent implements OnInit {

    @Input() dashboardComponent: DashboardComponent = null;

    public dashboardBoxesSelect: DashboardBoxSelect[] = [];

    public ngOnInit(): void {
        const dashboardBoxesSelect = [];

        for (const box of this.dashboardComponent.getDashboardService().getSetting().getBoxes()) {
            const boxSelect = new DashboardBoxSelect(box);

            dashboardBoxesSelect.push(boxSelect);
        }

        this.dashboardBoxesSelect = dashboardBoxesSelect;
    }

    public onAddBoxes() {
        const selectedBoxes = this.getSelectedBoxes();

        for (const dashboardBoxSelect of selectedBoxes) {
            this.dashboardComponent.getDashboardActionService().on(DashboardAction.AddBox, [
                new Parameter('dashboardBox', dashboardBoxSelect.dashboardBox)
            ]);
        }
    }

    public getSelectedBoxes(): DashboardBoxSelect[] {
        return this.dashboardBoxesSelect
            .filter((box: DashboardBoxSelect) => { return box.isSelected; });
    }
}
