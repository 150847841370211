import {Directive, OnInit, Self} from '@angular/core';
import {Table} from 'primeng';
import {DomHandler} from 'primeng/dom';

@Directive({
    selector: '[appTableOverrideBindDocumentEditListener]'
})
export class TableOverrideBindDocumentEditListenerDirective implements OnInit {

    public table: Table = null;

    public static isFromOverlay(event) {
        return event.path && event.path.some(path => path.className && path.className.includes('ui-datepicker'));
    }

    public constructor(@Self() table: Table) {
        this.table = table;

        if (!(this.table instanceof Table)) {
            console.error('Table works only with p-table component!');
        }
    }


    public ngOnInit(): void {
        this.override();
    }

    public override() {
        this.table.bindDocumentEditListener = () => {
            if (!this.table.documentEditListener) {
                this.table.documentEditListener = (event) => {
                    if (this.table.editingCell && !this.table.editingCellClick && this.table.isEditingCellValid() &&
                        !TableOverrideBindDocumentEditListenerDirective.isFromOverlay(event)
                    ) {
                        DomHandler.removeClass(this.table.editingCell, 'ui-editing-cell');
                        this.table.editingCell = null;
                        this.table.onEditComplete.emit({
                            field: this.table.editingCellField,
                            data: this.table.editingCellData, originalEvent: event
                        });
                        this.table.editingCellField = null;
                        this.table.editingCellData = null;
                        this.table.unbindDocumentEditListener();
                    }
                    this.table.editingCellClick = false;
                };
                document.addEventListener('click', this.table.documentEditListener);
            }
        };
    }

}
