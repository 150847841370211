import {PropertyPayloadModifierInterface} from '../property-payload-modifier-interface';
import {MathUtil} from '../../../../../../../src/app/core/utils/math-util';

export class DecimalPropertyPayloadModifier implements PropertyPayloadModifierInterface {

    public modify(property: string, entity: any, options: any = {decimalPlaces: 2}) {
        if (typeof entity[property] !== 'undefined') {
            entity[property] = MathUtil.round(entity[property], options.decimalPlaces);
        }
    }
}
