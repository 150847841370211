<p-panel styleClass="empty-messages" header="{{'HOME.MESSAGE_BOX.TITLE' | translate}}" *ngIf="messages.length === 0">
    <i class="fa fa-envelope-o"></i>
    <div>
        {{'HOME.MESSAGE_BOX.EMPTY' | translate}}
    </div>
</p-panel>
<app-flip-panel styleClass="messages" header="{{'HOME.MESSAGE_BOX.TITLE' | translate}}" *ngIf="messages.length > 0">
    <ul main #messageBox>
        <ng-container *ngFor="let message of messages">
            <li class="{{ message.getIsRead() ? 'read': 'unread'}}" (click)="viewMessage(message)">
                <app-lazy-image-preview
                        *ngIf="message?.getFromUser()"
                        [mediaObjectClassName]="'UserMediaObject'"
                        [mediaObjectOwner]="message.getFromUser()"
                        [mediaObjectContext]="'profilePicture'"
                >
                </app-lazy-image-preview>
                <img *ngIf="!message?.getFromUser()" [src]="placeHolderImage" alt="">
                <div class="box">
                    <div class="subject">{{message.getSubject()}}</div>
                    <div class="body"
                         [innerHTML]="(message.getBody().length>30)? (message.getBody() | slice:0:100)+'...':(message.getBody())"></div>
                </div>
                <span class="created-at">
                <i class="fa fa-calendar"></i>
                    {{message.getCreatedAt()| date:'dd.MM.yyyy. | HH:mm'}}
            </span>
            </li>
        </ng-container>
        <li class="all-messages">
            <span (click)="allMessages()">Sve poruke <i class="fa fa-angle-right"></i></span>
        </li>
    </ul>

    <div class="preview" secondary [style]="{'min-height': messagesBoxHeight + 'px'}">
        <div class="header">
            <app-lazy-image-preview
                    *ngIf="message?.getFromUser()"
                    [mediaObjectClassName]="'UserMediaObject'"
                    [mediaObjectOwner]="message.getFromUser()"
                    [mediaObjectContext]="'profilePicture'"
            >
            </app-lazy-image-preview>
            <img *ngIf="!message?.getFromUser()" [src]="placeHolderImage" alt="">
            <div class="user-from" *ngIf="message && message.getFromUser()">
                <strong>{{message.getFromUser().getFullName()}}<br/></strong>
                <span>{{message.getFromUser().getEmail()}}</span>
            </div>

            <div class="user-from" *ngIf="!message?.getFromUser()">
                <strong>Alexandar Cosmetics<br/></strong>
                <span>hr@alexandar-cosmetics.com</span>
            </div>

            <div class="created-at">
                <i class="fa fa-calendar"></i>
                {{message?.getCreatedAt() | date:'dd.MM.yyyy. | HH:mm'}}
            </div>
        </div>

        <h2 class="subject">
            <span>{{message?.getSubject()}}</span>
            <button (click)="viewMessages()"><i class="fa fa-mail-reply"></i></button>
        </h2>

        <div class="body" [innerHTML]="message?.getBody()"></div>
    </div>
</app-flip-panel>
