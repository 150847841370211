import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {WorkNote, WorkNoteType} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';

@Route('work_note_statistics')
@CrossDependency(() => {
    return {
        workNote: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNote
        },
        workNoteType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteType
        }
    };
})
export class WorkNoteStatistic {
    @AssociationOne(WorkNote)
    protected workNote;

    @AssociationOne(WorkNoteType)
    protected workNoteType;

    protected id: string;
    protected points: number;
    protected currentPoints: number;
    protected totalPoints: number;
    protected isLocked: boolean;

    public getId(): string {
        return this.id;
    }

    public setId(id: string): WorkNoteStatistic {
        this.id = id;
        return this;
    }

    public getWorkNote(): WorkNote {
        return this.workNote;
    }

    public setWorkNote(workNote: WorkNote): WorkNoteStatistic {
        this.workNote = workNote;
        return this;
    }

    public getWorkNoteType(): WorkNoteType {
        return this.workNoteType;
    }

    public setWorkNoteType(workNoteType: WorkNoteType): WorkNoteStatistic {
        this.workNoteType = workNoteType;
        return this;
    }

    public getPoints(): number {
        return this.points;
    }

    public setPoints(points: number): WorkNoteStatistic {
        this.points = points;
        return this;
    }

    public getCurrentPoints(): number {
        return this.currentPoints;
    }

    public setCurrentPoints(currentPoints: number): WorkNoteStatistic {
        this.currentPoints = currentPoints;
        return this;
    }

    public getTotalPoints(): number {
        return this.totalPoints;
    }

    public setTotalPoints(totalPoints: number): WorkNoteStatistic {
        this.totalPoints = totalPoints;
        return this;
    }
}
