import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {MessageService} from 'primeng/api';

@NgModule({
    imports: [
    ],
    exports: [],
    declarations: [
    ],
    providers: [
        MessageService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
}
