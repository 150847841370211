import 'reflect-metadata';
import {EntityManagerMetaDataService} from '../../service/meta/entity-manager-meta-data.service';
import {Meta} from '../../service/meta/meta';

export function PropertyResponseModifier(modifier: any): any {

    return (target: any, propertyKey: string) => {
        const meta = new EntityManagerMetaDataService();

        meta.createMetaData(target);

        const modifierNamespace = new modifier().constructor.name;

        if (!meta.hasMetaDataProperty(target, Meta.META_PROPERTY_RESPONSE_MODIFIER)) {
            const newObject = {};
            newObject[modifierNamespace] = {
                modifier,
                properties: {}
            };
            meta.setMetaDataProperty(target, Meta.META_PROPERTY_RESPONSE_MODIFIER, newObject);
        }

        if (!target.constructor[Meta.META][Meta.META_PROPERTY_RESPONSE_MODIFIER][modifierNamespace]) {
            target.constructor[Meta.META][Meta.META_PROPERTY_RESPONSE_MODIFIER][modifierNamespace] = {
                modifier,
                properties: {}
            };
        }

        target.constructor[Meta.META][Meta.META_PROPERTY_RESPONSE_MODIFIER][modifierNamespace].properties[propertyKey] = true;
    };
}
