import { NgModule } from '@angular/core';
import { XcentricComponent } from './xcentric.component';
import {DropdownModule} from 'primeng';



@NgModule({
  declarations: [XcentricComponent],
  imports: [
    DropdownModule
  ],
  exports: [XcentricComponent]
})
export class XcentricModule { }
