import { Directive, Self, OnInit, Output, EventEmitter, HostListener, Input, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import {Spinner} from 'primeng';
import {DecimalPipe} from '../pipes/decimal.pipe';

@Directive({
    selector: '[appSpinner]'
})
export class SpinnerDirective implements OnInit, AfterViewInit {

    @Output() valueChange = new EventEmitter<any>();

    @Input() public decimalSeparator = '.';
    @Input() public thousandSeparator = ',';
    @Input() public step = 0.25;
    @Input() public formatInput = true;
    @Input() public formatDecimalPlaces = 2;
    @Input() public formatIfZero = false;
    @Input() public changeOnFocus = false;
    @Input() public changeOnBlur = false;

    private decimalPipe = new DecimalPipe();
    private readonly spinner: Spinner = null;

    @HostListener('keyup', ['$event']) onKeyUp(event) {
        this.valueChange.emit({
            event,
            value: this.spinner.value
        });
    }

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
            this.valueChange.emit({
                event,
                value: this.spinner.value
            });
        }
    }

    public constructor(
        @Self() spinner: Spinner,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        this.spinner = spinner;

        if (!(this.spinner instanceof Spinner)) {
            console.error('Table works only with p-spinner component!');
        }

        this.spinner.decimalSeparator = this.decimalSeparator;
        this.spinner.thousandSeparator = this.thousandSeparator;
        this.spinner.step = this.step;
        this.spinner.formatInput = this.formatInput;
    }

    public ngOnInit(): void {
        this.spinner.onChange.subscribe((event: any) => {
            if (event instanceof MouseEvent) {
                this.valueChange.emit({
                    event,
                    value: this.spinner.value
                });
            }
        });

        if (this.changeOnBlur) {
            this.spinner.onBlur.subscribe((event: any) => {
                this.valueChange.emit({
                    event,
                    value: this.spinner.value
                });
            });
        }

        if (this.changeOnFocus) {
            this.spinner.onFocus.subscribe((event: any) => {
                this.valueChange.emit({
                    event: this.selectText(),
                    value: this.spinner.value
                });
            });
        }

        if (this.spinner.formatInput) {
            this.overrideSpinnerFormatInput();
            this.spinner.formatValue();
        }
    }

    public selectText() {
        const input = this.elementRef.nativeElement.querySelector('.ui-spinner-input');
        input.focus();
        input.select();
    }

    public ngAfterViewInit(): void {
        const spinnerButton = this.elementRef.nativeElement.querySelector('.ui-spinner-button');

        if (spinnerButton) {
            __ngRendererSetElementAttributeHelper(this.renderer, spinnerButton, 'tabIndex', '-1');
        }
    }

    protected overrideSpinnerFormatInput(): void {
        this.spinner.formatValue = () => {
            const value = this.spinner.value;

            this.spinner.formattedValue = this.decimalPipe.transform(value, `1.${this.formatDecimalPlaces}-${this.formatDecimalPlaces}`);

            if (value === 0 && !this.formatIfZero) {
                this.spinner.formattedValue = null;
            }

            if (this.spinner.inputfieldViewChild && this.spinner.inputfieldViewChild.nativeElement) {
                this.spinner.inputfieldViewChild.nativeElement.value = this.spinner.formattedValue;
            }
        };
    }
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ":") {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ["", name];
}

function __ngRendererSetElementAttributeHelper(renderer: AnyDuringRendererMigration, element: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    if (value != null) {
        renderer.setAttribute(element, name, value, namespace);
    }
    else {
        renderer.removeAttribute(element, name, namespace);
    }
}
