import {Associate} from './entities';
import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {StringPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {AssociateMonthlyCalculationRepository} from '../repositories/associate-monthly-calculation.repository';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';

export enum AssociateMonthlyCalculationPaymentTypeEnum {
    Account = 'account',
    Cash = 'cash'
}

@Route('associate_monthly_calculations')
@CrossDependency(() => {
    return {
        associate: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Associate
        },
        previousAssociateMonthlyCalculation: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: AssociateMonthlyCalculation
        }
    };
})
@Repository(AssociateMonthlyCalculationRepository)
@EntityDefaultFilters({
    'associate.isDismissed': false
})
export class AssociateMonthlyCalculation {

    protected id: string;

    @AssociationOne(Associate)
    protected associate: Associate;

    protected entryYear: string;
    protected entryMonth: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected entryDate: Date;

    @AssociationOne(AssociateMonthlyCalculation)
    protected previousAssociateMonthlyCalculation: AssociateMonthlyCalculation;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected paymentAmount: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected totalAmount: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected totalPaymentDeductionMobilePhoneBill: string;
    protected isPaymentDeductionMediGroupRegulated: boolean;

    protected paymentType: string;

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setAssociate(associate: Associate): this {
        this.associate = associate;
        return this;
    }

    public getAssociate(): Associate|undefined {
        return this.associate;
    }

    public setEntryYear(entryYear: string): AssociateMonthlyCalculation {
        this.entryYear = entryYear;
        return this;
    }

    public getEntryYear(): string {
        return this.entryYear;
    }

    public setEntryMonth(entryMonth: string): AssociateMonthlyCalculation {
        this.entryMonth = entryMonth;
        return this;
    }

    public getEntryMonth(): string {
        return this.entryMonth;
    }

    public setEntryDate(entryDate: Date): this {
        this.entryDate = entryDate;
        return this;
    }

    public getEntryDate(): Date | undefined {
        return this.entryDate;
    }

    public setPaymentAmount(paymentAmount: string): this {
        this.paymentAmount = paymentAmount;
        return this;
    }

    public getPaymentAmount(): string|undefined {
        return this.paymentAmount;
    }

    public setTotalAmount(totalAmount: string): this {
        this.totalAmount = totalAmount;
        return this;
    }

    public getTotalAmount(): string|undefined {
        return this.totalAmount;
    }

    public setTotalPaymentDeductionMobilePhoneBill(totalPaymentDeductionMobilePhoneBill: string): this {
        this.totalPaymentDeductionMobilePhoneBill = totalPaymentDeductionMobilePhoneBill;
        return this;
    }

    public getTotalPaymentDeductionMobilePhoneBill(): string|undefined {
        return this.totalPaymentDeductionMobilePhoneBill;
    }

    public setIsPaymentDeductionMediGroupRegulated(isPaymentDeductionMediGroupRegulated: boolean): this {
        this.isPaymentDeductionMediGroupRegulated = isPaymentDeductionMediGroupRegulated;
        return this;
    }

    public getIsPaymentDeductionMediGroupRegulated(): boolean|undefined {
        return this.isPaymentDeductionMediGroupRegulated;
    }

    public setPreviousAssociateMonthlyCalculation(previousAssociateMonthlyCalculation: AssociateMonthlyCalculation): this {
        this.previousAssociateMonthlyCalculation = previousAssociateMonthlyCalculation;
        return this;
    }

    public getPreviousAssociateMonthlyCalculation(): AssociateMonthlyCalculation|undefined {
        return this.previousAssociateMonthlyCalculation;
    }

    public setPaymentType(paymentType: string): this {
        this.paymentType = paymentType;
        return this;
    }

    public getPaymentType(): string|undefined {
        return this.paymentType;
    }
}

