import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe as NgDecimalPipe} from '@angular/common';

@Pipe({
    name: 'appDecimal'
})
export class DecimalPipe implements PipeTransform {

    public transform(value: string|number|undefined, format = '1.2-2'): string {
        const decimalPipe = new NgDecimalPipe('en-US');

        if (typeof value === 'undefined') {
            return '';
        }

        return decimalPipe.transform(value, format);
    }
}
