import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CommandEntity} from '../entities/command-entity';

@Injectable({
    providedIn: 'root'
})
export class CommandEntityRepository extends EntityRepository {

    public commandEntityAction(commandEntity: CommandEntity): Observable<CommandEntity> {

        const data = {
            name: commandEntity.getName(),
            commandArguments: commandEntity.getCommandArguments(),
            commandOptions: commandEntity.getCommandOptions()
        };

        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}command_entities`, data
        ).pipe(map((res) => {
            return this.parser.getParser().parse(new CommandEntity(), res);
        }));
    }
}
