import {CalendarRangeFilter} from './calendar-range-filter';
import * as moment from 'moment';

export class CalendarMultiDateRangeFilter extends CalendarRangeFilter {

    public buildFilterParams(params: any, filter): void {
        const filterValue = this.getFilterValue();

        params[`${filter}[${this.column.filter.index}][from][${this.column.filter.fromFieldKey}]`] = moment(filterValue[0]).format('YYYY-MM-DDTHH:mm:ss');
        params[`${filter}[${this.column.filter.index}][to][${this.column.filter.toFieldKey}]`] = moment(filterValue[1]).format('YYYY-MM-DDTHH:mm:ss');
    }
}
