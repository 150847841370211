import {Employee, Language, LanguageLevel} from './entities';
import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

@Route('employee_languages')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        language: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Language
        },
        level: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: LanguageLevel
        }
    };
})
export class EmployeeLanguage {

    protected id: string;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(Language)
    protected language: Language;

    @AssociationOne(LanguageLevel)
    protected level: LanguageLevel;

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public setLanguage(language: Language): this {
        this.language = language;
        return this;
    }

    public getLanguage(): Language|undefined {
        return this.language;
    }

    public setLevel(level: LanguageLevel): this {
        this.level = level;
        return this;
    }

    public getLevel(): LanguageLevel|undefined {
        return this.level;
    }
}

