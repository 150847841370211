import {Observable, of} from 'rxjs';
import {WorkflowConditionInterface} from './workflow-condition.interface';
import {WorkflowConditionStatus} from './workflow-condition-status';
import {Injector} from '@angular/core';
import {InjectorAwareInterface} from './injector-aware.interface';

export class WorkflowCondition implements WorkflowConditionInterface, InjectorAwareInterface {

    protected injector: Injector = null;

    public setInjector(injector: Injector): InjectorAwareInterface {
        this.injector = injector;
        return this;
    }

    public getInjector(): Injector {
        return this.injector;
    }

    public run(): Observable<WorkflowConditionStatus> {
        return of({
            status: true
        });
    }
}

