import {WorkflowCondition} from '../../../workflow/services/workflow-condition';
import {WorkflowConditionInterface} from '../../../workflow/services/workflow-condition.interface';
import {WorkflowConditionStatus} from '../../../workflow/services/workflow-condition-status';
import {Observable, of} from 'rxjs';
import {AuthenticationService} from '../../services/authentication.service';
import {User} from '../../entities/user';

export class IsUserLoggedInCondition extends WorkflowCondition implements WorkflowConditionInterface {
    public run(): Observable<WorkflowConditionStatus> {

        return of({
            status: this.getInjector().get(AuthenticationService).isLoggedIn() &&
                this.getInjector().get(AuthenticationService).getUser() instanceof User
        });
    }
}
