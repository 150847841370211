<p-panel>

  <p-header>

    <div class="pull-left">
      {{ getDashboardBox().getName() }}
    </div>

    <div class="pull-right">

      <button
        pButton
        type="button"
        *ngIf="null !== getDashboardBox() && null !== getDashboardBox().getEditComponent() && !isInEditMode"
        (click)="onEditOpen()"
        icon='fa fa-pencil'
      ></button>

      <button
        pButton
        type="button"
        *ngIf="isInEditMode"
        (click)="onEditFinish()"
        icon='fa fa-save'
        [pTooltip]="'Edit'"
      ></button>

      <button
        pButton
        type="button"
        *ngIf="isInEditMode"
        (click)="onEditCancel()"
        icon='fa fa-times'
        class='ui-button-danger'
      ></button>

      <button
        pButton
        type="button"
        *ngIf="!isInEditMode"
        (click)="onRemove()"
        icon='fa fa-trash'
        class='ui-button-danger'
      ></button>

    </div>
  </p-header>

  <div [hidden]="isInEditMode || isInLoadMode || errorMessage" class="card-block box-container">
    <!-- BoxComponent -->
    <ng-template #boxContainer></ng-template>
  </div>

  <div [hidden]="!isInEditMode || isInLoadMode" class="card-block edit-box-container">
    <!-- EditComponent -->
    <ng-template #editContainer></ng-template>
  </div>

  <div [hidden]="isInLoadMode || isInEditMode" class="card-block edit-box-container">
    <ng-template #editBoxContainer></ng-template>
  </div>

  <div [hidden]="!errorMessage || isInLoadMode || isInEditMode" class="alert alert-danger box-error-container" role="alert">
    <strong>Error!</strong> {{errorMessage}}
  </div>

  <div [hidden]="!isInLoadMode" class="loader"></div>
</p-panel>
