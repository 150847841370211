import {EventEmitter, Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SplashScreenService {

    public splashScreenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public constructor(

    ) {

    }

    public activate() {
        this.splashScreenChanged.next(true);
    }

    public deactivate() {
        this.splashScreenChanged.next(false);
    }
}
