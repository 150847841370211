import {Route} from 'entity-manager';

export enum PaymentTypeEnum {
    Virman = 'virman',
    Advance = 'advance'
}

@Route('payment_types')
export class PaymentType {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;
    protected isAdvance: boolean;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setIsAdvance(isAdvance: boolean) {
        this.isAdvance = isAdvance;
        return this;
    }

    public getIsAdvance(): boolean {
        return this.isAdvance;
    }

    public getIsVirmanType(): boolean {
        return this.getCode() === PaymentTypeEnum.Virman;
    }

    public getIsAdvanceType(): boolean {
        return this.getCode() === PaymentTypeEnum.Advance;
    }
}
