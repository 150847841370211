import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Department, Employee, EmployeeProfessionalQualification} from './entities';

export enum WorkTitleTypeEnum {
    Internal = 'internal',
    Official = 'official'
}

@Route('work_titles')
@CrossDependency(() => {
    return {
        department: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Department
        },
        professionalQualification: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeProfessionalQualification
        },
        superior: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        superiorDeputy: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        timeBookingResponsible: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        timeBookingResponsibleDeputy: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class WorkTitle {

    @AssociationOne(Department)
    protected department: Department;

    @AssociationOne(EmployeeProfessionalQualification)
    protected professionalQualification: EmployeeProfessionalQualification;

    @AssociationOne(Employee)
    protected superior: Employee;

    @AssociationOne(Employee)
    protected superiorDeputy: Employee;

    @AssociationOne(Employee)
    protected timeBookingResponsible: Employee;

    @AssociationOne(Employee)
    protected timeBookingResponsibleDeputy: Employee;

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;
    protected type: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription() {
        return this.description;
    }

    public setType(type: string) {
        this.type = type;
        return this;
    }

    public getType(): string {
        return this.type;
    }

    public getDepartment(): Department {
        return this.department;
    }

    public setDepartment(department: Department): WorkTitle {
        this.department = department;
        return this;
    }

    public setProfessionalQualification(professionalQualification: EmployeeProfessionalQualification): WorkTitle {
        this.professionalQualification = professionalQualification;
        return this;
    }

    public getProfessionalQualification(): EmployeeProfessionalQualification {
        return this.professionalQualification;
    }

    public setSuperior(superior: Employee): WorkTitle {
        this.superior = superior;
        return this;
    }

    public getSuperior(): Employee|undefined {
        return this.superior;
    }

    public setSuperiorDeputy(superiorDeputy: Employee): WorkTitle {
        this.superiorDeputy = superiorDeputy;
        return this;
    }

    public getSuperiorDeputy(): Employee|undefined {
        return this.superiorDeputy;
    }

    public setTimeBookingResponsible(timeBookingResponsible: Employee): WorkTitle {
        this.timeBookingResponsible = timeBookingResponsible;
        return this;
    }

    public getTimeBookingResponsible(): Employee|undefined {
        return this.timeBookingResponsible;
    }

    public setTimeBookingResponsibleDeputy(timeBookingResponsibleDeputy: Employee): WorkTitle {
        this.timeBookingResponsibleDeputy = timeBookingResponsibleDeputy;
        return this;
    }

    public getTimeBookingResponsibleDeputy(): Employee|undefined {
        return this.timeBookingResponsibleDeputy;
    }
}
