import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {VehicleService, VehicleRegistration, VehicleLicencePlate} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('vehicles')
@CrossDependency(() => {
    return {
        vehicleRegistrations: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: VehicleRegistration
        },
        vehicleServices: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: VehicleService
        },
        vehicleLicencePlates: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: VehicleLicencePlate
        },
        lastRegistration: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: VehicleRegistration
        },
        lastRegularService: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: VehicleService
        },
        lastBigService: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: VehicleService
        },
        lastSmallService: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: VehicleService
        },
        lastBrokenService: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: VehicleService
        }
    };
})
export class Vehicle {
    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationMany(VehicleRegistration)
    protected vehicleRegistrations: VehicleRegistration[];

    @AssociationOne(VehicleRegistration)
    protected lastRegistration: VehicleRegistration;

    @AssociationOne(VehicleService)
    protected lastRegularService: VehicleService;

    @AssociationOne(VehicleService)
    protected lastBigService: VehicleService;

    @AssociationOne(VehicleService)
    protected lastSmallService: VehicleService;

    @AssociationOne(VehicleService)
    protected lastBrokenService: VehicleService;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationMany(VehicleService)
    protected vehicleServices: VehicleService[];

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationMany(VehicleLicencePlate)
    protected vehicleLicencePlates: VehicleLicencePlate[];

    protected id: string;
    protected vehicleCategory: string;
    protected vehicleType: string;
    protected vehicleLicencePlate: string;
    protected previousVehicleLicencePlate: string;
    protected necessaryService: string;
    protected yearOfVehicle: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected dateOfPurchase: Date;

    protected warrantyPeriodMiles: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected warrantyPeriodDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected dateOfFirstRegistration: Date;

    protected typeOfFuel: string;
    protected bigServiceMilesInterval: string;
    protected smallServiceMilesInterval: string;
    protected regularServiceMilesInterval: string;
    protected averageFuelConsumption: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected regularServiceDate: Date;
    protected regularServiceMiles: string;
    protected fuelConsumption: string;
    protected kilometersTraveled: string;
    protected isVehicleSold: boolean;
    protected mileageVehicle: string;
    protected notificatorSenderBigServiceMileageVehicle: string;
    protected notificatorSenderSmallServiceMileageVehicle: string;
    protected notificatorSenderRegularServiceMileageVehicle: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected createdAt: Date;
    protected notice: string;
    protected isCarInsurance: boolean;

    public setVehicleRegistrations(vehicleRegistrations: VehicleRegistration[]): this {
        this.vehicleRegistrations = vehicleRegistrations;
        return this;
    }

    public getVehicleRegistrations(): VehicleRegistration[] | undefined {
        return this.vehicleRegistrations;
    }

    public setLastRegistration(lastRegistration: VehicleRegistration): this {
        this.lastRegistration = lastRegistration;
        return this;
    }

    public getLastRegistration(): VehicleRegistration|undefined {
        return this.lastRegistration;
    }

    public setLastRegularService(lastRegularService: VehicleService): this {
        this.lastRegularService = lastRegularService;
        return this;
    }

    public getLastRegularService(): VehicleService|undefined {
        return this.lastRegularService;
    }

    public setLastBigService(lastBigService: VehicleService): this {
        this.lastBigService = lastBigService;
        return this;
    }

    public getLastBigService(): VehicleService|undefined {
        return this.lastBigService;
    }

    public setLastSmallService(lastSmallService: VehicleService): this {
        this.lastSmallService = lastSmallService;
        return this;
    }

    public getLastSmallService(): VehicleService|undefined {
        return this.lastSmallService;
    }

    public setLastBrokenService(lastBrokenService: VehicleService): this {
        this.lastBrokenService = lastBrokenService;
        return this;
    }

    public getLastBrokenService(): VehicleService|undefined {
        return this.lastBrokenService;
    }

    public setVehicleServices(vehicleServices: VehicleService[]): this {
        this.vehicleServices = vehicleServices;
        return this;
    }

    public getVehicleServices(): VehicleService[] | undefined {
        return this.vehicleServices;
    }

    public setVehicleLicencePlates(vehicleLicencePlates: VehicleLicencePlate[]): this {
        this.vehicleLicencePlates = vehicleLicencePlates;
        return this;
    }

    public getVehicleLicencePlates(): VehicleLicencePlate[] | undefined {
        return this.vehicleLicencePlates;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setVehicleCategory(vehicleCategory: string): this {
        this.vehicleCategory = vehicleCategory;
        return this;
    }

    public getVehicleCategory(): string {
        return this.vehicleCategory;
    }

    public setVehicleType(vehicleType: string): this {
        this.vehicleType = vehicleType;
        return this;
    }

    public getVehicleType(): string {
        return this.vehicleType;
    }

    public setVehicleLicencePlate(vehicleLicencePlate: string): this {
        this.vehicleLicencePlate = vehicleLicencePlate;
        return this;
    }

    public getVehicleLicencePlate(): string {
        return this.vehicleLicencePlate;
    }

    public setPreviousVehicleLicencePlate(previousVehicleLicencePlate: string): this {
        this.previousVehicleLicencePlate = previousVehicleLicencePlate;
        return this;
    }

    public getPreviousVehicleLicencePlate(): string {
        return this.previousVehicleLicencePlate;
    }

    public setNecessaryService(necessaryService: string): this {
        this.necessaryService = necessaryService;
        return this;
    }

    public getNecessaryService(): string {
        return this.necessaryService;
    }

    public setYearOfVehicle(yearOfVehicle: string): this {
        this.yearOfVehicle = yearOfVehicle;
        return this;
    }

    public getYearOfVehicle(): string {
        return this.yearOfVehicle;
    }

    public setDateOfPurchase(dateOfPurchase: Date) {
        this.dateOfPurchase = dateOfPurchase;
        return this;
    }

    public getDateOfPurchase() {
        return this.dateOfPurchase;
    }

    public setWarrantyPeriodMiles(warrantyPeriodMiles: string): this {
        this.warrantyPeriodMiles = warrantyPeriodMiles;
        return this;
    }

    public getWarrantyPeriodMiles(): string {
        return this.warrantyPeriodMiles;
    }

    public setWarrantyPeriodDate(warrantyPeriodDate: Date) {
        this.warrantyPeriodDate = warrantyPeriodDate;
        return this;
    }

    public getWarrantyPeriodDate() {
        return this.warrantyPeriodDate;
    }

    public setDateOfFirstRegistration(dateOfFirstRegistration: Date) {
        this.dateOfFirstRegistration = dateOfFirstRegistration;
        return this;
    }

    public getDateOfFirstRegistration() {
        return this.dateOfFirstRegistration;
    }

    public setTypeOfFuel(typeOfFuel: string): this {
        this.typeOfFuel = typeOfFuel;
        return this;
    }

    public getTypeOfFuel(): string {
        return this.typeOfFuel;
    }

    public setBigServiceMilesInterval(bigServiceMilesInterval: string): this {
        this.bigServiceMilesInterval = bigServiceMilesInterval;
        return this;
    }

    public getBigServiceMilesInterval(): string {
        return this.bigServiceMilesInterval;
    }

    public setSmallServiceMilesInterval(smallServiceMilesInterval: string): this {
        this.smallServiceMilesInterval = smallServiceMilesInterval;
        return this;
    }

    public getSmallServiceMilesInterval(): string {
        return this.smallServiceMilesInterval;
    }

    public setRegularServiceMilesInterval(regularServiceMilesInterval: string): this {
        this.regularServiceMilesInterval = regularServiceMilesInterval;
        return this;
    }

    public getRegularServiceMilesInterval(): string {
        return this.regularServiceMilesInterval;
    }

    public setAverageFuelConsumption(averageFuelConsumption: string): this {
        this.averageFuelConsumption = averageFuelConsumption;
        return this;
    }

    public getAverageFuelConsumption(): string {
        return this.averageFuelConsumption;
    }

    public setRegularServiceDate(regularServiceDate: Date) {
        this.regularServiceDate = regularServiceDate;
        return this;
    }

    public getRegularServiceDate() {
        return this.regularServiceDate;
    }

    public setRegularServiceMiles(regularServiceMiles: string): this {
        this.regularServiceMiles = regularServiceMiles;
        return this;
    }

    public getRegularServiceMiles(): string {
        return this.regularServiceMiles;
    }

    public setFuelConsumption(fuelConsumption: string): this {
        this.fuelConsumption = fuelConsumption;
        return this;
    }

    public getFuelConsumption(): string {
        return this.fuelConsumption;
    }

    public setKilometersTraveled(kilometersTraveled: string): this {
        this.kilometersTraveled = kilometersTraveled;
        return this;
    }

    public getKilometersTraveled(): string {
        return this.kilometersTraveled;
    }

    public setIsVehicleSold(isVehicleSold: boolean): this {
        this.isVehicleSold = isVehicleSold;
        return this;
    }

    public getIsVehicleSold(): boolean|undefined {
        return this.isVehicleSold;
    }

    public getVehicleAndVehicleLicencePlate(): string {
        return this.getVehicleType()  + ' ' +  this.getVehicleLicencePlate();
    }

    public setMileageVehicle(mileageVehicle: string): this {
        this.mileageVehicle = mileageVehicle;
        return this;
    }

    public getMileageVehicle(): string {
        return this.mileageVehicle;
    }

    public setNotificatorSenderBigServiceMileageVehicle(notificatorSenderBigServiceMileageVehicle?: string): this {
        this.notificatorSenderBigServiceMileageVehicle = notificatorSenderBigServiceMileageVehicle;
        return this;
    }

    public getNotificatorSenderBigServiceMileageVehicle(): string {
        return this.notificatorSenderBigServiceMileageVehicle;
    }

    public setNotificatorSenderSmallServiceMileageVehicle(notificatorSenderSmallServiceMileageVehicle?: string): this {
        this.notificatorSenderSmallServiceMileageVehicle = notificatorSenderSmallServiceMileageVehicle;
        return this;
    }

    public getNotificatorSenderSmallServiceMileageVehicle(): string {
        return this.notificatorSenderSmallServiceMileageVehicle;
    }

    public setNotificatorSenderRegularServiceMileageVehicle(notificatorSenderRegularServiceMileageVehicle?: string): this {
        this.notificatorSenderRegularServiceMileageVehicle = notificatorSenderRegularServiceMileageVehicle;
        return this;
    }

    public getNotificatorSenderRegularServiceMileageVehicle(): string {
        return this.notificatorSenderRegularServiceMileageVehicle;
    }

    public setCreatedAt(createdAt: Date): this {
        this.createdAt = createdAt;
        return this;
    }

    public getCreatedAt(): Date|undefined {
        return this.createdAt;
    }

    public setNotice(notice: string): this {
        this.notice = notice;
        return this;
    }

    public getNotice(): string {
        return this.notice;
    }

    public setIsCarInsurance(isCarInsurance: boolean): this {
        this.isCarInsurance = isCarInsurance;
        return this;
    }

    public getIsCarInsurance(): boolean|undefined {
        return this.isCarInsurance;
    }
}
