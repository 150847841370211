import {AssociationOne, Route} from 'entity-manager';
import {WorkNote} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {StringPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier';

@Route('work_note_instalments')
@CrossDependency(() => {
    return {
        workNote: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNote,
            maxDepth: 2
        }
    };
})
export class WorkNoteInstalment {

    protected id: string;
    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected value: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected date: Date;

    @AssociationOne(WorkNote)
    protected workNote: WorkNote;

    public setId(id: string): WorkNoteInstalment {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setValue(value: string): WorkNoteInstalment {
        this.value = value;
        return this;
    }

    public getValue(): string {
        return this.value;
    }

    public setDate(date: Date): WorkNoteInstalment {
        this.date = date;
        return this;
    }

    public getDate(): Date {
        return this.date;
    }

    public setWorkNote(workNote: WorkNote): WorkNoteInstalment {
        this.workNote = workNote;
        return this;
    }

    public getWorkNote(): WorkNote {
        return this.workNote;
    }
}
