import {Route} from 'entity-manager';
import {DomainObjectInterface} from './domain-object.interface';

@Route('permissions')
export class ObjectPermission {

    protected fqn: string;
    protected key: string;
    protected grantOrRevoke: boolean;
    protected mask: string[];

    public static crateObjectPermission(grantOrRevoke: boolean, mask: string[]|string, object: any): ObjectPermission {
        const key = ObjectPermission.getKey(object);

        if (typeof mask === 'string') {
            mask = [mask];
        }

        return new ObjectPermission()
            .setFqn(object['@type'])
            .setKey(key)
            .setMask(mask)
            .setGrantOrRevoke(grantOrRevoke);
    }

    private static isDomainObjectInterface(object: any): object is DomainObjectInterface {
        return (object as any).getObjectIdentifier !== undefined;
    }

    private static getKey(object): string {

        if (ObjectPermission.isDomainObjectInterface(object)) {
            return object.getObjectIdentifier();
        }

        if (typeof object.getId === 'undefined') {
            console.error(`Object ${object} must either implement the DomainObjectInterface, or have a method named "getId"`);
        }

        return object.getId();
    }

    public setFqn(fqn: string): ObjectPermission {
        this.fqn = fqn;
        return this;
    }

    public getFqn(): string|undefined {
        return this.fqn;
    }

    public setKey(key: string): ObjectPermission {
        this.key = key;
        return this;
    }

    public getKey(): string|undefined {
        return this.key;
    }

    public setGrantOrRevoke(grantOrRevoke: boolean): ObjectPermission {
        this.grantOrRevoke = grantOrRevoke;
        return this;
    }

    public getGrantOrRevoke(): boolean|undefined {
        return this.grantOrRevoke;
    }

    public setMask(mask: string[] = []): ObjectPermission {
        this.mask = mask;
        return this;
    }

    public getMask(): string[]|undefined {
        return this.mask;
    }
}
