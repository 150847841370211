import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Absence, AbsenceRegulateTypeEnum, MediaObject} from '../entities/entities';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {HttpRequest} from "@angular/common/http";
import {Message} from "../entities/message";
import {Meta} from "../../../../projects/entity-manager/src/lib/service/meta/meta";

@Injectable({
    providedIn: 'root'
})
export class MessageRepository extends EntityRepository {

    public getLatest(params): Observable<any> {
        return this.connection.get(
            `${environment.ENTITY_MANAGER_URL_PREFIX}messages/latest/notifications`, {params: params}
        ).pipe(map((data: any[]) => {
                return {
                    unread: data['unread'],
                    messages: this.parser.getParser().parseArray(new Message(), data['messages'])
                }
        }));
    }
}
