import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Employee} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('booked_time_absences')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class BookedTimeAbsence {
    protected id: string;

    @AssociationOne(Employee)
    protected employee: Employee;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected fromDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected toDate: Date;

    protected numberOfHours: string;

    protected regulated: boolean;

    public getId(): string {
        return this.id;
    }

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getEmployee(): Employee {
        return this.employee;
    }

    public setEmployee(employee: Employee) {
        this.employee = employee;
        return this;
    }

    public getFromDate(): Date|undefined {
        return this.fromDate;
    }

    public setFromDate(fromDate: Date): this {
        this.fromDate = fromDate;
        return this;
    }

    public getToDate(): Date|undefined {
        return this.toDate;
    }

    public setToDate(toDate: Date): this {
        this.toDate = toDate;
        return this;
    }

    public getNumberOfHours(): string|undefined {
        return this.numberOfHours;
    }

    public setNumberOfHours(numberOfHours: string): this {
        this.numberOfHours = numberOfHours;
        return this;
    }

    public getRegulated(): boolean|undefined {
        return this.regulated;
    }

    public setRegulated(regulated: boolean): this {
        this.regulated = regulated;
        return this;
    }
}
