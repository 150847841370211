import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Employee} from '../entities/employee';
import {Observable, of} from 'rxjs';
import {Employment} from '../entities/employment';
import {switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EmploymentRepository extends EntityRepository {

    public findByEmployee(employee: string|Employee, forceFetch = false): Observable<Employment|null> {
        const employeeId = employee instanceof Employee ? employee.getId() : employee;

        return this.entityManager.getRepository(Employee)
            .find(employeeId)
            .pipe(switchMap((aEmployee: Employee|null) => {

                if (aEmployee instanceof Employee && aEmployee.getEmployment() instanceof Employment && forceFetch) {
                    return this.entityManager.getRepository(Employment)
                        .find(aEmployee.getEmployment().getId());
                }

                if (aEmployee instanceof Employee && aEmployee.getEmployment() instanceof Employment && !forceFetch) {
                    return aEmployee.getEmployment();
                }

                return of(null);
            }));
    }
}
