export class Parameter {

  private key: string = '';
  private value: any = null;

  public constructor(key: string = '', value: any = null) {
    this.key = key;
    this.value = value;
  }

  public getKey(): string {
    return this.key;
  }

  public setKey(key: string): Parameter {
    this.key = key;
    return this;
  }

  public getValue(): any | null {
    return this.value;
  }

  public setValue(value: any): Parameter {
    this.value = value;
    return this;
  }
}
