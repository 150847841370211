import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef, Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {delay, map, takeUntil} from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-flip-card',
    styleUrls: ['./flip-card.component.scss'],
    templateUrl: './flip-card.component.html'
})
export class FlipCardComponent implements OnInit, OnDestroy {

    @Input() public header = '';
    public context: 'main' | 'secondary' = 'main';
    public unsubscribe$ = new Subject<void>();

    public constructor(
        private cdr: ChangeDetectorRef,
        private elementRef: ElementRef
    ) {

    }

    public ngOnInit(): void {
        this.openMain(false);
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public openSecondary(): void {
        this.context = 'secondary';
        this.cdr.detectChanges();

        this.runFlipAnimation().subscribe();
    }

    public openSecondaryTest(): Observable<any> {
        this.context = 'secondary';
        this.cdr.detectChanges();

        return this.runFlipAnimation();
    }

    public openMain(flip: boolean = true): void {
        this.context = 'main';
        this.cdr.detectChanges();

        if (flip) {
            this.runFlipAnimation().subscribe();
        }
    }

    public runFlipAnimation(): Observable<any> {
        this.elementRef.nativeElement
            .querySelector('.container').classList
            .remove('flip');
        return of(true).pipe(
            delay(10)
        )
            .pipe(takeUntil(this.unsubscribe$),
                map(() => {
                    this.elementRef.nativeElement
                        .querySelector('.container').classList
                        .add('flip');
                }))
        ;
    }
}
