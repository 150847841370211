import {Injectable, Injector} from '@angular/core';
import {AbstractParser} from '../../parser/abstract-parser';
import {ParserMustDeriveFromAbstractParser} from '../../error/parser-must-derive-from-abstract-parser.error';
import {EntityManagerConfigurator} from '../../entity-manager.configurator';

@Injectable({
  providedIn: 'root'
})
export class EntityManagerParserService {

  private configuration;

  public constructor(
      private injector: Injector
  ) {
    this.configuration = this.injector.get<EntityManagerConfigurator>(EntityManagerConfigurator);
  }

  public getParser(): AbstractParser {
    const parserType: any = this.configuration.parser,
      parser = new parserType();

    if (!(parser instanceof AbstractParser) ) {
      throw new ParserMustDeriveFromAbstractParser(parser);
    }

    return parser;
  }
}
