import {AssociationMany, Repository, Route} from 'entity-manager';
import {Employee, Absence, WorkShift} from './entities';
import {CalendarEntryRepository} from '../repositories/calendar-entry.repository';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {AssociationManyToMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many-to-many';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('calendar_entries/generate')
@Repository(CalendarEntryRepository)
@CrossDependency(() => {
    return {
        employees: {
            mapping: Meta.META_ASSOCIATIONS_MANY_TO_MANY,
            entity: Employee
        },
        absences: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: Absence
        },
        workShift: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkShift
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class CalendarEntryGenerator {

    @AssociationManyToMany(Employee)
    protected employees: Employee[];

    @AssociationOne(Employee)
    protected employee: Employee;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected dateFrom: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected dateTo: Date;
    protected type: string;
    protected removeCurrent: boolean;

    @AssociationManyToMany(Absence)
    protected absences: Absence[];

    @AssociationMany(WorkShift)
    protected workShift: WorkShift;

    public constructor() {
        this.employees = [];
        this.absences = [];
    }

    public getEmployees(): Employee[] {
        return this.employees;
    }

    public setEmployees(employees: Employee[]): CalendarEntryGenerator {
        this.employees = employees;
        return this;
    }

    public addEmployee(employee: Employee): CalendarEntryGenerator {
        this.employees.push(employee);
        return this;
    }

    public getDateFrom(): Date {
        return this.dateFrom;
    }

    public setDateFrom(dateFrom: Date): CalendarEntryGenerator {
        this.dateFrom = dateFrom;
        return this;
    }

    public getDateTo(): Date {
        return this.dateTo;
    }

    public setDateTo(dateFrom: Date): CalendarEntryGenerator {
        this.dateTo = dateFrom;
        return this;
    }

    public getType(): string {
        return this.type;
    }

    public setType(type: string): CalendarEntryGenerator {
        this.type = type;
        return this;
    }

    public getRemoveCurrent(): boolean {
        return this.removeCurrent;
    }

    public setRemoveCurrent(removeCurrent: boolean): CalendarEntryGenerator {
        this.removeCurrent = removeCurrent;
        return this;
    }

    public getAbsences(): Absence[] {
        return this.absences;
    }

    public setAbsences(absences: Absence[]): CalendarEntryGenerator {
        this.absences = absences;
        return this;
    }

    public addAbsence(absence: Absence): CalendarEntryGenerator {
        this.absences.push(absence);
        return this;
    }

    public getWorkShift(): WorkShift {
        return this.workShift;
    }

    public setWorkShift(workShift: WorkShift): CalendarEntryGenerator {
        this.workShift = workShift;
        return this;
    }

    public getEmployee(): Employee {
        return this.employee;
    }

    public setEmployee(employee: Employee): CalendarEntryGenerator {
        this.employee = employee;
        return this;
    }
}
