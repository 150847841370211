import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Religion} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('patron_saint_days')
@CrossDependency(() => {
    return {
        religion: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Religion
        }
    };
})
export class PatronSaintDay {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected date: Date;

    protected month: number;
    protected day: number;

    @AssociationOne(Religion)
    protected religion: Religion;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription() {
        return this.description;
    }

    public setDate(date: Date) {
        this.date = date;
        return this;
    }

    public getDate() {
        return this.date;
    }

    public setMonth(month: number) {
        this.month = month;
        return this;
    }

    public getMonth() {
        return this.month;
    }

    public setDay(day: number) {
        this.day = day;
        return this;
    }

    public getDay() {
        return this.day;
    }

    public setReligion(religion: Religion) {
        this.religion = religion;
        return this;
    }

    public getReligion() {
        return this.religion;
    }
}
