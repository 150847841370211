import {EntityRepository} from "../../../../projects/entity-manager/src/lib/service/repository/entity-repository";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AssociateMonthlyPayment} from "../entities/associate-monthly-payment";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";
import {Meta} from "../../../../projects/entity-manager/src/lib/service/meta/meta";

@Injectable({
    providedIn: 'root'
})
export class AssociateMonthlyPaymentRepository extends EntityRepository {


    public getTotal(params: any = {}): Observable<any[]> {
        const route = this.meta.getMetaDataProperty(new AssociateMonthlyPayment(), Meta.META_ROUTE);

        return this.connection.get(environment.ENTITY_MANAGER_URL_PREFIX + route + '/total', {params})
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }
}
