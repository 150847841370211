import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {ObjectPermission} from '../entities/permission/object-permission';

@Injectable({
    providedIn: 'root'
})
export class PermissionRepository extends EntityRepository {

    public grantOrRevokeObject(grantOrRevoke: boolean, mask: string[]|string, object: any): Observable<ObjectPermission> {
        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}permissions/object`,
            ObjectPermission.crateObjectPermission(grantOrRevoke, mask, object)
        )
            .pipe(
                map((objectPermission: any) => {
                    return this.parser.getParser().parse(new ObjectPermission(), objectPermission);
                })
            );
    }

}
