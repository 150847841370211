<div class="user-profile">
    <img src="{{profilePictureUrl}}" alt="california-layout"/>
    <span class="sidebar-profile-name">{{userFullName}}</span>
    <span class="sidebar-profile-role" *ngIf="userSwitchService.isImpersonateUser()">
        <button
            pButton
            type="button"
            [label]="getOriginalUserFullName()"
            icon="fa fa-sign-out"
            class="ui-button-danger ui-button-min-width"
            (click)="userSwitchService.switchOff(app)"></button>
    </span>
    <span class="user-switch" *ngIf="roleService.hasMaintainerRole()">
        <p-autoComplete
            id="switchUser"
            appAutoComplete
            [field]="'fullName'"
            [dropdown]="true"
            [omitSelectedValue]="false"
            [handler]="userAutocompleteHandler"
            [forceSelection]="true"
            placeholder="---"
            (onSelect)="onSwitchUserSelected($event)"
        ></p-autoComplete>
        <button
            id="switchUserButton"
            pButton
            [disabled]="!switchUserSelected"
            type="button"
            icon="fa fa-sign-in"
            class="ui-button-success"
            (click)="userSwitchService.switchOn(app, switchUserSelected)"></button>
    </span>
    <span class="sidebar-profile-role"></span>
</div>
