import {Injectable} from '@angular/core';
import {KeyboardShortcutContext} from '../shared/table-keyboard-shortcut';

@Injectable({
    providedIn: 'root'
})
export class TableKeyboardShortcutService {

    public static getShortcutContext(event: KeyboardEvent): KeyboardShortcutContext {
        const eventKey = event.code;

        let context = null;

        switch (eventKey) {
            case 'KeyA': {
                if (event.ctrlKey && event.shiftKey) {
                    context = KeyboardShortcutContext.SelectAll;
                }
                break;
            }
            case 'KeyC': {
                if (event.ctrlKey && event.shiftKey) {
                    context = KeyboardShortcutContext.Copy;
                }
                break;
            }
            case 'KeyV': {
                if (event.ctrlKey && event.shiftKey) {
                    context = KeyboardShortcutContext.Paste;
                }
                break;
            }
            case 'Delete': {
                context = KeyboardShortcutContext.Delete;
                break;
            }
            case 'End': { // for now, stupid MacOs
                context = KeyboardShortcutContext.Add;
                break;
            }
            case 'Insert': {
                context = KeyboardShortcutContext.Add;
                break;
            }
            case 'KeyS': {
                if (event.ctrlKey && event.shiftKey) {
                    context = KeyboardShortcutContext.Save;
                }
            }
        }

        return context;
    }
}
