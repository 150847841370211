import {Route} from 'entity-manager';

export const UNNECESSARY_OPTIONS = [
    '--help',
    '--quiet',
    '--verbose',
    '--version',
    '--ansi',
    '--no-ansi',
    '--no-interaction',
    '--env',
    '--no-debug'
];

@Route('command_options')
export class CommandOption {

    protected name: string;
    protected description: string;
    protected shortcut: string;
    protected value: string;

    public getName() {
        return this.name;
    }

    public setName(name: string): this {
        this.name = name;
        return this;
    }

    public getDescription() {
        return this.description;
    }

    public setDescription(description: string): this {
        this.description = description;
        return this;
    }

    public getShortcut() {
        return this.shortcut;
    }

    public setShortcut(shortcut: string): this {
        this.shortcut = shortcut;
        return this;
    }

    public getValue() {
        return this.value;
    }

    public  setValue(value: string): this {
        this.value = value;
        return this;
    }
}
