import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TableSelectionStateService {

    private entities: any[] = [];

    public setEntities(entities: any[] = []): this {
        this.entities = entities;
        return this;
    }

    public getEntities(): any[] {
        return this.entities || [];
    }
}
