import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MediaObject} from '../../core/entities/media-object';
import {environment} from '../../../environments/environment';
import {User} from '../../core/entities/user';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';

@Component({
    selector: 'app-lazy-image-preview',
    templateUrl: './lazy-image-preview.component.html',
    styleUrls: ['./lazy-image-preview.component.scss']
})
export class LazyImagePreviewComponent implements OnInit {

    @Input() mediaObjectOwner = null;
    @Input() mediaObjectClassName = '';
    @Input() mediaObjectContext = '';
    public image = 'assets/layout/images/logo/alex-circle-logo.svg';

    public constructor(
        protected entityManager: EntityManagerService,
        protected cdr: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        const params = {
            mediaObjectClassName: this.mediaObjectClassName,
            mediaObjectOwnerId: this.mediaObjectOwner.getId(),
            mediaObjectContext: this.mediaObjectContext
        };
        this.entityManager.getRepository(MediaObject)
            .findOne(params)
            .subscribe((mediaObject: MediaObject) => {
                if (mediaObject) {
                    this.image = environment.BACKEND_ROOT_URL + mediaObject.getContentUrl();
                    this.cdr.detectChanges();
                }
            });
    }

}
