import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {User} from '../entities/user';

export enum RoleEnum {
    RoleUser = 'ROLE_USER',
    RoleMoneyTransactions = 'ROLE_MONEY_TRANSACTIONS',
    RoleMonthlyPayment = 'ROLE_MONTHLY_PAYMENT',
    RoleDriver = 'ROLE_DRIVER',
    RoleFinance = 'ROLE_FINANCE',
    RoleHeadOfDepartment = 'ROLE_HEAD_OF_DEPARTMENT',
    RoleHeadOfDrivers = 'ROLE_HEAD_OF_DRIVERS',
    RoleAdmin = 'ROLE_ADMIN',
    RoleSeniorAdmin = 'ROLE_SENIOR_ADMIN',
    RoleSuperAdmin = 'ROLE_SUPER_ADMIN',
    RoleMaintainer = 'ROLE_MAINTAINER'
}

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    public static readonly ROLES = [
        RoleEnum.RoleUser,
        RoleEnum.RoleMoneyTransactions,
        RoleEnum.RoleMonthlyPayment,
        RoleEnum.RoleDriver,
        RoleEnum.RoleFinance,
        RoleEnum.RoleHeadOfDepartment,
        RoleEnum.RoleHeadOfDrivers,
        RoleEnum.RoleAdmin,
        RoleEnum.RoleSeniorAdmin,
        RoleEnum.RoleSuperAdmin,
        RoleEnum.RoleMaintainer
    ];

    public static readonly ROLE_HIERARCHY: { [roleKey: string]: RoleEnum[]} = {
        ROLE_MONEY_TRANSACTIONS: [

        ],
        ROLE_MONTHLY_PAYMENT: [

        ],
        ROLE_FINANCE: [

        ],
        ROLE_USER: [

        ],
        ROLE_DRIVER: [

        ],
        ROLE_HEAD_OF_DEPARTMENT: [
            RoleEnum.RoleUser
        ],
        ROLE_HEAD_OF_DRIVERS: [
            RoleEnum.RoleDriver
        ],
        ROLE_ADMIN: [
            RoleEnum.RoleHeadOfDepartment,
            RoleEnum.RoleHeadOfDrivers,
            RoleEnum.RoleUser,
            RoleEnum.RoleDriver
        ],
        ROLE_SENIOR_ADMIN: [
            RoleEnum.RoleAdmin,
            RoleEnum.RoleHeadOfDepartment,
            RoleEnum.RoleHeadOfDrivers,
            RoleEnum.RoleUser,
            RoleEnum.RoleMoneyTransactions,
            RoleEnum.RoleDriver,
            RoleEnum.RoleFinance
        ],
        ROLE_SUPER_ADMIN: [
            RoleEnum.RoleSeniorAdmin,
            RoleEnum.RoleAdmin,
            RoleEnum.RoleHeadOfDepartment,
            RoleEnum.RoleHeadOfDrivers,
            RoleEnum.RoleUser,
            RoleEnum.RoleMoneyTransactions,
            RoleEnum.RoleDriver,
            RoleEnum.RoleFinance
        ],
        ROLE_MAINTAINER: [
            RoleEnum.RoleSuperAdmin,
            RoleEnum.RoleSeniorAdmin,
            RoleEnum.RoleAdmin,
            RoleEnum.RoleHeadOfDepartment,
            RoleEnum.RoleHeadOfDrivers,
            RoleEnum.RoleUser,
            RoleEnum.RoleMoneyTransactions,
            RoleEnum.RoleDriver,
            RoleEnum.RoleFinance,
            RoleEnum.RoleMonthlyPayment
        ]
    };

    public constructor(
        private authentication: AuthenticationService
    ) {

    }

    public isUserGranted(user: User, role: RoleEnum): boolean {
        let isGranted = user.hasRole(role);

        if (!isGranted) {
            isGranted = this.isGrantedInHierarchy(role);
        }

        return isGranted;
    }

    public isGranted(role: RoleEnum, user: User = null): boolean {
        return this.isUserGranted(user ?? this.getUser(), role);
    }

    private isGrantedInHierarchy(role: RoleEnum): boolean {
        const userRoles = this.getUser().getRoles();

        for (const userRole of userRoles) {
            if (RoleService.ROLE_HIERARCHY[userRole] && RoleService.ROLE_HIERARCHY[userRole].includes(role)) {
                return true;
            }
        }

        return false;
    }

    private getUser(): User {
        return this.authentication.getUser() || new User();
    }

    public hasHeadOfDepartmentRole(user: User = null): boolean {
        return this.isGranted(RoleEnum.RoleHeadOfDepartment, user);
    }

    public hasHeadOfDriversRole(): boolean {
        return this.isGranted(RoleEnum.RoleHeadOfDrivers);
    }

    public hasDriversRole(): boolean {
        return this.isGranted(RoleEnum.RoleDriver);
    }

    public hasAdminRole(user: User = null): boolean {
        return this.isGranted(RoleEnum.RoleAdmin, user);
    }

    public hasSeniorAdminRole(user: User = null): boolean {
        return this.isGranted(RoleEnum.RoleSeniorAdmin, user);
    }

    public hasSuperAdminRole(user: User = null): boolean {
        return this.isGranted(RoleEnum.RoleSuperAdmin, user);
    }

    public hasRoleMoneyTransactions(user: User = null): boolean {
        return this.isGranted(RoleEnum.RoleMoneyTransactions, user);
    }

    public hasRoleMonthlyPayment(user: User = null): boolean {
        return this.isGranted(RoleEnum.RoleMonthlyPayment, user);
    }

    public hasMaintainerRole(): boolean {
        return this.isGranted(RoleEnum.RoleMaintainer);
    }

    public isOnlyUserRole(): boolean {
        return this.isGranted(RoleEnum.RoleUser) && !this.isGranted(RoleEnum.RoleHeadOfDepartment) && !this.isGranted(RoleEnum.RoleMoneyTransactions);
    }

    public getUserHighestRoleInHierarchy(user: User): RoleEnum {
        const reversedRoles = [...RoleService.ROLES].reverse();

        for (const role of reversedRoles) {
            if (this.isUserGranted(user, role)) {
                return role;
            }
        }

        return RoleEnum.RoleUser;
    }

    public getHighestRoleInHierarchy(): RoleEnum {
        return this.getUserHighestRoleInHierarchy(this.getUser());
    }

    public getUserHighestRoleInHierarchyWeight(user: User): number {
        const highestRole = this.getUserHighestRoleInHierarchy(user);

        return RoleService.ROLES.indexOf(highestRole);
    }

    public getHighestRoleInHierarchyWeight(): number {
        return this.getUserHighestRoleInHierarchyWeight(this.getUser());
    }
}
