<div class="dashboard">
    <div class="ui-g" dragula="home">
        <div class="ui-g-12 ui-md-12 ui-lg-4">

            <div class="overview-box monthly-sales">
                <div class="overview-title">BOLOVANJA</div>
                <i class="fa fa-external-link-square"></i>
                <div class="overview-numbers">
                    <strong>12</strong>
                </div>
                <div class="overview-ratios">16% više nego prošli mesec</div>
                <img src="assets/layout/images/icon-users.png" alt="bolovanja" />
            </div>
        </div>
        <div  class="ui-g-12 ui-md-12 ui-lg-4">
            <div class="overview-box monthly-views">
                <div class="overview-title">GODIŠNJI ODMORI
                </div>


                <div class="overview-numbers">
                    <strong>5</strong>
                </div>
                <div class="overview-ratios">5% manje nego prošli mesec</div>
                <img src="assets/layout/images/icon-users.png" alt="godišnji odmor" />
            </div>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-4">
            <div class="overview-box monthly-users">
                <div class="overview-title">UKUPNO ZAPOSLENIH
                    <i class="fa fa-arrow-circle-up"></i>
                </div>
                <div class="overview-numbers">
                    <strong>256</strong>
                </div>
                <div class="overview-ratios">10% više nego prošle godine </div>
                <img src="assets/layout/images/icon-users.png" alt="Ukupno zaposlenih" />
            </div>
        </div>

        <app-home-messages-counter class="ui-g-12 ui-md-6 ui-lg-3"></app-home-messages-counter>

        <app-home-count-box
                [title]="'HOME.COUNT_BOX.DELAYS' | translate"
                number="12"
                [subtitle]="'HOME.COUNT_BOX.LESS_THEN_MONTH' | translate: {total: '23%'}"
                icon="fa fa-clock-o"
                class="ui-g-12 ui-md-6 ui-lg-3"
        ></app-home-count-box>

        <app-home-count-box
                [title]="'HOME.COUNT_BOX.WORK_NOTE' | translate"
                number="3"
                [subtitle]="'HOME.COUNT_BOX.MORE_THEN_MONTH' | translate: {total: '13%'}"
                icon="fa fa-exclamation"
                class="ui-g-12 ui-md-6 ui-lg-3"
        ></app-home-count-box>

        <app-home-count-box
                [title]="'HOME.COUNT_BOX.NEW_WORKERS' | translate"
                number="2"
                [subtitle]="'HOME.COUNT_BOX.MORE_THEN_MONTH' | translate: {total: '8%'}"
                icon="fa fa-user"
                class="ui-g-12 ui-md-6 ui-lg-3"
        ></app-home-count-box>

        <app-home-messages-box class="ui-g-12 ui-md-12 ui-lg-6"></app-home-messages-box>

        <div class="ui-g-12 ui-md-12 ui-lg-6">
            <p-panel header="Nedeljni zadaci" styleClass="weekly-tasks">
                <ul>
                    <li>
                        <p-checkbox binary="true"></p-checkbox>
                        <span>Sastanak sa timom</span>
                        <div class="weekly-task-status">
                            <span class="day">DANAS, 12:30</span>
                            <i class="fa fa-shopping-cart"></i>
                        </div>
                    </li>
                    <li>
                        <p-checkbox binary="true"></p-checkbox>
                        <span>Sastanak sa Aleksandrom</span>
                        <div class="weekly-task-status">
                            <span class="day">DANAS, 15:30</span>
                            <i class="fa fa-file"></i>
                        </div>
                    </li>
                    <li>
                        <p-checkbox binary="true"></p-checkbox>
                        <span>Kontaktirati NYX</span>
                        <div class="weekly-task-status">
                            <span class="day">SREDA, 10:00</span>
                            <i class="fa fa-birthday-cake"></i>
                        </div>
                    </li>
                    <li>
                        <p-checkbox binary="true"></p-checkbox>
                        <span>Nabavka kancelarijskog materijala</span>
                        <div class="weekly-task-status">
                            <span class="day">ČETVRTAK. 09:00</span>
                            <i class="fa fa-users"></i>
                        </div>
                    </li>
                    <li>
                        <p-checkbox binary="true"></p-checkbox>
                        <span>Sastanak sa programerima</span>
                        <div class="weekly-task-status">
                            <span class="day">ČETVRTAK, 13:00</span>
                            <i class="fa fa-photo"></i>
                        </div>
                    </li>
                    <li>
                        <p-checkbox binary="true"></p-checkbox>
                        <span>Rezarvacija avionske karte</span>
                        <div class="weekly-task-status">
                            <span class="day">PETAK, 12:00</span>
                            <i class="fa fa-plane"></i>
                        </div>
                    </li>
                    <li>
                        <p-checkbox binary="true"></p-checkbox>
                        <span>Sastanak sa timom</span>
                        <div class="weekly-task-status">
                            <span class="day">Petak, 14:30</span>
                            <i class="fa fa-cloud-upload"></i>
                        </div>
                    </li>
                </ul>
            </p-panel>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-6 ui-fluid">
            <p-panel header="Brzo slanje zahteva" styleClass="resolution-center">
                <div class="ui-g">
                    <div class="ui-g-12">
                        <p-dropdown [options]="[{label:'Bolovanje'},{label:'Godišnji'}]" ></p-dropdown>
                    </div>
                    <div class="ui-g-12">
                        <p-calendar selectionMode="range" placeholder="Izaberite datum" icon="fa fa-calendar" showIcon="true"></p-calendar>
                    </div
                    ><div class="ui-g-12">
                        <textarea type="text" rows="5" id="message" pInputTextarea placeholder="Komentar"></textarea>
                    </div>
                    <div class="ui-g-12">
                        <button type="button" label="Pošalji" pButton></button>
                    </div>
                </div>
            </p-panel>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-6">
            <p-panel styleClass="team" header="Tim">
                <ul>
                    <li>
                        <img src="assets/layout/images/avatar-arlene.png" alt="california-layout" />
                        <div class="team-box">
                            <span class="team-member">Dragana Janković</span>
                            <span class="team-member-role">Poslovođa</span>
                        </div>
                        <a href="#">
                            <i class="fa fa-phone"></i>
                        </a>
                        <a href="#">
                            <img class="logo" src="assets/layout/images/logo/alex-logo.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <img src="assets/layout/images/avatar-john.png" alt="california-layout" />
                        <div class="team-box">
                            <span class="team-member">Petar Petrović</span>
                            <span class="team-member-role">Komercijalista</span>
                        </div>
                        <a href="#">
                            <i class="fa fa-phone"></i>
                        </a>
                        <a href="#">
                            <img class="logo" src="assets/layout/images/logo/alex-logo.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <img src="assets/layout/images/avatar-brenda.png" alt="california-layout" />
                        <div class="team-box">
                            <span class="team-member">Jovana Nikolić</span>
                            <span class="team-member-role">Komercijalista</span>
                        </div>
                        <a href="#">
                            <i class="fa fa-phone"></i>
                        </a>
                        <a href="#">
                            <img class="logo" src="assets/layout/images/logo/alex-logo.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <img src="assets/layout/images/avatar-warren.png" alt="california-layout" />
                        <div class="team-box">
                            <span class="team-member">Dragan Nikolić</span>
                            <span class="team-member-role">Magacioner</span>
                        </div>
                        <a href="#">
                            <i class="fa fa-phone"></i>
                        </a>
                        <a href="#">
                            <img class="logo" src="assets/layout/images/logo/alex-logo.svg" alt="">
                        </a>
                    </li>
                </ul>
            </p-panel>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-4">
            <p-panel styleClass="daily-sales" header="Spisak zaposlenih kojima ističe ugovor ">
                <table>
                    <thead>
                    <tr>
                        <th>Zaposleni</th>
                        <th>Pozicija</th>
                        <th>Datum isteka</th>
                        <th>Pregled</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Nikola Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Jovana Miljković</td>
                        <td>Komericjalista</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragana Petrović Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Stefan Milenković</td>
                        <td>Komericjalista</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Petar Petrović</td>
                        <td>Komericjalista</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Jovana Jokić</td>
                        <td>Komericjalista</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Mirjana Milanović Savić</td>
                        <td>Komericjalista</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </p-panel>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-4">
            <p-panel styleClass="daily-sales" header="Spisak zaposlenih kojima ističe lični dokument ">
                <table>
                    <thead>
                    <tr>
                        <th>Zaposleni</th>
                        <th>Tip dokumenta</th>
                        <th>Datum isteka</th>
                        <th>Pregled</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Lična karta</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Lična karta</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Pasoš</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Vozačka dozvola</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Lična karta</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Lična karta</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Pasoš</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Lična karta</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Zdravstvena knjižica</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Lična karta</td>
                        <td>
                            12.05.2020.
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </p-panel>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-4">
            <p-panel styleClass="daily-sales" header="Spisak zaposlenih sa najviše prekršaja">
                <table>
                    <thead>
                    <tr>
                        <th>Zaposleni</th>
                        <th>Pozicija</th>
                        <th>Broj prekršaja</th>
                        <th>Pregled</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            12 <i class="fa fa-angle-up"></i>
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            11 <i class="fa fa-angle-up"></i>
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            11 <i class="fa fa-angle-down"></i>
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            10 <i class="fa fa-angle-up"></i>
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            10 <i class="fa fa-angle-up"></i>
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            8 <i class="fa fa-angle-down"></i>
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            5 <i class="fa fa-angle-up"></i>
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            5 <i class="fa fa-angle-up"></i>
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            4 <i class="fa fa-angle-up"></i>
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    <tr>
                        <td>Dragan Kovačević</td>
                        <td>Komericjalista</td>
                        <td>
                            4 <i class="fa fa-angle-down"></i>
                        </td>
                        <td>
                            <p-button type="button" icon="fa fa-search"></p-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </p-panel>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-6">
            <p-panel styleClass="chart" header="Broj zaposlenih">
                <p-chart type="line" [data]="chartData"></p-chart>
            </p-panel>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
            <p-panel styleClass="chart" header="Broj Prekršaja">
                <p-chart type="bar" [data]="chartData2"></p-chart>
            </p-panel>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-6">
            <p-panel header="Kalendar" styleClass="schedule">
                <p-fullCalendar [events]="events" [options]="fullcalendarOptions" ></p-fullCalendar>
            </p-panel>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-6">
            <p-panel header="Lokacije" styleClass="locations">
                <p-gmap [options]="gmapOptions" [overlays]="overlays" [style]="{'width':'100%','height':'570px'}" ></p-gmap>
            </p-panel>
        </div>

    </div>
</div>
