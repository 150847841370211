import {Runner} from './runner';
import {WorkflowInterface} from './workflow.interface';
import {WorkflowAction} from './workflow-action';
import {WorkflowActionInterface} from './workflow-action.interface';
import {WorkflowConditionInterface} from './workflow-condition.interface';
import {WorkflowCondition} from './workflow-condition';
import {Injectable, Injector} from '@angular/core';
import {InjectorAwareInterface} from './injector-aware.interface';

@Injectable({
    providedIn: 'root'
})
export class Workflow implements WorkflowInterface, InjectorAwareInterface {
    private runners: Runner[] = [];

    protected injector: Injector = null;

    public setInjector(injector: Injector): InjectorAwareInterface {
        this.injector = injector;
        return this;
    }

    public getInjector(): Injector {
        return this.injector;
    }

    public startWith(workflowAction: WorkflowActionInterface): this {
        this.add(workflowAction, 'startWith');

        return this;
    }

    public then(workflowActionOrWorkflow: WorkflowActionInterface|WorkflowInterface): this {
        this.add(workflowActionOrWorkflow, 'then');

        return this;
    }

    public isTrue(workflowActionOrWorkflow: WorkflowActionInterface|WorkflowInterface): this {
        this.add(workflowActionOrWorkflow, 'isTrue');

        return this;
    }

    public isFalse(workflowActionOrWorkflow: WorkflowActionInterface|WorkflowInterface): this {
        this.add(workflowActionOrWorkflow, 'isFalse');

        return this;
    }

    public if(workflowCondition: WorkflowConditionInterface): this {
        this.add(workflowCondition, 'if');

        return this;
    }

    public add(object: WorkflowAction|Workflow|WorkflowCondition|any, context: string): this {
        object.setInjector(this.injector);

        if (object instanceof WorkflowAction) {
            this.runners.push({
                context,
                object,
                index: this.runners.length,
                isExecuted: false
            });
        }

        if (object instanceof Workflow) {
            this.runners.push({
                context,
                object,
                index: this.runners.length,
                isExecuted: false
            });
        }

        if (object instanceof WorkflowCondition) {
            this.runners.push({
                context,
                object,
                index: this.runners.length,
                isExecuted: false
            });
        }

        return this;
    }

    public getRunners() {
        return this.runners;
    }

    public hasRunnerAt(index): boolean {
        return typeof this.runners[index] !== 'undefined';
    }

    public getRunnerAt(index): Runner|null {
        return this.hasRunnerAt(index) ? this.runners[index] : null;
    }

    public hasPreviousRunner(runner) {
        return this.hasRunnerAt(runner.index - 1);
    }

    public hasNextRunner(runner) {
        return this.hasRunnerAt(runner.index + 1);
    }

    public getPreviousRunner(runner): Runner|null {
        return this.hasRunnerAt(runner.index - 1) ? this.runners[runner.index - 1] : null;
    }

    public getPreviousExecutedRunner(runner): Runner|null {
        const previousRunner = this.getPreviousRunner(runner);

        if (previousRunner && previousRunner.isExecuted === true) {
            return previousRunner;
        }

        if (previousRunner && previousRunner.isExecuted !== true) {
            return this.getPreviousRunner(previousRunner);
        }

        return null;
    }

    public getPreviousConditionRunner(runner): Runner|null {
        const previousRunner = this.getPreviousRunner(runner);

        if (previousRunner && previousRunner.object instanceof WorkflowCondition) {
            return previousRunner;
        }

        if (previousRunner && !(previousRunner.object instanceof WorkflowCondition)) {
            return this.getPreviousConditionRunner(previousRunner);
        }

        return null;
    }

    public getNextRunner(runner): Runner|null {
        return this.hasRunnerAt(runner.index + 1) ? this.runners[runner.index + 1] : null;
    }
}
