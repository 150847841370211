import {AbstractAdvanceFilter} from './abstract-advance-filter';

export class TextAdvanceFilter extends AbstractAdvanceFilter {

    public getValueFromChange(event, param) {
        if (event.target.value.length === 0) {
          return null;
        }

        return event.target.value;
    }

}
