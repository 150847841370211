import {Associate} from './entities';
import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {StringPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier';
import {AssociateMonthlyPaymentPayoutListComponent} from "../../hr/associate/monthly-payment/payout/payout-list/associate-monthly-payment-payout-list.component";
import {AssociateMonthlyPaymentRepository} from "../repositories/associate-monthly-payment.repository";
import {Repository} from "../../../../projects/entity-manager/src/lib/decorator/repository";

@Route('associate_monthly_payments')
@CrossDependency(() => {
    return {
        associate: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Associate
        },
        previousAssociateMonthlyPayment: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: AssociateMonthlyPayment
        }
    };
})
@Repository(AssociateMonthlyPaymentRepository)
export class AssociateMonthlyPayment {

    protected id: string;

    @AssociationOne(Associate)
    protected associate: Associate;

    protected entryYear: string;
    protected entryMonth: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected payout: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected payoutRest: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected payoutReal: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected payoutDifference: string;

    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected payoutPercent: string;

    @AssociationOne(AssociateMonthlyPayment)
    protected previousAssociateMonthlyPayment: AssociateMonthlyPayment;

    protected isPaid: boolean;

    protected paymentType: string;

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setAssociate(associate: Associate): this {
        this.associate = associate;
        return this;
    }

    public getAssociate(): Associate|undefined {
        return this.associate;
    }

    public setEntryYear(entryYear: string): this {
        this.entryYear = entryYear;
        return this;
    }

    public getEntryYear(): string {
        return this.entryYear;
    }

    public setEntryMonth(entryMonth: string): this {
        this.entryMonth = entryMonth;
        return this;
    }

    public getEntryMonth(): string {
        return this.entryMonth;
    }

    public setPayout(payout: string): this {
        this.payout = payout;
        return this;
    }

    public getPayout(): string|undefined {
        return this.payout;
    }

    public setPayoutRest(payoutRest: string): this {
        this.payoutRest = payoutRest;
        return this;
    }

    public getPayoutRest(): any|undefined {
        return this.payoutRest;
    }

    public setPayoutReal(payoutReal: string): this {
        this.payoutReal = payoutReal;
        return this;
    }

    public getPayoutReal(): any|undefined {
        return this.payoutReal;
    }

    public setPayoutDifference(payoutDifference: string|number|any): this {
        this.payoutDifference = payoutDifference;
        return this;
    }

    public getPayoutDifference(): any|undefined {
        return this.payoutDifference;
    }

    public setPayoutPercent(payoutPercent: string): this {
        this.payoutPercent = payoutPercent;
        return this;
    }

    public getPayoutPercent(): string|undefined {
        return this.payoutPercent;
    }

    public setIsPaid(isPaid: boolean): this {
        this.isPaid = isPaid;
        return this;
    }

    public getIsPaid(): boolean|undefined {
        return this.isPaid;
    }

    public setPreviousAssociateMonthlyPayment(previousAssociateMonthlyPayment: AssociateMonthlyPayment): this {
        this.previousAssociateMonthlyPayment = previousAssociateMonthlyPayment;
        return this;
    }

    public getPreviousAssociateMonthlyPayment(): AssociateMonthlyPayment|undefined {
        return this.previousAssociateMonthlyPayment;
    }

    public setPaymentType(paymentType: string): this {
        this.paymentType = paymentType;
        return this;
    }

    public getPaymentType(): string|undefined {
        return this.paymentType;
    }
}

