import {DashboardComponent} from '../../component/dashboard/dashboard.component';
import {DashboardActionService} from '../renderer/dashboard-action.service';
import {Parameter} from '../parameter';

export abstract class AbstractActionHandler {

  protected dashboardComponent: DashboardComponent = null;
  protected dashboardActionService: DashboardActionService = null;

  protected parameters: Parameter[] = [];

  public abstract handleAction();

  public getPayload(): any {
    // virtual
  }

  public setParameters(parameters: Parameter[] = []): this {
    this.parameters = parameters;
    return this;
  }

  public getParameter(key: string): Parameter | null {
    let found = null;

    for (const parameter of this.parameters) {
      if (parameter.getKey() === key) {
        found = parameter;
        break;
      }
    }

    return found;
  }

  public getParameterValue(key: string): any {
    let value = null;
    const parameter = this.getParameter(key);

    if (null !== parameter) {
      value = parameter.getValue();
    }

    return value;
  }

  public setDashboardComponent(dashboardComponent: DashboardComponent): this {
    this.dashboardComponent = dashboardComponent;
    return this;
  }

  public getDashboardComponent(): DashboardComponent {
    return this.dashboardComponent;
  }

  public setDashboardActionService(dashboardActionService: DashboardActionService): this {
    this.dashboardActionService = dashboardActionService;
    return this;
  }

  public getDashboardActionService(): DashboardActionService {
    return this.dashboardActionService;
  }
}
