<div class="ui-g">
    <div
        class="ui-g-12"
    >
        <p-spinner
                appSpinner
                [formatInput]="column?.filter?.formatInput || false"
                decimalSeparator="."
                thousandSeparator=","
                [step]="column?.filter?.step || 0.25"
                [(ngModel)]="inputValue"
                (valueChange)="onValueChange($event)"
        ></p-spinner>
    </div>

    <div
        *ngIf="filter.getFilterOperatorValue() === 'between'"
        class="ui-g-12 range"
    >
        <p-spinner
            appSpinner
            [min]="inputValue"
            [formatInput]="column?.filter?.formatInput || false"
            [step]="column?.filter?.step || 0.25"
            decimalSeparator="."
            thousandSeparator=","
            [(ngModel)]="secondInputValue"
            (valueChange)="onValueChange($event)"
        ></p-spinner>
    </div>
</div>
