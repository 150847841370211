<div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
        <div class="ui-grid-col-12">
            <div class="ui-grid ui-grid-responsive ui-fluid">
                <div class="ui-grid-row">
                    <div class="ui-grid-col-12" *ngIf="items.length > 0 && display==='inline'">
                        <p-splitButton
                                label="{{ 'FILE.ADD_FILES' | translate }}"
                                [model]="items"
                                [disabled]="disabled"
                                icon="fa fa-paperclip"
                                styleClass="ui-button-info"
                                [style]="{'width': '100%'}"
                                (click)="onOpenUpload($event)"
                                (onDropdownClick)="onDropdownClick($event)"
                        ></p-splitButton>
                    </div>

                    <div class="ui-grid-col-12" *ngIf="items.length > 0 && display==='list'">
                        <button
                                pButton
                                type="button"
                                [disabled]="disabled"
                                label="{{ 'FILE.ADD_FILES' | translate }}"
                                icon="fa fa-paperclip"
                                class="ui-button-info"
                                (click)="onOpenUpload($event)"
                        >
                        </button>
                        <p-tabMenu [model]="items" styleClass="upload-files">
                            <ng-template let-item pTemplate="item">
                                <div>
                                    <i class="{{item.icon}}"></i>
                                    <span>{{item.label}}</span>
                                </div>
                                <div>
                                    <i (click)="onItemDownload(item)" class="fa fa-cloud-download"></i>
                                    <i (click)="onItemRemove(item)" class="fa fa-trash-o" style="margin-left: 40px" ></i>
                                </div>
                            </ng-template>
                        </p-tabMenu>
                    </div>

                    <div class="ui-grid-col-12" *ngIf="items.length === 0">
                        <button
                                pButton
                                type="button"
                                [disabled]="disabled"
                                label="{{ 'FILE.ADD_FILES' | translate }}"
                                icon="fa fa-print"
                                class="ui-button-info"
                                (click)="onOpenUpload($event)"
                        >
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog
        *ngIf="isUploadVisible"
        [(visible)]="isUploadVisible"
        [modal]="true"
        [style]="{width: '80vw'}"
        [maximizable]="false"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
>
    <p-fileUpload
            chooseLabel="{{ 'FILE.CHOOSE' | translate }}"
            uploadLabel="{{ 'FILE.UPLOAD' | translate }}"
            cancelLabel="{{ 'FILE.CANCEL' | translate }}"
            mode="{{mode}}"
            name="{{name}}"
            url="{{url}}"
            accept="{{accept}}"
            multiple="true"
            maxFileSize="1000000000000"
            customUpload="true"
            (onClear)="onClear()"
            (uploadHandler)="onUpload($event)"
    >
        <ng-template let-i="index" let-file pTemplate="file">
            <div class="ui-g" [style.width.vw]="60">
                <div class="ui-g-2">
                    <img alt="{{file.name}}" [src]="file.objectURL" *ngIf="fileUpload.isImage(file)" [width]="100"/>
                </div>
                <div class="ui-g-6">
                    <input appDisableAutofill
                           [style.width.%]="100"
                           pInputText
                           [ngModel]="file.name"
                           type="text"
                           (keydown)="onFileNameChange($event, i)"
                    >
                </div>
                <div class="ui-g-1">
                    {{fileUpload.formatSize(file.size)}}
                </div>
                <div class="ui-g-1">
                    <button type="button" icon="pi pi-times" pButton (click)="fileUpload.remove($event,i)"
                            [disabled]="fileUpload.uploading"></button>
                </div>
            </div>
        </ng-template>
    </p-fileUpload>
</p-dialog>
