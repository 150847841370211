import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EstimateRepository extends EntityRepository {

    public generateNumber(): Observable<string> {
        return this.connection.post(
            `${environment.ENTITY_MANAGER_URL_PREFIX}estimates/generatenumber`,
            {}
        ).pipe(
            map((generatedNumber: {number: string}) => {
                return generatedNumber.number;
            })
        );
    }
}
