import {Route} from 'entity-manager';
import {
    Currency,
    Employee,
    MoneyLoanNote,
    MoneyTransactionBookTransaction,
    MoneyTransactionStatus,
    Workplace
} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {User} from './user';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {MoneyTransactionRepository} from '../repositories/money-transaction.repository';
import {IncomeType} from './income-type';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';

export enum MoneyTransactionRefundMoneyStatus {
    RefundedPartially = 'REFUNDED_PARTIALLY',
    Refunded = 'REFUNDED',
    NotRefunded = 'NOT_REFUNDED'
}

@Route('money_transactions')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        status: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: MoneyTransactionStatus
        },
        moneyLoanNote: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: MoneyLoanNote
        },
        createdBy: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: User
        },
        master: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: MoneyTransaction
        },
        slave: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: MoneyTransaction
        },
        currency: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Currency
        },
        incomeType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: IncomeType
        },
        bookTransaction: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: MoneyTransactionBookTransaction
        }
    };
})
@EntityDefaultFilters({
    'exists[employee.employment.dismissalType]': false
})
@Repository(MoneyTransactionRepository)
export class MoneyTransaction {

    protected income: string;
    protected expense: string;
    protected notice: string;
    protected hasFiscalBill: boolean;
    protected fiscalBillNotice: string;
    protected hasCashBill: boolean;
    protected cashBillAmount: string;
    protected canBook: boolean;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected date: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected refundMoneyDate: Date;
    protected refundMoneyAmount: string;
    protected missingRefundMoneyAmount: string;
    protected refundMoneyStatus: string;
    protected spent: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected createdAt: Date;
    protected note: string;
    protected waitingFiscalBill: boolean;
    protected fiscalBillAmount: string;
    protected archived: boolean;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(IncomeType)
    protected incomeType: IncomeType;

    @AssociationOne(User)
    protected createdBy: User;

    @AssociationOne(MoneyLoanNote)
    protected moneyLoanNote: MoneyLoanNote;

    @AssociationOne(MoneyTransaction)
    protected master;

    @AssociationOne(MoneyTransaction)
    protected slave;

    @AssociationOne(MoneyTransactionStatus)
    protected status;

    @AssociationOne(MoneyTransactionStatus)
    protected currency: Currency;

    @AssociationOne(MoneyTransactionBookTransaction)
    protected bookTransaction: MoneyTransactionBookTransaction;

    public setIncome(income: string): this {
        this.income = income;
        return this;
    }

    public getIncome(): string|undefined {
        return this.income;
    }

    public setExpense(expense: string): this {
        this.expense = expense;
        return this;
    }

    public getExpense(): string|undefined {
        return this.expense;
    }

    public setCurrency(currency: Currency): this {
        this.currency = currency;
        return this;
    }

    public getCurrency(): Currency|undefined {
        return this.currency;
    }

    public getBookTransaction(): MoneyTransactionBookTransaction | null {
        return this.bookTransaction;
    }

    public setBookTransaction(bookTransaction: MoneyTransactionBookTransaction | null): MoneyTransaction {
        this.bookTransaction = bookTransaction;
        return this;
    }

    public setNotice(notice: string): this {
        this.notice = notice;
        return this;
    }

    public getNotice(): string|undefined {
        return this.notice;
    }

    public setFiscalBillNotice(fiscalBillNotice: string): this {
        this.fiscalBillNotice = fiscalBillNotice;
        return this;
    }

    public getFiscalBillNotice(): string|undefined {
        return this.fiscalBillNotice;
    }

    public setHasFiscalBill(hasFiscalBill: boolean): this {
        this.hasFiscalBill = hasFiscalBill;
        return this;
    }

    public getHasFiscalBill(): boolean|undefined {
        return this.hasFiscalBill;
    }

    public setHasCashBill(hasCashBill: boolean): this {
        this.hasCashBill = hasCashBill;
        return this;
    }

    public getHasCashBill(): boolean|undefined {
        return this.hasCashBill;
    }

    public setCashBillAmount(cashBillAmount: string): this {
        this.cashBillAmount = cashBillAmount;
        return this;
    }

    public getCashBillAmount(): string | undefined {
        return this.cashBillAmount;
    }

    public setCanBook(canBook: boolean): this {
        this.canBook = canBook;
        return this;
    }

    public getCanBook(): boolean|undefined {
        return this.canBook;
    }

    public setDate(date: Date): this {
        this.date = date;
        return this;
    }

    public getDate(): Date|undefined {
        return this.date;
    }

    public setRefundMoneyDate(refundMoneyDate: Date): this {
        this.refundMoneyDate = refundMoneyDate;
        return this;
    }

    public getRefundMoneyDate(): Date|undefined {
        return this.refundMoneyDate;
    }

    public getRefundMoneyStatus(): string|undefined {
        return this.refundMoneyStatus;
    }

    public setRefundMoneyStatus(refundMoneyStatus: string): this {
        this.refundMoneyStatus = refundMoneyStatus;
        return this;
    }

    public getRefundMoneyAmount(): string|undefined {
        return this.refundMoneyAmount;
    }

    public setRefundMoneyAmount(refundMoneyAmount: string): this {
        this.refundMoneyAmount = refundMoneyAmount;
        return this;
    }

    public getMissingRefundMoneyAmount(): string|undefined {
        return this.missingRefundMoneyAmount;
    }

    public setMissingRefundMoneyAmount(missingRefundMoneyAmount: string): this {
        this.missingRefundMoneyAmount = missingRefundMoneyAmount;
        return this;
    }

    public setSpent(spent: string): this {
        this.spent = spent;
        return this;
    }

    public getSpent(): string|undefined {
        return this.spent;
    }

    public setCreatedAt(createdAt: Date): this {
        this.createdAt = createdAt;
        return this;
    }

    public getCreatedAt(): Date|undefined {
        return this.createdAt;
    }

    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getMoneyLoanNote(): MoneyLoanNote | undefined {
        return this.moneyLoanNote;
    }

    public setMoneyLoanNote(moneyLoanNote: MoneyLoanNote): this {
        this.moneyLoanNote = moneyLoanNote;
        return this;
    }

    public getStatus(): MoneyTransactionStatus | undefined {
        return this.status;
    }

    public setStatus(status: MoneyTransactionStatus): this {
        this.status = status;
        return this;
    }

    public getIncomeType(): IncomeType | undefined {
        return this.incomeType;
    }

    public setIncomeType(incomeType: IncomeType): this {
        this.incomeType = incomeType;
        return this;
    }

    public getCreatedBy(): User | undefined {
        return this.createdBy;
    }

    public setCreatedBy(createdBy: User): this {
        this.createdBy = createdBy;
        return this;
    }

    public setWaitingFiscalBill(waitingFiscalBill: boolean): this {
        this.waitingFiscalBill = waitingFiscalBill;
        return this;
    }

    public getWaitingFiscalBill(): boolean|undefined {
        return this.waitingFiscalBill;
    }

    public setFiscalBillAmount(fiscalBillAmount: string): this {
        this.fiscalBillAmount = fiscalBillAmount;
        return this;
    }

    public getFiscalBillAmount(): string | undefined {
        return this.fiscalBillAmount;
    }

    public setArchived(archived: boolean): this {
        this.archived = archived;
        return this;
    }

    public getArchived(): boolean|undefined {
        return this.archived;
    }
}
