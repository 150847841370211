import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {
    DismissalType,
    Employee,
    EmployeeProfessionalQualification,
    EmploymentType,
    PaymentDeductionType,
    Workplace,
    WorkTitle
} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Department} from './department';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {EmploymentRepository} from '../repositories/employment.repository';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {AssociationManyToMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many-to-many';
import {DecimalPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';

export enum EmploymentPaymentTypeEnum {
    Account = 'account',
    Cash = 'cash'
}

@Route('employments')
@CrossDependency(() => {
    return {
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmploymentType
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        workplace: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Workplace
        },
        departments: {
            mapping: Meta.META_ASSOCIATIONS_MANY_TO_MANY,
            entity: Department
        },
        workTitle: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkTitle
        },
        dismissalType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: DismissalType
        },
        paymentDeductionType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PaymentDeductionType
        },
        professionalQualification: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeProfessionalQualification
        },
        deletedByEmployee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
    };
})
@EntityDefaultFilters({
    'exists[dismissalType]': false
})
@Repository(EmploymentRepository)
export class Employment {

    @AssociationOne(EmploymentType)
    protected type: EmploymentType;

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(Workplace)
    protected workplace: Workplace;

    @AssociationManyToMany(Department)
    protected departments: Department[];

    @AssociationOne(WorkTitle)
    protected workTitle: WorkTitle;

    @AssociationOne(DismissalType)
    protected dismissalType: DismissalType;

    @AssociationOne(PaymentDeductionType)
    protected paymentDeductionType: PaymentDeductionType;

    @AssociationOne(EmployeeProfessionalQualification)
    protected professionalQualification: EmployeeProfessionalQualification;

    protected isMedicalExaminationRequired;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected medicalExaminationIssuedAt: Date;
    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected medicalExaminationValidTo: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected applicationDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected registrationDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected registeredFrom: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected registeredTo: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected permanentRegistrationDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected dismissalDate: Date;

    protected dismissalReason: string;
    protected isDrivingLicenceRequired;
    protected id: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected monthlyWage: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected previousWork: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected holidayAddition: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected deduction: string;

    protected paymentType: string;
    protected profession: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected dateDeletedOfEmployee: Date;

    @AssociationOne(Employee)
    protected deletedByEmployee: Employee;
    protected hidden: boolean;

    public setId(id: string): Employment {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public getType(): EmploymentType|undefined {
        return this.type;
    }

    public setType(type: EmploymentType): Employment {
        this.type = type;
        return this;
    }

    public setEmployee(employee: Employee): Employment {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public setWorkplace(workplace: Workplace): Employment {
        this.workplace = workplace;
        return this;
    }

    public getWorkplace(): Workplace|undefined {
        return this.workplace;
    }

    public getDepartments(): Department[]|undefined {
        return this.departments;
    }

    public setDepartments(departments: Department[]): Employment {
        this.departments = departments;
        return this;
    }

    public setApplicationDate(applicationDate: Date) {
        this.applicationDate = applicationDate;
        return this;
    }

    public getApplicationDate(): Date|null {
        return this.applicationDate;
    }

    public setRegistrationDate(registrationDate: Date) {
        this.registrationDate = registrationDate;
        return this;
    }

    public getRegistrationDate(): Date|null {
        return this.registrationDate;
    }

    public setRegisteredFrom(registeredFrom: Date) {
        this.registeredFrom = registeredFrom;
        return this;
    }

    public getRegisteredFrom(): Date|null {
        return this.registeredFrom;
    }

    public setPermanentRegistrationDate(permanentRegistrationDate: Date) {
        this.permanentRegistrationDate = permanentRegistrationDate;
        return this;
    }

    public getPermanentRegistrationDate(): Date|null {
        return this.permanentRegistrationDate;
    }

    public setRegisteredTo(registeredTo: Date) {
        this.registeredTo = registeredTo;
        return this;
    }

    public getRegisteredTo(): Date|null {
        return this.registeredTo;
    }

    public setDismissalDate(dismissalDate: Date) {
        this.dismissalDate = dismissalDate;
        return this;
    }

    public getDismissalDate(): Date|null {
        return this.dismissalDate;
    }

    public setWorkTitle(workTitle: WorkTitle): Employment {
        this.workTitle = workTitle;
        return this;
    }

    public getWorkTitle(): WorkTitle {
        return this.workTitle;
    }

    public setIsMedicalExaminationRequired(isMedicalExaminationRequired: boolean) {
        this.isMedicalExaminationRequired = isMedicalExaminationRequired;
        return this;
    }

    public getIsMedicalExaminationRequired(): boolean {
        return this.isMedicalExaminationRequired;
    }

    public setMedicalExaminationIssuedAt(medicalExaminationIssuedAt: Date) {
        this.medicalExaminationIssuedAt = medicalExaminationIssuedAt;
        return this;
    }

    public getMedicalExaminationIssuedAt(): Date|undefined {
        return this.medicalExaminationIssuedAt;
    }

    public setMedicalExaminationValidTo(medicalExaminationValidTo: Date) {
        this.medicalExaminationValidTo = medicalExaminationValidTo;
        return this;
    }

    public getMedicalExaminationValidTo(): Date|undefined {
        return this.medicalExaminationValidTo;
    }

    public setIsDrivingLicenceRequired(isDrivingLicenceRequired: boolean): this {
        this.isDrivingLicenceRequired = isDrivingLicenceRequired;
        return this;
    }

    public getIsDrivingLicenceRequired(): boolean {
        return this.isDrivingLicenceRequired;
    }

    public getDismissalType(): DismissalType|undefined {
        return this.dismissalType;
    }

    public setDismissalType(dismissalType: DismissalType): Employment {
        this.dismissalType = dismissalType;
        return this;
    }

    public getDismissalReason(): string|undefined {
        return this.dismissalReason;
    }

    public setDismissalReason(dismissalReason: string): Employment {
        this.dismissalReason = dismissalReason;
        return this;
    }

    /**
     * @deprecated
     */
    public setMonthlyWage(monthlyWage: string): Employment {
        this.monthlyWage = monthlyWage;
        return this;
    }

    /**
     * @deprecated
     */
    public getMonthlyWage(): string {
        return this.monthlyWage;
    }

    /**
     * @deprecated
     */
    public setPreviousWork(previousWork: string): Employment {
        this.previousWork = previousWork;
        return this;
    }

    /**
     * @deprecated
     */
    public getPreviousWork(): string {
        return this.previousWork;
    }

    /**
     * @deprecated
     */
    public getHolidayAddition(): string {
        return this.holidayAddition;
    }

    /**
     * @deprecated
     */
    public setHolidayAddition(holidayAddition: string): Employment {
        this.holidayAddition = holidayAddition;
        return this;
    }

    /**
     * @deprecated
     */
    public getDeduction(): string {
        return this.deduction;
    }

    /**
     * @deprecated
     */
    public setDeduction(deduction: string): Employment {
        this.deduction = deduction;
        return this;
    }

    public getPaymentType(): string {
        return this.paymentType;
    }

    public setPaymentType(paymentType: string): Employment {
        this.paymentType = paymentType;
        return this;
    }

    /**
     * @deprecated
     */
    public setPaymentDeductionType(paymentDeductionType: PaymentDeductionType): Employment {
        this.paymentDeductionType = paymentDeductionType;
        return this;
    }

    /**
     * @deprecated
     */
    public getPaymentDeductionType(): PaymentDeductionType|undefined {
        return this.paymentDeductionType;
    }

    public setProfessionalQualification(professionalQualification: EmployeeProfessionalQualification): Employment {
        this.professionalQualification = professionalQualification;
        return this;
    }

    public getProfessionalQualification(): EmployeeProfessionalQualification {
        return this.professionalQualification;
    }

    public setProfession(profession: string): Employment {
        this.profession = profession;
        return this;
    }

    public getProfession(): string {
        return this.profession;
    }

    public setDateDeletedOfEmployee(dateDeletedOfEmployee: Date) {
        this.dateDeletedOfEmployee = dateDeletedOfEmployee;
        return this;
    }

    public getDateDeletedOfEmployee(): Date|null {
        return this.dateDeletedOfEmployee;
    }

    public setHidden(hidden: boolean): this {
        this.hidden = hidden;
        return this;
    }

    public getHidden(): boolean|undefined {
        return this.hidden;
    }

    public setDeletedByEmployee(deletedByEmployee: Employee): Employment {
        this.deletedByEmployee = deletedByEmployee;
        return this;
    }

    public getDeletedByEmployee(): Employee|undefined {
        return this.deletedByEmployee;
    }
}
