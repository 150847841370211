import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {map, switchMap} from 'rxjs/operators';
import {LocalStorageService} from './local-storage.service';
import {User} from '../entities/user';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {LdJsonParser} from '../parsers/ld-json-parser';
import {Subject} from 'rxjs';
import {UserSwitchService} from "../user-switch/user-switch.service";

export class JwtLoginResponse extends Response {
    token: string;
    id: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private static readonly LOCAL_STORAGE_TOKEN_KEY = 'token';
    private static readonly LOCAL_STORAGE_USER_KEY = 'user';

    private user: User = null;

    public loggedOut: Subject<any> = new Subject<any>();

    public constructor(
        private router: Router,
        private http: HttpClient,
        private localStorage: LocalStorageService,
        private entityManager: EntityManagerService
    ) {

    }

    public login(username: string, password: string): Observable<JwtLoginResponse> {

        const headers = new HttpHeaders({
            accept: 'application/json'
        });

        return this.http.post(
            environment.ENTITY_MANAGER_URL_PREFIX + 'login_check',
            {
                username,
                password
            },
            {
                headers
            }
        ).pipe(
            map((response: JwtLoginResponse) => {
                this.setToken(response.token);
                return response;
            }),
            switchMap((response: JwtLoginResponse) => {
                return this.entityManager.getRepository(User)
                    .find(response.id)
                    .pipe(
                        map((user: User) => {
                            this.setUser(user);
                            return response;
                        })
                    );
            }),
            map((response: JwtLoginResponse) => {
                return response;
            })
        );
    }

    public logout(): void {
        this.removeToken();

        this.loggedOut.next();
    }

    public setToken(token: string): void {
        this.localStorage.setItem(AuthenticationService.LOCAL_STORAGE_TOKEN_KEY, token);
    }

    public setUser(user: User): void {
        this.localStorage.setItem(AuthenticationService.LOCAL_STORAGE_USER_KEY, user);
    }

    public getUser(): User {
        return new LdJsonParser().parse(
            new User(),
            this.localStorage.getItem(AuthenticationService.LOCAL_STORAGE_USER_KEY)
        );
    }

    private removeToken(): void {
        this.localStorage.removeItem(AuthenticationService.LOCAL_STORAGE_TOKEN_KEY);
        this.localStorage.removeItem(AuthenticationService.LOCAL_STORAGE_USER_KEY);
        this.localStorage.removeItem(UserSwitchService.LOCAL_STORAGE_ORIGINAL_USER_KEY);
        this.localStorage.removeItem(UserSwitchService.LOCAL_STORAGE_IMPERSONATE_USER_KEY);
    }

    public isLoggedIn(): boolean {
        return this.localStorage.itemExists(AuthenticationService.LOCAL_STORAGE_TOKEN_KEY);
    }

    public getToken(): string {
        return 'Bearer ' + this.localStorage.getItem(AuthenticationService.LOCAL_STORAGE_TOKEN_KEY);
    }
}
