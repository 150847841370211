<div class="route-bar">
    <div class="route-bar-breadcrumb">
        <i class="fa fa-home"></i>
        <span>/ </span>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
            <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
            <span *ngIf="!last">/</span>
        </ng-template>
    </div>

    <ul class="route-bar-menu">
        <li class="search-item">
            <i class="fa fa-search" ></i>
            <input appDisableAutofill  type="text" placeholder="Search..."/>
        </li>
        <li>
            <a href="#" pTooltip="Notifications" tooltipPosition="bottom">
                <i class="fa fa-globe" ></i>
            </a>
        </li>
        <li>
            <a href="#" pTooltip="Calendar" tooltipPosition="bottom">
                <i class="fa fa-calendar"></i>
            </a>
        </li>
        <li>
            <a href="#" pTooltip="Help" tooltipPosition="bottom">
                <i class="fa fa-life-saver" ></i>
            </a>
        </li>
    </ul>
</div>
