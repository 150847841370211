import {InjectorAwareInterface} from '../../../workflow/services/injector-aware.interface';
import {WorkflowAction} from '../../../workflow/services/workflow-action';
import {WorkflowActionInterface} from '../../../workflow/services/workflow-action.interface';
import {WorkflowActionStatus} from '../../../workflow/services/workflow-action-status';
import {Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {Route} from '../../../shared/enums/route';

export class UserRerouteAction extends WorkflowAction implements WorkflowActionInterface, InjectorAwareInterface {

    public run(): Observable<WorkflowActionStatus> {

        if (!this.injector.get(AuthenticationService).getUser().getIsDefaultPasswordChanged()) {
            this.injector.get(MessageService).add({
                severity: 'error',
                summary: this.injector.get(TranslateService).instant('COMMON.WARNING'),
                detail: this.injector.get(TranslateService).instant('ADMIN.USER.CHANGE_DEFAULT_PASSWORD'),
                sticky: true
            });

            this.injector.get(Router).navigate(['/admin/user']).then();
        } else if (this.injector.get(Router).url === '/login') {
            this.injector.get(Router).navigate([Route.HOME]).then();
        }

        return of({
            success: true,
            stop: false,
            status: ''
        });
    }
}
