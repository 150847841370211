import {Route} from 'entity-manager';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {WorkNoteSubType} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

export enum WorkNoteSubTypeCategoryEnum {
    Absence = 'absence'
}

@Route('work_note_sub_type_categories')
@CrossDependency(() => {
    return {
        workNoteSubTypes: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: WorkNoteSubType
        }
    };
})
export class WorkNoteSubTypeCategory {

    @AssociationMany(WorkNoteSubType)
    protected workNoteSubTypes: WorkNoteSubType[];

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;

    public setWorkNoteSubTypes(workNoteSubTypes: WorkNoteSubType[]): WorkNoteSubTypeCategory {
        this.workNoteSubTypes = workNoteSubTypes;
        return this;
    }

    public getWorkNoteSubTypes(): WorkNoteSubType[] | undefined {
        return this.workNoteSubTypes;
    }

    public setId(id: string): WorkNoteSubTypeCategory {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string): WorkNoteSubTypeCategory {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string): WorkNoteSubTypeCategory {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string): WorkNoteSubTypeCategory {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }
}
