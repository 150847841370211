<div class="list-group">
  <a *ngFor="let dashboardBoxSelect of dashboardBoxesSelect" class="list-group-item list-group-item-action flex-column align-items-start list-group-box-item">
    <div class="ui-g">
      <div class="ui-g-12 ui-md-4">
        <h6>{{dashboardBoxSelect.dashboardBox.getName()}}</h6>
        <small class="text-muted">{{dashboardBoxSelect.dashboardBox.getDescription()}}</small>
      </div>
      <div class="ui-g-12 ui-md-2 ui-md-offset-6">
        <p-checkbox [(ngModel)]="dashboardBoxSelect.isSelected" (change)="dashboardBoxSelect.isSelected = !dashboardBoxSelect.isSelected" binary="true"></p-checkbox>
      </div>
    </div>
    <hr>
  </a>
</div>

<p-footer>
  <p-button icon="fa fa-save" [disabled]="getSelectedBoxes().length === 0" (click)="onAddBoxes()" label="{{ 'COMMON.SAVE' | translate }}"></p-button>
</p-footer>
