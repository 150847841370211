import { Route } from 'entity-manager';

@Route('vats')
export class Vat {
    protected code: string;
    protected id: string;
    protected name: string;
    protected description: string;
    protected vat: number;
    protected isDefault: boolean;

    public getCode(): string | undefined {
        return this.code;
    }

    public setCode(code: string): Vat {
        this.code = code;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): Vat {
        this.id = id;
        return this;
    }

    public getName(): string | undefined {
        return this.name;
    }

    public setName(name: string): Vat {
        this.name = name;
        return this;
    }

    public getDescription(): string | undefined {
        return this.description;
    }

    public setDescription(description: string): Vat {
        this.description = description;
        return this;
    }

    public getVat(): number | undefined {
        return this.vat;
    }

    public setVat(vat: number): Vat {
        this.vat = vat;
        return this;
    }

    public setIsDefault(isDefault: boolean): Vat {
        this.isDefault = isDefault;
        return this;
    }

    public getIsDefault(): boolean|undefined {
        return this.isDefault;
    }
}
