import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Employee, WorkNoteAction, WorkNoteSubType, WorkNoteType} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';

@Route('employee_work_note_statistics')
@CrossDependency(() => {
    return {
        workNoteSubType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteSubType
        },
        workNoteType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteType
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        latestAction: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteAction
        }
    };
})
export class EmployeeWorkNoteStatistic {

    @AssociationOne(WorkNoteSubType)
    protected workNoteSubType;

    @AssociationOne(WorkNoteType)
    protected workNoteType;

    @AssociationOne(Employee)
    protected employee;

    @AssociationOne(WorkNoteAction)
    protected latestAction;

    protected id: string;
    protected points: number;
    protected totalPoints: number;
    protected year: number;

    public setWorkNoteSubType(workNoteSubType: WorkNoteSubType): EmployeeWorkNoteStatistic {
        this.workNoteSubType = workNoteSubType;
        return this;
    }

    public getWorkNoteSubType(): WorkNoteSubType | undefined {
        return this.workNoteSubType;
    }

    public setWorkNoteType(workNoteType: WorkNoteType): EmployeeWorkNoteStatistic {
        this.workNoteType = workNoteType;
        return this;
    }

    public getWorkNoteType(): WorkNoteType | undefined {
        return this.workNoteType;
    }

    public setEmployee(employee: Employee): EmployeeWorkNoteStatistic {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee {
        return this.employee;
    }

    public setId(id: string): EmployeeWorkNoteStatistic {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setPoints(counter: number): EmployeeWorkNoteStatistic {
        this.points = counter;
        return this;
    }

    public getPoints(): number {
        return this.points;
    }

    public setTotalPoints(yearlyTotalCounter: number): EmployeeWorkNoteStatistic {
        this.totalPoints = yearlyTotalCounter;
        return this;
    }

    public getTotalPoints(): number {
        return this.totalPoints;
    }

    public setYear(year: number): EmployeeWorkNoteStatistic {
        this.year = year;
        return this;
    }

    public getYear(): number {
        return this.year;
    }

    public setLatestAction(latestAction: WorkNoteAction): EmployeeWorkNoteStatistic {
        this.latestAction = latestAction;
        return this;
    }

    public getLatestAction(): WorkNoteAction {
        return this.latestAction;
    }
}
