import {Injectable, Injector} from '@angular/core';
import {Workflow} from './workflow';

@Injectable({
    providedIn: 'root'
})
export class WorkflowBuilder {

    public constructor(
        private injector: Injector
    ) {
    }

    public create(): Workflow {
        const workflow = new Workflow();

        workflow.setInjector(this.injector);

        return workflow;
    }
}
