import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Vehicle} from './vehicle';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {VehicleMonthlyConsumptionRepository} from '../repositories/vehicle-monthly-consumption.repository';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DecimalPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';

@Route('vehicle_monthly_consumptions')
@CrossDependency(() => {
    return {
        vehicle: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Vehicle
        },
        previousVehicleMonthlyConsumption: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: VehicleMonthlyConsumption
        }
    };
})
@Repository(VehicleMonthlyConsumptionRepository)
export class VehicleMonthlyConsumption {

    @AssociationOne(Vehicle)
    protected vehicle: Vehicle;

    @AssociationOne(VehicleMonthlyConsumption)
    protected previousVehicleMonthlyConsumption: VehicleMonthlyConsumption;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected previousTotalKilometersTraveled: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected previousTotalFuelConsumption: string;
    protected id: string;
    protected entryMonth: number;
    protected entryYear: number;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected fuelConsumption: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected fuelConsumptionBefore15: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected fuelConsumptionAfter15: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected amountFuelConsumptionBefore15: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected amountFuelConsumptionAfter15: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected kilometersTraveled: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalFuelConsumption: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected totalKilometersTraveled: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected amountFuelConsumption: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected additionalKilometersTraveled: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected averageMonthlyConsumption: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected currentKilometersOnVehicle: string;

    public setVehicle(vehicle: Vehicle): this {
        this.vehicle = vehicle;
        return this;
    }

    public getVehicle(): Vehicle|undefined {
        return this.vehicle;
    }

    public setPreviousVehicleMonthlyConsumption(previousVehicleMonthlyConsumption: VehicleMonthlyConsumption): this {
        this.previousVehicleMonthlyConsumption = previousVehicleMonthlyConsumption;
        return this;
    }

    public getPreviousVehicleMonthlyConsumption(): VehicleMonthlyConsumption|undefined {
        return this.previousVehicleMonthlyConsumption;
    }

    public setPreviousTotalKilometersTraveled(previousTotalKilometersTraveled: string): this {
        this.previousTotalKilometersTraveled = previousTotalKilometersTraveled;
        return this;
    }

    public getPreviousTotalKilometersTraveled(): string|undefined {
        return this.previousTotalKilometersTraveled;
    }

    public setPreviousTotalFuelConsumption(previousTotalFuelConsumption: string): this {
        this.previousTotalFuelConsumption = previousTotalFuelConsumption;
        return this;
    }

    public getPreviousTotalFuelConsumption(): string|undefined {
        return this.previousTotalFuelConsumption;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setEntryMonth(entryMonth: number): this {
        this.entryMonth = entryMonth;
        return this;
    }

    public getEntryMonth(): number|undefined {
        return this.entryMonth;
    }

    public setEntryYear(entryYear: number): this {
        this.entryYear = entryYear;
        return this;
    }

    public getEntryYear(): number|undefined {
        return this.entryYear;
    }

    public setFuelConsumption(fuelConsumption: string): this {
        this.fuelConsumption = fuelConsumption;
        return this;
    }

    public getFuelConsumption(): string | undefined {
        return this.fuelConsumption;
    }

    public setFuelConsumptionBefore15(fuelConsumptionBefore15: string): this {
        this.fuelConsumptionBefore15 = fuelConsumptionBefore15;
        return this;
    }

    public getFuelConsumptionBefore15(): string | undefined {
        return this.fuelConsumptionBefore15;
    }

    public setFuelConsumptionAfter15(fuelConsumptionAfter15: string): this {
        this.fuelConsumptionAfter15 = fuelConsumptionAfter15;
        return this;
    }

    public getFuelConsumptionAfter15(): string | undefined {
        return this.fuelConsumptionAfter15;
    }

    public setAmountFuelConsumptionBefore15(amountFuelConsumptionBefore15: string): this {
        this.amountFuelConsumptionBefore15 = amountFuelConsumptionBefore15;
        return this;
    }

    public getAmountFuelConsumptionBefore15(): string | undefined {
        return this.amountFuelConsumptionBefore15;
    }

    public setAmountFuelConsumptionAfter15(amountFuelConsumptionAfter15: string): this {
        this.amountFuelConsumptionAfter15 = amountFuelConsumptionAfter15;
        return this;
    }

    public getAmountFuelConsumptionAfter15(): string | undefined {
        return this.amountFuelConsumptionAfter15;
    }

    public setKilometersTraveled(kilometersTraveled: string): this {
        this.kilometersTraveled = kilometersTraveled;
        return this;
    }

    public getKilometersTraveled(): string | undefined {
        return this.kilometersTraveled;
    }

    public setTotalFuelConsumption(totalFuelConsumption: string): this {
        this.totalFuelConsumption = totalFuelConsumption;
        return this;
    }

    public getTotalFuelConsumption(): string | undefined {
        return this.totalFuelConsumption;
    }

    public setTotalKilometersTraveled(totalKilometersTraveled: string): this {
        this.totalKilometersTraveled = totalKilometersTraveled;
        return this;
    }

    public getTotalKilometersTraveled(): string | undefined {
        return this.totalKilometersTraveled;
    }

    public setAmountFuelConsumption(amountFuelConsumption: string): this {
        this.amountFuelConsumption = amountFuelConsumption;
        return this;
    }

    public getAmountFuelConsumption(): string | undefined {
        return this.amountFuelConsumption;
    }

    public setAdditionalKilometersTraveled(additionalKilometersTraveled: string): this {
        this.additionalKilometersTraveled = additionalKilometersTraveled;
        return this;
    }

    public getAdditionalKilometersTraveled(): string | undefined {
        return this.additionalKilometersTraveled;
    }

    public setAverageMonthlyConsumption(averageMonthlyConsumption: string): this {
        this.averageMonthlyConsumption = averageMonthlyConsumption;
        return this;
    }

    public getAverageMonthlyConsumption(): string | undefined {
        return this.averageMonthlyConsumption;
    }

    public setCurrentKilometersOnVehicle(currentKilometersOnVehicle: string): this {
        this.currentKilometersOnVehicle = currentKilometersOnVehicle;
        return this;
    }

    public getCurrentKilometersOnVehicle(): string | undefined {
        return this.currentKilometersOnVehicle;
    }
}
