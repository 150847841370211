import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Country, Employment} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';


@Route('workplaces')
@CrossDependency(() => {
    return {
        employments: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employment
        },
        country: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Country
        }
    };
})
export class Workplace {

    @AssociationOne(Employment)
    protected employments: Employment;

    @AssociationOne(Country)
    protected country: Country;

    protected id: string;
    protected postalCode: string;
    protected city: string;
    protected street: string;
    protected streetNumber: string;
    protected doorNumber: string;
    protected phoneNumber: string;
    protected faxNumber: string;
    protected name: string;
    protected description: string;
    protected mobilePhoneNumber: string;


    public setEmployments(employments: Employment): Workplace {
        this.employments = employments;
        return this;
    }

    public getEmployments(): Employment | undefined {
        return this.employments;
    }

    public setCountry(country: Country): Workplace {
        this.country = country;
        return this;
    }

    public getCountry(): Country | undefined {
        return this.country;
    }

    public setId(id: string): Workplace {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setPostalCode(postalCode: string): Workplace {
        this.postalCode = postalCode;
        return this;
    }

    public getPostalCode(): string | undefined {
        return this.postalCode;
    }

    public setCity(city: string): Workplace {
        this.city = city;
        return this;
    }

    public getCity(): string | undefined {
        return this.city;
    }


    public setStreet(street: string): Workplace {
        this.street = street;
        return this;
    }

    public getStreet(): string | undefined {
        return this.street;
    }

    public setStreetNumber(streetNumber: string): Workplace {
        this.streetNumber = streetNumber;
        return this;
    }

    public getStreetNumber(): string | undefined {
        return this.streetNumber;
    }

    public setDoorNumber(doorNumber: string): Workplace {
        this.doorNumber = doorNumber;
        return this;
    }

    public getDoorNumber(): string | undefined {
        return this.doorNumber;
    }

    public setPhoneNumber(phoneNumber: string): Workplace {
        this.phoneNumber = phoneNumber;
        return this;
    }

    public getPhoneNumber(): string | undefined {
        return this.phoneNumber;
    }

    public setFaxNumber(faxNumber: string): Workplace {
        this.faxNumber = faxNumber;
        return this;
    }

    public getFaxNumber(): string | undefined {
        return this.faxNumber;
    }

    public setName(name: string): Workplace {
        this.name = name;
        return this;
    }

    public getName(): string | undefined {
        return this.name;
    }

    public setDescription(description: string): Workplace {
        this.description = description;
        return this;
    }

    public getDescription(): string | undefined {
        return this.description;
    }

    public setMobilePhoneNumber(mobilePhoneNumber: string): Workplace {
        this.mobilePhoneNumber = mobilePhoneNumber;
        return this;
    }

    public getMobilePhoneNumber(): string | undefined {
        return this.mobilePhoneNumber;
    }
}
