<div class="notificator" (click)="app.onNotificationButtonClick($event)">
    <i class="fa fa-inbox" aria-hidden="true"></i>
    <span class="unread" *ngIf="unreadMessages">{{unreadMessages}}</span>
    <ul *ngIf="messages && messages.length > 0 && app.notificationMenuActive"
        class="notificatormenu notificatormenu-overlay fadeInDown">
        <ng-container *ngFor="let message of messages | slice:0:4">
        <li class="{{ message.getIsRead() ? 'read': 'unread'}}" (click)="onClick(message)">
            <div class="profile-picture">
                <app-lazy-image-preview
                        *ngIf="message?.getFromUser()"
                        [mediaObjectClassName]="'UserMediaObject'"
                        [mediaObjectOwner]="message.getFromUser()"
                        [mediaObjectContext]="'profilePicture'"
                >
                </app-lazy-image-preview>
                <img *ngIf="!message?.getFromUser()" [src]="placeHolderImage" alt="">
            </div>
            <div class="box">
                <div [ngClass]="getSubjectClasses(message)">{{message.getSubject()}}</div>
                <div class="body"
                     [innerHTML]="(message.getBody().length>30)? (message.getBody() | slice:0:50)+'...':(message.getBody())">
                </div>
                <span class="created-at">
                {{message.getCreatedAt()| dateAgo}}
                </span>
            </div>
            <div class="dot weight_{{message.getWeight()}}" *ngIf="!message.getIsRead()"></div>

        </li>
        </ng-container>
        <li class="all-messages">
            <span (click)="allMessages()">Sve poruke <i class="fa fa-angle-right"></i></span>
        </li>
    </ul>
    <div *ngIf="messages && messages.length === 0 && app.notificationMenuActive"
         class="notificatormenu notificatormenu-overlay empty-messages fadeInDown">
        <i class="fa fa-envelope-o"></i>
        <div>
            {{'HOME.MESSAGE_BOX.EMPTY' | translate}}
        </div>
    </div>
</div>
