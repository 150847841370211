import { Injectable } from "@angular/core";
import {HttpResponse} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    private cache: Map<string, HttpResponse<any>> = new Map<string, HttpResponse<any>>();

    constructor() { }

    put(url: string, response: HttpResponse<any>): void {
        if (this.cache.size >= 30) {
            this.cache.delete(this.cache.keys().next().value);
        }
        this.cache.set(url, response);
    }

    get(url: string): HttpResponse<any> | undefined {
        return this.cache.get(url);
    }

    invalidateCache(): void {
        this.cache.clear();
    }
}
