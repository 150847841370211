import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    OnInit, Renderer2
} from '@angular/core';

@Directive({
    selector: '[appDisableAutofill]'
})
export class DisableAutofillDirective implements OnInit {

    public constructor(
        protected elementRef: ElementRef,
        protected renderer: Renderer2,
        protected cdr: ChangeDetectorRef
    ) {
    }

    public ngOnInit(): void {
        const randomString = Math.random().toString(36).slice(-6);

        if (this.elementRef.nativeElement && this.elementRef.nativeElement.nodeName === 'INPUT') {
            this.renderer.setAttribute(this.elementRef.nativeElement, 'name', randomString);
            this.renderer.setAttribute(this.elementRef.nativeElement, 'autocomplete', randomString);
        }
    }
}
