import {ModuleWithProviders, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {Configuration} from './entity-manager.configurator';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [

  ],
  declarations: [],
  exports: []
})
export class EntityManagerModule {

  static forRoot(config: Configuration): ModuleWithProviders<EntityManagerModule> {

    return {
      ngModule: EntityManagerModule,
      providers: [config.loader]
    };
  }
}

