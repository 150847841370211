import {InjectorAwareInterface} from '../../../workflow/services/injector-aware.interface';
import {WorkflowAction} from '../../../workflow/services/workflow-action';
import {WorkflowActionInterface} from '../../../workflow/services/workflow-action.interface';
import {WorkflowActionStatus} from '../../../workflow/services/workflow-action-status';
import {Observable, of} from 'rxjs';
import {AppComponent} from '../../../app.component';
import {AuthenticationService} from '../../services/authentication.service';

export class BootstrapAppComponentLayoutAction extends WorkflowAction implements WorkflowActionInterface,
    InjectorAwareInterface {

    public constructor(
        private appComponent: AppComponent
    ) {
        super();
    }

    public run(): Observable<WorkflowActionStatus> {

        this.appComponent.layoutMode = this.injector.get(AuthenticationService).getUser().getSettingValue('layoutMode') || 'horizontal';
        this.appComponent.isMainNavigationVisible = this.injector.get(AuthenticationService).getUser()
            .getSettingValue('isMainNavigationVisible') !== 'false';

        return of({
            success: true,
            stop: false,
            status: ''
        });
    }
}
