import {EntityManagerMetaDataService} from '../service/meta/entity-manager-meta-data.service';
import {Meta} from '../service/meta/meta';

export function CrossDependency(data: any) {

    let target = null;

    return (...args) => {
        target = args[0].prototype;

        setTimeout(() => {
            const properties = data();

            const meta = new EntityManagerMetaDataService();

            meta.createMetaData(target);

            if (!meta.hasMetaDataProperty(target, Meta.META_ASSOCIATIONS)) {
                meta.setMetaDataProperty(target, Meta.META_ASSOCIATIONS, {});
            }

            if (!meta.hasMetaDataProperty(target, Meta.META_ASSOCIATIONS_MANY)) {
                meta.setMetaDataProperty(target, Meta.META_ASSOCIATIONS_MANY, {});
            }

            if (!meta.hasMetaDataProperty(target, Meta.META_ASSOCIATIONS_MANY_TO_MANY)) {
                meta.setMetaDataProperty(target, Meta.META_ASSOCIATIONS_MANY_TO_MANY, {});
            }

            if (!meta.hasMetaDataProperty(target, Meta.META_MAX_DEPTH)) {
                meta.setMetaDataProperty(target, Meta.META_MAX_DEPTH, {});
            }

            for (const property in properties) {
                if (properties.hasOwnProperty(property)) {
                    target.constructor[Meta.META][properties[property].mapping][property] = properties[property].entity;

                    if (typeof properties[property].maxDepth !== 'undefined') {
                        target.constructor[Meta.META][Meta.META_MAX_DEPTH][property] = {
                            maxDepth: properties[property].maxDepth,
                            used: 0
                        };
                    }
                }
            }
        }, 0);
    };
}
