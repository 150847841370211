import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {MultiUploadButtonComponent} from './multi-upload-button/multi-upload-button.component';
import {MediaObjectPreviewComponent} from './media-object-preview/media-object-preview.component';
import {SingleUploadButtonComponent} from './single-upload-button/single-upload-button.component';
import {LazyImagePreviewComponent} from './lazy-image-preview/lazy-image-preview.component';

@NgModule({
    imports: [
        SharedModule
    ],
    exports: [
        SingleUploadButtonComponent,
        MediaObjectPreviewComponent,
        MultiUploadButtonComponent,
        LazyImagePreviewComponent
    ],
    declarations: [
        SingleUploadButtonComponent,
        MediaObjectPreviewComponent,
        MultiUploadButtonComponent,
        LazyImagePreviewComponent
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [
    ]
})
export class FileModule {
}
