import { AbstractActionHandler } from './abstract-action-handler';
import { DashboardBoxWrapperComponent } from '../../component/dashboard-box-wrapper/dashboard-box-wrapper.component';
import { DashboardBox } from '../dashboard-box';
import { Parameter } from '../parameter';
import { DashboardAction } from '../dashboard-action';

export class RemoveBoxActionHandler extends AbstractActionHandler {

    public handleAction() {
        const dashboard = this.dashboardComponent.getDashboardService(),
            dashboardRendererService = this.dashboardComponent.getDashboardRendererService(),
            dashboardBoxWrapper = this.getParameterValue('dashboardBoxWrapper');

        if (dashboardBoxWrapper instanceof DashboardBoxWrapperComponent) {
            const toRemoveDashboardBox = dashboardBoxWrapper.getBoxComponent().getDashboardBox();

            const removedBoxIndex = dashboard.removeBox(toRemoveDashboardBox);

            if (false !== removedBoxIndex) {
                dashboardRendererService.removeBoxComponent(removedBoxIndex);

                this.onBoxRemoved(toRemoveDashboardBox);
            }
        }
    }

    private onBoxRemoved(dashboardBox: DashboardBox) {
        this.dashboardActionService.on(DashboardAction.BoxRemoved, [new Parameter('dashboardBox', dashboardBox)]);
    }
}
