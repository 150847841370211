import {
  Component, Input,
  OnDestroy,
  OnInit
} from '@angular/core';

import {TableSelectionService} from '../services/table-selection.service';
import {TableFilterService} from '../services/table-filter.service';
import {TableColumnService} from '../services/table-column.service';
import {TableInlineService} from '../services/table-inline.service';
import {TableAdvanceFilterService} from '../services/table-advance-filter.service';
import {TableComponent} from '../table.component';


@Component({
  selector: 'app-simple-table',
  styleUrls: ['./simple-table.component.scss'],
  templateUrl: './simple-table.component.html',
  providers: [TableSelectionService, TableFilterService, TableAdvanceFilterService, TableColumnService, TableInlineService]
})
export class SimpleTableComponent extends TableComponent implements OnInit, OnDestroy {

}
