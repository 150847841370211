import {Route} from 'entity-manager';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('work_shifts')

export class WorkShift {

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected startsAt: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected endsAt: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected break: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected startsAt2: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected endsAt2: Date;

    protected isDefault: boolean;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }

    public getDescription() {
        return this.description;
    }

    public setStartsAt(startsAt: Date) {
        this.startsAt = startsAt;
        return this;
    }

    public getStartsAt() {
        return this.startsAt;
    }

    public setEndsAt(endsAt: Date) {
        this.endsAt = endsAt;
        return this;
    }

    public getEndsAt() {
        return this.endsAt;
    }

    public setBreak(breakAt: Date) {
        this.break = breakAt;
        return this;
    }

    public getBreak() {
        return this.break;
    }

    public setStartsAt2(startsAt2: Date) {
        this.startsAt2 = startsAt2;
        return this;
    }

    public getStartsAt2() {
        return this.startsAt2;
    }

    public setEndsAt2(endsAt2: Date) {
        this.endsAt2 = endsAt2;
        return this;
    }

    public getEndsAt2() {
        return this.endsAt2;
    }

    public setIsDefault(isDefault: boolean) {
        this.isDefault = isDefault;
        return this;
    }

    public getIsDefault() {
        return this.isDefault;
    }
}
