import {TableColumn} from '../../shared/table-column';
import {AbstractEditor} from './abstract-editor';

export class AutocompleteEditor extends AbstractEditor {

    public onEdit(event: any, column: TableColumn, entity: object): void {
        entity[column.key] = event;
    }

    public isEditFinished(event: any, column: TableColumn, entity: object): boolean {
        return true;
    }
}
