import {TableColumn} from '../../shared/table-column';
import {AbstractEditor} from './abstract-editor';

export class DefaultEditor extends AbstractEditor {

    public onEdit(event: any, column: TableColumn, entity: object): void {

    }

    public isEditFinished(event: any, column: TableColumn, entity: object): boolean {
        return true;
    }
}
