import {Injectable} from '@angular/core';
import {Guid} from '../helper/guid';
import {EntityManagerMetaDataService} from './meta/entity-manager-meta-data.service';
import {Meta} from './meta/meta';

export enum State {
    Create = 1,
    Update = 2,
    Delete = 3
}

@Injectable({
    providedIn: 'root'
})
export class EntityManagerStateService {

    public entities = {};

    public constructor(
      private meta: EntityManagerMetaDataService
    ) {
        this.init();
    }

    public getAll(): any[] {
      return [].concat(
        this.getEntities(State.Create),
        this.getEntities(State.Update),
        this.getEntities(State.Delete)
      );
    }

    public getEntityState(entity: any): State {
        if (this.find(State.Update, entity) !== null) {
            return State.Update;
        }

        if (this.find(State.Delete, entity) !== null) {
            return State.Delete;
        }

        return State.Create;
    }

    public getEntities(state: State): any[] {
        const persisted = this.entities[state] || [],
            entities = [];

        for (const apiRoute in persisted) {
          if (persisted[apiRoute]) {
            for (const entity of persisted[apiRoute]) {
              entities.push(entity);
            }
          }
        }

        return entities;
    }

    public persist(entity: any): EntityManagerStateService {
        const state = entity.id ? State.Update : State.Create;

        this.prepare(state, entity)
            .addOrReplace(state, entity);

        return this;
    }

    public remove(entity: any): EntityManagerStateService {
        this.prepare(State.Delete, entity)
            .addOrReplace(State.Delete, entity);

        this.removeFromState(State.Create, entity);
        this.removeFromState(State.Update, entity);

        return this;
    }

    public removeFromState(state: State, entity: any): EntityManagerStateService {
        const entities = this.entities[state][entity.constructor.name] || [],
            index = this.findIndex(state, entity);

        entities.splice(index, 1);

        this.entities[state][entity.constructor.name] = entities;

        return this;
    }

    public clear(): void {
        this.entities[State.Create] = {};
        this.entities[State.Update] = {};
        this.entities[State.Delete] = {};
    }

    private prepare(state: State, entity: any): EntityManagerStateService {
        this.entities[state][entity.constructor.name] = this.entities[state][entity.constructor.name] || [];

        return this;
    }

    private addOrReplace(state: State, entity: any): EntityManagerStateService {
        if (this.exists(state, entity)) {
            this.replace(state, entity);
        } else {
            this.add(state,  entity);
        }

        return this;
    }

    private add(state: State, entity: any): EntityManagerStateService {
        entity[Meta.META_UNIQUE_ID] = Guid.guid();
        entity[Meta.IS_DIRTY] = true;

        this.entities[state][entity.constructor.name].push(entity);

        return this;
    }

    private replace(state: State, entity: any): EntityManagerStateService {
        const entities = this.entities[state][entity.constructor.name] || [],
            index = this.findIndex(state, entity);

        entities[index] = entity;

        this.entities[state][entity.constructor.name] = entities;

        return this;
    }

    private exists(state: State, entity: any): boolean {
        return this.find(state, entity) !== null;
    }

    private find(state: State, entity: any): any|null {
      const entities = this.entities[state][entity.constructor.name] || [];

      for (const stateEntity of entities) {
        if (stateEntity[Meta.META_UNIQUE_ID] &&
            entity[Meta.META_UNIQUE_ID] &&
            stateEntity[Meta.META_UNIQUE_ID] ===
            entity[Meta.META_UNIQUE_ID]
        ) {
          return stateEntity;
        }
      }

      return null;
    }

    private findIndex(state: State, entity: any): number {
        const entities = this.entities[state][entity.constructor.name] || [];

        let index = -1;

        if (entity.id) {
            index = entities.findIndex((aEntity) => {
                return aEntity.id === entity.id;
            });
        }

        if (entity[Meta.META_UNIQUE_ID]) {
            index = entities.findIndex((aEntity) => {
                return aEntity[Meta.META_UNIQUE_ID] === entity[Meta.META_UNIQUE_ID];
            });
        }

        return index;
    }

    private init(): void {
        this.clear();
    }
}
