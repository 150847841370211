import {Route} from 'entity-manager';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {AssociationMany} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-many';
import {AbsenceDeductionType, AbsenceSubType, AbsenceType, Employee, EmployeeRequest} from './entities';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';
import {Repository} from '../../../../projects/entity-manager/src/lib/decorator/repository';
import {AbsenceRepository} from '../repositories/absence.repository';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';
import {MediaObject} from './media-object';
import {DeletePropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/delete-property-payload-modifier';
import {DecimalPropertyPayloadModifier} from "../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier";

export enum AbsenceRegulateTypeEnum {
    SubtractDaysOff = 'subtractDaysOff',
    SubtractHoliday = 'subtractHoliday',
    TransferToEmployeeMonthlyPayment = 'transferToEmployeeMonthlyPayment'
}

@Route('absences')
@EntityDefaultFilters({
    disableVisibleFilter: 1,
    'exists[employee.employment.dismissalType]': false
})
@CrossDependency(() => {
    return {
        type: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: AbsenceType
        },
        subType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: AbsenceSubType
        },
        employeeRequest: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeRequest
        },
        deductionType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: AbsenceDeductionType
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        medias: {
            mapping: Meta.META_ASSOCIATIONS_MANY,
            entity: MediaObject
        }
    };
})
@Repository(AbsenceRepository)
export class Absence {
    protected id: string;

    @AssociationOne(Employee)
    protected employee;

    @AssociationOne(AbsenceType)
    protected type;

    @AssociationOne(AbsenceSubType)
    protected subType;

    @AssociationOne(EmployeeRequest)
    protected employeeRequest;

    @AssociationOne(AbsenceDeductionType)
    protected deductionType;

    @PropertyPayloadModifier(DeletePropertyPayloadModifier)
    @AssociationMany(MediaObject)
    protected medias: MediaObject[];

    protected name: string;
    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected fromDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected toDate: Date;
    protected approvedWithoutDocumentation: boolean;
    protected areDocumentsAttached: boolean;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected documentsAttachmentDate: Date;
    protected description: string;
    protected isRegulated: boolean;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected createdAt: Date;

    protected isSickLeaveRegulatedBySalary: boolean;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected regulatedSickLeaveAmount: string;

    protected numberOfDaysRegulatedThroughSalary: number;
    protected totalNumberOfDaysRegulatedThroughSalary: number;
    protected numberOfSickLeaveRegulatedBySalary: number;
    private numberOfDays: number;

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getType(): AbsenceType|undefined {
        return this.type;
    }

    public setType(type: AbsenceType): this {
        this.type = type;
        return this;
    }

    public getDeductionType(): AbsenceDeductionType|undefined {
        return this.deductionType;
    }

    public setDeductionType(deductionType: AbsenceDeductionType): this {
        this.deductionType = deductionType;
        return this;
    }

    public getSubType(): AbsenceSubType|undefined {
        return this.subType;
    }

    public setSubType(subType: AbsenceSubType): this {
        this.subType = subType;
        return this;
    }

    public getEmployeeRequest(): EmployeeRequest|undefined {
        return this.employeeRequest;
    }

    public setEmployeeRequest(employeeRequest: EmployeeRequest): this {
        this.employeeRequest = employeeRequest;
        return this;
    }

    public setMedias(medias: MediaObject[]): Absence {
        this.medias = medias;
        return this;
    }

    public getMedias(): MediaObject[] {
        return this.medias;
    }

    public getSickLeaveRegulatedMedias(): MediaObject[] {
        const medias = this.getMedias(),
            sickLeaveRegulatedMedias = [];

        if (medias instanceof Array) {
            for (const media of medias) {
                if (media instanceof MediaObject && media.getContext() === 'sickLeaveRegulated') {
                    sickLeaveRegulatedMedias.push(media);
                }
            }
        }

        return sickLeaveRegulatedMedias;
    }

    public setName(name: string): this {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public getFromDate(): Date|undefined {
        return this.fromDate;
    }

    public setFromDate(fromDate: Date): this {
        this.fromDate = fromDate;
        return this;
    }

    public getToDate(): Date|undefined {
        return this.toDate;
    }

    public setToDate(toDate: Date): this {
        this.toDate = toDate;
        return this;
    }

    public getApprovedWithoutDocumentation(): boolean {
        return this.approvedWithoutDocumentation;
    }

    public setApprovedWithoutDocumentation(approvedWithoutDocumentation: boolean): this {
        this.approvedWithoutDocumentation = approvedWithoutDocumentation;
        return this;
    }

    public getAreDocumentsAttached(): boolean {
        return this.areDocumentsAttached;
    }

    public setAreDocumentsAttached(areDocumentsAttached: boolean): this {
        this.areDocumentsAttached = areDocumentsAttached;
        return this;
    }

    public getDocumentsAttachmentDate(): Date|undefined {
        return this.documentsAttachmentDate;
    }

    public setDocumentsAttachmentDate(documentsAttachmentDate: Date): this {
        this.documentsAttachmentDate = documentsAttachmentDate;
        return this;
    }

    public getDescription(): string|undefined {
        return this.description;
    }

    public setDescription(description: string): this {
        this.description = description;
        return this;
    }

    public getIsRegulated(): boolean|undefined {
        return this.isRegulated;
    }

    public setIsRegulated(isRegulated: boolean): this {
        this.isRegulated = isRegulated;
        return this;
    }

    public setCreatedAt(createdAt: Date): this {
        this.createdAt = createdAt;
        return this;
    }

    public getCreatedAt(): Date|undefined {
        return this.createdAt;
    }


    public getIsSickLeaveRegulatedBySalary(): boolean {
        return this.isSickLeaveRegulatedBySalary;
    }

    public setIsSickLeaveRegulatedBySalary(isSickLeaveRegulatedBySalary: boolean): this {
        this.isSickLeaveRegulatedBySalary = isSickLeaveRegulatedBySalary;
        return this;
    }

    public setRegulatedSickLeaveAmount(regulatedSickLeaveAmount: string): this {
        this.regulatedSickLeaveAmount = regulatedSickLeaveAmount;
        return this;
    }

    public getRegulatedSickLeaveAmount(): string|undefined {
        return this.regulatedSickLeaveAmount;
    }

    public setNumberOfDaysRegulatedThroughSalary(numberOfDaysRegulatedThroughSalary: number): this {
        this.numberOfDaysRegulatedThroughSalary = numberOfDaysRegulatedThroughSalary;
        return this;
    }

    public getNumberOfDaysRegulatedThroughSalary(): number|undefined {
        return this.numberOfDaysRegulatedThroughSalary;
    }

    public setTotalNumberOfDaysRegulatedThroughSalary(totalNumberOfDaysRegulatedThroughSalary: number): this {
        this.totalNumberOfDaysRegulatedThroughSalary = totalNumberOfDaysRegulatedThroughSalary;
        return this;
    }

    public getTotalNumberOfDaysRegulatedThroughSalary(): number|undefined {
        return this.totalNumberOfDaysRegulatedThroughSalary;
    }

    public setNumberOfSickLeaveRegulatedBySalary(numberOfSickLeaveRegulatedBySalary: number): this {
        this.numberOfSickLeaveRegulatedBySalary = numberOfSickLeaveRegulatedBySalary;
        return this;
    }

    public getNumberOfSickLeaveRegulatedBySalary(): number|undefined {
        return this.numberOfSickLeaveRegulatedBySalary;
    }

    public setNumberOfDays(numberOfDays: number): this {
        this.numberOfDays = numberOfDays;
        return this;
    }

    public getNumberOfDays(): number|undefined {
        return this.numberOfDays;
    }
}
