import {Injectable} from '@angular/core';
import {HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Modifier} from 'entity-manager';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationModifier implements Modifier {

    public constructor(
        protected authentication: AuthenticationService
    ) {

    }

    public modifyRequest(entity: any, request: HttpRequest<any>): HttpRequest<any>  {

        // request = request.clone({
        //     headers: new HttpHeaders().set('Authorization', this.authentication.getToken())
        //         .set('Content-Type', 'application/json')
        // });

        return request;
    }

    public modifyResponse(entity: any, request: HttpRequest<any>, response: HttpResponse<any>): any {
        return response;
    }
}
