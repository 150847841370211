import {Injectable} from '@angular/core';
import {EntityRepository} from '../../../../projects/entity-manager/src/lib/service/repository/entity-repository';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Product} from '../entities/product';

@Injectable({
    providedIn: 'root'
})
export class StockInfoRepository extends EntityRepository {

    public getChartData(product: Product): Observable<any> {
        return this.connection.get(
            `${environment.ENTITY_MANAGER_URL_PREFIX}stock_infos/product/${product.getId()}/chart`
        ).pipe(
            map((chartData: any) => {
                return chartData;
            })
        );
    }
}
