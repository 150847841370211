import {PropertyPayloadModifierInterface} from '../../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier-interface';

export class DatetimeWithoutTimezonePropertyResponseModifier implements PropertyPayloadModifierInterface {

    public modify(property: string, entity: any) {
        if (typeof entity[property] === 'string') {
            try {

                if (entity[property].includes('+')) {
                    entity[property] = entity[property].substring(0, entity[property].indexOf('+')); // remove after '+' (remove timezone)
                }

            } catch (e) {
                console.error(e);
            }
        }
    }
}
