import {Injectable} from '@angular/core';
import {Message} from '../../entities/message';
import {interval, Subject} from 'rxjs';
import {EntityManagerService} from "../../../../../projects/entity-manager/src/lib/service/entity-manager.service";
import {User} from "../../entities/user";
import {flatMap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})

export class MessageNotificatorService {
    public messageRead: Subject<Message> = new Subject<Message>();

    public constructor(
        protected entityManager: EntityManagerService,
    ) {
    }

    getData(user: User) {
        return interval(30000).pipe(
            flatMap(() => {
                return this.entityManager.getRepository(Message).getLatest({
                    toUser: user.getId(),
                    'order[createdAt]': 'DESC'
                });
            })
        );
    }

    getInstantData(user: User) {
        return this.entityManager.getRepository(Message).getLatest({
            toUser: user.getId(),
            'order[createdAt]': 'DESC'
        });
    }
}
