import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AppComponent} from '../../app.component';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import * as MenuItemBackendImport from '../../core/entities/menu-item';
import {MenuItemRepository} from '../../core/repositories/menu-item.repository';
import {MenuItem} from 'primeng/api';
import {Location} from "@angular/common";
import {AuthenticationService} from "../../core/services/authentication.service";
import {UserSwitchService} from "../../core/user-switch/user-switch.service";
import {User} from "../../core/entities/user";

@Component({
    selector: 'app-menu',
    template: `
        <ul app-submenu [item]="model" root="true" class="layout-menu"
            [reset]="reset" visible="true" parentActive="true"></ul>
    `
})
export class AppMenuComponent implements OnInit {

    @Input() public reset: boolean;

    public model: any[];

    public constructor(
        public app: AppComponent,
        protected entityManager: EntityManagerService,
        protected userSwitchService: UserSwitchService
    ) {
    }

    ngOnInit() {
        this.loadItems();
        this.userSwitchService.getUserSwitchEmitter().subscribe((User) => {
            this.loadItems();
        })
    }

    private loadItems(): void {
        const repository: MenuItemRepository = this.entityManager.getRepository(MenuItemBackendImport.MenuItem);

        repository
            .findMoreAndParseToPrimeNg({}, 'VIEW')
            .subscribe((menuItems: MenuItem[] = []) => {
                this.model = menuItems;
            });
    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *ngIf="child.visible === false ? false : true">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
                   class="ripplelink" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i [ngClass]="child.icon"></i><span>{{child.label}}</span>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                    <i class="fa fa-fw fa-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
                </a>

                <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink" *ngIf="child.routerLink"
                   [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i [ngClass]="child.icon"></i><span>{{child.label}}</span>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                    <i class="fa fa-fw fa-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
                </a>
                <div class="submenu-arrow" *ngIf="child.items"></div>
                <ul app-submenu [item]="child" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)" [parent]="self" [index]="i"
                    [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px',
                opacity: 0
            })),
            state('visibleAnimated', style({
                height: '*',
                opacity: 1
            })),
            state('visible', style({
                height: '*',
                'z-index': 100,
                opacity: 1
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*',
                opacity: 0
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent implements OnInit {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    @Input() parent: AppSubMenuComponent;

    @Input() index: number;

    self: AppSubMenuComponent;

    _reset: boolean;

    _parentActive: boolean;

    activeIndex: number;

    constructor(
        public app: AppComponent,
        public location: Location,
        public authentication: AuthenticationService,
    ) {
        this.self = this;
    }

    ngOnInit() {
        this.checkIfActive();
    }

    checkIfActive() {
        const layoutsToCheck = ["vertical", "overlay"];

        if (layoutsToCheck.includes(this.authentication.getUser().getSetting("layoutMode").getValue())) {
            let currentPath = (this.location.path() + "/");
            let elementPath = ("/" + (this.item ? this.item.routerLink : "") + "/");

            currentPath = currentPath.replace("//", "/");
            currentPath = currentPath.replace("/list", "");

            elementPath = elementPath.replace("//", "/");
            elementPath = elementPath.replace("/list", "");

            if (this.item && this.item.routerLink && (currentPath.includes(elementPath))) {
                this.makeActive();
            }
        }
    }

    makeActive() {
        if (this.parent) {
            this.parent.activeIndex = this.index;
            this.parent.makeActive();
        }
    }

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        this.activeIndex = (this.activeIndex === index) ? null : index;

        // execute command
        if (item.command) {
            item.command({originalEvent: event, item});
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
                if (this.app && this.app.layoutMenuScrollerViewChild) {
                    this.app.layoutMenuScrollerViewChild.moveBar();
                }
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isHorizontal() || this.app.isSlim()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }
}
