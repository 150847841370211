import {ChangeDetectorRef, Component, ElementRef, Injector, OnInit, ViewChild} from '@angular/core';
import {Message} from '../../../../core/entities/message';
import {Paginated} from '../../../../shared/table/shared/paginated';
import {EntityManagerService} from '../../../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {FlipPanelComponent} from '../../../../shared/flip-panel/flip-panel.component';
import {Route} from '../../../../shared/enums/route';
import {MessageNotificatorService} from '../../../../core/services/message/message-notificator.service';
import {Router} from '@angular/router';
import {ConfirmationService, MenuItem} from 'primeng';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {delay} from 'rxjs/operators';

@Component({
    selector: 'app-home-messages-box',
    templateUrl: 'messages-box.component.html',
    styleUrls: ['messages-box.component.scss']
})
export class MessagesBoxComponent implements OnInit {
    cols: any[];

    public messages = [];
    public message: Message;
    public messagesBoxHeight;
    public placeHolderImage = 'image/layout/images/logo/alex-circle-logo.svg';
    @ViewChild(FlipPanelComponent) public flipPanel: FlipPanelComponent;
    @ViewChild('messageBox', {read: ElementRef, static: false}) messageBox: ElementRef;

    constructor(
        protected entityManager: EntityManagerService,
        public authentication: AuthenticationService,
        protected cdr: ChangeDetectorRef,
        protected messageNotificator: MessageNotificatorService,
        protected router: Router,
        protected injector: Injector,
        protected translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.entityManager.getRepository(Message)
            .findMore({toUser: this.authentication.getUser().getId(), 'order[createdAt]': 'DESC', itemsPerPage: 4})
            .subscribe((pagination: Paginated) => {
                this.messages = pagination.data;
                this.cdr.detectChanges();
            });
    }


    viewMessage(message: Message) {
        this.message = message;
        this.messagesBoxHeight = this.messageBox.nativeElement.offsetHeight;
        if (!this.message.getIsRead()) {
            this.markAsRead(this.message);
        }
        this.cdr.detectChanges();
        this.flipPanel.openSecondary();
    }

    viewMessages() {
        this.message = null;
        this.flipPanel.openMain();
    }

    private markAsRead(message: Message) {
        message.setIsRead(true);
        message.setReadAt(new Date());
        this.entityManager.persist(message).flush(message).subscribe(
            () => {
                this.messageNotificator.messageRead.next(message);
            }
        );
    }

    protected removeConfirmDialog(): void {
        of(true).pipe(
            delay(200)
        ).subscribe(() => {
            this.cdr.detectChanges();
        });
    }

    public allMessages() {
        const route = Route.MESSAGE_LIST;
        this.router.navigate([route]).then();
    }
}
