import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {WorkNoteType, WorkNoteSubTypeCategory} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {StringPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/string-property-payload-modifier';


@Route('work_note_sub_types')
@CrossDependency(() => {
    return {
        workNoteType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteType
        },
        workNoteSubTypeCategory: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: WorkNoteSubTypeCategory
        }
    };
})
export class WorkNoteSubType {
    @AssociationOne(WorkNoteType)
    protected workNoteType: WorkNoteType;

    @AssociationOne(WorkNoteSubTypeCategory)
    protected workNoteSubTypeCategory: WorkNoteSubTypeCategory;

    protected id: string;
    protected code: string;
    protected name: string;
    protected description: string;
    protected points: number;
    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected numberOfHoursFrom: string;
    @PropertyPayloadModifier(StringPropertyPayloadModifier)
    protected numberOfHoursTo: string;
    protected sortPosition: number;

    public setWorkNoteType(workNoteType: WorkNoteType): WorkNoteSubType {
        this.workNoteType = workNoteType;
        return this;
    }

    public getWorkNoteType(): WorkNoteType {
        return this.workNoteType;
    }

    public setWorkNoteSubTypeCategory(workNoteSubTypeCategory: WorkNoteSubTypeCategory): WorkNoteSubType {
        this.workNoteSubTypeCategory = workNoteSubTypeCategory;
        return this;
    }

    public getWorkNoteSubTypeCategory(): WorkNoteSubTypeCategory {
        return this.workNoteSubTypeCategory;
    }

    public setId(id: string): WorkNoteSubType {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string): WorkNoteSubType {
        this.code = code;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setName(name: string): WorkNoteSubType {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string): WorkNoteSubType {
        this.description = description;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setPoints(points: number): WorkNoteSubType {
        this.points = points;
        return this;
    }

    public getPoints(): number {
        return this.points;
    }

    public setNumberOfHoursFrom(numberOfHoursFrom: string): WorkNoteSubType {
        this.numberOfHoursFrom = numberOfHoursFrom;
        return this;
    }

    public getNumberOfHoursFrom(): string {
        return this.numberOfHoursFrom;
    }

    public setNumberOfHoursTo(numberOfHoursTo: string): WorkNoteSubType {
        this.numberOfHoursTo = numberOfHoursTo;
        return this;
    }

    public getNumberOfHoursTo(): string {
        return this.numberOfHoursTo;
    }

    public setSortPosition(sortPosition: number): WorkNoteSubType {
        this.sortPosition = sortPosition;
        return this;
    }

    public getSortPosition(): number {
        return this.sortPosition;
    }
}
