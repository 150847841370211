import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {Employee, Todo} from './entities';
import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';

@Route('todo_states')
@CrossDependency(() => {
    return {
        todo: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Todo
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
})
export class TodoState {
    @AssociationOne(Todo)
    protected todo: Todo;

    @AssociationOne(Employee)
    protected employee: Employee;

    protected id: string;
    protected completed: boolean;

    public getTodo(): Todo {
        return this.todo;
    }

    public setTodo(todo: Todo): TodoState {
        this.todo = todo;
        return this;
    }

    public getEmployee(): Employee {
        return this.employee;
    }

    public setEmployee(employee: Employee): TodoState {
        this.employee = employee;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setId(id: string): TodoState {
        this.id = id;
        return this;
    }

    public getCompleted(): boolean {
        return this.completed;
    }

    public setCompleted(completed: boolean): TodoState {
        this.completed = completed;
        return this;
    }
}
