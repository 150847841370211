import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {Employee, PersonalDocumentType} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('personal_documents')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        personalDocumentType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PersonalDocumentType
        }
    };
})
export class PersonalDocument {

    @AssociationOne(PersonalDocumentType)
    protected personalDocumentType: PersonalDocumentType;

    @AssociationOne(Employee)
    protected employee: Employee;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected issueDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected expiryDate: Date;
    protected issuingBody: string;
    protected documentNumber: string;

    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public getPersonalDocumentType(): PersonalDocumentType | undefined {
        return this.personalDocumentType;
    }

    public setPersonalDocumentType(personalDocumentType: PersonalDocumentType): this {
        this.personalDocumentType = personalDocumentType;
        return this;
    }

    public getIssueDate(): Date | undefined {
        return this.issueDate;
    }

    public setIssueDate(issueDate: Date): this {
        this.issueDate = issueDate;
        return this;
    }

    public getExpiryDate(): Date | undefined {
        return this.expiryDate;
    }

    public setExpiryDate(expiryDate: Date): this {
        this.expiryDate = expiryDate;
        return this;
    }

    public getIssuingBody(): string | undefined {
        return this.issuingBody;
    }

    public setIssuingBody(issuingBody: string): this {
        this.issuingBody = issuingBody;
        return this;
    }

    public getDocumentNumber(): string | undefined {
        return this.documentNumber;
    }

    public setDocumentNumber(documentNumber: string): this {
        this.documentNumber = documentNumber;
        return this;
    }
}
