import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringToDate'
})
export class TableStringToDatePipe implements PipeTransform {

    transform(value: string|Date): Date|null {
        if (value instanceof Date) {
            return value;
        }

        if (typeof value === 'string' && value !== '') {
            return new Date(value);
        }

        return null;
    }
}
