import {Component, Input, OnInit} from '@angular/core';
import {MediaObject} from '../../core/entities/media-object';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-media-object-preview',
    templateUrl: './media-object-preview.component.html',
    styleUrls: ['./media-object-preview.component.scss']
})
export class MediaObjectPreviewComponent implements OnInit {

    @Input() mediaObject: MediaObject = null;
    @Input() showCreatedAt = true;

    public ngOnInit(): void {

    }

    public isText(): boolean {
        return this.mediaObject.getFileType().includes('text') ||
            this.mediaObject.getFileType().includes('pdf') ||
            this.mediaObject.getFileType().includes('msword');
    }

    public isImage(): boolean {
        return this.mediaObject.getFileType().includes('image');
    }

    public getDownloadUrl(): string {
        return this.mediaObject instanceof MediaObject ? environment.BACKEND_ROOT_URL + this.mediaObject.getContentUrl() : '';
    }
}
