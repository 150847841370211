import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {Paginated} from '../../shared/table/shared/paginated';
import {User} from '../entities/user';

@Injectable()
export class UserAutocompleteHandler {

    public constructor(
        private entityManagerService: EntityManagerService
    ) {

    }

    public getData(query: string, params: any = {}, queryField = 'multiSearch[firstName,lastName]', properties: any = null): Observable<any[]> {
        const queryParams = {
            ...{itemsPerPage: '30'},
            ...properties,
            ...params
        };
        queryParams[queryField] = query;
        return this.entityManagerService
            .getRepository(User)
            .findMore(queryParams)
            .pipe(map((paginated: Paginated) => {
                const users: User|any[] = paginated.data;

                for (const user of users) {
                    let template = '';

                    if (user.getFullName()) {
                        template += user.getFullName();
                    }

                    user.fullName = template;
                }

                return users;
            }));
    }
}
