import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {EntityManagerService} from '../../../../projects/entity-manager/src/lib/service/entity-manager.service';
import {MenuItem, MessageService} from 'primeng/api';
import {MediaObject} from '../../core/entities/media-object';
import {MediaObjectRepository} from '../../core/repositories/media-object.repository';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {AbstractUploadButtonComponent} from '../abstract-upload-button.component';
import {FileUpload} from 'primeng';
import {DatePipe} from '../../shared/pipes/date.pipe';

interface MenuItemExtended extends MenuItem {
    mediaObject: MediaObject;
}

@Component({
    selector: 'app-multi-upload-button',
    templateUrl: './multi-upload-button.component.html',
    styleUrls: ['./multi-upload-button.component.scss']
})
export class MultiUploadButtonComponent extends AbstractUploadButtonComponent {

    @ViewChild(FileUpload) public fileUpload: FileUpload = null;
    @Input() display = 'inline';
    public selectedFiles: any[] = [];
    public items: MenuItemExtended[] = [];
    public isUploadVisible = false;
    public mediaItem;

    public constructor(
        protected entityManager: EntityManagerService,
        protected message: MessageService,
        protected translate: TranslateService,
        protected cdr: ChangeDetectorRef
    ) {
        super(entityManager, message, translate, cdr);
    }

    public onOpenUpload(event): void {
        this.isUploadVisible = true;
    }

    public onDropdownClick(event): void {
        event.preventDefault();
        event.stopPropagation();
    }

    public onItemDownload(menuItem: MenuItemExtended): void {
        const repo: MediaObjectRepository = this.entityManager.getRepository(MediaObject);

        repo.download(menuItem.mediaObject, this.mediaObjectClassName).subscribe();

        event.preventDefault();
        event.stopPropagation();
    }

    public onItemRemove(menuItem: MenuItemExtended): void {
        const repo: MediaObjectRepository = this.entityManager.getRepository(MediaObject);

        repo.remove(menuItem.mediaObject, this.mediaObjectClassName).subscribe(() => {
            this.loadMedias().subscribe();
        });

        event.preventDefault();
        event.stopPropagation();
    }

    public onUpload(event: { files: File[] }): void {
        this.selectedFiles = event.files;

        if (this.autoUpload) {
            this.upload().subscribe(() => {
                this.isUploadVisible = false;
            });
        } else {
            this.isUploadVisible = false;
        }
    }

    public onClear(): void {
        this.isUploadVisible = false;
    }

    public onFileNameChange(event: any, index: number): void {
        this.changedFileNames[index] = event.target.value;
    }

    public loadMedias(): Observable<any> {
        const params = {
                mediaObjectClassName: this.mediaObjectClassName,
                mediaObjectOwnerId: this.mediaEntity.getId(),
                mediaObjectContext: this.mediaObjectContext
            },
            datePipe = new DatePipe();

        const repo: MediaObjectRepository = this.entityManager.getRepository(MediaObject);

        return repo.findMore(params)
            .pipe(
                map((mediaObjects: MediaObject[]) => {
                    this.items = [];

                    for (const mediaObject of mediaObjects) {
                        const createdAt = datePipe.transform(mediaObject.getCreatedAt());

                        this.items.push({
                            label: `${mediaObject.getName()} - ${createdAt}`,
                            icon: 'pi pi-file',
                            command: () => {
                                this.mediaItem = mediaObject;
                            },
                            mediaObject
                        });
                    }

                    this.cdr.detectChanges();

                    return mediaObjects;
                })
            );
    }

    public clearItems(): void {
        this.items = [];
    }
}
