import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {TableColumn} from '../../../shared/table-column';
import {AbstractFilter} from '../../../services/filter/abstract-filter';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TableComponent} from '../../../table.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-number-filter',
    styleUrls: ['./number-filter.component.scss'],
    templateUrl: './number-filter.component.html'
})
export class NumberFilterComponent implements OnInit {

    @Input() column: TableColumn;
    @Input() table: TableComponent = null;

    @Output() filterChange = new EventEmitter<any>();
    @Input() formatInput = false;

    public inputValue = null;
    public secondInputValue = null;

    public filter: AbstractFilter = null;

    public constructor(
        protected cdr: ChangeDetectorRef
    ) {

    }

    public ngOnInit(): void {
        this.filter = this.table.filter.getOrCreateFilter(this.column);

        if (typeof this.filter.getFilterValue() === 'string' && this.filter.getFilterValue().indexOf('..') !== -1) {
            this.inputValue = this.filter.getFilterValue().split('..')[0];
            this.secondInputValue = this.filter.getFilterValue().split('..')[1];
        } else {
            this.inputValue = this.filter.getFilterValue();
        }

        this.table.lazyLoad.subscribe(() => {
            this.filter = this.table.filter.getOrCreateFilter(this.column);

            if (this.filter.getFilterOperatorValue() !== 'between') {
                this.secondInputValue = null;
            }

            if (this.column.filter.operator && this.filter.getFilterOperatorValue() === null) {
                this.inputValue = null;
                this.secondInputValue = null;
            }

            this.cdr.detectChanges();
        });
    }

    public onValueChange(event): void {
        if (this.filter.getFilterOperatorValue() === 'between') {
            event.value = `${this.inputValue}..${this.secondInputValue}`;
        } else {
            this.secondInputValue = null;
        }

        this.filterChange.emit(event);
        this.cdr.detectChanges();
    }
}
