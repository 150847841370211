import {Route} from 'entity-manager';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {
    Employee,
    Education
} from './entities';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DatetimeWithoutTimezonePropertyPayloadModifier} from '../modifiers/property-payload-modifiers/datetime-without-timezone-property-payload-modifier';
import {PropertyResponseModifier} from '../../../../projects/entity-manager/src/lib/decorator/response-modifiers/property-response-modifier';
import {DatetimeWithoutTimezonePropertyResponseModifier} from '../modifiers/property-response-modifiers/datetime-without-timezone-property-response-modifier';

@Route('employee_educations')
@CrossDependency( () => {
    return {
        education: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Education
        },
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        }
    };
} )

export class EmployeeEducation {

    protected id: string;
    @AssociationOne(Employee)
    protected employee;

    @AssociationOne(Education)
    protected education: Education;

    protected name: string;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected startDate: Date;

    @PropertyPayloadModifier(DatetimeWithoutTimezonePropertyPayloadModifier)
    @PropertyResponseModifier(DatetimeWithoutTimezonePropertyResponseModifier)
    protected endDate: Date;

    protected medias: any;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setEducation(education: Education): this {
        this.education = education;
        return this;
    }

    public getEducation(): Education | undefined {
        return this.education;
    }

    public getEmployee(): Employee | undefined {
        return this.employee;
    }

    public setEmployee(employee: Employee): this {
        this.employee = employee;
        return this;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setStartDate(startDate: Date) {
        this.startDate = startDate;
        return this;
    }

    public getStartDate(): Date {
        return this.startDate;
    }

    public setEndDate(endDate: Date) {
        this.endDate = endDate;
        return this;
    }

    public getEndDate(): Date {
        return this.endDate;
    }

    public setMedias(medias: any) {
        this.medias = medias;
        return this;
    }

    public getMedias(): any {
        return this.medias;
    }
}
