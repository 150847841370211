import {Provider} from '@angular/core';
import {JsonParser} from './parser/json.parser';

export interface Configuration {
    loader: Provider;
}

export class BaseConfigurator implements EntityManagerConfigurator {

    public urlPrefix;
    public providers: any[];
    public parser: any = null;

    public constructor(urlPrefix: string, providers?: any[], parser?: any) {
        this.urlPrefix = urlPrefix;
        this.providers = providers;
        this.parser = parser || JsonParser;
    }
}

export class EntityManagerConfigurator {
    urlPrefix: string;
    providers: any[];
    parser: any;
}
